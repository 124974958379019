import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-keyways-to-a-successful-rpa-implementation',
  templateUrl: './keyways-to-a-successful-rpa-implementation.component.html',
  styleUrls: ['./keyways-to-a-successful-rpa-implementation.component.scss']
})
export class KeywaysToASuccessfulRPAImplementationComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | 8 keyways to a successful RPA implementation');
    this.seoService.updateOgTitle('Rapinno Tech Blog | 8 keyways to a successful RPA implementation');
    this.seoService.updateOgDescription('Robotic process automation can reap efficiencies and free up staff time for higher-value tasks. Here are eight proven ways how to do RPA right.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/8-keyways-to-a-successful-RPA-implementation');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/43.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | 8 keyways to a successful RPA implementation'),
    this.seoService.updateMetaDescription('Robotic process automation can reap efficiencies and free up staff time for higher-value tasks. Here are eight proven ways how to do RPA right.')
  }
}