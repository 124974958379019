<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Intelligent Automation</h1>
                    </div>
                    <p>
                        With deep domain expertise, the ability to co-create innovation with flexible experimentation, a large-scale speed-optimized deployment model, an ever-evolving partner ecosystem, and a unique talent development model, Rapinno Tech's AI and Automation
                        Services team works with clients to Navigate their AI & Automation journey.
                    </p>
                    <p>Our automation solutions will also help you meet your business process challenges cost-effectively, without risking your "large IT systems". This releases the value of existing platforms in a managed and controlled manner, ensuring
                        that intelligent automation is applied where it can bring optimal value to your business.
                    </p>
                    <p>
                        Rapinno Tech offers a level of knowledge and skills that allows your business to move from basic robotic process automation (RPA) to a more complex and intelligently automated environment.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/intelligent-automation.jpg" alt="Showcasing the usage of brain like power in business process
            ">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="service-heading-content-sec ai-automation-sec intelligent-automation-sec">
    <div class="wrapper">
        <div class="service_head">
            <h2>We at RapinnoTech have expertise in the areas of</h2>
        </div>
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="automation-offers">
                    <ul>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/mechine_learning.svg" alt="Line icon for machine learning">
                                <span>Machine Learning</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/natural_language.svg" alt="Line icon for natural language processing">
                                <span>Natural Language
                  Processing</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/speech_reognition.svg" alt="Line icon for speech recognition">
                                <span>Speech Recognition</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/virtual_agents.svg" alt="Line icon for virtual agents">
                                <span>Virtual Agents</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/image_processing.svg" alt="Line icon for image processing">
                                <span>Image Processing</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/knowledge_virtualization.svg" alt="Line icon for knowledge virtualization">
                                <span>Knowledge Virtualization</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/robotic_process.svg" alt="Line icon for robotuc process automation">
                                <span>Robotic Process
                  Automation</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/decision_management.svg" alt="Line icon for decision management">
                                <span>Decision Management</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Intelligent Automation</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>
<section class="service-heading-content-sec ai-automation-sec intelligent-automation-sec">
    <div class="wrapper">
        <div class="service_head">
            <h4>Our Key Services Include</h4>
        </div>
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="automation-offers">
                    <ul>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/ai_strategy.svg" alt="Icon for Ai startegy & consultancing">
                                <span>AI Strategy and Consulting</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/ai_framework.svg" alt="Icon for Ai framework development">
                                <span>AI Framework Development</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/ai_framework_customization.svg" alt="Icon for Ai framework development">
                                <span>AI Framework Customization</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/ai_implementation.svg" alt="Icon for Ai implementation">
                                <span>AI Implementation</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/ai_framework_migration.svg" alt="Icon for Ai framework migration">
                                <span>AI Framework Migration</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/ai_integration.svg" alt="Icon for Ai integration ">
                                <span>AI Integration</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/intelligent_automation/robotic_process.svg" alt="Icon for Ai framework maintenance">
                                <span>AI Framework Maintenance</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>