import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DmsBrochureComponent } from '../../components/dms-brochure/dms-brochure.component';

@Component({
  selector: 'app-dms',
  templateUrl: './dms.component.html',
  styleUrls: ['./dms.component.scss']
})
export class DmsComponent implements OnInit {

  constructor(

    private _dialog: MatDialog,
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  dmsBrochure(): void {
    this._dialog.open(DmsBrochureComponent, {
      width: '50%',
      panelClass: 'custom-modal',
      disableClose: true
    });
  }


  setSeo(): void {
    this.seoService.updatePageTitle('DMS | RDP | Rapinno Tech');
    this.seoService.updateOgTitle('DMS | RDP | Rapinno Tech');
    this.seoService.updateOgDescription('With Rapinno Tech\'s document management system(DMS), you will be offered an integrated system where you can find all the essential file & folder management, advanced search facility & collaboration options in one system.');
    this.seoService.updateOgType('RDP - Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/rapinno-digital-platform/dms');
    this.seoService.updateMetaTitle('DMS | RDP | Rapinno Tech'),
    this.seoService.updateMetaDescription('With Rapinno Tech\'s document management system(DMS), you will be offered an integrated system where you can find all the essential file & folder management, advanced search facility & collaboration options in one system.')
  }
}
