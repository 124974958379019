import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';
@Component({
  selector: 'app-ai-and-iot-blended',
  templateUrl: './ai-and-iot-blended.component.html',
  styleUrls: ['./ai-and-iot-blended.component.scss']
})
export class AiAndIotBlendedComponent implements OnInit {
  constructor(
    private seoService: SEOService
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | AI and IoT Blended in Future Technology - What It Is and Why It Matters?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | AI and IoT Blended in Future Technology - What It Is and Why It Matters?');
    this.seoService.updateOgDescription(' Artificial Intelligence and IoT will play a vital role in the near future. Blending AI with IoT helps organizations comprehend and predict a wide range of risks and automate for the prompt response. ');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/why-your-small-business-requires-a-document-management-system');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/18.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | AI and IoT Blended in Future Technology - What It Is and Why It Matters?'),
    this.seoService.updateMetaDescription(' Artificial Intelligence and IoT will play a vital role in the near future. Blending AI with IoT helps organizations comprehend and predict a wide range of risks and automate for the prompt response. ')
  }
}

