<section class="master_rapino_sec">
  <!-- SERVICE-BANNER-SEC -->
  <section class="service-intro-sec">
    <div class="banner-dots left">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
      <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
        fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <div fxLayout="column" fxFlex="60" fxFlexFill>
          <div class="intro-content">
            <div class="section-heading">
              <h1 class="main-heading">Rapinno Digital platform</h1>
            </div>
            <p>
              Technological requirements often emerge suddenly, without providing any prior clues. We understand the
              importance of this urgency faced by companies of all sizes and types. Our rapid app development services
              are designed to meet the urgent needs of customers.
            </p>
            <p>
              Guided by a proven rapid application development model, we have created thousands of software in the
              shortest possible time. Whether you are in urgent need of a mobile app, website, online store or chatbot,
              we are ready to serve you.
            </p>
            <p>
              Guided by a proven rapid application development model, we have created thousands of software in the
              shortest possible time. Whether you are in urgent need of a mobile app, website, online store or chatbot,
              we are ready to serve you.
            </p>
          </div>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlexFill>
          <div class="intro-img">
            <img class="raduis-img" src="../../../../../assets/images/service-banner/rapinno-digital-platform.jpg"
              alt="img">
            <!-- <img class="raduis-img" src="../../../../../assets/test-bn.svg" alt="img"> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- engines_sec -->
  <section class="engines_sec" id="scrollEngine">
    <div class="wrapper">
      <div class="global_heading service_head  text-center mb-20">
        <h2>Engines / API's</h2>
        <p>With powerful pre-designed engines, we assure rapid software development</p>
      </div>
      <div class="engines_listing">
        <ul>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/1.svg" alt="img">
              <h4>
                Custom ecommerce solutions development
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/2.svg" alt="img">
              <h4>
                User profiles
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/3.svg" alt="img">
              <h4>
                Sell or Rent a property
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/4.svg" alt="img">
              <h4>
                Multi-Vendors
                management
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/5.svg" alt="img">
              <h4>
                Multi language support
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/6.svg" alt="img">
              <h4>
                Multi currency support
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/7.svg" alt="img">
              <h4>
                Book a property visit
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/8.svg" alt="img">
              <h4>
                payment gateway integration
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/9.svg" alt="img">
              <h4>
                Coupon management
                system
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/10.svg" alt="img">
              <h4>
                Orders management
              </h4>
            </div>
          </li>
          <!-- <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/11.svg" alt="img">
              <h4>
                service
              </h4>
            </div>
          </li> -->
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/12.svg" alt="img">
              <h4>
                Catalogue Management
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/13.svg" alt="img">
              <h4>
                Notifications & alerts
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/14.svg" alt="img">
              <h4>
                News & Articles
              </h4>
            </div>
          </li>
          <!-- <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/15.svg" alt="img">
              <h4>
                service
              </h4>
            </div>
          </li> -->
          <!-- <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/16.svg" alt="img">
              <h4>
                service
              </h4>
            </div>
          </li> -->
          <!-- <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/17.svg" alt="img">
              <h4>
                service
              </h4>
            </div>
          </li> -->
          <!-- <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/18.svg" alt="img">
              <h4>
                service
              </h4>
            </div>
          </li> -->
          <!-- <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/20.svg" alt="img">
              <h4>
                Orders Management
              </h4>
            </div>
          </li> -->
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/21.svg" alt="img">
              <h4>wishlist
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/22.svg" alt="img">
              <h4>
                Push Notifications
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/23.svg" alt="img">
              <h4>
                Filters
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/24.svg" alt="img">
              <h4>
                Properties listing
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/25.svg" alt="img">
              <h4>
                Join online events
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/26.svg" alt="img">
              <h4>
                Sales trends
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/27.svg" alt="img">
              <h4>
                Heat maps
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/28.svg" alt="img">
              <h4>
                Users Management
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/29.svg" alt="img">
              <h4>
                Agents Management
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/30.svg" alt="img">
              <h4>
                compatibility questionnaire
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/19.svg" alt="img">
              <h4>
                Inventory management
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/31.svg" alt="img">
              <h4>
                Support chat
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/32.svg" alt="img">
              <h4>
                Reports & Analytics
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/33.svg" alt="img">
              <h4>
                Content management
                system
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/34.svg" alt="img">
              <h4>
                Google analytics
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/35.svg" alt="img">
              <h4>
                Horoscope Compatibility
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/36.svg" alt="img">
              <h4>
                Express interest
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/37.svg" alt="img">
              <h4>
                Membership Packages
              </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/1.svg" alt="">
              <h4>Document input</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/2.svg" alt="">
              <h4>Document indexing</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/3.svg" alt="">
              <h4>Robust Document search</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/4.svg" alt="">
              <h4>Document processing</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/5.svg" alt="">
              <h4>Workflow automation</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/6.svg" alt="">
              <h4>Coupon management
                system</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/7.svg" alt="">
              <h4>Customization</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/8.svg" alt="">
              <h4>Embedded viewer</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/9.svg" alt="">
              <h4>Embedded viewer</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/10.svg" alt="">
              <h4>versioning control </h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/11.svg" alt="">
              <h4>Permissions</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/12.svg" alt="">
              <h4>Universal format
                support</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/dms/13.svg" alt="">
              <h4>On-site & cloud
                deployments</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/38.svg" alt="">
              <h4>GPS vehicle tracking</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/39.svg" alt="">
              <h4>Route planning and monitoring</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/40.svg" alt="">
              <h4>Driver identification</h4>
            </div>
          </li>
          <li>
            <div class="box">
              <img src="../../../../../assets/images/rapinno-digital-platform/icons/41.svg" alt="">
              <h4>Log sheet</h4>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</section>
