import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-development',
  templateUrl: './product-development.component.html',
  styleUrls: ['./product-development.component.scss']
})
export class ProductDevelopmentComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Innovative Product Development | Rapinno Tech');
    this.seoService.updateOgTitle('Innovative Product Development | Rapinno Tech');
    this.seoService.updateOgDescription('Our proven cross-industry expertise enables us to identify innovative strategies and processes geared towards generating customer benefits, ensuring profitable products, and driving growth.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/innovative-product-development');
    this.seoService.updateMetaTitle('Innovative Product Development | Rapinno Tech'),
    this.seoService.updateMetaDescription('Our proven cross-industry expertise enables us to identify innovative strategies and processes geared towards generating customer benefits, ensuring profitable products, and driving growth.')
  }
}
