import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-why-your-small-business-requires-blog',
  templateUrl: './why-your-small-business-requires-blog.component.html',
  styleUrls: ['./why-your-small-business-requires-blog.component.scss']
})
export class WhyYourSmallBusinessRequiresBlogComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Why Your Small Business Requires a Document Management System?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Why Your Small Business Requires a Document Management System?');
    this.seoService.updateOgDescription('Rapinno Tech Blog | Why Your Small Business Requires a Document Management System?');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/why-your-small-business-requires-a-document-management-system');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/15.webp');
    this.seoService.updateMetaTitle(' How blockchain ensures security for the banking industry? '),
    this.seoService.updateMetaDescription('Rapinno Tech Blog | Why Your Small Business Requires a Document Management System?')
  }
}
