<!-- WEB0-MENU -->
<nav id="nav" class="master-navbar-section secondToolbar">
    <div class="main-top-navbar" [ngClass]="{ sticky: isSticky, 'inner-header': !router.isActive('/index', true) }">
        <!-- ------------------------ fxShow.lt-sm="false" ------------------------- -->
        <div class="header-main">
            <div class="wrapper">
                <div class="header-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
                    <div fxLayout="column" fxFlex.xl="21" fxFlex.lg="21" fxFlex.md="21" fxFlex.sm="100%" fxFlex.xs="100" fxFlexFill>
                        <div>
                            <div class="main-logo">
                                <!-- <img [routerLink]="['/index']"
                routerLinkActive="router-link-active" src="../../../../../assets/images/logo.svg" alt="" /> -->
                                <img [routerLink]="['/']" routerLinkActive="router-link-active" src="../../../../../assets/images/logo.svg" alt="" />
                                <!-- <img *ngIf="!router.isActive('/index', false)" [routerLink]="['/index']"
                  routerLinkActive="router-link-active" src="../../../../../assets/images/footer-logo.svg" alt="" /> -->
                                <!-- <span fxShow.lt-sm="true" fxShow.sm="true" fxShow="false">
                  <button class="toggle-btn menu-button" (click)="toggleMenu()" [ngClass]="{'open': opened}">
                    <div class="menu-lines">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </button>
                </span> -->
                            </div>
                        </div>
                    </div>
                    <div fxShow.lt-sm="false" fxShow.lt-md="false" fxLayout="column" fxFlex.xl="79" fxFlex.lg="79" fxFlex.md="79" fxFlex.sm="100%" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                        <ul class="header-bottom-navbar">
                            <!-- <li [routerLink]="['/index']" routerLinkActive="router-link-active"> Home</li> -->
                            <!-- About Us -->
                            <li><a [routerLink]="['/about-us']" routerLinkActive="router-link-active">About Us</a></li>
                            <!-- Services -->
                            <li appHoverClass="bottom-mega-menu-show" [matMenuTriggerFor]="serviceMenu">
                                <a>Services <i class="material-icons">keyboard_arrow_down</i></a>
                                <mat-menu #serviceMenu="matMenu" class="nav_dropdown_menu">
                                    <button mat-menu-item [matMenuTriggerFor]="subServiceOne">Application Engineering <i
                                            class="material-icons">
                                            keyboard_arrow_right
                                        </i></button>
                                    <button mat-menu-item [matMenuTriggerFor]="subServiceTwo">NextGen Tech <i
                                            class="material-icons">
                                            keyboard_arrow_right
                                        </i></button>
                                    <button mat-menu-item [matMenuTriggerFor]="subServiceThree">Business Process
                                        Engineering <i class="material-icons">
                                            keyboard_arrow_right
                                        </i></button>
                                    <button [routerLink]="['/security-services']" routerLinkActive="router-link-active" mat-menu-item>
                                        Security Services</button>
                                </mat-menu>
                                <mat-menu #subServiceOne="matMenu" class="sub_menu_services">
                                    <button [routerLink]="['/rapid-software-development']" routerLinkActive="router-link-active" mat-menu-item>Rapid
                                        Software Development</button>
                                    <button [routerLink]="['/innovative-product-development']" routerLinkActive="router-link-active" mat-menu-item>
                                        Innovative Product Development </button>
                                    <button [routerLink]="['/full-stack-development']" routerLinkActive="router-link-active" mat-menu-item>Full Stack
                                        Development</button>
                                    <button [routerLink]="['/software-testing']" routerLinkActive="router-link-active" mat-menu-item>Software
                                        Testing
                                    </button>
                                    <button [routerLink]="['/tech-innovation-consulting']" routerLinkActive="router-link-active" mat-menu-item>Tech
                                        Innovation Consulting</button>
                                    <button [routerLink]="['/big-data']" routerLinkActive="router-link-active" mat-menu-item>Big
                                        Data</button>
                                    <!-- <button [routerLink]="['/it-recruitment']" routerLinkActive="router-link-active" mat-menu-item>IT
                    Recruitment</button> -->
                                </mat-menu>
                                <mat-menu #subServiceTwo="matMenu" class="sub_menu_services">
                                    <!-- <button [routerLink]="['/adaptive-sourcing']" routerLinkActive="router-link-active"
                    mat-menu-item>Adaptive Sourcing</button> -->
                                    <!-- <button [routerLink]="['/our-services/ai-&-automation']" routerLinkActive="router-link-active"
                    mat-menu-item>AI And
                    Automation</button> -->
                                    <!-- <button [routerLink]="['/intelligent-automation']" routerLinkActive="router-link-active" mat-menu-item>Intelligent
                                        Automation</button> -->
                                    <button [routerLink]="['/robotic-process-automation']" routerLinkActive="router-link-active" mat-menu-item>Robotic Process
                                        Automation</button>
                                    <button [routerLink]="['/blockchain']" routerLinkActive="router-link-active" mat-menu-item>Blockchain</button>
                                    <button [routerLink]="['/cloud-tech-and-services']" routerLinkActive="router-link-active" mat-menu-item>Cloud Technologies &
                                        Services
                                    </button>
                                    <button [routerLink]="['/internet-of-things']" routerLinkActive="router-link-active" mat-menu-item>Internet Of Things</button>
                                    <!-- <button [routerLink]="['/our-services/adaptive-sourcing']" routerLinkActive="router-link-active"
                    mat-menu-item>Adaptive Sourcing</button> -->
                                </mat-menu>
                                <mat-menu #subServiceThree="matMenu" class="sub_menu_services">
                                    <button [routerLink]="['/enterprise-resource-planning']" routerLinkActive="router-link-active" mat-menu-item>Enterprise Resource
                                        Planning</button>
                                    <button [routerLink]="['/human-capital-management']" routerLinkActive="router-link-active" mat-menu-item>Human Capital
                                        Management</button>
                                    <!-- <button [routerLink]="['/our-services/integrated-marketing-service']"
                    routerLinkActive="router-link-active" mat-menu-item>Integrated Marketing Service</button> -->
                                    <!-- <button [routerLink]="['/our-service/crm']" routerLinkActive="router-link-active"
                    mat-menu-item>Customer
                    Relationship Management</button>
                  <button [routerLink]="['/our-services/digital-marketing']" routerLinkActive="router-link-active"
                    mat-menu-item>Digital Marketing</button>
                  <button [routerLink]="['/our-services/sales-service']" routerLinkActive="router-link-active"
                    mat-menu-item>Sales as
                    a Service</button> -->
                                </mat-menu>
                            </li>
                            <!-- <li>
                <a [routerLink]="['/rapinno-digital-platform']" routerLinkActive="router-link-active">Rapinno Digital
                  Solution</a>
              </li> -->
                            <li appHoverClass="bottom-mega-menu-show" [matMenuTriggerFor]="digitalMenu">
                                <a>Solutions<i class="material-icons">
                                        keyboard_arrow_down
                                    </i>
                                </a>
                                <mat-menu #digitalMenu="matMenu" class="nav_dropdown_menu">
                                    <button [routerLink]="['/rapinno-digital-platform']" routerLinkActive="router-link-active" mat-menu-item>Rapinno Digital
                                        Platform</button>
                                    <button [routerLink]="['/rapinno-product-360']" routerLinkActive="router-link-active" mat-menu-item>Rapinno Product 360</button>
                                    <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapinno-ia-eco-system']" routerLinkActive="router-link-active" mat-menu-item>Rapinno IA Ecosystem</button>
                                    <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapinno-managed-services']" routerLinkActive="router-link-active" mat-menu-item>Rapinno Managed
                                        Services</button>

                                    <button [routerLink]="['/rapinno-marketing-360']" routerLinkActive="router-link-active" mat-menu-item>Rapinno Marketing
                                        360</button>
                                    <button [routerLink]="['/rapinno-data-digitization']" routerLinkActive="router-link-active" mat-menu-item>Rapinno Data
                                        Digitization</button>


                                    <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/adaptive-sourcing']" routerLinkActive="router-link-active" mat-menu-item>Rapinno Adaptive Sourcing</button>
                                    <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapicon-advanced-planning-system']" routerLinkActive="router-link-active" mat-menu-item>RapiCon APS
                                    </button>
                                </mat-menu>
                            </li>
                            <!-- <li appHoverClass="bottom-mega-menu-show" [matMenuTriggerFor]="digitalMenu">
                Rapinno Digital Platform <i class="material-icons">
                  keyboard_arrow_down
                </i>
                <mat-menu #digitalMenu="matMenu" class="nav_dropdown_menu">
                  <button [routerLink]="['/application-development']" routerLinkActive="router-link-active"
                    mat-menu-item>Rapid Application Development</button>
                  <button [routerLink]="['/customized-application-development']" routerLinkActive="router-link-active"
                    mat-menu-item>Customized Application
                    Development</button>
                </mat-menu>
              </li> -->
                            <li appHoverClass="bottom-mega-menu-show" [matMenuTriggerFor]="resourceMenu">
                                <a>Resources
                                    <i class="material-icons">keyboard_arrow_down</i></a>
                                <mat-menu #resourceMenu="matMenu" class="nav_dropdown_menu">
                                    <button [routerLink]="['/blogs']" routerLinkActive="router-link-active" mat-menu-item>Blogs</button>
                                    <button [routerLink]="['/case-studies']" mat-menu-item>Case Studies &
                                        WhitePaper</button>
                                    <button [routerLink]="['/videos']" mat-menu-item>Videos</button>
                                </mat-menu>
                            </li>
                            <li>
                                <a [routerLink]="['/career']" routerLinkActive="router-link-active">Career</a>
                            </li>
                            <!-- <li><a>Our Partners</a></li> -->
                            <!-- <li> Our Partners </li> -->
                            <li><a [routerLink]="['/contact']" routerLinkActive="router-link-active">Contact Us</a>
                            </li>
                            <!-- <li [routerLink]="['']" routerLinkActive="router-link-active">
                <span class="chat-icon"><img src="../../../../../assets/images/icons/icons_messaging.svg" alt=""
                     > </span>
              </li> -->
                            <!-- <li>Contact </li>
              <li> Latest News </li>
              <li> E-Governence</li> -->
                            <!--  <li>
                <button class="global-btn" mat-button [matMenuTriggerFor]="menu">
                  <img src="../../../../../assets/images/icons/expand_arrow.svg" alt=""  > LNG
                </button>
                <mat-menu class="lng-drop-down" #menu="matMenu">
                  <button mat-menu-item>TEL</button>
                  <button mat-menu-item>ENG</button>
                </mat-menu></li> -->
                            <!-- <li>
                <button (click)="selectLocation()" class="global-btn" mat-button>
                  <img src="../../../../../assets/images/icons/pin.svg" alt=""  >
                  Select Location</button>
              </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
<!-- MOBILE-MENU -->
<div class="top-navbar mobile_menu mobile-top-menu-show" fxFlex.gt-md="false">
    <div class="" fxLayout="column" fxFlex="100" fxFlexFill>
        <div class="mobile-navbar-sec" [ngClass]="{ sticky: isSticky, 'inner-header': !router.isActive('/index', true) }">
            <div class="mobile-logo-box" [routerLink]="['/index']" routerLinkActive="router-link-active">
                <!-- <img *ngIf="router.isActive('/index', true)" [routerLink]="['/index']" routerLinkActive="router-link-active"
          src="../../../../../assets/images/logo.svg" alt="" />
        <img *ngIf="!router.isActive('/index', false)" [routerLink]="['/index']" routerLinkActive="router-link-active"
          src="../../../../../assets/images/footer-logo.svg" alt="" /> -->
                <img [routerLink]="['/']" routerLinkActive="router-link-active" src="../../../../../assets/images/logo.svg" alt="" />
            </div>
            <div class="mobile-accordion">
                <mat-accordion>
                    <mat-expansion-panel [(expanded)]="xpandStatus">
                        <mat-expansion-panel-header class="toggler" [collapsedHeight]="customNavCollapsedHeight" [expandedHeight]="customNavExpandedHeight">
                            <mat-panel-title>
                                <div class="mobile-toolbar-toggler">
                                    <span class=""></span>
                                    <span class=""></span>
                                    <span class=""></span>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="top-right-nav">

                            <!-- Home -->
                            <!-- <li>
                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/index']" routerLinkActive="router-link-active">
                                    Home
                                </a>
                            </li> -->


                            <!-- About Us -->
                            <li>
                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/about-us']" routerLinkActive="router-link-active">
                                    About Us
                                </a>
                            </li>
                            <!-- Services -->
                            <li class="menu-icons is-dropdown-submenu-parent" (click)="listNone = true">
                                <!-- Services -->
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header [collapsedHeight]="customNavCollapsedHeight" [expandedHeight]="customNavExpandedHeight">
                                        <a>
                                            <span class="parent-drop">Services </span>
                                            <span class="inner-dropdown">
                                                <img src="../../../../../assets/images/icons/down-arrow.svg" alt="img">
                                            </span>
                                        </a>
                                    </mat-expansion-panel-header>
                                    <div class="outer-dropdown-ul">
                                        <ul class="menu-list">
                                            <!-- Application Engineering -->
                                            <li class="parent-list-li">
                                                <mat-expansion-panel [expanded]="panelOpenState === 'Application'" (opened)="panelOpenState = 'Application'">
                                                    <mat-expansion-panel-header [collapsedHeight]="customNavCollapsedHeight" [expandedHeight]="customNavExpandedHeight">
                                                        <a>
                                                            <span class="parent-drop">Application Engineering</span>
                                                            <span class="inner-dropdown">
                                                                <img src="../../../../../assets/images/icons/down-arrow.svg"
                                                                    alt="img">
                                                            </span>
                                                        </a>
                                                    </mat-expansion-panel-header>
                                                    <div class="inner-dropdown-ul">
                                                        <ul>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapid-software-development']" routerLinkActive="router-link-active" mat-menu-item>
                                                                Rapid Software Development</button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/innovative-product-development']" routerLinkActive="router-link-active" mat-menu-item>Innovative Product Development</button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/full-stack-development']" routerLinkActive="router-link-active" mat-menu-item>Full
                                                                Stack Development</button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/software-testing']" routerLinkActive="router-link-active" mat-menu-item>Software Testing
                                                            </button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/tech-innovation-consulting']" routerLinkActive="router-link-active" mat-menu-item>Tech
                                                                Innovation Consulting
                                                            </button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/big-data']" routerLinkActive="router-link-active" mat-menu-item>Big
                                                                Data
                                                            </button>
                                                            <!-- <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/it-recruitment']"
                                routerLinkActive="router-link-active" mat-menu-item>IT
                                Recruitment</button> -->
                                                        </ul>
                                                    </div>
                                                </mat-expansion-panel>
                                            </li>
                                            <!-- NextGen Tech -->
                                            <li class="parent-list-li">
                                                <mat-expansion-panel [expanded]="panelOpenState === 'NextGen'" (opened)="panelOpenState = 'NextGen'">
                                                    <mat-expansion-panel-header [collapsedHeight]="customNavCollapsedHeight" [expandedHeight]="customNavExpandedHeight">
                                                        <a>
                                                            <span class="parent-drop">NextGen Tech</span>
                                                            <span class="inner-dropdown">
                                                                <img src="../../../../../assets/images/icons/down-arrow.svg"
                                                                    alt="img">
                                                            </span>
                                                        </a>
                                                    </mat-expansion-panel-header>
                                                    <div class="inner-dropdown-ul">
                                                        <ul>
                                                            <!-- <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/adaptive-sourcing']"
                                routerLinkActive="router-link-active" mat-menu-item>Adaptive Sourcing</button> -->
                                                            <!-- <button (click)='xpandStatus=xpandStatus?false:true'
                                [routerLink]="['/our-services/ai-&-automation']" routerLinkActive="router-link-active"
                                mat-menu-item>AI And
                                Automation</button> -->
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/intelligent-automation']" routerLinkActive="router-link-active" mat-menu-item>Intelligent Automation</button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/robotic-process-automation']" routerLinkActive="router-link-active" mat-menu-item>Robotic Process
                                                                Automation</button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/blockchain']" routerLinkActive="router-link-active" mat-menu-item>Blockchain</button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/cloud-tech-and-services']" routerLinkActive="router-link-active" mat-menu-item>Cloud Technologies &
                                                                Services</button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/internet-of-things']" routerLinkActive="router-link-active" mat-menu-item>Internet Of Things</button>
                                                        </ul>
                                                    </div>
                                                </mat-expansion-panel>
                                            </li>
                                            <!-- Business Process Engineering -->
                                            <li class="parent-list-li">
                                                <mat-expansion-panel [expanded]="panelOpenState === 'Business'" (opened)="panelOpenState = 'Business'">
                                                    <mat-expansion-panel-header [collapsedHeight]="customNavCollapsedHeight" [expandedHeight]="customNavExpandedHeight">
                                                        <a>
                                                            <span class="parent-drop">Business Process
                                                                Engineering</span>
                                                            <span class="inner-dropdown">
                                                                <img src="../../../../../assets/images/icons/down-arrow.svg"
                                                                    alt="img">
                                                            </span>
                                                        </a>
                                                    </mat-expansion-panel-header>
                                                    <div class="inner-dropdown-ul">
                                                        <ul>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/enterprise-resource-planning']" routerLinkActive="router-link-active" mat-menu-item>Enterprise Resource
                                                                Planning</button>
                                                            <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/human-capital-management']" routerLinkActive="router-link-active" mat-menu-item>Human Capital Management</button>
                                                            <!-- <button (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/our-service/crm']"
                                routerLinkActive="router-link-active" mat-menu-item>Customer
                                Relationship Management</button>
                              <button (click)='xpandStatus=xpandStatus?false:true'
                                [routerLink]="['/our-services/digital-marketing']" routerLinkActive="router-link-active"
                                mat-menu-item>Digital Marketing</button>
                              <button (click)='xpandStatus=xpandStatus?false:true'
                                [routerLink]="['/our-services/sales-service']" routerLinkActive="router-link-active"
                                mat-menu-item>Sales as
                                a Service</button> -->
                                                        </ul>
                                                    </div>
                                                </mat-expansion-panel>
                                            </li>
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/security-services']" routerLinkActive="router-link-active">Security Services</a>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-expansion-panel>
                            </li>
                            <!-- Rapinno Digital Platform -->
                            <!-- <li>
                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapinno-digital-platform']"
                  routerLinkActive="router-link-active" (click)="toggleMenu()">
                  Rapinno Digital Platform
                </a>
              </li> -->
                            <li class="menu-icons is-dropdown-submenu-parent" (click)="listNone = true">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header [collapsedHeight]="customNavCollapsedHeight" [expandedHeight]="customNavExpandedHeight">
                                        <a>
                                            <span class="parent-drop">Solutions</span>
                                            <span class="inner-dropdown">
                                                <img src="../../../../../assets/images/icons/down-arrow.svg" alt="img">
                                            </span>
                                        </a>
                                    </mat-expansion-panel-header>
                                    <div class="outer-dropdown-ul">
                                        <ul class="menu-list">
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapinno-digital-platform']" routerLinkActive="router-link-active">Rapinno Digital Platform</a>
                                            </li>
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapinno-product-360']" routerLinkActive="router-link-active">Rapinno Product 360</a>
                                            </li>
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapinno-ia-eco-system']" routerLinkActive="router-link-active">Rapinno IA Ecosystem</a>
                                            </li>
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapinno-managed-services']" routerLinkActive="router-link-active">Rapinno Managed Services</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/rapinno-marketing-360']" (click)='xpandStatus=xpandStatus?false:true' routerLinkActive="router-link-active">Rapinno
                                                    Marketing 360</a>
                                            </li>
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapinno-data-digitization']" routerLinkActive="router-link-active">Rapinno Data
                                                    Digitization</a>
                                            </li>
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/adaptive-sourcing']" routerLinkActive="router-link-active">Rapinno Adaptive Sourcing</a>
                                            </li>
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/rapicon-advanced-planning-system']" routerLinkActive="router-link-active">RapiCon APS</a>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-expansion-panel>
                            </li>
                            <!-- Resources -->
                            <li class="menu-icons is-dropdown-submenu-parent" (click)="listNone = true">
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header [collapsedHeight]="customNavCollapsedHeight" [expandedHeight]="customNavExpandedHeight">
                                        <a>
                                            <span class="parent-drop">Resources</span>
                                            <span class="inner-dropdown">
                                                <img src="../../../../../assets/images/icons/down-arrow.svg" alt="img">
                                            </span>
                                        </a>
                                    </mat-expansion-panel-header>
                                    <div class="outer-dropdown-ul">
                                        <ul class="menu-list">
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/blogs']" routerLinkActive="router-link-active">Blogs</a>
                                            </li>
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/case-studies']" routerLinkActive="router-link-active">Case Studies & WhitePaper</a>
                                            </li>
                                            <li>
                                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/videos']" routerLinkActive="router-link-active">Videos</a>
                                            </li>
                                        </ul>
                                    </div>
                                </mat-expansion-panel>
                            </li>
                            <!-- Career -->
                            <li>
                                <a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/career']" routerLinkActive="router-link-active" (click)="toggleMenu()">Career</a>
                            </li>

                            <li><a (click)='xpandStatus=xpandStatus?false:true' [routerLink]="['/contact']" routerLinkActive="router-link-active">Contact Us</a> </li>
                            <!-- Our Partners -->
                            <!-- <li (click)='xpandStatus=xpandStatus?false:true'>
                <a>Our Partners</a></li> -->
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>
<span (click)="scrollTop()" class="scroll-top" [ngClass]="{ 'scroll-top-in': isScrollTop }">
    <i class="material-icons"> keyboard_arrow_up</i>
</span>