import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-human-management',
  templateUrl: './human-management.component.html',
  styleUrls: ['./human-management.component.scss']
})
export class HumanManagementComponent implements OnInit {
  constructor(
    private seoService: SEOService
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
    setSeo(): void {
      this.seoService.updatePageTitle('Human Capital Management | Rapinno Tech');
      this.seoService.updateOgTitle('Human Capital Management | Rapinno Tech');
      this.seoService.updateOgDescription('To manage human capital Rappino Tech provides various services like payroll management, talent acquisition, HR helpdesk, etc. ');
      this.seoService.updateOgType('Service Page');
      this.seoService.updateOgUrl('https://www.rapinnotech.com/human-capital-management');
      this.seoService.updateMetaTitle('Human Capital Management | Rapinno Tech'),
      this.seoService.updateMetaDescription('To manage human capital Rappino Tech provides various services like payroll management, talent acquisition, HR helpdesk, etc. ')
    }
}
