import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-blockchain-transform-ecommerce',
  templateUrl: './blockchain-transform-ecommerce.component.html',
  styleUrls: ['./blockchain-transform-ecommerce.component.scss']
})
export class BlockchainTransformEcommerceComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('How can blockchain transform E-commerce?');
    this.seoService.updateOgTitle('How can blockchain transform E-commerce?');
    this.seoService.updateOgDescription('Let’s quickly understand some of the key takeaways that E-Commerce can derive using blockchain technology.');
    this.seoService.updateOgType('Blog Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/How-can-blockchain-transform-the-E-commerce-segment');
    this.seoService.updateMetaTitle('How can blockchain transform E-commerce?'),
    this.seoService.updateMetaDescription('Let’s quickly understand some of the key takeaways that E-Commerce can derive using blockchain technology.')
  }
}
