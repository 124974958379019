import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-mobile-banking-adoption',
  templateUrl: './mobile-banking-adoption.component.html',
  styleUrls: ['./mobile-banking-adoption.component.scss']
})
export class MobileBankingAdoptionComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
  }
  setSeo(): void {
    this.seoService.updatePageTitle('RapinnoTech Blog | How Your Mobile Bank App Can Be a Source of Extra Revenue?');
    this.seoService.updateOgTitle('RapinnoTech Blog | How Your Mobile Bank App Can Be a Source of Extra Revenue?');
    this.seoService.updateOgDescription('Why Should Every Bank Have a Banking Application? Is it beneficial for achieving the revenue?');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/8.webp');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/How_Your_Mobile_Bank_App_Can_Be_a_Source_of_Extra_Revenue?');
    this.seoService.updateMetaTitle('RapinnoTech Blog | How Your Mobile Bank App Can Be a Source of Extra Revenue?'),
    this.seoService.updateMetaDescription('Why Should Every Bank Have a Banking Application? Is it beneficial for generating the revenue?')
  }
}
