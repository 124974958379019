import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-in-bfsi-is-no-longer-a-technology',
  templateUrl: './digital-in-bfsi-is-no-longer-a-technology.component.html',
  styleUrls: ['./digital-in-bfsi-is-no-longer-a-technology.component.scss']
})
export class DigitalInBfsiIsNoLongerATechnologyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
