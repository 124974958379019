<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Rapinno Marketing 360</h1>
                    </div>
                    <p>
                        Rapinno Tech's integrated marketing system is an approach to brand communication. The different marketing modules will create a seamless and hassle-free experience for the customer and are presented similarly that reinforce the message center of the brand.
                    </p>
                    <p>
                        Our goal is to make all aspects of marketing communication such as advertising, customer relation management, sales promotion, public relations, direct marketing, personal sales, online communications, and social media work as one extensive system with
                        integrated cogwheels and screws that turns to maximize profitability. We will design a holistic plan that will dovetail into your business model.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/integrated_marketing_service.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec">
    <div class="wrapper">
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-right-img">
                    <img src="../../../../assets/images/integrated-marketing service/1.png">
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-left-content">
                    <div>
                        <div class="left-head">
                            <h4>CRM</h4>
                            <p>We know that selling can be difficult. But with just one comprehensive tool like Rapinno Tech's CRM, you can:</p>
                        </div>
                        <div class="service_ul">
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Discover the best leads
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Boost contextual engagement
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Manage multiple pipelines
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Drive agreements to close, and
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Nurture existing prospects and customers
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="xs-col-rev service_row mt-20" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                <div class="service-left-content">
                    <div>
                        <div class="left-head">
                            <h3>Digital Marketing</h3>
                            <p>We understand the need and urgency of every firm to get heard and understood completely at the right time and at the right place is paramount. That's why our team of experienced and creative digital marketing team will find
                                the pulse of your business model and the heart of your target audience and target them in the most efficient way ever.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxFlexFill fxLayoutAlign="center end">
                <div class="service-right-img">
                    <img src="../../../../assets/images/integrated-marketing service/2.png">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Integrated Marketing Service</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>
<section class="service-heading-content-sec">
    <div class="wrapper">

        <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxFlexFill fxLayoutAlign="start start">
                <div class="service-right-img">
                    <img src="../../../../assets/images/integrated-marketing service/inside_sales.svg">
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                <div class="service-left-content">
                    <div>
                        <div class="left-head">
                            <h3>Inside sale</h3>
                            <p>RapinnoTech discovers your business prospects and determines their needs through our cold calling and email marketing. For effective results, we first analyse the target market, figure out the key decision makers in a particular
                                firm, and then implement a custom strategy to engage them.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">

            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                <div class="service-left-content">
                    <div>
                        <div class="left-head">
                            <h3>Sales As A Service</h3>
                            <p>RapinnoTech provides Sales as a Service to find and locate the right business partners to provide you with turnkey sales organizations. Our experts will help you determine the benefits and unique features of your product. We
                                then take care of the most important marketing communications and track progress and achievement (goals) with effective control measures.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxFlexFill fxLayoutAlign="start start">
                <div class="service-right-img">
                    <img src="../../../../assets/images/integrated-marketing service/3.png">
                </div>
            </div>

        </div>
        <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxFlexFill fxLayoutAlign="start start">
                <div class="service-right-img">
                    <img src="../../../../assets/images/integrated-marketing service/sales-support.svg">
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                <div class="service-left-content">
                    <div>
                        <div class="left-head">
                            <h3>Sales and support</h3>
                            <p>RapinnoTech's customer service provides the best option with 24 * 7 services. With the latest technology, improving customer experience and providing better solutions is an essential requirement. Our customer support services
                                include planning, documentation, product reviews, technical troubleshooting, installation, maintenance, upgrades and many more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>