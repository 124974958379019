import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-blog-one',
  templateUrl: './blog-one.component.html',
  styleUrls: ['./blog-one.component.scss']
})
export class BlogOneComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) {}

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Need for Security Testing In Changing Times');
    this.seoService.updateOgTitle('Need for Security Testing In Changing Times');
    this.seoService.updateOgDescription('While creating new interfaces or systems, it is vital to test at every stage. This helps to identify the imminent threats to the security of the system and also help understand what needs to be improved. There are two possibilities of doing these security checks- manual or automatic.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/Need_for_Security_Testing_In_Changing_Times');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/blog_1.webp');
    this.seoService.updateMetaTitle('Need for Security Testing In Changing Times'),
      this.seoService.updateMetaDescription('While creating new interfaces or systems, it is vital to test at every stage. This helps to identify the imminent threats to the security of the system and also help understand what needs to be improved. There are two possibilities of doing these security checks- manual or automatic.')
  }

}
