import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-erp-helps-keep-your-healthcare-organisation-healthy-in-this-pandemic',
  templateUrl: './how-erp-helps-keep-your-healthcare-organisation-healthy-in-this-pandemic.component.html',
  styleUrls: ['./how-erp-helps-keep-your-healthcare-organisation-healthy-in-this-pandemic.component.scss']
})
export class HowErpHelpsKeepYourHealthcareOrganisationHealthyInThisPandemicComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How ERP Helps Keep Your Healthcare Organisation Healthy in This Pandemic?');
    this.seoService.updateOgTitle('Rapinno Tech Blog |How ERP Helps Keep Your Healthcare Organisation Healthy in This Pandemic?');
    this.seoService.updateOgDescription('Healthcare ERP systems help to meet industry-specific challenges, reduce costs and increase productivity. This software is capable of measuring and evaluating the operational performance of a hospital. ');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/how-is-the-banking-industry-benefiting-from-cloud-transformation');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/20.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How ERP Helps Keep Your Healthcare Organisation Healthy in This Pandemic?'),
    this.seoService.updateMetaDescription('Healthcare ERP systems help to meet industry-specific challenges, reduce costs and increase productivity. This software is capable of measuring and evaluating the operational performance of a hospital. ')
  }
}
