import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-testing-in-the-cloud',
  templateUrl: './testing-in-the-cloud.component.html',
  styleUrls: ['./testing-in-the-cloud.component.scss']
})
export class TestingInTheCloudComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Testing in the Cloud : Exploring the Banking Sector');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Testing in the Cloud : Exploring the Banking Sector');
    this.seoService.updateOgDescription('Cloud Testing is also enabling financial institutions to achieve considerable gains in efficiency and reductions in costs, as the technology requires banks to pay for only the services they use.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/testing-in-the-cloud-exploring-the-banking-sector');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/19.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Testing in the Cloud : Exploring the Banking Sector'),
    this.seoService.updateMetaDescription('Cloud Testing is also enabling financial institutions to achieve considerable gains in efficiency and reductions in costs, as the technology requires banks to pay for only the services they use.')
  }
}
