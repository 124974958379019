import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-automation',
  templateUrl: './ai-automation.component.html',
  styleUrls: ['./ai-automation.component.scss']
})
export class AiAutomationComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }

  setSeo(): void {
    this.seoService.updatePageTitle('AI & Automation | Rapinno Tech');
    this.seoService.updateOgTitle('AI & Automation | Rapinno Tech');
    this.seoService.updateOgDescription('Our AI & Automation team is well versed and equipped to solve your business problems intuitively.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/');
    this.seoService.updateMetaTitle('AI & Automation | Rapinno Tech'),
    this.seoService.updateMetaDescription('Our AI & Automation team is well versed and equipped to solve your business problems intuitively.')
  }

}
