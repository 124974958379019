import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  hoverImg1 = false;
  hoverImg2 = false;
  hoverImg3 = false;
  public testConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    speed: 3000,
    observer: true,
    observeParents: true,
    longSwipes: false,
    freeMode: true,
    //autoplay : false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    loop: true,
    lazy: true,
    preloadImages: false,
    navigation: {
      nextEl: '.test-button-next',
      prevEl: '.test-button-prev',
    },
  };
  public clientConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    speed: 6000,
    observer: true,
    observeParents: true,
    longSwipes: false,
    freeMode: true,
    //autoplay : false,
    autoplay: {
      delay: 5500,
      disableOnInteraction: false,
    },
    loop: true,
    lazy: true,
    preloadImages: false,
    navigation: {
      nextEl: '.test-button-next',
      prevEl: '.test-button-prev',
    },
  };
  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();

    this.hoverImg1 = true;
  }

  hoverBox1() {
    this.hoverImg1 = true;
    this.hoverImg2 = false;
    this.hoverImg3 = false;
  }
  hoverBox2() {
    this.hoverImg1 = false;
    this.hoverImg2 = true;
    this.hoverImg3 = false;
  }
  hoverBox3() {
    this.hoverImg1 = false;
    this.hoverImg2 = false;
    this.hoverImg3 = true;
  }

  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech | About Us');
    this.seoService.updateOgTitle('Rapinno Tech | About Us');
    this.seoService.updateOgDescription('Rapinno Tech provides cutting-edge IT solutions and services to all organizations across different industry verticals to empower their business operational capability.');
    this.seoService.updateOgType('About Us');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/about-us');
    this.seoService.updateMetaTitle('Rapinno Tech | About Us'),
      this.seoService.updateMetaDescription('Rapinno Tech provides cutting-edge IT solutions and services to all organizations across different industry verticals to empower their business operational capability.')
  }
}

