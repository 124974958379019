import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-artificial-intelligence-is-a-game-changer-for-banks',
  templateUrl: './how-artificial-intelligence-is-a-game-changer-for-banks.component.html',
  styleUrls: ['./how-artificial-intelligence-is-a-game-changer-for-banks.component.scss']
})
export class HowArtificialIntelligenceIsAGameChangerForBanksComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('RapinnoTech Blog | How Artificial Intelligence Is A Game-Changer For Banks In The 21st Century?');
    this.seoService.updateOgTitle('RapinnoTech Blog | How Artificial Intelligence Is A Game-Changer For Banks In The 21st Century?');
    this.seoService.updateOgDescription('Artificial Intelligence is set to be a game-changer in the 21st century. This blog presents an overview of how AI models are boosting growth in the banking sector.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/How-Artificial-Intelligence-Is-A-Game-Changer-For-Banks-In-The-21-st-Century');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/33.webp');
    this.seoService.updateMetaTitle('RapinnoTech Blog | How Artificial Intelligence Is A Game-Changer For Banks In The 21st Century?'),
    this.seoService.updateMetaDescription('Artificial Intelligence is set to be a game-changer in the 21st century. This blog presents an overview of how AI models are boosting growth in the banking sector.')
  }
}
