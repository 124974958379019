import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-single-file',
  templateUrl: './single-file.component.html',
  styleUrls: ['./single-file.component.scss'],
})
export class SingleFileComponent implements OnInit, OnDestroy {
  /* --------------------------------- Fields --------------------------------- */
  mimeArray: string[] = [
    'application/x-rar-compressed',
    'application/rtf',
    'application/pdf',
    'application/zip',
    'application/x-7z-compressed',
  ];
  /* --------------------------------- Fields --------------------------------- */
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: any;
  file: File;
  fileSize: true;
  nextId = 0;
  @Input() fileInput: FormControl;
  @Input() size: number;
  @Input() directory = 'general';
  @Output() fileEvent: EventEmitter<any> = new EventEmitter<string>();
  isHovering: boolean;
  progress = 0;
  fileSub: Subscription;
  @Input() isDimensionCheck: boolean;
  @Input() width: number;
  @Input() height: number;
  /* ------------------------------- Constructor ------------------------------ */

  constructor(
    private toast: ToastService,
    private storage: AngularFireStorage
  ) {}

  /* ---------------------------- Life Cycle Hooks ---------------------------- */

  ngOnInit() {
    this.fileSub = this.fileInput.valueChanges.subscribe((data) => {
      if (!data) {
        this.file = null;
        this.percentage = null;
      }
    });
  }

  ngOnDestroy() {
    this.fileSub.unsubscribe();
  }

  /* ---------------------------- Custom Functions ---------------------------- */
  startUpload(file: File) {
    // The storage path
    console.log(file);
    const path = `resume/${file.name}`;
    // Reference to storage bucket
    const ref = this.storage.ref(path);
    // The main task
    this.task = this.storage.upload(path, file);
    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      finalize(async () => {
        console.log('test');
        this.downloadURL = await ref.getDownloadURL().toPromise();
        console.log(this.downloadURL);
        this.fileInput.setValue(this.downloadURL);
      })
    );
  }
  removeFile() {
    this.downloadURL = '';
    this.fileInput.setValue(this.downloadURL);
  }

  /* ---------------------------- Custom Functions ---------------------------- */
  toggleHover(event: boolean) {
    this.isHovering = event;
  }
  onDrop(files: FileList) {
    this.file = files.item(0);
    console.log(this.file);
    if (
      this.mimeArray.includes(this.file.type) &&
      this.file.size <= 10 * 1024 * 1024
    ) {
      this.startUpload(this.file);
    } else {
      this.toast.openSnackBar(
        !this.mimeArray.includes(this.file.type)
          ? 'Enter Valid File'
          : 'File Size Can`t Exceed 10mb'
      );
    }
  }
}
