import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-rpa-ia-what-the-difference-and-which-is-best-for-your-business-now',
  templateUrl: './rpa-ia-what-the-difference-and-which-is-best-for-your-business-now.component.html',
  styleUrls: ['./rpa-ia-what-the-difference-and-which-is-best-for-your-business-now.component.scss']
})
export class RpaIaWhatTheDifferenceAndWhichIsBestForYourBusinessNowComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('RapinnoTech Blog | RPA & IA: What The Difference and Which Is Best For Your Business Now?');
    this.seoService.updateOgTitle('RapinnoTech Blog | RPA & IA: What The Difference and Which Is Best For Your Business Now?');
    this.seoService.updateOgDescription('What is RPA and what is IA? What are the benefits of Intelligent Automation? IA and RPA makes automating incredibly difficult business tasks a reality');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/rpa-ia-what-the-difference-and-which-is-best-for-your-business-now');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/30.webp');
    this.seoService.updateMetaTitle('RapinnoTech Blog | RPA & IA: What The Difference and Which Is Best For Your Business Now?'),
    this.seoService.updateMetaDescription('What is RPA and what is IA? What are the benefits of Intelligent Automation? IA and RPA makes automating incredibly difficult business tasks a reality')
  }
}