<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
  <div class="banner-dots left">
    <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
  </div>
  <div class="banner-dots right">
    <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
  </div>
  <div class="wrapper">
    <div class="intro-row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
      fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
      <div class="column about_column_left" fxFlex="55" fxFlex.sm="50" fxFlexFill>
        <div class="intro-content">
          <div class="section-heading">
            <h1 class="main-heading">About Us</h1>
          </div>
          <p>Rapinno Tech solutions is a software innovation company whose primary vision is to deliver technology
            innovations at a rapid pace to its clients. The company is headquartered in California, USA and has its
            development centers at Hyderabad & Bangalore in INDIA. The tag line “Rapid Technology Innovations Delivered”
            makes it apt as we innovate on advanced intelligent technologies to address client needs.</p>
          <p>
            Rapinno Tech provides cutting-edge IT solutions and services to all organizations across different industry
            verticals to enable them to empower their business operational capability. We enable a time advance, cost
            advantage, quality advantage, and innovation
            advantage to our clients.
          </p>
        </div>
      </div>
      <div class="column about_column_right" fxFlex="45" fxFlex.sm="50" fxFlexFill>
        <div class="intro-img">
          <img class="raduis-img" src="../../../../../assets/images/service-banner/about.jpg"
            alt="Team of professionals set up for any challange in Rapinno tech">
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ------------------------vision mission bussiness section---------------------------- -->
<section class="vmb_section">
  <div class="hover_boxes_sec">
    <div class="hover-boxes-wrapper">
      <div class="hover-box-backgrounds">
        <div class="background-overlay"></div>
        <div class="background-wrapper">
          <div class="bgimage-1" *ngIf="hoverImg1"></div>
          <div class="bgimage-2" *ngIf="hoverImg2"></div>
          <div class="bgimage-3" *ngIf="hoverImg3"></div>
        </div>
      </div>
      <div class="hover-box-content">
        <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
          <div class="content-wrapper hover-box-1" fxLayout="column" fxFlex="34" fxFlex.md="50" fxFlex.sm="100"
            fxFlex.xs="100" fxLayoutAlign="center center" (mouseover)="hoverBox1()">
            <div class="about-content">
              <div class="w-100">
                <h3>Our Mission</h3>
                <p class="hover-content">Our mission is to help clients in achieving business goals through our
                  innovative solutions and flexible service offerings.</p>
              </div>
            </div>
          </div>
          <div class="content-wrapper hover-box-2" fxLayout="column" fxFlex="34" fxFlex.md="50" fxFlex.sm="100"
            fxFlex.xs="100" fxLayoutAlign="center center" (mouseover)="hoverBox2()">
            <div class="about-content">
              <div class="w-100">
                <h3>Our Vision </h3>
                <p class="hover-content">Our vision is to become a preferred partner to various industries with valuable
                  technology services.</p>
              </div>
            </div>
          </div>
          <div class="content-wrapper hover-box-3" fxLayout="column" fxFlex="34" fxFlex.md="50" fxFlex.sm="100"
            fxFlex.xs="100" fxLayoutAlign="center center" (mouseover)="hoverBox3()">
            <div class="about-content">
              <div class="w-100">
                <h3>Our Business Philosophy </h3>
                <p class="hover-content">To provide rapid software solutions and to build market ready products which
                  are adaptive to any kind of market change.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- ------------------------vision mission bussiness section---------------------------- -->
<!-- CTS SECTION-->
<section class="section-cta">
  <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
  <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
  <div class="cta-content">
    <h3 class="cta-heading">We can help you in business solutions</h3>
    <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact
      Now
    </button>
  </div>
  <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
  <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>
<!-- ------------------------how we work section---------------------------- -->
<section class="howwework">
  <div>
    <div class="overlay" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
      fxLayoutAlign="center center" fxLayoutGap="0px">
      <div class="content_text">
        <h2>How we work</h2>
        <p>We are an employee-centric organization and we take utmost care in creating positive environment for
          employees to bringing out the best of their potential. Our work culture imbibes ethical practices and positive
          work atmosphere. We believe
          in the philosophy that "All work and no play makes Jack a dull boy" Thus, fun at work is mandatory to create
          an healthy and lively environment, where employees wish to come office their working. At RapinnoTech Cultural
          diversity is
          given utmost importance as this brings new of innovative ideas from different corner of the world.
        </p>
      </div>
    </div>
  </div>
</section>
<!-- ------------------------how we work section---------------------------- -->
<!-- ------------------------Our Team section---------------------------- -->
<section class="team">
  <div class="wrapper">
    <div class="contentheader">
      <h2>Our Team</h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="column" fxFlex="75" fxFlex.lt-sm="95">
        <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center"
          fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
          <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
            <img src="../../../../assets/images/team/Pavan.png" alt="img">
          </div>
          <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
            <h4>Pavan Kumar Kodedela</h4>
            <p>With 20+ years of experience working in and mentoring start-ups, Pavan Kumar has accumulated a plethora
              of technology management experience while building multiple innovative and out-of-the-box software
              products for clients across
              the globe. Specialized in strategic management, IT product development and software quality assurance,
              Business operations, and Cloud Infrastructure management Pavan has single-handedly managed teams of 500+
              members effortlessly
              on multiple occasions. And currently, with a vision to develop and implement intelligent software products
              rapidly, Pavan acts as Founder, CEO, and one of the members of the Board of Directors of RapinnoTech
              Solutions Private
              Limited and he also acts as Managing Partner of Applied Synergy Technologies Private Limited.
            </p>
          </div>
        </div>
        <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center"
          fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
          <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center"
            fxFlexOrder.lt-md="2">
            <h4>Naresh Velaga</h4>
            <p>Naresh Velaga is a serial entrepreneur with 10+ years of experience in the IT solutions and service
              industry. With immense knowledge and expertise, Naresh has a unique vision for intelligent and innovative
              solutions that have helped
              many businesses and companies to ﬂourish. Having core strength in developing and implementing
              Customer-Centric On-Demand Technology Solutions & Services, currently, Naresh acts as Co-Founder of
              RapinnoTech Solutions Private
              Limited which deals in providing faster and smarter software solutions to businesses all over the globe
              and he also acts as CFO and Managing Partner for Applied Synergy Technologies Private Limited.
            </p>
          </div>
          <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
            <img src="../../../../assets/images/team/Naresh.png" alt="img">
          </div>
        </div>


      </div>
    </div>
    <div class="view-more">
      <button><a href="/our-team" target="_blank" routerLinkActive="router-link-active" class="global-btn" mat-button>
          View
          More </a></button>
    </div>
  </div>
</section>
<!-- ------------------------our Team section---------------------------- -->
<!-- ------------------------testomonial section---------------------------- -->
<!-- <section class="testimonial">
    <div class="wrapper">
        <div class="contentheader">
            <h2>Testimonials</h2>
        </div>
        <div fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="row" fxLayoutAlign="start stretch" fxLayoutGap="0px">
            <div class="swiper-container" [swiper]="testConfig">
                <div class="swiper-wrapper navigation_buttonss">
                    <div class="swiper-slide">
                        <div class="swiper-slide">
                            <div fxLayout="column" fxFlex="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                                <div class="test-content">
                                    <h2>As a Junior market research executive at RapinnoTech, the team is amicable, welcoming, and supportive. The most interesting thing about working here is Work environment and database building, along with Colleague's
                                        support. It's been an excellent start for me.</h2>
                                    <h4>G. Sandeep Reddy </h4>
                                    <p>Junior Market Research Executive </p>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="50" fxFlexFill>
                                <div class="test-name">
                                    <img src="../../../../../assets/images/about-us/sandeep.jpg" alt="" title="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span class="inner-custom-controls navigations navigation_buttons">
                    <span class="controls">
                        <div class="swiper-button-prev test-button-prev">
                            <img src="../../../../../assets/images/about-us/icon-left.svg" alt="" srcset="">
                        </div>
                        <div class="swiper-button-next test-button-next">
                            <img src="../../../../../assets/images/about-us/icon-left.svg" alt="" srcset="">
                        </div>
                    </span>
                </span>
            </div>
        </div>
    </div>
</section> -->
<!-- ------------------------testomonial section---------------------------- -->
<!-- <section class="clientsection">
  <div class="wrapper">
    <div class="contentheader">
      <h2>Our Clients</h2>
    </div>
    <div class="row-wrap" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
      fxLayoutAlign="space-around stretch" fxLayoutGap="0px">
      <div fxLayout="column" fxFlex="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
        <ul class="client-main-logos row-wrap">
          <li><img src="../../../../assets/images/revised/12.png"></li>
          <li><img src="../../../../assets/images/revised/27.png"></li>
          <li><img src="../../../../assets/images/revised/5.png"></li>
          <li><img src="../../../../assets/images/revised/26.png"></li>
          <li><img src="../../../../assets/images/revised/9.png"></li>
          <li><img src="../../../../assets/images/revised/11.png"></li>
          <li><img src="../../../../assets/images/revised/15.png"></li>
          <li><img src="../../../../assets/images/revised/16.png"></li>
          <li><img src="../../../../assets/images/revised/1.png"></li>
          <li><img src="../../../../assets/images/revised/2.png"></li>
          <li><img src="../../../../assets/images/revised/3.png"></li>
          <li><img src="../../../../assets/images/revised/4.png"></li>
          <li><img src="../../../../assets/images/revised/6.png"></li>
          <li><img src="../../../../assets/images/revised/7.png"></li>
          <li><img src="../../../../assets/images/revised/8.png"></li>
          <li><img src="../../../../assets/images/revised/10.png"></li>
          <li><img src="../../../../assets/images/revised/13.png"></li>
          <li><img src="../../../../assets/images/revised/14.png"></li>
          <li><img src="../../../../assets/images/revised/17.png"></li>
          <li><img src="../../../../assets/images/revised/18.png"></li>
          <li><img src="../../../../assets/images/revised/19.png"></li>
          <li><img src="../../../../assets/images/revised/21.png"></li>
          <li><img src="../../../../assets/images/revised/22.png"></li>
          <li><img src="../../../../assets/images/revised/23.png"></li>
          <li><img src="../../../../assets/images/revised/24.png"></li>
          <li><img src="../../../../assets/images/revised/25.png"></li>
        </ul>
      </div>
    </div>
  </div>
</section> -->
<!-- ------------------------client section---------------------------- -->
