import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-what-are-nfts-and-how-are-they-impacting-the-world-of-art',
  templateUrl: './what-are-nfts-and-how-are-they-impacting-the-world-of-art.component.html',
  styleUrls: ['./what-are-nfts-and-how-are-they-impacting-the-world-of-art.component.scss']
})
export class WhatAreNFTsAndHowAreTheyImpactingTheWorldOfArtComponent implements OnInit {

  constructor(
    private seoService: SEOService,
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | What are NFTs and how are they impacting the world of art?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | What are NFTs and how are they impacting the world of art?');
    this.seoService.updateOgDescription('NFTs are unique digital representations of quality and good artworks.  It certifies the authenticity and now you can know how are they impact the world of art for good.');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/What-are-NFTs-and-how-are-they-impacting-the-world-of-art-?');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/47.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | What are NFTs and how are they impacting the world of art?'),
    this.seoService.updateMetaDescription('NFTs are unique digital representations of quality and good artworks.  It certifies the authenticity and now you can know how are they impact the world of art for good.')
  }
}
