<section class="blog-listing-section">
  <div class="wrapper">
    <div fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
      fxLayoutAlign="start stretch" fxLayoutGap="25px">
      <div fxLayout="column" fxFlex="100" fxFlexFill>
        <div class="blog-details">
          <div class="blog-head">
            <h4 class="blog-title">Malesuada dictum imperdiet </h4>
            <span class="date">JANUARY 2, 2017</span>
          </div>
          <div class="blog-media">
            <img src="../../../../../assets/images/blog/blog_img_1.svg" alt=""  >
          </div>

          <div class="blog-content">
            <p>A crown is a traditional form of head adornment worn by monarchs as a symbol of their power and dignity.
              A crown is often, by extension, a symbol of the monarch's government or items endorsed by it. The word
              itself is used, particularly in Commonwealth countries, as an abstract name for the monarchy itself, as
              distinct from the individual who inhabits it (see The Crown). A specific type of crown (or coronet for
              lower ranks of peerage) is employed in heraldry under strict rules. Indeed, some monarchies never had a
              physical crown, just a heraldic representation, as in the constitutional kingdom of Belgium, where no
              coronation ever took place; the royal installation is done by a solemn oath in parliament, wearing a
              military uniform: the King is not acknowledged as by divine right, but assumes the only hereditary public
              office in the service of the law; so he in turn will swear in all members of "his" federal government.</p>

              <p>Technology (“science of craft”, from Greek τέχνη, techne, “art, skill, cunning of hand”; and -λογία, -logia) is the sum of techniques, skills, methods, and processes used in the production of goods or services or in the accomplishment of objectives, such as scientific investigation. Technology can be the knowledge of techniques, processes, and the like, or it can be embedded in machines to allow for operation without detailed knowledge of their workings. Systems (e.g. machines) applying technology by taking an input, changing it according to the system’s use, and then producing an outcome are referred to as technology systems or technological systems.</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="blog-comment-sec">
  <h4>Comments</h4>
  <ul class="comment-listing">
    <li>
      <div class="media">
        <img src="https://miro.medium.com/fit/c/48/48/2*Rpz28zVIory5g9NvnSYRDQ.jpeg" alt="img">
        <div class="media-body">
          <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
          <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum
            at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean
            nec felis dui.</p>
        </div>
      </div>
    </li>
    <li>
      <div class="media">
        <img src="https://miro.medium.com/fit/c/48/48/2*Rpz28zVIory5g9NvnSYRDQ.jpeg" alt="img">
        <div class="media-body">
          <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
          <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum
            at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean
            nec felis dui.</p>
        </div>
      </div>
    </li>
    <li>
      <div class="media">
        <img src="https://miro.medium.com/fit/c/48/48/2*Rpz28zVIory5g9NvnSYRDQ.jpeg" alt="img">
        <div class="media-body">
          <h6>Mark Jecno <span>( 12 Jannuary 2018 at 1:30AM )</span></h6>
          <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum
            at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean
            nec felis dui.</p>
        </div>
      </div>
    </li>
  </ul>
</div>
<div class="more-blogs-sec">
  <div class="popular-post">
    <h4>Popular Posts</h4>
    <ul>
      <li class="more-blog-box">
        <div class="flex_blog">
          <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum
            at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean
            nec felis dui.</p>
          <img src="../../../../../assets/images/blog_listing/1.svg" alt="img">
        </div>
      </li>
      <li class="more-blog-box">
        <div class="flex_blog">
          <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum
            at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean
            nec felis dui.</p>
          <img src="../../../../../assets/images/blog_listing/2.svg" alt="img">
        </div>
      </li>
      <li class="more-blog-box">
        <div class="flex_blog">
          <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum
            at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean
            nec felis dui.</p>
          <img src="../../../../../assets/images/blog_listing/3.svg" alt="img">
        </div>
      </li>
      <li class="more-blog-box">
        <div class="flex_blog">
          <p>Donec rhoncus massa quis nibh imperdiet dictum. Vestibulum id est sit amet felis fringilla bibendum
            at at leo. Proin molestie ac nisi eu laoreet. Integer faucibus enim nec ullamcorper tempor. Aenean
            nec felis dui.</p>
          <img src="../../../../../assets/images/blog_listing/4.svg" alt="img">
        </div>
      </li>

    </ul>
  </div>
</div>

