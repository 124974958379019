import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-data',
  templateUrl: './big-data.component.html',
  styleUrls: ['./big-data.component.scss']
})
export class BigDataComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }


    setSeo(): void {
      this.seoService.updatePageTitle('Big Data | Rapinno Tech');
      this.seoService.updateOgTitle('Big Data | Rapinno Tech');
      this.seoService.updateOgDescription('Big Data Analytics is the use of advanced analytical techniques in relation to big data, including structured / unstructured data and streaming / batch data');
      this.seoService.updateOgType('Service Page');
      this.seoService.updateOgUrl('https://www.rapinnotech.com/big-data');
      this.seoService.updateMetaTitle('Big Data | Rapinno Tech'),
      this.seoService.updateMetaDescription('Big Data Analytics is the use of advanced analytical techniques in relation to big data, including structured / unstructured data and streaming / batch data')
    }
}
