import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    // this.setSeo();
  }



    // setSeo(): void {
    //   this.seoService.updatePageTitle('CRM | Rapinno Tech');
    //   this.seoService.updateOgTitle('CRM | Rapinno Tech');
    //   this.seoService.updateOgDescription('We provide tailor-made CRM for small businesses and start-ups which has helped them to grow faster.');
    //   this.seoService.updateOgType('Service Page');
    //   this.seoService.updateOgUrl('https://www.rapinnotech.com/crm');
    //   this.seoService.updateMetaTitle('CRM | Rapinno Tech'),
    //   this.seoService.updateMetaDescription('We provide tailor-made CRM for small businesses and start-ups which has helped them to grow faster.')
    // }
}
