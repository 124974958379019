import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-benefits-of-robotics-in-financial-fervices',
  templateUrl: './benefits-of-robotics-in-financial-fervices.component.html',
  styleUrls: ['./benefits-of-robotics-in-financial-fervices.component.scss']
})
export class BenefitsOfRoboticsInFinancialFervicesComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Benefits of Robotics in Financial Services | Rapinno Tech');
    this.seoService.updateOgTitle('Benefits of Robotics in Financial Services | Rapinno Techl');
    this.seoService.updateOgDescription('Robotic Process Automation (RPA) is one of the exciting and most promising technologies in recent times.');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://rapinnotech.com/blogs/benefits-of-robotics-in-financial-fervices');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/51.webp');
    this.seoService.updateMetaTitle('Benefits of Robotics in Financial Services | Rapinno Tech'),
    this.seoService.updateMetaDescription('Robotic Process Automation (RPA) is one of the exciting and most promising technologies in recent times.')
  }
}
