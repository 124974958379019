<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Internet of Things</h1>
                    </div>
                    <p>
                        The Rapinno database design will guide the detailed analysis of requirements, structure, relationships, and everything else. Our database design architecture will always be specific: requirements analysis, development, and implementation.</p>
                    <p>
                        Requirements analysis is an important part of database design. We strictly adhere to the "The better the query optimization, the better the performance" approach.
                    </p>
                    <p>
                        Our end-to-end IoT solutions enable intelligent industries, intelligent life and intelligent companies and offer connected experiences by connecting assets, operations, logistics and services. We have expertise in all phases of IoT rollout to provide
                        you with a seamless single-vendor experience to maximize efficiency.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/internet-of-things.jpg" alt="Man using mobile phone to access different devices like AC, Tv">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="iot-blue-sec">
    <div class="wrapper">
        <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="left-iot">
                    <img src="../../../../../assets/images/services/internet-of-things/mobile_globe.svg" alt="Illustration showcasing how from mobile all other devices can be used">
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxLayoutAlign="start start">
                <div class="right-iot">
                    <div class="service_head">
                        <h4>Our key IoT offerings include</h4>
                    </div>
                    <ul class="iot-list">
                        <li>Connecting the previously deployed devices</li>
                        <li>Quick Prototyping of IoT framework</li>
                        <li>Showcase business value in a prototype</li>
                        <li>Create a series of connected devices as per need</li>
                        <li>Identify the right IoT solution that best fits the client needs</li>
                        <li>Making devices operational as per desire functionality</li>
                        <li>Monitor and Support the IoT framework</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Internet Of Things</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>