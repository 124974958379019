import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-why-is-an-erp-implementation-challenging',
  templateUrl: './why-is-an-erp-implementation-challenging.component.html',
  styleUrls: ['./why-is-an-erp-implementation-challenging.component.scss']
})
export class WhyIsAnErpImplementationChallengingComponent implements OnInit {

  constructor( 
    private seoService: SEOService
    ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Why Is an ERP Implementation a Challenging Task?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Why Is an ERP Implementation a Challenging Task?');
    this.seoService.updateOgDescription('Implementing ERP is challenging work because adopting a new system or anything new is not easy. ERP applications require sufficient storage space and high work performance.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/Why-is-an-erp-implementation-challenging');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/21.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Why Is an ERP Implementation a Challenging Task?'),
    this.seoService.updateMetaDescription('Implementing ERP is challenging work because adopting a new system or anything new is not easy. ERP applications require sufficient storage space and high work performance.')
  }
}
