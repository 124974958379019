import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';
@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  constructor(
    private seoService: SEOService
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('career | Rapinno Tech');
    this.seoService.updateOgTitle('career | Rapinno Tech');
    this.seoService.updateOgDescription('Rapinnotech is committed to helping our clients be their best. We love to learn & grow fulfilling not only ours but also the organizational goals as well. If you are interested in joining a team that will push you to redefine your full potential, check out our job openings below and join us!');
    this.seoService.updateOgType('career Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/career');
    this.seoService.updateMetaTitle('career | Rapinno Tech'),
    this.seoService.updateMetaDescription('Rapinnotech is committed to helping our clients be their best. We love to learn & grow fulfilling not only ours but also the organizational goals as well. If you are interested in joining a team that will push you to redefine your full potential, check out our job openings below and join us!')
  }
}
