import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-what-is-rapinno-intelligent-automation-ecosystem',
  templateUrl: './what-is-rapinno-intelligent-automation-ecosystem.component.html',
  styleUrls: ['./what-is-rapinno-intelligent-automation-ecosystem.component.scss']
})
export class WhatIsRapinnoIntelligentAutomationEcosystemComponent implements OnInit {

 
  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | What Is Rapinno Intelligent Automation Ecosystem?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | What Is Rapinno Intelligent Automation Ecosystem?');
    this.seoService.updateOgDescription('The Intelligent Automation Ecosystem will bring new advancements & applications in the technology. We at Rapino assist the evolution of innovations in an automation process.  Click here to know more');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/index/blog/what-is-rapinno-intelligent-automation-ecosystem');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/38.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | What Is Rapinno Intelligent Automation Ecosystem?s'),
    this.seoService.updateMetaDescription('The Intelligent Automation Ecosystem will bring new advancements & applications in the technology. We at Rapino assist the evolution of innovations in an automation process.  Click here to know more')
  }
}