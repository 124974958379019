<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Rapinno Data Digitization</h1>
                    </div>
                    <p>
                        Data digitization is an innovative mechanism for converting conventional files such as documents, images, raw data, and spreadsheets into digital media or formats. It uses a robust algorithm to convert various file types into digital formats based on
                        binary code. It is a sophisticated approach to transforming unorganized data into a structured format. Digitization is the representation of a series of signals. To digitize data efficiently, we provide different types of unrefined
                        material such as text files, images, and videos in an organized manner. Structured files are available in well-known digital formats such as PDF, DOC, JPEG, SVG, BMP, XLS, and XML. It uses an advanced sampling pattern to obtain
                        high-quality output.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/banner_2.png" alt="Android typing codes on computer representing RPA">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="service-data-collect-sec">
    <div class="wrapper">
        <div class="process-data">
            <img class="data-flow-img" src="../../assets/images/service-banner/data.png" alt="flow chat of data processing">
        </div>
        <div class="data-bnt">

            <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="row" fxLayoutAlign="start stretch">
                <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="25" fxFlex.xs="25" fxFlexFill>
                    <button [routerLink]="" routerLinkActive="router-link-active" class="know-btn" mat-button> Document Collection
          </button>
                </div>
                <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="25" fxFlex.xs="25" fxFlexFill>
                    <button [routerLink]="" routerLinkActive="router-link-active" class="know-btn" mat-button> Scanning / Indexing
          </button>
                </div>
                <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="25" fxFlex.xs="25" fxFlexFill>
                    <button [routerLink]="" routerLinkActive="router-link-active" class="know-btn" mat-button> Data Processing
          </button>
                </div>
                <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="25" fxFlex.xs="25" fxFlexFill>
                    <button [routerLink]="" routerLinkActive="router-link-active" class="know-btn" mat-button> Data Storage
          </button>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Innovate -->
<section class="robotic-process-automation-sec">
    <section class="innovate-sec service-heading-content-sec">
        <div class="wrapper">
            <div class="service_head">
                <h2>How We Approach Rapinno Data Digitization</h2>
                <p>The first thing we do when digitizing data is to understand your specific needs about the whole digitization process. With your help, we will understand your vision and will design a robust process.</p>
            </div>
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="0px">
                <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill fxLayoutAlign="center start">
                    <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                            <div class="service-right-img">
                                <img src="../../assets/images/rapinno-data-digitization/1.png" alt="Illustration showcasing RPA consultant monitoring
                  ">
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                            <div class="service-left-content">
                                <div class="left-head  min-height-align">
                                    <div>
                                        <div class="left-head">
                                            <h3>Data & Document Collection</h3>
                                        </div>
                                        <p>
                                            In this phase, we collect all the documents to be scanned from you and align them in the best manageable way possible.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- SERVICE-HEADING-SEC -->
    <section class="service-heading-content-sec">
        <div class="wrapper">
            <!-- <div class="section-heading-content">
    </div> -->
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="60" fxFlex.lg="60" fxFlex.md="60" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head  min-height-align">
                            <div>
                                <div class="left-head">
                                    <h3>Document, Image Scanning & Digital Conversion</h3>
                                </div>
                                <p>
                                    After aligning the documents and images, they are all scanned and saved as image files. These image files are then processed through OCR, editing, and proofreading and converted into easy to-read text files. To make it easier for you to recover the files
                                    they need in the future, we will index all files appropriately as required.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../assets/images/rapinno-data-digitization/2.png" alt="Illustration showcasing RPA consultant validating the overall automation process">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- SERVICE-SEC -->
    <section class="robotic-process-automation-sec">
        <section class="innovate-sec service-heading-content-sec">
            <div class="wrapper">
                <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="0px">
                    <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill fxLayoutAlign="center start">
                        <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                            <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                                <div class="service-right-img">
                                    <img src="../../assets/images/rapinno-data-digitization/3.png" alt="Illustration showcasing RPA consultant monitoring
                    ">
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                                <div class="service-left-content">
                                    <div class="left-head  min-height-align">
                                        <div>
                                            <div class="left-head">
                                                <h3>Delivery</h3>
                                            </div>
                                            <p>
                                                Once all documents have been converted to digital format, we will transfer all data to the cloud storage securely with the required encryption. Also, the physical records with us are destroyed or delivered to customers, according to their instructions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- CTS SECTION-->
        <section class="section-cta section-padding">
            <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
            <!-- <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  > -->
            <div class="cta-content">
                <h3 class="cta-heading">We can help you in Data Digitization</h3>
                <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> CONTACT
          NOW </button>
            </div>
            <!-- <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  > -->
            <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
        </section>
        <!-- SERVICE-HEADING-SEC -->
        <section class="service-heading-content-sec">
            <div class="wrapper">
                <div class="section-heading-content">
                    <h2>Key Features</h2>
                    <p>Our process-driven approach is fast, efficient & secure.</p>
                </div>
                <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="service-right-img">
                            <img src="../../assets/images/rapinno-data-digitization/4.png" alt="Illustration showcasing machine learning entity implementing new processes">
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-left-content">
                            <div class="left-head min-height-align">
                                <div>
                                    <div class="left-head">
                                        <h2>No Storage Issues</h2>
                                    </div>
                                    <p>
                                        The storage limit is a big problem for today's businesses that need to keep a lot of information in different segments. In this context, digitization is a great relief when it comes to questions of space restrictions. Digital files are lightweight, so
                                        they are minimally sized on the hard drive or destination location.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-left-content">
                            <div class="left-head min-height-align">
                                <div>
                                    <div class="left-head">
                                        <h2>Cost-Effective Data Management</h2>
                                    </div>
                                    <p>
                                        Our team applies an effective strategy to minimize operating costs while maximizing the use of available resources at your end. Undoubtedly, it is the best solution for all those who participate in digitization and data conversion projects.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-right-img">
                            <img src="../../assets/images/rapinno-data-digitization/5.png" alt="Illustration showcasing support work provided  by machine">
                        </div>
                    </div>
                </div>


                <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="service-right-img">
                            <img src="../../assets/images/rapinno-data-digitization/6.png" alt="Illustration showcasing machine learning entity implementing new processes">
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-left-content">
                            <div class="left-head min-height-align">
                                <div>
                                    <div class="left-head">
                                        <h2>Enhance Productivity</h2>
                                    </div>
                                    <p>
                                        Digitized files are fully interactive and provide a better user experience, promoting the maintenance of quality and excellence in the work portfolio. Our team uses all tactics to improve the productivity of all business processes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-left-content">
                            <div class="left-head min-height-align">
                                <div>
                                    <div class="left-head">
                                        <h2>Proofreading</h2>
                                    </div>
                                    <p>
                                        We have a dedicated team for corrections to ensure the proper functioning of activities. If there is something wrong with the end product, this team reports a bug and notifies the appropriate team. The role of our proofreader team is crucial in maintaining
                                        a flawless system.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-right-img">
                            <img src="../../assets/images/rapinno-data-digitization/7.png" alt="Illustration showcasing support work provided  by machine">
                        </div>
                    </div>
                </div>


                <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="service-right-img">
                            <img src="../../assets/images/rapinno-data-digitization/8.png" alt="Illustration showcasing machine learning entity implementing new processes">
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-left-content">
                            <div class="left-head min-height-align">
                                <div>
                                    <div class="left-head">
                                        <h2>Complete Transparency</h2>
                                    </div>
                                    <p>
                                        We believe in a transparent system. That is why we offer data digitization services in a very transparent way. This approach helps build mutual trust and cooperation between us.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-left-content">
                            <div class="left-head min-height-align">
                                <div>
                                    <div class="left-head">
                                        <h2>100% Secure</h2>
                                    </div>
                                    <p>
                                        During the data digitization and conversion process, we implement safe methodologies that are approved by certified authorities. This really is the best approach to following protocols for safe conversion.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-right-img">
                            <img src="../../assets/images/rapinno-data-digitization/9.png" alt="Illustration showcasing support work provided  by machine">
                        </div>
                    </div>
                </div>

                <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="service-right-img">
                            <img src="../../assets/images/rapinno-data-digitization/10.png" alt="Illustration showcasing machine learning entity implementing new processes">
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <div class="service-left-content">
                            <div class="left-head min-height-align">
                                <div>
                                    <div class="left-head">
                                        <h2>Quality Assurance</h2>
                                    </div>
                                    <p>
                                        As an expert in the data digitization industry, we never compromise on quality standards and industry guidelines. It is a matter of the highest quality for both our team and our clients.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>