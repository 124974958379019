<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">AI & Automation</h1>
                    </div>
                    <p>
                        With deep domain expertise, the ability to co-create innovation with flexible experimentation, a large-scale speed-optimized deployment model, an ever-evolving partner ecosystem, and a unique talent development model, Rapinno's AI and Automation Services
                        team works with clients to Navigate their AI & Automation journey through:
                    </p>
                    <ul>
                        <li>AI and automation consulting for evaluation and AI maturity level assessment, CoE setup, use case discovery, creation of a business case, and ease of change management </li>
                        <li>Value creation through scaling by establishing an automation ecosystem, incorporating operational excellence, RPA, and chatbot factory models into the implementation of use cases</li>
                        <li>Custom AI platform for customers to enable AI democratization and scale adoption</li>
                        <li>Bring ready-made cognitive solutions such as logo recognition, sentiment analysis, image analysis, chatbot frameworks, a comprehensive HR solution, and other apps for you.</li>
                    </ul>
                </div>
            </div>
            <div fxLayout="column" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/ai-automation.jpg" alt="img">


                </div>
            </div>
        </div>
    </div>
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec ai-automation-sec">
    <div class="wrapper">
        <div class="service_head text-center">
            <h2>Key Service Offerings include</h2>
        </div>
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="automation-offers">
                    <ul class="service-offerings-ul">
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/icons/ai-icon1.svg" alt="">
                                <span>AI and Automation Consulting services</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/icons/ai-icon2.svg" alt="">
                                <span>Assessment & Tool evaluation</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/icons/ai-icon3.svg" alt="">
                                <span>Architecture design services</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/icons/ai-icon4.svg" alt="">
                                <span>AI and Automation Solution on Cloud</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/icons/ai-icon5.svg" alt="">
                                <span>RPA and IPA development services</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in AI & Automation</h3>

        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec ai-automation-sec">
    <div class="wrapper">
        <div class="service_head text-center">
            <h4>Benefits</h4>
        </div>
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="automation-offers">
                    <ul class="service-offerings-ul">
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/ai_automation/enhance.svg" alt="">
                                <span>Enhance Business</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/ai_automation/productivity.svg" alt="">
                                <span>Productivity</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/ai_automation/roi.svg" alt="">
                                <span>Improve Your ROI</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/ai_automation/fast_time.svg" alt="">
                                <span>Faster Time To
                  Market</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/ai_automation/operational.svg" alt="">
                                <span>Operational
                  Excellence</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/ai_automation/value_at_scale.svg" alt="">
                                <span>Value At Scale</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>