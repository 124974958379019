import { SEOService } from '../../core/seo.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { RealEstateBrochureComponent } from '../../components/real-estate-brochure/real-estate-brochure.component';

@Component({
  selector: 'app-real-estate',
  templateUrl: './real-estate.component.html',
  styleUrls: ['./real-estate.component.scss']
})
export class RealEstateComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  realEstateBrochure(): void {
    this._dialog.open(RealEstateBrochureComponent, {
      width: '50%',
      panelClass: 'custom-modal',
      disableClose: true
    });
  }


  setSeo(): void {
    this.seoService.updatePageTitle('Real Estate | PDP | Rapinno Tech');
    this.seoService.updateOgTitle('Real Estate | PDP | Rapinno Tech');
    this.seoService.updateOgDescription('Rapinno Tech Customer Service Platform Helps You By Understand Your Ideas And Concepts So That We Can Deliver An All Feature Web/Mobile Application That Will Help You Grow.');
    this.seoService.updateOgType('RDP - Service Page');
    this.seoService.updateMetaTitle('Real Estate | PDP | Rapinno Tech'),
    this.seoService.updateMetaDescription('Rapinno Tech Customer Service Platform Helps You By Understand Your Ideas And Concepts So That We Can Deliver An All Feature Web/Mobile Application That Will Help You Grow.')
  }
}
