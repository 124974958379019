<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Human Capital Management</h1>
                    </div>
                    <p>
                        RapinnoTech's Human Capital Management (HCM) services help organizations move beyond traditional shared services and the operating model to boost talent and skills in line with strategic needs. Emerging technologies and best practices are leveraged to
                        create a humane experience for employees, with faster decision-making and less human effort. This helps to position HR as an important business enabler, rather than a support function. Our services span the life cycle of hiring
                        an employee to retire, including, but not limited to, workforce management, payroll management, time and attendance, performance management, learning management and management of payroll and compensation for retirement.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/human-capital-management.jpg" alt="Showcasing organizations move beyond traditional shared services Human Capital Management">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec">
    <div class="wrapper">
        <div class="section-heading-content">
            <h2 class="heading_sec">Key features of HCM include</h2>
        </div>
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-left-content">
                    <div>
                        <div class="service_ul min-height-align">
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Self Help HR Portal
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> HR Helpdesk
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Workforce Directory
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Workforce Hierarchy
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Payroll
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Health Benefits
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Skill Matrix
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Talent Acquisition
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-right-img">
                    <img src="../../../../../assets/images/human-right-img.svg" alt="Showcasing person taking about Key Features Of HCM">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Human Capital Management</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec">
    <div class="wrapper">
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-right-img">
                    <img src="../../../../../assets/images/human-left-img.svg" alt="Showcasing person working in laptop">
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-left-content">
                    <div>
                        <!-- <div class="left-head">
              <h4> Quality Engineering</h4>
            </div> -->
                        <div class="service_ul min-height-align">
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Compensation
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Career Development
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Talent Review & Succession
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Talent Acquisition
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Goal and Performance Management
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Learning & Development
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>