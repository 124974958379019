<section class="blog-listing-section case_studies_sec">
  <div class="wrapper">
    <div class="section-heading mt-30">
      <h1 class="main-heading">White Paper</h1>
    </div>
    <div class="row blog_listing_row case_studies_row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
      fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
      <div class="column_one_listing" fxFlex="65" fxFlexFill>
        <!-- LATEST  03-aug-2021 -->
        <div class="blog-listing">
          <div class="blog-media">
            <img src="../../../../assets/images/case-studies/whitepaper/white/1.webp" alt="">
          </div>
          <div class="blog-head">
            <h1 class="blog-title">The Key Technology Challenges of Emerging Startups and How to Navigate Them : White
              Paper</h1>
            <span class="date">Posted on Aug 03. 2021, by Eliza</span>
          </div>
          <div class="blog-content">
            <p>
              In recent years, startups have received increasing attention in many parts of the world. In India, the
              number of startups has overgrown, and more support has become available across the ecosystem. With this
              whitepaper we are analyzing the current situation of the Indian startup ecosystem. It has three goals: to
              understand the growth drivers and motivations of Indian startup founders, identify the challenges these
              startups face, and delineate the pillars that support them. The purpose of this study is to give a
              comprehensive understanding of both growth factors and challenges facing Indian startups.
            </p>
            <button [routerLink]="['/case-studies/the-key-technology-challenges-of-emerging-startups-and-how-to-navigate']" class="global-btn"
              mat-button>Read
              the Case Study</button>
          </div>
        </div>
        <!-- LATEST  07-May-2021 -->
        <div class="blog-listing">
          <div class="blog-media">
            <img src="../../../../assets/images/case-studies/whitepaper/1.webp" alt="">
          </div>
          <div class="blog-head">
            <h1 class="blog-title">DIGITAL IN BFSI IS NO LONGER A TECHNOLOGY : White Paper</h1>
            <span class="date">Posted on jun 22. 2021, by Eliza</span>
          </div>
          <div class="blog-content">
            <p>
              The COVID-19 pandemic has been one of the most severe challenges faced by the financial services industry
              in a decade. The impact of COVID-19 on the banking sector will be a severe drop in demand,
              decrease in income, production stoppages, and negatively affect banks' activities. Staff shortages,
              insufficient digital maturity exacerbate the situation and pressure on existing infrastructure as
              businesses scramble to cope with the impact of COVID-19 on financial services. The COVID-19 pandemic has
              accelerated and already established decline in intra-firm banking, creating a need to grow or fail. This
              white paper also describes the key drivers of cloud adoption and business value and benefits.
            </p>
            <button [routerLink]="['/case-studies/digital-in-bfsi-is-no-longer-a-technology']" class="global-btn"
              mat-button>Read
              the Case Study</button>
          </div>
        </div>

        <!-- LATEST  07-May-2021 -->
        <div class="section-heading mt-30">
          <h1 class="main-heading">Case Studies</h1>
        </div>
        <div class="blog-listing">
          <div class="blog-media">
            <img src="../../../../../assets/images/case-studies/1.webp" alt="">
          </div>
          <div class="blog-head">
            <h1 class="blog-title">We Go Beyond ERP Implementation : A Case Study</h1>
            <!-- <span class="date">Posted on Apr 19. 2021, by Krishna</span> -->
          </div>
          <div class="blog-content">
            <p>
              With our years of experience, we at RappinoTech understand the nuances involved in
              business transformation, especially when it consists of transforming from a conventional
              business model to a digital business model. That is why when one of the leading consumer
              electronic goods and services companies approached us for digital transformation, we
              immediately got into our natural mode of work. Which is first understanding our client’s
              present and aspired state of business model and then educating them. This has been our
              style since our inception because we feel that is how we can create an impact.
            </p>
            <button [routerLink]="['/case-studies/we-go-beyond-erp-implementation']" class="global-btn" mat-button>Read
              the Case Study</button>
          </div>
        </div>
      </div>
      <div class="column_two_listing" fxFlex="35" fxFlexFill>
        <div class="newsletter-section form-section">
          <h3 class="news-heading">Subscribe to newsletter</h3>
          <form (ngSubmit)="addNewsSletter(newsletterForm)" #newsletterForm="ngForm" [formGroup]="newsSletterForm"
            class="form-section">
            <div class="custom-form-group">
              <div class="custom-form-control">
                <mat-form-field appearance="outline">
                  <input type="text" matInput formControlName="email" placeholder="E-MAIL ADDRESS" />
                  <mat-error>
                    <span>Enter Valid Email</span>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <button type="submit" class="global-btn" mat-button>Subscribe Now </button>
          </form>
          <ul class="s-links">
            <p>Follow Us</p>
            <li>
              <a href="https://www.linkedin.com/company/rapinnotech/" target="_blank">
                <img src="../../../../../assets/images/social/1.svg">
              </a>
            </li>
            <li>
              <a href="https://twitter.com/rapinnotech" target="_blank">
                <img src="../../../../../assets/images/social/2.svg">
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/RapinnoTech" target="_blank">
                <img src="../../../../../assets/images/social/3.svg">
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
