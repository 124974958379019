import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-do-digital-trends-predict-a-post-pandemic-future',
  templateUrl: './how-do-digital-trends-predict-a-post-pandemic-future.component.html',
  styleUrls: ['./how-do-digital-trends-predict-a-post-pandemic-future.component.scss']
})
export class HowDoDigitalTrendsPredictAPostPandemicFutureComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How Do 2021 Digital Trends Predict A Post-Pandemic Future?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | How Do 2021 Digital Trends Predict A Post-Pandemic Future?');
    this.seoService.updateOgDescription('We are in the middle of 2021. Here a trending update based on this years best digital agility and operations predictions');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/index/blog/How-Do-2021-Digital-Trends-Predict-A-Post-Pandemic-Future-?');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/37.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How Do 2021 Digital Trends Predict A Post-Pandemic Future??'),
    this.seoService.updateMetaDescription('We are in the middle of 2021. Here a trending update based on this years best digital agility and operations predictions')
  }
}