import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SEOService } from '../../core/seo.service';
@Component({
  selector: 'app-product-migration',
  templateUrl: './product-migration.component.html',
  styleUrls: ['./product-migration.component.scss']
})
export class ProductMigrationComponent implements OnInit {
  public migrationConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    speed: 2000,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    keyboard: true,
    loop: false,
    longSwipes: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: false,
    // autoplay: true,
    slidesOffsetAfter: 0,
    spaceBetween: 20,
    pagination: true,
    navigation: {
      nextEl: '.web-button-next',
      prevEl: '.web-button-prev',
    },
    breakpoints: {
      240: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  };
  constructor(
    private seoService: SEOService
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Product Migration | Rapinno Tech');
    this.seoService.updateOgTitle('Product Migration | Rapinno Tech');
    this.seoService.updateOgDescription('Rapinno Tech Product Migration follows a scientific approach to technology migration and has considerable experience in upgrading and migrating technology stacks, re-engineering, and re-architecture systems.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/rapinno-digital-platform/product-migration');
    this.seoService.updateMetaTitle('Product Migration | Rapinno Tech'),
    this.seoService.updateMetaDescription('Rapinno Tech Product Migration follows a scientific approach to technology migration and has considerable experience in upgrading and migrating technology stacks, re-engineering, and re-architecture systems.')
  }
}
