<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Rapinno Adaptive Sourcing</h1>
                    </div>
                    <p>
                        Rapinno's adaptive sourcing approach helps organizations move away from the traditionally tiered sourcing strategy and aims to align their IT resources to respond faster to changes in customer demand or seize opportunities in new sectors and markets by
                        rapidly adapting new technologies. With our expertise in innovating and adapting new technologies as per business requirements, we guide our clients to venture into their desired segments and into the new market.
                    </p>
                    <p>Adding to that we have designed a specialized recruitment methodology to deliver engaging, significant, and performance-ready quickly IT resources to the businesses who can adapt to the dynamic technological environment. We integrate
                        our recruitment services with the client’s business vision and ongoing process, which helps us to provide the best talented resources on time.</p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/adaptive-sourcing.jpg" alt="Businessmen meeting to discuss the implementation of Adaptive sourcing">

                </div>
            </div>
        </div>
    </div>
</section>
<!-- Innovate -->
<section class="innovate-sec">
    <div class="wrapper">
        <!-- <div class="service_head text-center">
      <h2>Our Adaptive Sourcing Services Include</h2>
    </div> -->
        <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div class="innovate-box-shadow" fxLayout="column" fxFlex.xl="80" fxFlex.lg="80" fxFlex.md="80" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="innovate-box">
                    <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                        <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="innovate-img">
                                <img src="../../../../../assets/images/services/innovate-img.svg" alt="Illustration showcasing girl innovating new ideas by testing multiple solutions">
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="innovate-content">
                                <div>
                                    <h3>Innovate</h3>
                                    <p>
                                        We partner with a diverse and distinguished essential businesses who can innovate, communicate and respond as per your business needs of future. We operate in short life cycles and guided by industry best practices to provide you with the best adaptive
                                        solution.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec innovate-sec">
    <div class="wrapper">
        <!-- <div class="section-heading-content">
    </div> -->
        <div class="innovate-box">
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head">
                            <h3>Differentiate</h3>
                            <p>
                                With adaptive sourcing, we give you unique organizational skills. We obtain the most advanced and unique technology strategies from external suppliers we work with to make you stand out from the competition.
                            </p>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/services/adaptive-left.svg" alt="Illustration showcasing man working on process oriented services">
                    </div>
                </div>
            </div>
        </div>
        <div class="innovate-box">
            <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/services/adaptive-right.svg" alt="Illustration showcasing team working as support assistance for different adaptive modules">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head">
                            <h3>Support</h3>
                            <p>
                                We support your core day-to-day activities to enable an adaptive supply in your daily operations. Low support rate, reliability, compliance and security are the main points of focus in Rapinno's adaptive sourcing support for our customers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Adaptive Sourcing</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>