<section class="master_rapino_sec">
  <!-- SERVICE-BANNER-SEC -->
  <section class="service-intro-sec">
    <div class="banner-dots left">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
      <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
        fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <div fxLayout="column" fxFlex="60" fxFlexFill>
          <div class="intro-content">
            <div class="section-heading">
              <h1 class="main-heading">RDP E-commerce</h1>
            </div>
            <p>
              Your retail/e-commerce business will augment as your e-commerce will integrate all the top features like,
              coupon management, catalogue management, multi-vendor management system. Even if you have an all
              set-up e-commerce but you are looking for a better advance platform, we will ensure that the transition is
              completely seamless and hassle-free. Our dedicated team will ensure that your vision pen outs the way you
              want.
            </p>
          </div>
        </div>
        <div fxLayout="column" fxFlex="40" fxFlexFill>
          <div class="intro-img">
            <img class="raduis-img" src="../../../../../assets/images/service-banner/e-commerce.jpg" alt="img">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="wrapper">
      <div class="global_heading service_head  text-center">
        <h2>Our Expertise </h2>
        <p>We promise an all-in one platform where you can sell anything anywhere</p>
      </div>
      <section class="sec-padding">
        <div class="wrapper">
          <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
            fxLayout.xs="column" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/1.svg" alt="">
                <h4>Custom ecommerce solutions development </h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/2.svg" alt="">
                <h4>Catalogue Management</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/3.svg" alt="">
                <h4>Orders management</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/4.svg" alt="">
                <h4>Multi-Vendors
                  management</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/5.svg" alt="">
                <h4>Inventory management</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/6.svg" alt="">
                <h4>Coupon management
                  system</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/7.svg" alt="">
                <h4>Payment gateway integration</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/8.svg" alt="">
                <h4>Multi-language support</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/9.svg" alt="">
                <h4>Multi-currency support</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/10.svg" alt="">
                <h4>Support chat</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/11.svg" alt="">
                <h4>Reports & Analytics</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/12.svg" alt="">
                <h4>Content management
                  system</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/e-commerce/13.svg" alt="">
                <h4>Google analytics</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
  <!-- CTS SECTION-->
  <section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt=""  >
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  >
    <div class="cta-content">
      <h3 class="cta-heading">RapinnoTech E-commerce development service will take care of the entire front end and back
        development</h3>
      <button (click)="brochure()" class="know-btn" mat-button> View
        Brochure </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  >
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt=""  >
  </section>
  <section class="service-heading-content-sec">
    <div class="wrapper">
      <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
        fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-right-img">
            <img src="../../../../../assets/images/e-commerce/problem-statement.svg" alt="">
          </div>
        </div>
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-left-content d-flex-center">
            <div class="left-head">
              <h3>Problem Statement</h3>
              <p>
                Scalability and adaptability in terms of tech integration is one of the biggest problems that most of
                the e-commerce platforms deal with. This hinders the growth of retail firms.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
        fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-left-content d-flex-center">
            <div class="left-head">
              <h4>Solution</h4>
              <p>
                With RapinnoTech's robust and scalable e-commerce solution, you will be able to scale your business as
                per your requirement. We have many clients who have grown to many folds using our adaptive e-commerce
                solution.
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-right-img">
            <img src="../../../../../assets/images/e-commerce/solution.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="tech_stock_sec sec-padding">
    <div class="wrapper">
      <div class="global_heading service_head  text-center">
        <h4>Tech Stack</h4>
        <p>We follow the time & material business model and Offshore dedicated team business model. Our technology stack
          includes:</p>
      </div>
      <div class="wrapper">
        <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
          fxLayout.xs="column" fxLayoutAlign="center stretch">
          <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
            <div class="tech_stock_box first_box">
              <h4>Mobile Application</h4>
              <div class="tech_images">
                <img src="../../../../../assets/images/e-commerce/t-8.svg" alt="">
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
            <div class="tech_stock_box first_box">
              <h4>Database</h4>
              <div class="tech_images">
                <img src="../../../../../assets/images/e-commerce/t-1.svg" alt="">
                <!-- <img src="../../../../../assets/images/e-commerce/t-2.svg" alt=""> -->
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
            <div class="tech_stock_box second_box">
              <h4>Front End</h4>
              <div class="tech_images">
                <img src="../../../../../assets/images/e-commerce/t-3.svg" alt="">
                <img src="../../../../../assets/images/e-commerce/t-4.svg" alt="">
                <img src="../../../../../assets/images/e-commerce/t-5.svg" alt="">
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
            <div class="tech_stock_box third_box">
              <h4>Backend</h4>
              <div class="tech_images">
                <!-- <img src="../../../../../assets/images/e-commerce/t-6.svg" alt=""> -->
                <img src="../../../../../assets/images/e-commerce/t-7.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
