import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-automation-in-banking-and-finance',
  templateUrl: './automation-in-banking-and-finance.component.html',
  styleUrls: ['./automation-in-banking-and-finance.component.scss']
})
export class AutomationInBankingAndFinanceComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('How Financial and Banking Services Can Benefit  From Intelligent Process Automation | Rapinno Tech');
    this.seoService.updateOgTitle('How Financial and Banking Services Can Benefit  From Intelligent Process Automation | Rapinno Tech');
    this.seoService.updateOgDescription('Technologies in combination with RPA can work wonders in the financial services industry and can process a wide variety of tasks that can seldom be accomplished by a stand-alone RPA.');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://rapinnotech.com/blogs/automation-in-banking-and-finance');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/49.webp');
    this.seoService.updateMetaTitle('How Financial and Banking Services Can Benefit  From Intelligent Process Automation | Rapinno Tech'),
    this.seoService.updateMetaDescription('Technologies in combination with RPA can work wonders in the financial services industry and can process a wide variety of tasks that can seldom be accomplished by a stand-alone RPA.')
  }
}
