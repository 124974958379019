import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-technical-buzzwords-you-need-to-know-to-get-started-for-an-exciting-innovation-journey',
  templateUrl: './technical-buzzwords-you-need-to-know-to-get-started-for-an-exciting-innovation-journey.component.html',
  styleUrls: ['./technical-buzzwords-you-need-to-know-to-get-started-for-an-exciting-innovation-journey.component.scss']
})
export class TechnicalBuzzwordsYouNeedToKnowToGetStartedForAnExcitingInnovationJourneyComponent implements OnInit {

  constructor(
    private seoService: SEOService
    ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Technical Buzzwords you need to know to get started for an exciting innovation journey');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Technical Buzzwords you need to know to get started for an exciting innovation journey');
    this.seoService.updateOgDescription('The digital world is in a constant state of evolution, with trends and technologies. Here are new technical buzzwords to help to keep up with the innovations.');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/Technical-Buzzwords-you-need-to-know-to-get-started-for-an-exciting-innovation-journey');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/46.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Technical Buzzwords you need to know to get started for an exciting innovation journey'),
    this.seoService.updateMetaDescription('The digital world is in a constant state of evolution, with trends and technologies. Here are new technical buzzwords to help to keep up with the innovations.')
  }
}
