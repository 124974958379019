import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-artificial-intelligence-boost-the-erp-software',
  templateUrl: './how-artificial-intelligence-boost-the-erp-software.component.html',
  styleUrls: ['./how-artificial-intelligence-boost-the-erp-software.component.scss']
})
export class HowArtificialIntelligenceBoostTheErpSoftwareComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How Artificial Intelligence Boost The ERP Software in 2021?');
    this.seoService.updateOgTitle('Rapinno Tech Blog |How Artificial Intelligence Boost The ERP Software in 2021?');
    this.seoService.updateOgDescription('Can you incorporate Artificial Intelligence into your ERP System? Learn about the benefits of AI-powered ERP software solutions and how AI is transforming the future of businesses.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/how-artificial-intelligence-boost-the-erp-software');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/25.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How Artificial Intelligence Boost The ERP Software in 2021?'),
    this.seoService.updateMetaDescription('Can you incorporate Artificial Intelligence into your ERP System? Learn about the benefits of AI-powered ERP software solutions and how AI is transforming the future of businesses. ')
  }
}
