import { ToastService } from './toast.service';
import { environment } from '../../../../environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private http: HttpClient, private toast: ToastService, private afs: AngularFirestore) { }
  async submitContactForm(contactFormDetails: any): Promise<boolean> {
    const fid = this.afs.createId()
    contactFormDetails.id = fid
    try {
      await this.afs.doc(`contact/${contactFormDetails.id}`).set(contactFormDetails)
      // const header = {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json',
      //   }),
      // };
      // await this.http
      //   .post(
      //     `${environment.serverBaseUrl}/auth/contact-us`,
      //     contactFormDetails,
      //     header
      //   )
      //   .toPromise();
      this.toast.openSnackBar('Enquiry submitted successfully');
      return true;
    } catch (err) {
      console.log(err);
      this.toast.openSnackBar(
        'Unable to submit form please try again later'
      );
      return false;
    }
  }
  // async submitQuotationForm(contactUSFormDetails: any): Promise<boolean> {
  //   const aid = this.afs.createId()
  //   contactUSFormDetails.id = aid
  //   try {
  //    await this.afs.doc(`contact us/${contactUSFormDetails.id}`).set(contactUSFormDetails)
  //     // const header = {
  //     //   headers: new HttpHeaders({
  //     //     'Content-Type': 'application/json',
  //     //   }),
  //     // };
  //     // await this.http
  //     //   .post(
  //     //     `${environment.serverBaseUrl}/auth/contact-us`,
  //     //     contactFormDetails,
  //     //     header
  //     //   )
  //     //   .toPromise();
  //     this.toast.openSnackBar('Enquiry submitted successfully');
  //     return true;
  //   } catch (err) {
  //     console.log(err);
  //     this.toast.openSnackBar(
  //       'Unable to submit form please try again later'
  //     );
  //     return false;
  //   }
  // }
  async contactViaEmailForm(newsletter: any): Promise<boolean> {
    const nid = this.afs.createId()
    newsletter.id = nid
    try {
      await this.afs.doc(`newletter/${newsletter.id}`).set(newsletter)
      this.toast.openSnackBar('Email submitted successfully');
      return true;
    } catch (err) {
      console.log(err);
      this.toast.openSnackBar(
        'Unable to submit form please try again later'
      );
      return false;
    }
  }
  async newsletterEmailForm(newsletterDetails: any): Promise<boolean> {
    try {
      const header = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      await this.http
        .post(
          `${environment.serverBaseUrl}/auth/newsletter`,
          newsletterDetails,
          header
        )
        .toPromise();
      return true;
    } catch (err) {
      console.log(err);
      this.toast.openSnackBar(
        'Unable to submit form please try again later'
      );
      return false;
    }
  }
  async carrierForm(carrierFormDetails: any): Promise<boolean> {
    const fid = this.afs.createId();
    carrierFormDetails.id = fid
    try {
      await this.afs.doc(`carrer/${carrierFormDetails.id}`).set(carrierFormDetails)
      // try {
      // const header = {
      //   headers: new HttpHeaders({
      //     'Content-Type': 'application/json',
      //   }),
      // };
      // await this.http
      //   .post(
      //     `${environment.serverBaseUrl}/auth/carrier`,
      //     carrierFormDetails,
      //     header
      //   )
      //   .toPromise();
      this.toast.openSnackBar('Your application have been submitted successfully');
      return true;
    } catch (err) {
      console.log(err);
      this.toast.openSnackBar('Unable to submit form please try again later');
      return false;
    }
  }
  async submitQuoteForm(quoteDetails: any): Promise<boolean> {
    try {
      const header = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      await this.http
        .post(
          `${environment.serverBaseUrl}/auth/get-quote`,
          quoteDetails,
          header
        )
        .toPromise();
      this.toast.openSnackBar('Quote  Submitted Successfully ');
      return true;
    } catch (err) {
      console.log(err);
      this.toast.openSnackBar('Unable to submit form please try again later');
      return false;
    }
  }
}
