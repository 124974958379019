import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-poc-vs-mvp-vs-prototype',
  templateUrl: './poc-vs-mvp-vs-prototype.component.html',
  styleUrls: ['./poc-vs-mvp-vs-prototype.component.scss']
})
export class PocVsMvpVsPrototypeComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | POC vs. MVP vs. Prototype: How to Choose the Best Approach?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | POC vs. MVP vs. Prototype: How to Choose the Best Approach?');
    this.seoService.updateOgDescription('What is a Proof of Concept, Prototype, and MVP? What are the main features of the product and its value proposition? Lets explore.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources//blogs/poc-vs-mvp-vs-Prototype');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/28.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | POC vs. MVP vs. Prototype: How to Choose the Best Approach?');
    this.seoService.updateMetaDescription('What is a Proof of Concept, Prototype, and MVP? What are the main features of the product and its value proposition? Lets explore.')
  }
}