<section class="master_rapino_sec">
  <!-- SERVICE-BANNER-SEC -->
  <section class="service-intro-sec">
    <div class="banner-dots left">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
      <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
        fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <div class="column stack_left " fxFlex="60" fxFlexFill>
          <div class="intro-content">
            <div class="section-heading">
              <h1 class="main-heading">Rapinno Digital platform</h1>
            </div>
            <p>
              Technological requirements often emerge suddenly, without providing any prior clues. We understand the
              importance of this urgency faced by companies of all sizes and types. Our rapid app development services
              are designed to meet the urgent needs of customers.
            </p>
            <p>
              Guided by a proven rapid application development model, we have created thousands of software in the
              shortest possible time. Whether you are in urgent need of a mobile app, website, online store or chatbot,
              we are ready to serve you.
            </p>

          </div>
        </div>
        <div class="column stack_right" fxFlex="100" fxFlexFill>
          <div class="intro-img">
            <img class="raduis-img" src="../../../../../assets/images/service-banner/rapinno-digital-platform.jpg"
              alt="Women showing  importance of Digital platform and need of a mobile app, website, online store or chatbot">
            <!-- <img class="raduis-img" src="../../../../../assets/test-bn.svg" alt="img"> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- IMG-BOX -->
  <section class="tech_gif_sec">
    <div class="wrapper">
      <div class="tech_gif_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
        fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
        <div class="tech_gif_column" fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100"
          fxFlex.xs="100" fxFlexFill>
          <div class="box">
            <img src="../../../../../assets/images/rapinno-digital-platform/rdp_tech.svg" alt="Steps of Procedure follow in Rapinno Digital Platform">
            <div class="rdp_btn_flex">
              <div class="rdp_btn tech_btn">
                <a class="btn btn-sm" mat-button [routerLink]="['/tech-stack']"
                  routerLinkActive="router-link-active">Tech stack</a></div>
              <div class="rdp_btn api_btn">
                <a class="btn btn-sm" mat-button [routerLink]="['/engine-api']"
                  routerLinkActive="router-link-active">Engines & APIs</a></div>
              <div class="rdp_desc">
                <p>Combination of tech stack, Engines, & APIs
                  We deliver rapid innovative solutions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="tech_desc_sec">
    <div class="wrapper">
      <div class="tech_gif_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
        fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
        <div class="tech_gif_column" fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="30"
          fxFlex.xs="100" fxFlexFill>
          <div class="rdp_right">
            <ul>
              <div class="overlay"></div>
              <div class="list_box">
                <div class="section-heading">
                  <h3 class="main-heading">Application Development</h3>
                </div>
                <li>
                  <a [routerLink]="['/rapinno-digital-platform/rapid-application-development']">
                    <img src="../../../../../assets/images/rapinno-digital-platform/tech_icons/3.svg" alt="img">
                    <span>Rapid Application development </span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/innovative-product-development']">
                    <img src="../../../../../assets/images/rapinno-digital-platform/tech_icons/5.svg" alt="img">
                    <span>Product Development</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/rapinno-digital-platform/product-migration']">
                    <img src="../../../../../assets/images/rapinno-digital-platform/tech_icons/2.svg" alt="img">
                    <span>Product Migration</span>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div class="tech_gif_column" fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="70" fxFlex.sm="70"
          fxFlex.xs="100" fxFlexFill>
          <div class="rdp_right rdp_left">
            <ul>
              <div class="overlay"></div>
              <div class="list_box">
                <div class="section-heading">
                  <h3 class="main-heading">Solutions</h3>
                </div>
                <li>
                  <a [routerLink]="['/rapinno-digital-platform/e-commerce']">
                    <img src="../../../../../assets/images/rapinno-digital-platform/tech_icons/3.svg" alt="img">
                    <span>RDP Ecommerce</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/rapinno-digital-platform/service-booking']">
                    <img src="../../../../../assets/images/rapinno-digital-platform/tech_icons/5.svg" alt="img">
                    <span>RDP Service Booking</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/rapinno-digital-platform/social-Platform']">
                    <img src="../../../../../assets/images/rapinno-digital-platform/tech_icons/2.svg" alt="img">
                    <span>RDP Social Platform</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/rapinno-digital-platforms/customer-service-platform']">
                    <img src="../../../../../assets/images/rapinno-digital-platform/tech_icons/4.svg" alt="img">
                    <span>RDP Customer Service Platform</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/rapinno-digital-platform/dms']">
                    <img src="../../../../../assets/images/rapinno-digital-platform/tech_icons/5.svg" alt="img">
                    <span>RDP DMS</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/rapinno-digital-platform/fleet-management']">
                    <img src="../../../../../assets/images/rapinno-digital-platform/tech_icons/1.svg" alt="img">
                    <span>RDP Fleet Management</span>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="tech_how_we_do">
    <div class="wrapper">
      <div class="tech_gif_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
        fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
        <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="section-heading">
            <h3 class="main-heading">How we do it? </h3>
          </div>
          <p class="desc">At Rapinno Tech, we have pre-structured engines and modules for the spectrum of industries
            like manufacturing, health care, business consultancy, etc. Thus, as per our customers' requirements in
            terms of features and business model, we start our Rapid Application Development procedure. Our team of
            developers and designers experienced with the latest technologies like Flutter, Node Js, etc., will work
            smartly and efficiently to transform your vision into reality.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="tech_how_we_do">
    <div class="wrapper">
      <div class="tech_gif_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
        fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
        <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="section-heading">
            <h3 class="main-heading">Why Should We Choose Rapinno Digital Platform? </h3>
          </div>
          <ul class="choose_list">
            <li><img src="../../../../../assets/images/green_ok.svg" alt=""  > Faster delivery of the product
            </li>
            <li><img src="../../../../../assets/images/green_ok.svg" alt=""  > Pure end-user centric approach
              for selection of technologies and APIs</li>
            <li><img src="../../../../../assets/images/green_ok.svg" alt=""  > Rapid digitization process using
              automation and machine learning</li>
            <li><img src="../../../../../assets/images/green_ok.svg" alt=""  > With every new client, we reduce
              the scope of error, thus an error-free application</li>
            <li><img src="../../../../../assets/images/green_ok.svg" alt=""  > 24*7 continuous support and
              assistance for any kind of unprecedented issues</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</section>
