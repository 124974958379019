<section class="footer-sec">
    <div class="footer">
        <div class="overlay"></div>
        <div class="wrapper">
            <div fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="ft-logo">
                        <a [routerLink]="['/']"> <img class="img-fluid" src="../../../../../assets/images/logo.svg" alt="Logo"></a>
                    </div>
                    <ul class="s-links">
                        <!-- <p>FOLLOW US</p> -->
                        <!-- LINKEDIN -->
                        <li>
                            <a href="https://www.linkedin.com/company/rapinnotech/" target="_blank">
                                <img src="../../../../../assets/images/social/1.svg">
                            </a>
                        </li>
                        <!-- TWITTER -->
                        <li>
                            <a href="https://twitter.com/rapinnotech" target="_blank">
                                <img src="../../../../../assets/images/social/2.svg">
                            </a>
                        </li>
                        <!-- FB -->
                        <li>
                            <a href="https://www.facebook.com/RapinnoTech" target="_blank">
                                <img src="../../../../../assets/images/social/3.svg">
                            </a>
                        </li>
                    </ul>
                    <form (ngSubmit)="addNewsSletter(sletterForm)" #sletterForm="ngForm" [formGroup]="newsSletterForm">
                        <h4>Subscribe To Newsletter</h4>
                        <div class="custom-form-group">
                            <div class="custom-form-control">
                                <mat-form-field appearance="outline">
                                    <input type="text" matInput formControlName="email" placeholder="E-MAIL ADDRESS" />
                                    <mat-error>
                                        <span>Enter Valid Email</span>
                                    </mat-error>
                                </mat-form-field>
                                <button type="submit" class="custom-icon">
                  <img src="../../../../../assets/images/icons/arrow-right-circle.svg" alt="">
                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <h4 class="footer-heading">Quick links</h4>
                    <div class="more-links-flex">
                        <ul class="more-links">
                            <!-- <li> <a [routerLink]="['/rapinno-digital-platform']">Rapinno Digital Platform</a></li> -->
                            <li> <a [routerLink]="['/rapid-software-development']">Rapid Software Development</a></li>
                            <li> <a [routerLink]="['/robotic-process-automation']">Robotic Process Automation</a></li>
                            <li> <a [routerLink]="['/intelligent-automation']">Intelligent Automation</a></li>
                            <li> <a [routerLink]="['/cloud-tech-and-services']">Cloud Technologies & Services</a></li>


                            <!-- <li><a [routerLink]="['/']">Rapinno Data Digitization</a></li> -->
                        </ul>
                        <ul class="more-links">
                            <li><a [routerLink]="['/rapinno-digital-platform']">Rapinno Digital Platform</a></li>
                            <li><a [routerLink]="['/rapinno-product-360']">Rapinno Product 360</a></li>
                            <li><a [routerLink]="['/rapinno-ia-eco-system']">Rapinno IA Ecosystem</a></li>
                            <li><a [routerLink]="['/rapinno-managed-services']">Rapinno Managed Services</a></li>
                            <!-- <li> <a [routerLink]="['/rapinno-data-digitization']">Rapinno Data Digitization </a></li>
                            <li> <a [routerLink]="['/rapinno-marketing-360']">Rapinno Marketing 360</a></li> -->
                            <!-- <li> <a [routerLink]="['/our-services/blockchain']">Blockchain</a></li>
              <li> <a [routerLink]="['/our-services/digital-marketing']">Digital Marketing</a></li>
              <li> <a [routerLink]="['/our-services/ai-&-automation']">AI & Automation</a></li>
              <li> <a [routerLink]="['/our-services/sales-service']">Sales as a service</a></li> -->
                        </ul>
                        <ul class="more-links">
                            <li> <a [routerLink]="['/blogs']">Resources</a></li>
                            <!-- <li> <a (click)="scrollOne()">Contact Us</a></li> -->
                            <li> <a [routerLink]="['/contact']">Contact Us</a></li>


                            <!-- (click)="scrollOne()" -->
                            <li> <a [routerLink]="['/about-us']">About Us</a></li>
                        </ul>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                     <h4 class="footer-heading">Reach us</h4> 
                    <!--<img src="../../../../../assets/images/reach_us.png" alt="img">-->
                    <div class="" style="padding-top: 5px;">
                        <div class="ft-text">
                            <h4 class="location-type"><img src="../../../../../assets/images/icons/Icons/usa.svg" alt="">&nbsp; USA</h4>
    
                            <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/mail.svg" alt="">
                                Email:
                                info.usa@rapinnotech.com
                            </h6>
                            <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/phone.svg" alt="">
                                Phone:+1 949 516 8425
                                ‎
                            </h6>
                            <br>
                            <h4 class="location-type"><img src="../../../../../assets/images/icons/Icons/germany.svg"
                                    alt=""> &nbsp; GERMANY</h4>
                            <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/mail.svg" alt="">
                                Email:
                                info.europe@rapinnotech.com
                            </h6>
                            <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/phone.svg" alt="">
                                Phone: + 49 17693133848
                                ‎
                            </h6>
                            <br>
                            <h4 class="location-type"><img src="../../../../../assets/images/icons/Icons/indai.svg" alt="">
                                &nbsp; INDIA</h4>
                            <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/mail.svg" alt="">
                                Email:
                                info.india@rapinnotech.com
                            </h6>
                            <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/phone.svg" alt="">
                                Phone:
                                ‎+91 40 44720040
                            </h6>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <div fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <p class="copy-right">
                    <!-- © RapinnoTech Solutions. 2019. All Rights Reserved -->
                    © 2021, RapinnoTech Solutions
                </p>
            </div>
        </div>
    </div>
</section>
