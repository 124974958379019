import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-to-choose-the-best-suited-erp-software-for-schools-colleges-and-universities',
  templateUrl: './how-to-choose-the-best-suited-erp-software-for-schools-colleges-and-universities.component.html',
  styleUrls: ['./how-to-choose-the-best-suited-erp-software-for-schools-colleges-and-universities.component.scss']
})
export class HowToChooseTheBestSuitedErpSoftwareForSchoolsCollegesAndUniversitiesComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Page Title Rapinno Tech Blog | How To Choose The Best Suited ERP Software For Schools, Colleges, and Universities in 2021?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Page Title Rapinno Tech Blog | How To Choose The Best Suited ERP Software For Schools, Colleges, and Universities in 2021?');
    this.seoService.updateOgDescription('How to Choose the Best ERP Software for Educational Institutions? A management system that is best suited for education ERP software. The ERP software you choose for your institution can be modified to suit your needs.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/how_to_choose_the_best_suited_erp_software_for_schools_colleges_and_universities');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/24.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Page Title Rapinno Tech Blog | How To Choose The Best Suited ERP Software For Schools, Colleges, and Universities in 2021?');
    this.seoService.updateMetaDescription('How to Choose the Best ERP Software for Educational Institutions? A management system that is best suited for education ERP software. The ERP software you choose for your institution can be modified to suit your needs.')
  }
}
