import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { MatrimonyBrochureComponent } from '../../components/matrimony-brochure/matrimony-brochure.component';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-matrimony',
  templateUrl: './matrimony.component.html',
  styleUrls: ['./matrimony.component.scss']
})
export class MatrimonyComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private seoService: SEOService
  ) { }

  ngOnInit(): void {

this.setSeo();
  }
  matrimonyBrochure(): void {
    this._dialog.open(MatrimonyBrochureComponent, {
      width: '50%',
      panelClass: 'custom-modal',
      disableClose: true
    });
  }


  setSeo(): void {
    this.seoService.updatePageTitle('Social Platform | Rapinno Tech | RDP');
    this.seoService.updateOgTitle('Social Platform | Rapinno Tech | RDP');
    this.seoService.updateOgDescription('Create your Matrimony web or mobile app with all your desired features like Social sign-in or log-in, GPS integration, in-app chat & camera for video calls, etc.');
    this.seoService.updateOgType('RDP Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/rapinno-digital-platform/social-Platform');
    this.seoService.updateMetaTitle('Social Platform | Rapinno Tech | RDP'),
    this.seoService.updateMetaDescription('Create your Matrimony web or mobile app with all your desired features like Social sign-in or log-in, GPS integration, in-app chat & camera for video calls, etc.')
  }
}
