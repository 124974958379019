import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';
@Component({
  selector: 'app-integrated-marketing-service',
  templateUrl: './integrated-marketing-service.component.html',
  styleUrls: ['./integrated-marketing-service.component.scss']
})
export class IntegratedMarketingServiceComponent implements OnInit {
  constructor(
    private seoService: SEOService,
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Marketing 360 | Rapinno Tech');
    this.seoService.updateOgTitle('Rapinno Marketing 360 | Rapinno Tech');
    this.seoService.updateOgDescription('Rapinno Techs Marketing 360  is to make all aspects of marketing communication from advertising, customer relation management to online communications, and social media that helps tour business model.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/intelligent-automation');
    this.seoService.updateMetaTitle('Rapinno Marketing 360 | Rapinno Tech'),
    this.seoService.updateMetaDescription('RRapinno Techs Marketing 360  is to make all aspects of marketing communication from advertising, customer relation management to online communications, and social media that helps tour business model.')
  }
}
