<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Product Migration</h1>
                    </div>
                    <p>
                        Rapinno Tech helps companies migrate existing technology stacks to newer technology stacks in less time and minimal cost.
                    </p>
                    <p>
                        Organizations need to keep pace with the latest technology stack in their product offerings over time. This means updating the version of the technology stack, libraries, and tools to an evolved version. The Newer versions of the technology usually make
                        changes in the codebase, and therefore the applications cannot be easily updated. Sometimes the application needs to be redesigned and architecturally redesigned to support newer business and use cases to use the latest technology
                        stack. We support companies with requirements in updating their systems and applications with the required technology.
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/product-migration.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="rapinno-solution-sec">
    <section class="migration-sec service-heading-content-sec">
        <div class="wrapper">
            <div class="service_head migration_head">
                <h2 class="text-center">Methodology</h2>
                <p>Rapinno Tech follows a scientific approach to technology migration and has considerable experience in upgrading and migrating technology stacks, re-engineering, and re-architecture systems. AFour follows a unique philosophy to migrate
                    and understands that the following aspects must also be delivered and managed:</p>
            </div>
            <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
                <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                    <div class="product_migration_swiper">
                        <div class="swiper-container" [swiper]="migrationConfig" (indexChange)="onIndexChange($event)">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="product_migration_box">
                                        <img src="../../../../assets/images/product-migration/1.png" alt="" loading="lazy">
                                        <h3 class="title text-center">Requirement Engineering</h3>
                                        <p class="sub-title text-center">Client Discussion</p>
                                        <p class="sub-title text-center">Designing Blue Print</p>
                                        <p class="sub-title text-center">Mapping our Pain Points</p>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="product_migration_box">
                                        <img src="../../../../assets/images/product-migration/2.png" alt="" loading="lazy">
                                        <h3 class="title text-center">Technology Selection</h3>
                                        <p class="sub-title text-center">Upgradations</p>
                                        <p class="sub-title text-center">Identifying Limitations</p>
                                        <p class="sub-title text-center">Evaluation Of Different Technologies</p>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="product_migration_box">
                                        <img src="../../../../assets/images/product-migration/4.svg" alt="" loading="lazy">
                                        <h3 class="title text-center">Re-architecting and Re-engineering</h3>
                                        <p class="sub-title text-center">Detailed Plan for development</p>
                                        <p class="sub-title text-center">Create & Cater Use Cases</p>
                                        <p class="sub-title text-center">Decoding Market Dynamics</p>
                                        <p class="sub-title text-center">Defining the User Experience</p>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="product_migration_box">
                                        <img src="../../../../assets/images/product-migration/3.png" alt="" loading="lazy">
                                        <h3 class="title text-center">Documentation</h3>
                                        <p class="sub-title text-center">Document Migration</p>
                                        <p class="sub-title text-center">Document Upgradation</p>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="product_migration_box">
                                        <img src="../../../../assets/images/product-migration/5.svg" alt="" loading="lazy">
                                        <h3 class="title text-center">Costing</h3>
                                        <p class="sub-title text-center">Estimating & Evaluating Cost</p>
                                        <p class="sub-title text-center"> Finding better resources and tools</p>
                                    </div>
                                </div>
                            </div>
                            <div class="navigations">
                                <div class="swiper-button-prev web-button-prev">
                                </div>
                                <div class="swiper-button-next web-button-next">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Rapid Product Migration</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact
      Now</button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>
<section class="rapinno-solution-sec key_offerings_migration">
    <section class="innovate-sec service-heading-content-sec">
        <div class="wrapper">
            <div class="service_head">
                <h2>Our key offerings include</h2>
            </div>
            <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                    <div class="key_offerings_box">
                        <h3>
                            <span>Requirement Engineering</span>
                        </h3>
                        <p>Rapinno Tech experts connect with customers and understand their pain areas and the "why" of technology migration/upgrade. As described, two broad use cases are the cause of migration:
                        </p>
                        <p>- Technology stack upgrade to a newer version</p>
                        <p>- Rebuild and rebuild applications to accommodate new business cases and applications</p>
                        <p>Once the requirement has been identified, the next step is to work with customer teams to learn more about the needs and dependencies and obtain artifacts to make the right technology selection.</p>
                    </div>
                    <div class="key_offerings_box">
                        <h3>
                            <span>Technology Selection</span>
                        </h3>
                        <p>If the older technology requires you to upgrade to a newer version, there are few options. Customers may not like using the latest technology stack and may prefer to use more recent versions of the existing stack. Based on these
                            constraints, technology choices are then assessed and discussed in detail with the customer. We also create concept validation applications and demonstrates to the client to evaluate some of the capabilities if needed.</p>
                        <p>Customers want to redesign and rebuild their systems to handle use cases. Rapinno Tech helps you select and evaluate technologies to create great products and save time to redesign and remodel your architecture. One good example
                            that requires redesigning and changing the application architecture is when organizations want their existing on-premises applications deployed in the cloud. It helps you choose a cloud provider and its services with a minimal
                            total cost of ownership.</p>
                    </div>
                    <div class="key_offerings_box">
                        <h3>
                            <span>Re-architecting and Re-engineering</span>
                        </h3>
                        <p>Re-architecting and re-engineering a product or service is easier said than done and often means developing from scratch. However, it is necessary to examine the product or service's existing technical debt and the "why" of the
                            new development. However, the re-engineering exercise must still consider all current use cases, changes in some use cases, and new use cases and additions. Besides, the dynamics must be taken into account in the new development,
                            e.g., the changing demands on user needs, cloud adoption, and the emphasis on a great user experience. With these and more, Rapinno Tech supports the migration of technology to satisfaction.</p>
                    </div>
                    <div class="key_offerings_box">
                        <h3>
                            <span>Documentation</span>
                        </h3>
                        <p>
                            Technology migration is a new system that accommodates new use cases. As such, Rapinno Tech helps prepare the unique system's documentation and helps companies make a smooth transition. Customers with an old technology stack often don't have the expertise
                            and documentation to get started. We will help you with the migration and update your documentation.
                        </p>
                    </div>
                    <div class="key_offerings_box">
                        <h3>
                            <span>Costing</span>
                        </h3>
                        <p>
                            Rapinno Tech helps reduce long-term costs by migrating to the cloud, using open source tools and technologies, and re-architecting and re-engineering the product to minimize technical debt and facilitate changes and improvements. With excellent documentation,
                            we will reduce the customer team's knowledge transfer time and maintain systems more manageable and better.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>