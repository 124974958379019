<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Software Testing</h1>
                    </div>
                    <p>
                        At Rapinno Tech, we offer a wide range of software testing and QA services latest technologies like AI/ML products. We continuously face demanding quality assurance requirements and can quickly adapt to the industry's agility with our structured processes,
                        tools, and techniques.
                    </p>
                    <p>
                        Our software testers and QA specialists have experience in various QA and test services such as test automation, performance testing, security testing, and functional testing. Our professional approach throughout SDLC makes it easy to improve product/project
                        quality and development experience.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/software-testing.jpg" alt="Person Testing browser compactabality in laptop and mobile as a part of software testing in rapinno tech">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec">
    <div class="wrapper">
        <div class="section-heading-content">
            <h2 class="heading_sec">Our Software Testing Services include</h2>
        </div>
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-left-content">
                    <div>
                        <div class="left-head">
                            <h4>Quality Assurance</h4>
                        </div>
                        <div class="service_ul">
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Test Automation
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Functional Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Performance Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Mobile Testing
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Security Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Regression Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Crowd Sourced Testing
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-right-img">
                    <img src="../../../../../assets/images/services/test-left.svg" alt="Person Testing Quality assureance as a part of Software Testing at rapinno tech">
                </div>
            </div>
        </div>
        <div class="xs-col-rev service_row mt-20" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxFlexFill fxLayoutAlign="start start">
                <div class="service-right-img">
                    <img src="../../../../../assets/images/services/test-right.svg" alt="Person Testing Quality Engineering as a part of Software Testing at rapinno tech">
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                <div class="service-left-content">
                    <div>
                        <div class="left-head">
                            <h3> Quality Engineering</h3>
                        </div>
                        <div class="service_ul">
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Test Data Management
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Agile Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> DevOps Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Service Virtualization
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> ERP Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Big Data Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Artificial Intelligence Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> IoT Testing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Blockchain Testing
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Software Testing</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>