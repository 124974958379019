<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Robotic Process Automation</h1>
                    </div>
                    <p>
                        Robotic Process Automation (RPA) enables you to automate routine tasks with intelligent software bots. It helps in automating business processes such as administrative tasks, billing, accounting, and much more.
                    </p>
                    <p>
                        At Rapinno Tech, we help organizations integrate machine learning and artificial intelligence into necessary business units to transform the system. With our comprehensive RPA consulting services, we instill intelligent automation into our clients' businesses
                        and help them increase productivity, reduce operating costs, and improve scalability.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/robotic_process_automation.jpg" alt="Android typing codes on computer representing RPA">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Innovate -->
<section class="robotic-process-automation-sec">
    <section class="innovate-sec service-heading-content-sec">
        <div class="wrapper">
            <div class="service_head">
                <h2>Key RPA offerings include</h2>
            </div>
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="0px">
                <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill fxLayoutAlign="center center">
                    <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                            <div class="service-right-img">
                                <img src="../../../../../assets/images/services/robotic_process_automation/constant_monitoring.svg" alt="Illustration showcasing RPA consultant monitoring
                  ">
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                            <div class="service-left-content">
                                <div class="left-head  min-height-align">
                                    <div>
                                        <div class="left-head">
                                            <h2>RPA Consulting</h2>
                                        </div>
                                        <p>
                                            We help you understand and identify processes that need to be automated. As soon as we have optimized productivity and FTE, we plan the roadmap for introducing automation. Our RPA consultants then identify the right RPA and OCR tools and finally present
                                            you with automation PoCs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- SERVICE-HEADING-SEC -->
    <section class="service-heading-content-sec">
        <div class="wrapper">
            <!-- <div class="section-heading-content">
    </div> -->
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head  min-height-align">
                            <div>
                                <div class="left-head">
                                    <h2>Automation Design</h2>
                                </div>
                                <p>
                                    Rapinno's automation experts will help you map manual processes that require automation. Then we will identify the intervention models to apply and after designing the automation architecture, we will develop a custom framework to implement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/services/robotic_process_automation/cntinuous_validation.svg" alt="Illustration showcasing RPA consultant validating the overall automation process">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- CTS SECTION-->
    <section class="section-cta section-padding">
        <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
        <!-- <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  > -->
        <div class="cta-content">
            <h3 class="cta-heading">We can help you in Robotic Process Automation</h3>
            <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> CONTACT
        NOW </button>
        </div>
        <!-- <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  > -->
        <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
    </section>
    <!-- SERVICE-HEADING-SEC -->
    <section class="service-heading-content-sec">
        <div class="wrapper">
            <!-- <div class="section-heading-content">
      </div> -->
            <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/services/robotic_process_automation/monitoring_application.svg" alt="Illustration showcasing machine learning entity implementing new processes">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head min-height-align">
                            <div>
                                <div class="left-head">
                                    <h2>RPA Implementation</h2>
                                </div>
                                <p>
                                    We will design and develop bots with AI, ML and cognitive services for process automation. Our RPA experts will then coordinate workflows and governance as well as the validation of the rollout. Finally, we will help you scale your business continuity
                                    as per your requirement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head min-height-align">
                            <div>
                                <div class="left-head">
                                    <h2>RPA SUPPORT</h2>
                                </div>
                                <p>
                                    Our support team helps you with bot management, error and disaster recovery, risk management and opportunity discovery. We also offer RPA business training for your employees to stay up to date with RPA tools.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/services/robotic_process_automation/Identify.svg" alt="Illustration showcasing support work provided  by machine">
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>