import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.scss']
})
export class CloudComponent implements OnInit {
  constructor(
    private seoService: SEOService
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
    setSeo(): void {
      this.seoService.updatePageTitle('Cloud Technologies & Services | Rapinno Tech');
      this.seoService.updateOgTitle('Cloud Technologies & Services | Rapinno Tech');
      this.seoService.updateOgDescription('Rapinno Tech provides best cloud services for small businesses and start-ups at very affordable price. ');
      this.seoService.updateOgType('Cloud Technologies & Services Page');
      this.seoService.updateOgUrl('https://www.rapinnotech.com/cloud-tech-and-services');
      this.seoService.updateMetaTitle('Cloud Technologies & Services | Rapinno Tech'),
      this.seoService.updateMetaDescription('Rapinno Tech provides best cloud services for small businesses and start-ups at very affordable price. ')
    }
}
