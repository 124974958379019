import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-rapinno-intelligent-automation-ecosystem-defining-business-process-management',
  templateUrl: './rapinno-intelligent-automation-ecosystem-defining-business-process-management.component.html',
  styleUrls: ['./rapinno-intelligent-automation-ecosystem-defining-business-process-management.component.scss']
})
export class RapinnoIntelligentAutomationEcosystemDefiningBusinessProcessManagementComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Rapinno Intelligent Automation Ecosystem: Defining Business Process Management');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Rapinno Intelligent Automation Ecosystem: Defining Business Process Management');
    this.seoService.updateOgDescription('Business Process Management  is the process of advanced optimization to increase the proficiency and adjust them to hierarchical objectives. Get in touch with Rapinno today.');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('Rapinno Tech Blog | Rapinno Intelligent Automation Ecosystem: Defining Business Process Management');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/40.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Rapinno Intelligent Automation Ecosystem: Defining Business Process Management');
    this.seoService.updateMetaDescription('Business Process Management  is the process of advanced optimization to increase the proficiency and adjust them to hierarchical objectives. Get in touch with Rapinno today.')
  }
}