import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-what-is-hyperledger-and-how-is-it-adding-value-to-various-industries',
  templateUrl: './what-is-hyperledger-and-how-is-it-adding-value-to-various-industries.component.html',
  styleUrls: ['./what-is-hyperledger-and-how-is-it-adding-value-to-various-industries.component.scss']
})
export class WhatIsHyperledgerAndHowIsItAddingValueToVariousIndustriesComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | What is Hyperledger and how is it adding value to various industries?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | What is Hyperledger and how is it adding value to various industries?');
    this.seoService.updateOgDescription('Hyperledger helps any organization to build blockchain applications that enhance and framework cases that simplify and improve productivity and growth of various industries.');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/ What-is-Hyperledger-and-how-is-it-adding-value-to-various-industries-?');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/48.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | What is Hyperledger and how is it adding value to various industries?'),
    this.seoService.updateMetaDescription('Hyperledger helps any organization to build blockchain applications that enhance and framework cases that simplify and improve productivity and growth of various industries.')
  }
}
