import { Component, OnInit, HostListener } from '@angular/core';
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from '@angular/animations';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openCloseMenu', [
      state('true', style({ height: '*', opacity: '1' })),
      state('false', style({ height: '0px', opacity: '0' })),
      transition('false <=> true', [animate(500)]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  xpandStatus = false;
  panelOpenState;
  // MBOILE-MENU
  customNavCollapsedHeight = '30px';
  customNavExpandedHeight = '30px';
  listNone = false;

  isOpen = false;
  logoHidden = false;
  megaMenuShow = false;
  opened: boolean;
  isSticky: boolean = false;
  isScrollTop: boolean = false;
  customCollapsedHeight = '35px';
  customExpandedHeight = '35px';
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 0;
    this.isScrollTop = window.pageYOffset >= 0;
  }
  constructor(public router: Router) { }

  toggleMenu() {
    this.opened = !this.opened;
  }

  menuToggle(): void {
    this.megaMenuShow = !this.megaMenuShow;
  }

  // scrollTop() {
  //   (function smoothscroll() {
  //     var currentScroll =
  //       document.documentElement.scrollTop || document.body.scrollTop || 0;
  //     if (currentScroll > 0) {
  //       window.requestAnimationFrame(smoothscroll);
  //       window.scrollTo(0, 0);
  //     }
  //   })();
  // }
  scrollTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;

      if (currentScroll > 10) {
        window.scrollTo(0, 0);
      }
    })();
  }
  ngOnInit(): void { }
  // loginPopup() {
  //   this.dialog.open(LoginComponent, {
  //     width: '65%',
  //     panelClass: 'login-modal'
  //   });
  // }
}
