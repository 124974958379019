import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-testing',
  templateUrl: './software-testing.component.html',
  styleUrls: ['./software-testing.component.scss']
})
export class SoftwareTestingComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }


    setSeo(): void {
      this.seoService.updatePageTitle('Software Testing | Rapinno Tech');
      this.seoService.updateOgTitle('Software Testing | Rapinno Tech');
      this.seoService.updateOgDescription('At Rapinno Tech, we offer a wide range of software testing and QA services latest technologies like AI/ML products.');
      this.seoService.updateOgType('Service Page');
      this.seoService.updateOgUrl('https://www.rapinnotech.com/software-testing');
      this.seoService.updateMetaTitle('Software Testing | Rapinno Tech'),
      this.seoService.updateMetaDescription('At Rapinno Tech, we offer a wide range of software testing and QA services latest technologies like AI/ML products.')
    }
}
