<section class="team">
    <div class="wrapper">
        <div class="contentheader">
            <h2>Our Team</h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="column" fxFlex="75" fxFlex.lt-sm="95">
                <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
                        <img src="../../../../assets/images/team/Pavan.png" alt="img">
                    </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
                        <h4>Pavan Kumar Kodedela</h4>
                        <p>With 20+ years of experience working in and mentoring start-ups, Pavan Kumar has accumulated a plethora of technology management experience while building multiple innovative and out-of-the-box software products for clients across
                            the globe. Specialized in strategic management, IT product development and software quality assurance, Business operations, and Cloud Infrastructure management Pavan has single-handedly managed teams of 500+ members effortlessly
                            on multiple occasions. And currently, with a vision to develop and implement intelligent software products rapidly, Pavan acts as Founder, CEO, and one of the members of the Board of Directors of RapinnoTech Solutions Private
                            Limited and he also acts as Managing Partner of Applied Synergy Technologies Private Limited.
                        </p>
                    </div>
                </div>
                <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Naresh Velaga</h4>
                        <p>Naresh Velaga is a serial entrepreneur with 10+ years of experience in the IT solutions and service industry. With immense knowledge and expertise, Naresh has a unique vision for intelligent and innovative solutions that have helped
                            many businesses and companies to ﬂourish. Having core strength in developing and implementing Customer-Centric On-Demand Technology Solutions & Services, currently, Naresh acts as Co-Founder of RapinnoTech Solutions Private
                            Limited which deals in providing faster and smarter software solutions to businesses all over the globe and he also acts as CFO and Managing Partner for Applied Synergy Technologies Private Limited.
                        </p>
                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/Naresh.png" alt="img">
                    </div>
                </div>

                <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/7.png" alt="img">
                    </div>
                  <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                    <h4>Venkatesh Yerramalla</h4>
                    <p>Venkatesh is Business Transformation specialist. He have been involved in providing Process Consulting services and had worked with diverse organizations since last 28 years. Graduate in Mechanical engineering with more than two decade’s of experience across diverse industry segments. Possesses professional certification in Basics of Supply Chain Management from APICS, USA. He a Certified Manager of Quality and Organizational Excellence from American Society for Quality, USA and trained facilitator for conducting Effective Personal Productivity Program of Leadership Management International (LMI India).
                    </p>
                </div>     
</div>

               <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                  <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                    <img src="../../../../assets/images/team/Abdul-Khan.png" alt="img">
                </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>AK</h4>
                        <p>With over 17 years of experience in recruiting, AK operates as Rapinno Tech's Senior Vice President. AK is exceptionally ambitious at analysing the organization's requirements and efficient in driving sophisticated business processes.
                            With impeccable leadership skills and impressive professional-speaking proficiency, AK always thrives on challenges and paves unique paths for tunnel visions and goals. He can easily adapt to the new business environment, is
                            passionate to learn new technology, and helps to develop and strengthen corporate relationships thus helping organizations to embark on valuable quests towards every desired milestone. AK strongly asserts that education and
                            experience will positively impact any organization and enhance its growth.
                        </p>
                    </div>

                </div>
                <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">

                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Seshatreya Kosuri</h4>
                        <p>Sesh has successfully managed business operations and achieved year-on-year growth in business & revenue targets across assignments. With experience of 17+ years, Sesh has worked with some of the news runners of the business world
                            like Infosys, HP, Satyam, SRM. He is an effective communicator with skills in interfacing with clients, suggesting viable products & solutions, cultivating relations with them for securing repeat business, and ensuring quality
                            delivery of products to the clients. He has immense knowledge of implementing effective solutions to the customer needs, intending to improve customer contentment and customer loyalty, repeat & referral business. Sesh has extensive
                            knowledge of Automotive, Industrial Systems, Consumer Electronics, and the Retail industry. He also holds the merit of handling teams from across the geographies, such as the US & Canada. Currently, he is acting as Vice-President
                            Sales at RapinnoTech Solutions Private Limited.
                        </p>

                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                      <img src="../../../../assets/images/team/Sesh.png" alt="img">
                  </div>
                </div>
                <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                  <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
                    <img src="../../../../assets/images/team/Tarun.png" alt="img">
                </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
                        <h4>Tarun Addagarla</h4>
                        <p>As a strong professional with a B.Tech degree from Jawaharlal Nehru Technological University, Tarun has worked with some of the big ﬁrms of the world. He has a demonstrated history of working in the information technology and service
                            industry and is extremely skilled in Business Solutions Development, Software Development, Project Management. Tarun is also known for his exceptional people skills and managing multiple teams working on multiple projects.
                            He has a unique ability for coming up with smart and adaptive solutions for complex and unique problems faced by businesses and ﬁrms. Currently, Tarun is one of the Co-founders, COO, and members of the Board of Directors of
                            Applied Synergy Technology Private Limited and Vice President Service Delivery for Rapinno Tech Solutions Private Limited., that provides innovative software products and solutions to businesses worldwide.
                        </p>
                    </div>

                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
                        <img src="../../../../assets/images/team/5.png" alt="img">
                    </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
                        <h4>Bharadwaj SNP</h4>
                        <p>With his vast experience in rapid software development, Bharadwaj, a graduate from Lendi Institute of Engineering and Technology, is the acting CTO of Applied Synergy Technologies and Technology Architect for Rapinno Tech Solutions
                            Private Limited. He explicitly values the innovative and progressive nature of the working environment that enables everyone to ideate and grow. He always looks forward to providing intelligent and innovative solutions for
                            complex problems faced by businesses worldwide. With experience over ﬁve years in application development, he specializes in cross-platform iOS & Android applications using React Native & Flutter. He is certiﬁed in Spring Boot
                            Microservices, and his expertise in Hasura & Firebase empowered him in designing and building several cross-platform mobile applications.
                        </p>
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
                        <img src="../../../../assets/images/team/7.png" alt="img">
                    </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
                        <h4>Venkatesh Yerramalla</h4>
                        <p>Venkatesh is Busness Transformation specialist. He have been involved in providing Process Consulting services and had worked with diverse organizations since last 28 years. Graduate in Mechanical engineering with more than two
                            decade’s of experience across diverse industry segments. Possesses professional certification in Basics of Supply Chain Management from APICS, USA. He a Certified Manager of Quality and Organizational Excellence from American
                            Society for Quality, USA and trained facilitator for conducting Effective Personal Productivity Program of Leadership Management International (LMI India).
                        </p>
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Nilesh Naik</h4>
                        <p>Nilesh carries 20 years of industry experience. He is also a Six Sigma Black Belt. He is a thorough Quality Professional with work experience in India and Malaysia. Prior to his joining our team, he was working with Gulf Oil Corporation
                            Limited. He comes with a rich experience of working in automobile sector companies such as NRB Bearings, Synergies Dooray & Local Basic Sdn Bhn., Malaysia. Nilesh Worked with 34 SMEs of various sectors like Infrastructure,
                            Paper Industry, Manufacturing (Casting, Precision Component Mfg), Food, Laboratory, Ministry of Defence & Information technology.
                        </p>
                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/8.png" alt="img">
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
                        <img src="../../../../assets/images/team/9.png" alt="img">
                    </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
                        <h4>Shankar Mandapaka</h4>
                        <p>Shankar carries around 25 yrs. Of experience with Blue-Chip Companies across Verticals in IT, Systems and Operations helped me in delivering various IT Programs/Projects (budgets varied – from as low as £ 50k to 10 million/annum
                            and beyond (Part of central teams driving Large programs-GBP 75M-165M –Digital Transformation)) across Business Processes in various Domains. Shankar helps SME to large corporate in assessing their #IT Strategies and #Technology
                            #Strategy to re-define new #Enterprise #Architectures using most sought after disruptions there by create #Customer Centric product innovations.
                        </p>
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Uma Shekar Avvari</h4>
                        <p>Uma shekar carries 19 Years of experience in the IT Industry across verticals, including Information Security, Services Management, Project management and Remote infrastructure management services. Worked as Senior Manager at GENPACT
                            with responsibilities of Information security, operations delivery, process management and end point security management.
                        </p>
                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/10.png" alt="img">
                    </div>
                </div> -->
               <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">

                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Teja Addepalli</h4>
                        <p>Though Teja is a Computer Science graduate from Jawaharlal Nehru Technological University, he started his professional journey by handling a manufacturing unit with 250+ employees. This venture inculcated his fantastic skill of
                            employee management and, at the same time, developed a taste for entrepreneurship. He is exceptionally skilled in delivering business solutions and handling complex software projects. Born with natural salesmanship and with
                            an eye for creativity, Teja Addepalli is one of the Co-founders, CEO, and members of the Board of Directors of Applied Synergy Technology and Vice President Strategy and Business Development for Rapinno Tech Solutions Private
                            Limited., that provides innovative software products and solutions to businesses worldwide.
                        </p>
                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                      <img src="../../../../assets/images/team/Teja.png" alt="img">
                  </div>
                </div> -->
                <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                 
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Kiran Kumar</h4>
                        <p>Kiran is Head of IT department in charge of the company’s IT Infrastructure and Cloud strategy and responsible for advancing it. Kiran coordinates and leads a team of IT and Cloud experts/IT department. Overall 18+ years of experience
                            on different technologies on Windows& Linux Administration, ESXi VMware VDI Implementation and management, IT Support services as Associate IT Manager & with 8+ years of professional Experience on ITIL Process implementing
                            and Acting Chief Information Security Officer(CISO) taking care of Data and Networking Security in compliance with 9001-2015 & 27001 ISO Standards. Implement DLP rules for both web content and email on Force Point Websense.
                            Now with additional Feather of AWS Certificated Solution Architect experience Looking for a new challenging role that Suits by knowledge.
                        </p>
                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/kiran.png" alt="img">
                    </div>

                </div> 
                <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/Swarna.png" alt="img">
                    </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Swarna Pilli</h4>
                        <p>Swarna is Accounts and Finance Manager takes care of financial health of an organization. Swarna carries more than 15 years of experience and specialized in financial Accounting management. Swarna manages the continuous improvement
                            of end-to-end accounting practices and Client Invoice processes. Swarna prepare and post monthly accruals, prepayments and similar accounting entries and Manages an end-to-end audit process of current systems – while acting
                            as the first point of contact for external auditors. Swarna also handles working capital management and production of cash flow forecasts, Budgeting and forecasting. Leading the team responsible for payroll, GST and other statutory
                            and regulatory processes.
                        </p>
                    </div>
                    
                </div>
               <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                  <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                    <img src="../../../../assets/images/team/Murthy.png" alt="img">
                </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>T L N Murthy</h4>
                        <p>As a Senior HR Manager my focus will be on employee engagement, employer branding, manage performance, and overall help in scaling up of the organization and controlling attrition. Understanding of the business and thinking about
                            the business needs of tomorrow and developing the talent required and enabling the talent strategy and execution of HR strategies in alignment with the current and future needs of Company. As a legal advisor I am also accountable
                            for taking the day-to-day legal responsibilities and providing legal advice that includes all the aspects of the analysis, investigation and drafting process and providing support in discrete legal issues by advising the organization,
                            drafting undertakings and structuring remedies for the relevant issues.
                        </p>
                    </div>

                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Prem Kumar Varma</h4>
                        <p>As Digital Marketing Team Lead, Prem is highly skilled in formulating digital strategies for clients. With rich international experience in Digital Marketing, Prem has a good eye for design which has been proved extremely valuable
                            for the organization. He is extremely skilled in marketing analytics, Google & Facebook ads, Social Media Marketing and Client Servicing. Prem has Strong analytical skills including the ability to identify trends, risks and
                            opportunities in conversion metrics and make recommendations to ensure goals are met.
                        </p>
                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/14.png" alt="img">
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
                        <img src="../../../../assets/images/team/15.png" alt="img">
                    </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
                        <h4>Amrit Mohanty</h4>
                        <p>As a marketer by passion, Amrit, Digital Marketing Team Lead and he is highly skilled in managing digital activities for clients across the globe. With 2+ years of experience in Digital Marketing, Amrit is very proﬁcient in content
                            writing and management. His management and damage control skills have been a key value addition to the organization. He has expertise in SEO, Marketing Analytics, Ad Copy Writing, Content Marketing, Google & Facebook Ads and
                            Client Servicing.
                        </p>
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Tirumalesh Kota</h4>
                        <p>With 7+ years of experience in the IT service industry, Tirumalesh is one the initial employee and is extremely skilled in HTML5, CSS, SCSS & also UI frameworks like bootstrap, angular material Ui, javascript and jQuery, Adobe
                            photoshop XD. He has been working as Front-End Developer Lead for the last 3+ years. He has single-handedly worked on complex projects. His curiosity and zeal to provide the best every time has been an asset to our company.
                        </p>
                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/16.png" alt="img">
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
                        <img src="../../../../assets/images/team/17.png" alt="img">
                    </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
                        <h4>Sundeep Allareddy</h4>
                        <p>Sundeep has been working as Design Lead. He has overall 9+ years of experience in the animation and graphics designing industry. He is highly proﬁcient in Adobe illustrator, Adobe photoshop, Adobe XD, Adobe after effects, Adobe
                            premiere, sketch, 3ds max, Maya, Cinema 4d, Nuke. With amazing management skills, Sundeep has time and again provided extreme value addition to the organization.
                        </p>
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Teja Deevi</h4>
                        <p>As an experienced Project Manager and Product manager, Teja offers expertise in overseeing all aspects of project planning, from budgeting and resource allocation to ensuring quality deliverables while achieving complete compliance
                            to contract terms and conditions. He also has expertise in problem-solving and giving creative solutions to overcome obstacles and achieve business goals.
                        </p>
                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/18.png" alt="img">
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
                        <img src="../../../../assets/images/team/19.png" alt="img">
                    </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
                        <h4>Vamsi Krishna</h4>
                        <p>Vamsi Krishna is a very valuable asset for Applied Synergy Technologies with rich experience in project management. He is extremely skilled in SDLC, implementing Agile Methodology Waterfall Model & Rapid Application Development
                            (RAD). Vamsi has worked extensively on projects like - ERP, Document Management System, E-Commerce Applications and the list goes on. He has been providing inspired leadership for the organization. Vamsi adds value in resource
                            planning & technical Recruitment as well.
                        </p>
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center" fxFlexOrder.lt-md="2">
                        <h4>Aakash Gupta</h4>
                        <p>With 5+ years of experience in IT solution development, Aakash leads the Backend Development Team. He has been a valuable part of the organization for the last 2 years. Developed architecture and development methodology and guidelines
                            for insuring enterprise level systems. He is extremely skilled in Javascript,TypeScript, Java, HTML, CSS, Angular, Node, Express, Nestjs, MySql, Postgresql, Graphql, Hasura, Firebase.
                        </p>
                    </div>
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100" fxFlexOrder.lt-md="1">
                        <img src="../../../../assets/images/team/20.png" alt="img">
                    </div>
                </div> -->
                <!-- <div class="team-box" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutAlign.lt-md="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-md="25px">
                    <div fxLayout="column" fxFlex="25" fxFlex.lt-md="100">
                        <img src="../../../../assets/images/team/21.png" alt="img">
                    </div>
                    <div fxLayout="column" fxFlex="75" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center center">
                        <h4>Rajkiran Telagamsetty</h4>
                        <p>As Mobile Application Development Team Lead, Rajkiran carries overall experience of 5.5+ years, Rajkiran is extremely ﬂuent in Html, Java,Type Script,ﬂutter,XD. His dedication and commitment has been an example for everyone in
                            the organization.
                        </p>
                    </div>
                </div> -->
            </div>
        </div>

    </div>
</section>
