<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Cloud Technologies & Services</h1>
                    </div>
                    <p>
                        We provide comprehensive cloud offerings like Software as a Service (SaaS), Platform as a Service (PaaS), and Infrastructure as a Service (IaaS) and DevOps as a Service (DaaS). These cloud offerings help organizations reduce overall IT costs in multiple
                        ways and reap maximum value.RapinnoTech strategic delivery approach to Cloud offerings is to deliver tailor-made tech-services to all sizes of diverse domains through flexible delivery models of Cloud, such as Private, Public or
                        Hybrid, which is agile enough to frequently changing demands. This dynamic service delivery enables easy scaling of solutions in response to client demand, matching rapid business growth or fluctuations as required. Our Cloud ensures
                        reduced TCO (Total Cost of Ownership) and optimum ROI (Return on Investment).
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/cloud.png" alt="Man using cloud technologies for different purposes
          ">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="service-heading-content-sec ai-automation-sec intelligent-automation-sec cloud-sec-main">
    <div class="wrapper">
        <div class="service_head">
            <h2>Cloud Services</h2>
            <p>
                Leap to the cloud with our industrialized and packaged services
            </p>
        </div>
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="automation-offers cloud-offers cloud_service_ul">
                    <ul>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/cloud/migration_services.svg" alt="Line icon for cloud migration services
                ">
                                <span>MIGRATION SERVICES</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/cloud/managed_services.svg" alt="Line icon for cloud managed services
                ">
                                <span>MANAGED SERVICES</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/cloud/devops_&_automation.svg" alt="Line icon for cloud developed & automation services
                ">
                                <span>DevOps & AUTOMATION</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/cloud/consulting_services.svg" alt="Line icon for cloud consulting services
                ">
                                <span>CONSULTING SERVICES</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/cloud/startup_services.svg" alt="Line icon for startup cloud services
                ">
                                <span>STARTUP SERVICES</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="cloud-consulting-points">
    <div class="wrapper">
        <div class="service_head">
            <h2>Cloud Consulting</h2>
        </div>
        <div class="consulting_ul">
            <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <ul>
                        <li>
                            <img src="../../../../../assets/images/services/check-circle.svg" alt="img">
                            <span>Identify opportunities for leveraging Cloud offerings •Analyze Private, Public, Hybrid models for
                preferred
                applications and development
              </span>
                        </li>
                        <li>
                            <img src="../../../../../assets/images/services/check-circle.svg" alt="img">
                            <span>Define architecture to achieve IaaS, PaaS, and SaaS service model goals</span>
                        </li>
                        <li>
                            <img src="../../../../../assets/images/services/check-circle.svg" alt="img">
                            <span>Architecture, deployment of services in the Cloud</span>
                        </li>
                    </ul>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <ul>
                        <li>
                            <img src="../../../../../assets/images/services/check-circle.svg" alt="img">
                            <span>Security services including assessment, vulnerability and penetration scanning, and risk mitigation
              </span>
                        </li>
                        <li>
                            <img src="../../../../../assets/images/services/check-circle.svg" alt="img">
                            <span></span> Design a roadmap for prioritization and implementation
                        </li>
                        <li>
                            <img src="../../../../../assets/images/services/check-circle.svg" alt="img">
                            <span>Recommend the best cloud automation solution set</span>
                            <span></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Cloud Technologies</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>
<section class="service-heading-content-sec ai-automation-sec intelligent-automation-sec cloud_implementation_sec">
    <div class="wrapper">
        <div class="service_head">
            <h2>Cloud Implementation</h2>
        </div>
        <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="automation-offers cloud-implementation-offers">
                    <ul>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/cloud/cloud_migration.svg" alt="Line icon for cloud migration related services
                ">
                                <span>Cloud Migration &
                  deployment Services</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/cloud/cloud_development.svg" alt="Icon for Cloud Development & Deployment Services
                ">
                                <span>Cloud Development &
                  Deployment Services</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <img src="../../../../../assets/images/services/cloud/devops_implementation.svg" alt="Icon for DevOps implementation Services
                ">
                                <span>DevOps implementation
                  Services</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- NORMAL-BOX -->
<section class="service-heading-content-sec ai-automation-sec intelligent-automation-sec consulting-solution-sec">
    <div class="wrapper">
        <div class="service_head">
            <h2>Hybrid Cloud</h2>
        </div>
        <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="automation-offers cloud-offers consulting-solution-box hybrid_cloud_ul">
                    <ul>
                        <li>
                            <div class="li_content">
                                <span>RapinnoTech offers managed services for a cloud environment</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <span>RapinnoTech experts have experience and expertise to handle AZURE, AWS, Google Cloud, AliCloud,
                  Oracle
                  Cloud, and IBM Cloud</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <span>On-Demand Management of IaaS, PaaS, and SaaS components</span>
                            </div>
                        </li>
                        <li>
                            <div class="li_content">
                                <span>Service management for 24/7 operations</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>