import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-fleet-management-brochure',
  templateUrl: './fleet-management-brochure.component.html',
  styleUrls: ['./fleet-management-brochure.component.scss']
})
export class FleetManagementBrochureComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<FleetManagementBrochureComponent>,
  ) { }

  ngOnInit(): void {
  }
  dialogClose(result?: any) {
    this.dialogRef.close(result ? result : null);
  }
}
