<section class="master_rapino_sec">
    <!-- SERVICE-BANNER-SEC -->
    <section class="service-intro-sec">
        <div class="banner-dots left">
            <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
        </div>
        <div class="banner-dots right">
            <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
        </div>
        <div class="wrapper">
            <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="60" fxFlexFill>
                    <div class="intro-content">
                        <div class="section-heading">
                            <h1 class="main-heading">RDP Social Platform</h1>
                        </div>
                        <p>
                            Create your own Matrimony web or mobile app with all your desired features like, Social sign-in or log-in, GPS integration, in-app chat & camera for video calls, etc. Our team will work along side you tirelessly t understand your vision. We will deep
                            dive into the minds of the target audience as well to design the best platform where they can start their journey of love.
                        </p>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="40" fxFlexFill>
                    <div class="intro-img">
                        <img class="raduis-img" src="../../../../../assets/images/service-banner/matrimony.jpg" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h2>Our Expertises</h2>
                <p>We promise an all-in one platform where your clients will definitely meet their better halves</p>
            </div>
            <section class="sec-padding">
                <div class="wrapper">
                    <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/1.svg" alt="">
                                <h4>Profile Verification</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/2.svg" alt="">
                                <h4>Set Partner preference
                                </h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/3.svg" alt="">
                                <h4>Horoscope Compatibility</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/4.svg" alt="">
                                <h4>Browse Profiles</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/5.svg" alt="">
                                <h4>Express interest</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/6.svg" alt="">
                                <h4>Membership Packages</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/7.svg" alt="">
                                <h4>Join Online Events</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/8.svg" alt="">
                                <h4>Payment Gateway Integrations
                                </h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/9.svg" alt="">
                                <h4>Customer Support</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/10.svg" alt="">
                                <h4>Notifications & Alerts</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/matrimony/11.svg" alt="">
                                <h4>Compatibility questionnaire </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
    <!-- CTS SECTION-->
    <section class="section-cta section-padding">
        <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
        <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <div class="cta-content">
            <h3 class="cta-heading">RapinnoTech Matrimony development service will take care of the entire front end and back development</h3>
            <button (click)="matrimonyBrochure()" class="know-btn" mat-button> View
        Brochure </button>
        </div>
        <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
    </section>
    <section class="service-heading-content-sec">
        <div class="wrapper">
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/matrimony/ps.svg" alt="">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h3>Problem Statement</h3>
                            <p>
                                Most of the leading matrimonial applications face complexity when it comes to agility which leads to an increase in management complexity as well. Also, a lot of matrimonial websites and applications follow a rudimentary matching algorithm which is facilitated
                                by the repository of profiles.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h4>Solution</h4>
                            <p>
                                We have closely watched the matrimony industry evolve across the world and thus RapinnoTech's solution will be very agile to reduce any kind of complexity while scaling. Our AI integration and new algorithm will provide robust profile matching.
                            </p>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/matrimony/sol.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tech_stock_sec sec-padding">
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h4>Tech Stack</h4>
                <p>We follow the time & material business model and Offshore dedicated team business model. Our technology stack includes:
                </p>
            </div>
            <div class="wrapper">
                <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box first_box">
                            <h4>Mobile Application</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-8.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box first_box">
                            <h4>Database</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-1.svg" alt="">
                                <!-- <img src="../../../../../assets/images/e-commerce/t-2.svg" alt=""> -->
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box second_box">
                            <h4>Front End</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-3.svg" alt="">
                                <img src="../../../../../assets/images/e-commerce/t-4.svg" alt="">
                                <img src="../../../../../assets/images/e-commerce/t-5.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box third_box">
                            <h4>Backend</h4>
                            <div class="tech_images">
                                <!-- <img src="../../../../../assets/images/e-commerce/t-6.svg" alt=""> -->
                                <img src="../../../../../assets/images/e-commerce/t-7.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>