import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-matrimony-brochure',
  templateUrl: './matrimony-brochure.component.html',
  styleUrls: ['./matrimony-brochure.component.scss']
})
export class MatrimonyBrochureComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<MatrimonyBrochureComponent>,
  ) { }

  ngOnInit(): void {
  }
  dialogClose(result?: any) {
    this.dialogRef.close(result ? result : null);
  }
}
