import { SEOService } from './../../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accelerating-digital-transformation',
  templateUrl: './accelerating-digital-transformation.component.html',
  styleUrls: ['./accelerating-digital-transformation.component.scss']
})
export class AcceleratingDigitalTransformationComponent implements OnInit {


  constructor(
    private seoService: SEOService
  ) {}

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Accelerating Digital Transformation through an Intelligent Enterprise Automation Platform');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Accelerating Digital Transformation through an Intelligent Enterprise Automation Platform');
    this.seoService.updateOgDescription('Enable Digital transformation with AI and Digital Twin. Intelligent Process Automation driving digital transformation intelligent process Automation is a combination of Document, Data, and Conversational AI Automation Technology that work together. ');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/why-your-small-business-requires-a-document-management-system');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/17.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Accelerating Digital Transformation through an Intelligent Enterprise Automation Platform'),
      this.seoService.updateMetaDescription('Enable Digital transformation with AI and Digital Twin. Intelligent Process Automation driving digital transformation intelligent process Automation is a combination of Document, Data, and Conversational AI Automation Technology that work together. ')
  }

}
