import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dms-brochure',
  templateUrl: './dms-brochure.component.html',
  styleUrls: ['./dms-brochure.component.scss']
})
export class DmsBrochureComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DmsBrochureComponent>,
  ) { }

  ngOnInit(): void {
  }
  dialogClose(result?: any) {
    this.dialogRef.close(result ? result : null);
  }
}
