import { Form, FormBuilder, NgForm, Validators } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SuccessPopupComponent } from 'src/app/modules/shared/modules/success-popup/success-popup.component';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { HomeService } from '../../core/services/home.service';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})

export class VideosComponent implements OnInit {
  emailRegex = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  newsSletterForm = this.fb.group({
    email: [null, [Validators.required, Validators.pattern(this.emailRegex)]]
  });

  @ViewChild('sletterForm') mySletterForm: any;
  // MatPaginator Inputs
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent!: PageEvent;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(public snackBar: MatSnackBar,
    private fb: FormBuilder
    , private toast: ToastService,
    private seoService: SEOService,
    private service: HomeService) { }
  openSnackBar() {
    this.snackBar.openFromComponent(SuccessPopupComponent, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['success-snackbar']
    });
  }
  ngOnInit(): void {
    this.setSeo();
  }
  async addNewsSletter(form: NgForm) {
    if (this.newsSletterForm.invalid) {
      this.toast.openSnackBar('Please Enter Valid Email');
      return;
    }
    const res = await this.service.contactViaEmailForm(this.newsSletterForm.value);
    if (res) {
      this.openSnackBar();
      this.newsSletterForm.reset();
      form.resetForm();
    }
    // this.openSnackBar();
  }
  setSeo(): void {
    // this.seoService.updatePageTitle('Reasons Why to Digitize Your Business Right Away (Part 1)');
    // this.seoService.updateOgTitle('Reasons Why to Digitize Your Business Right Away (Part 1)');
    // this.seoService.updateOgDescription('The transition to digital technologies has long ceased to be fashionable. Unsurprisingly, exploring the world or doing business at your fingertips are not trends but an everyday necessity now.');
    // this.seoService.updateOgType('Blog');
    // this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/Reasons_Why_to_Digitize_Your_Business_Right_Away_Part_1');
    // this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/1.webp');
    // this.seoService.updateMetaTitle('Reasons Why to Digitize Your Business Right Away (Part 1)'),
    // this.seoService.updateMetaDescription('The transition to digital technologies has long ceased to be fashionable. Unsurprisingly, exploring the world or doing business at your fingertips are not trends but an everyday necessity now.')
  }
}
