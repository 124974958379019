
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, NgForm } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SuccessPopupComponent } from 'src/app/modules/shared/modules/success-popup/success-popup.component';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { HomeService } from '../../core/services/home.service';
import { ToastService } from '../../core/services/toast.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  emailRegex = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  newsSletterForm = this.fb.group({
    email: [null, [Validators.required, Validators.pattern(this.emailRegex)]]
  });
  @ViewChild('sletterForm') mySletterForm: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    public snackBar: MatSnackBar,
    private fb: FormBuilder,
    private toast: ToastService,
    private service: HomeService,
    private router: Router,
    private vps: ViewportScroller,
  ) { }
  // vps
  // (private router: Router,
  //   private vps: ViewportScroller,) { }
  // ngOnInit(): void {
  // }
  openSnackBar() {
    this.snackBar.openFromComponent(SuccessPopupComponent, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['success-snackbar']
    });
  }
  ngOnInit(): void {
  }
  async addNewsSletter(form: NgForm) {
    if (this.newsSletterForm.invalid) {
      this.toast.openSnackBar('Please Enter Valid Email');
      return;
    }
    console.log(this.newsSletterForm.value)
    this.newsSletterForm.value.status="SUBSCRIBED"
    this.newsSletterForm.value.createdAt=new Date()
    const res = await this.service.contactViaEmailForm(this.newsSletterForm.value);
    if (res) {
      this.openSnackBar();
      this.newsSletterForm.reset();
      form.resetForm();
    }
    // this.openSnackBar();
  }
  scrollOne() {
    //   this.vps.scrollToAnchor('scrollOne');
    //   setTimeout(() => {
    //     this.vps.scrollToAnchor('scrollOne');
    //   }, 1000);
    // }
    this.vps.scrollToAnchor('scrollOne');
    this.router.navigate(['/index']).then((data) => {
      if (data) {
        setTimeout(() => {
          this.vps.scrollToAnchor('scrollOne');
        }, 1000);
      }
    });
  }




}
