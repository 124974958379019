import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-augmented-intelligence-is-a-better-solution-for-healthcare',
  templateUrl: './how-augmented-intelligence-is-a-better-solution-for-healthcare.component.html',
  styleUrls: ['./how-augmented-intelligence-is-a-better-solution-for-healthcare.component.scss']
})
export class HowAugmentedIntelligenceIsABetterSolutionForHealthcareComponent implements OnInit {
 
 
  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How Augmented Intelligence Is A Better Solution for Healthcare?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | How Augmented Intelligence Is A Better Solution for Healthcare?');
    this.seoService.updateOgDescription('Augmented intelligence sounds a bit science fiction, but it is available to healthcare providers in reality and is helping to streamline operations. That where AI can help.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/how-augmented-intelligence-is-a-better-solution-for-healthcare');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/36.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How Augmented Intelligence Is A Better Solution for Healthcare?'),
    this.seoService.updateMetaDescription('Augmented intelligence sounds a bit science fiction, but it is available to healthcare providers in reality and is helping to streamline operations. Thats where AI can help.')
  }
}
