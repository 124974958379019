import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-blockchain',
  templateUrl: './blockchain.component.html',
  styleUrls: ['./blockchain.component.scss']
})
export class BlockchainComponent implements OnInit {
  constructor(
    private seoService: SEOService
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
    setSeo(): void {
      this.seoService.updatePageTitle('Blockchain | Rapinno Tech');
      this.seoService.updateOgTitle('Blockchain | Rapinno Tech');
      this.seoService.updateOgDescription('Rapinno\'s team of blockchain experts can help companies implement specialized distributed ledger technology solutions');
      this.seoService.updateOgType('Service Page');
      this.seoService.updateOgUrl('https://www.rapinnotech.com/blockchain');
      this.seoService.updateMetaTitle('Blockchain | Rapinno Tech'),
      this.seoService.updateMetaDescription('Rapinno\'s team of blockchain experts can help companies implement specialized distributed ledger technology solutions')
    }
}
