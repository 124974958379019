import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FleetManagementBrochureComponent } from '../../components/fleet-management-brochure/fleet-management-brochure.component';

@Component({
  selector: 'app-fleet-management',
  templateUrl: './fleet-management.component.html',
  styleUrls: ['./fleet-management.component.scss']
})
export class FleetManagementComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  fleetBrochure(): void {
    this._dialog.open(FleetManagementBrochureComponent, {
      width: '50%',
      panelClass: 'custom-modal',
      disableClose: true
    });
  }


  setSeo(): void {
    this.seoService.updatePageTitle('Fleet Management | Rapinno Tech | RDP');
    this.seoService.updateOgTitle('Fleet Management | Rapinno Tech | RDP');
    this.seoService.updateOgDescription('With Rapinno Tech\'s fleet management software, we will take care of all your requirements starting from pool vehicle bookings to fuel management, maintenance management, and more. ');
    this.seoService.updateOgType('RDP Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/rapinno-digital-platform/fleet-management');

    this.seoService.updateMetaTitle('Fleet Management | Rapinno Tech | RDP'),
    this.seoService.updateMetaDescription('With Rapinno Tech\'s fleet management software, we will take care of all your requirements starting from pool vehicle bookings to fuel management, maintenance management, and more. ')
  }
}
