<section class="master_rapino_sec">
    <!-- SERVICE-BANNER-SEC -->
    <section class="service-intro-sec">
        <div class="banner-dots left">
            <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
        </div>
        <div class="banner-dots right">
            <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
        </div>
        <div class="wrapper">
            <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="60" fxFlexFill>
                    <div class="intro-content">
                        <div class="section-heading">
                            <h1 class="main-heading">RDP Customer Service Platform</h1>
                        </div>
                        <p>
                            Rappino Tech will provide a 360 - degree solution which will include real estate web design and development for realtors, real estate agents for your real estate firm. We will work with you to understand your ideas and concepts so that we can deliver
                            an all feature web/mobile application that will help you grow.
                        </p>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="40" fxFlexFill>
                    <div class="intro-img">
                        <img class="raduis-img" src="../../../../../assets/images/service-banner/real-estate.jpg" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Real estate features -->
    <section class="sec-padding">
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h2>Our Expertise</h2>
                <p>We promise an all-in one platform where your clients will find their dream home for sure. </p>
            </div>
        </div>
    </section>
    <!-- Mobile App Features -->
    <section>
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h3>Mobile App Features</h3>
            </div>
            <section class="sec-padding">
                <div class="wrapper">
                    <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/1.svg" alt="">
                                <h4>User profiles</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/2.svg" alt="">
                                <h4>Add a property</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/3.svg" alt="">
                                <h4>Properties listing</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/4.svg" alt="">
                                <h4>Sell or Rent the property</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/5.svg" alt="">
                                <h4>Wishlist</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/6.svg" alt="">
                                <h4>Push notifications</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/7.svg" alt="">
                                <h4>Book a property visit</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/8.svg" alt="">
                                <h4>Sales trends</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/9.svg" alt="">
                                <h4>Heat maps</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/10.svg" alt="">
                                <h4>News & Articles</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/11.svg" alt="">
                                <h4>Property filters</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
    <!-- Admin Dashboard Features -->
    <section>
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h4>Admin Dashboard Features</h4>
            </div>
            <section class="sec-padding">
                <div class="wrapper">
                    <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/12.svg" alt="">
                                <h4>Users management</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/13.svg" alt="">
                                <h4>Agents management</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/14.svg" alt="">
                                <h4>Property verification</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/15.svg" alt="">
                                <h4>Content management system</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/16.svg" alt="">
                                <h4>Heat maps</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/17.svg" alt="">
                                <h4>Reports & Analytics</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/real-estate/18.svg" alt="">
                                <h4>Broadcasts & Notifications</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
    <!-- CTS SECTION-->
    <section class="section-cta section-padding">
        <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
        <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <div class="cta-content">
            <h3 class="cta-heading">RapinnoTech real estate development service will take care of the entire front end and back development</h3>
            <button (click)="realEstateBrochure()" class="know-btn" mat-button> View
        Brochure </button>
        </div>
        <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
    </section>
    <section class="service-heading-content-sec">
        <div class="wrapper">
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/real-estate/ps.svg" alt="">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h4>Problem Statement</h4>
                            <p>
                                When our clients approached us, they were facing issues like MLS errors on their data page flagged by Google, caching issues that led to increase in page load speed and finally the issues that most websites face, responsiveness. Apart from that, a real
                                estate website needs a solid on-page & off-page SEO implementation which lacked when our clients approached us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h4>Solution</h4>
                            <p>
                                Our Experienced and talented designing and developing team will make sure that these basic issues are taken care of along with some high level integrations like slot booking as per clients requirement. We will ensure that your website is optimized for
                                all the screens (Mobile, Tablet & Desktop). Our digital marketing team will take care of SEO & will optimize the website for google or for any other search engine as per your requirement.
                            </p>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/real-estate/sol.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tech_stock_sec sec-padding">
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h4>Tech Stack</h4>
                <p>We follow the time & material business model and Offshore dedicated team business model. Our technology stack includes:
                </p>
            </div>
            <div class="wrapper">
                <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box first_box">
                            <h4>Mobile Application</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-8.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box first_box">
                            <h4>Database</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-1.svg" alt="">
                                <!-- <img src="../../../../../assets/images/e-commerce/t-2.svg" alt=""> -->
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box second_box">
                            <h4>Front End</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-3.svg" alt="">
                                <img src="../../../../../assets/images/e-commerce/t-4.svg" alt="">
                                <img src="../../../../../assets/images/e-commerce/t-5.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box third_box">
                            <h4>Backend</h4>
                            <div class="tech_images">
                                <!-- <img src="../../../../../assets/images/e-commerce/t-6.svg" alt=""> -->
                                <img src="../../../../../assets/images/e-commerce/t-7.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>