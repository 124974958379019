import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-to-prefer-the-best-software-architecture-pattern-and-what-are-the-advantages',
  templateUrl: './how-to-prefer-the-best-software-architecture-pattern-and-what-are-the-advantages.component.html',
  styleUrls: ['./how-to-prefer-the-best-software-architecture-pattern-and-what-are-the-advantages.component.scss']
})
export class HowToPreferTheBestSoftwareArchitecturePatternAndWhatAreTheAdvantagesComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('RapinnoTech Blog | How to prefer the Best Software Architecture Pattern and What Are the Advantages?');
    this.seoService.updateOgTitle('RapinnoTech Blog | How to prefer the Best Software Architecture Pattern and What Are the Advantages?');
    this.seoService.updateOgDescription('How can software architecture patterns help prevent this and similar clutter? Here are five application architecture patterns that you can use to create the best software.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/blogs/how_to_prefer_the_best_software_architecture_pattern_and_what_are_the_advantages');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/26.webp');
    this.seoService.updateMetaTitle('RapinnoTech Blog | How to prefer the Best Software Architecture Pattern and What Are the Advantages?');
    this.seoService.updateMetaDescription('How can software architecture patterns help prevent this and similar clutter? Here are five application architecture patterns that you can use to create the best software.')
  }
}