import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-of-things',
  templateUrl: './internet-of-things.component.html',
  styleUrls: ['./internet-of-things.component.scss']
})
export class InternetOfThingsComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }


    setSeo(): void {
      this.seoService.updatePageTitle('Internet Of Things | Rapinno Tech');
      this.seoService.updateOgTitle('Internet Of Things | Rapinno Tech');
      this.seoService.updateOgDescription('Our end-to-end IoT solutions enable intelligent industries & intelligent companies & offer connected experiences by connecting assets, operations');
      this.seoService.updateOgType('Service Page');
      this.seoService.updateOgUrl('https://www.rapinnotech.com/internet-of-things');
      this.seoService.updateMetaTitle('Internet Of Things | Rapinno Tech'),
      this.seoService.updateMetaDescription('Our end-to-end IoT solutions enable intelligent industries & intelligent companies & offer connected experiences by connecting assets, operations')
    }
}
