import { SingleFileComponent } from './../file-uploader/single-file/single-file.component';
import { SuccessPopupComponent } from './success-popup/success-popup.component';
import { MaterialModule } from './material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [SuccessPopupComponent, SingleFileComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule
  ],

  exports: [
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SuccessPopupComponent,
    SingleFileComponent
  ],
  entryComponents: [
    SuccessPopupComponent
  ]
})
export class SharedModule { }
