import { Form, FormBuilder, NgForm, Validators } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SuccessPopupComponent } from 'src/app/modules/shared/modules/success-popup/success-popup.component';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { HomeService } from '../../core/services/home.service';
import { SEOService } from '../../core/seo.service';
@Component({
  selector: 'app-blog-listing',
  templateUrl: './blog-listing.component.html',
  styleUrls: ['./blog-listing.component.scss']
})
export class BlogListingComponent implements OnInit {
  emailRegex = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  newsSletterForm = this.fb.group({
    email: [null, [Validators.required, Validators.pattern(this.emailRegex)]]
  });

  @ViewChild('sletterForm') mySletterForm: any;
  // MatPaginator Inputs
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  // MatPaginator Output
  pageEvent!: PageEvent;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(public snackBar: MatSnackBar,
    private fb: FormBuilder
    , private toast: ToastService,
    private seoService: SEOService,
    private service: HomeService) { }
  openSnackBar() {
    this.snackBar.openFromComponent(SuccessPopupComponent, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['success-snackbar']
    });
  }
  ngOnInit(): void {
    this.setSeo();
  }
  async addNewsSletter(form: NgForm) {
    if (this.newsSletterForm.invalid) {
      this.toast.openSnackBar('Please Enter Valid Email');
      return;
    }
    const res = await this.service.contactViaEmailForm(this.newsSletterForm.value);
    if (res) {
      this.openSnackBar();
      this.newsSletterForm.reset();
      form.resetForm();
    }
    // this.openSnackBar();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Blogs | Rapinno tech');
    this.seoService.updateOgTitle('Blogs | Rapinno tech');
    this.seoService.updateOgDescription('Rapinno Tech blogs is home to our best blog posts on topics from Product planning, Software Development to testing and security. Explore more.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/Reasons_Why_to_Digitize_Your_Business_Right_Away_Part_1');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/1.webp');
    this.seoService.updateMetaTitle('Blogs | Rapinno tech'),
    this.seoService.updateMetaDescription('Rapinno Tech blogs is home to our best blog posts on topics from Product planning, Software Development to testing and security. Explore more.')
  }
}
