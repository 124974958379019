import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-intelligent-automation-a-new-era-of-innovation',
  templateUrl: './intelligent-automation-a-new-era-of-innovation.component.html',
  styleUrls: ['./intelligent-automation-a-new-era-of-innovation.component.scss']
})
export class IntelligentAutomationANewEraOfInnovationComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Intelligent Automation (IA): A New Era Of Innovation');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Intelligent Automation (IA): A New Era Of Innovation');
    this.seoService.updateOgDescription('Intelligent automation combines artificial intelligence, machine learning, and process automation to create more innovative processes.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/intelligent-automation-a-new-era-of-innovation');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/31.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Intelligent Automation (IA): A New Era Of Innovation');
    this.seoService.updateMetaDescription('Intelligent automation combines artificial intelligence, machine learning, and process automation to create more innovative processes.')
  }
}
