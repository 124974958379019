<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Enterprise Resource Planning</h1>
                    </div>
                    <p>
                        Rapinno Tech provides a business view of ERP implementations. We help our clients map the risks and opportunities associated with ERP solutions, including the impact on the life cycle. This collaborative approach to choosing the right solution and planning
                        its lifecycle proactively considers short- and long-term business goals, planning changes, and reducing risk while ensuring a higher return on investment from such projects.
                    </p>
                    <p>
                        We at RapinnoTech bring you a business view of ERP implementations and help you choose the right solution aligning to your business goals. Change Management needs to be given thought to mitigate risk in the business process. We work with you to enable
                        you to make the right choice.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/enterprise-resource-planning.jpg" alt="Showcasing view of ERP implementations">
                </div>
            </div>
        </div>
    </div>
</section>
<section class=" enterprise-logs-sec">
    <div class="wrapper">
        <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="70" fxFlex.sm="80" fxFlex.xs="100" fxFlexFill>
                <div class="logos-ul">
                    <ul>
                        <li><img src="../../../../../assets/images/services/enterprise/logo1.svg" alt="Logo of SAP
              "></li>
                        <li><img src="../../../../../assets/images/services/enterprise/logo2.svg" alt="Logo of Oracle Jd Edwards
              "></li>
                        <li><img src="../../../../../assets/images/services/enterprise/logo3.svg" alt="Logo of Microsoft Dynamics
              "></li>
                        <li><img src="../../../../../assets/images/services/enterprise/logo4.svg" alt="Logo of Netsuite
              "></li>
                        <li><img src="../../../../../assets/images/services/enterprise/logo5.svg" alt="Logo of Infor
              "></li>
                        <li><img src="../../../../../assets/images/services/enterprise/logo6.svg" alt="Logo of Sage
              "></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="our-erp-sec">
    <div class="wrapper">
        <div class="service_head">
            <h4>Our ERP offerings include</h4>
        </div>
        <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="80" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="100" fxFlexFill>
                <div class="erp-ul">
                    <ul>
                        <li>
                            <img src="../../../../../assets/images/services/enterprise/erp-1.svg" alt="Icon For Strategy & Roadmaps for ERP">
                            <span>Strategy & Roadmaps</span>
                        </li>
                        <li>
                            <img src="../../../../../assets/images/services/enterprise/erp-2.svg" alt="Icon For End to End Implementation for ERP">
                            <span>End to End
                Implementation</span>
                        </li>
                        <li>
                            <img src="../../../../../assets/images/services/enterprise/erp-1.svg" alt="Icon For Data Quality & Conversions for ERP">
                            <span>Data Quality &
                Conversions</span>
                        </li>
                        <li>
                            <img src="../../../../../assets/images/services/enterprise/erp-2.svg" alt="Icon For ERP Test Automation for ERP">
                            <span>ERP Test Automation</span>
                        </li>
                        <li>
                            <img src="../../../../../assets/images/services/enterprise/erp-1.svg" alt="Icon For ERP Managed Services for ERP">
                            <span>ERP Managed
                Services</span>
                        </li>
                        <li>
                            <img src="../../../../../assets/images/services/enterprise/erp-2.svg" alt="Icon For ERP Migrations for ERP">
                            <span>ERP Migrations</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Enterprise Resource Planning</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>