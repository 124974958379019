import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-rapid-application-development',
  templateUrl: './rapid-application-development.component.html',
  styleUrls: ['./rapid-application-development.component.scss']
})
export class RapidApplicationDevelopmentComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
   setSeo(): void {
    this.seoService.updatePageTitle('Rapid Application Development| Rapinno Tech');
    this.seoService.updateOgTitle('Rapid Application Development| Rapinno Tech');
    this.seoService.updateOgDescription('Rapinno Tech helps companies migrate existing technology stacks to newer technology stacks in less time and minimal cost.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/rapinno-digital-platform/rapid-application-development');
    this.seoService.updateMetaTitle('Rapid Application Development| Rapinno Tech'),
    this.seoService.updateMetaDescription('Rapinno Tech helps companies migrate existing technology stacks to newer technology stacks in less time and minimal cost.')
  }

}
