<!-- SERVICE INTRO SECTION-->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlex.sm="50" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Tech Innovation Consulting</h1>
                    </div>
                    <p>The development and use of new products and technologies are critical factors in securing and strengthening our market position. Innovation and systematically planned processes make your company stand out from the competition and are
                        the driving forces behind growth and efficiency. The necessary strategic development of the business area is based on:</p>
                    <ul>
                        <li>Identifying and assessing market potential</li>
                        <li>Analytical technology assessments</li>
                        <li>Consideration of external scenarios</li>
                        <li>Active technology portfolio management</li>
                    </ul>
                    <p>These actions should not be underestimated and require the selective use of high-performance tools. As an experienced consulting service provider, we help you meet these challenges and work with you to translate ideas into solutions
                        that add value.</p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlex.sm="50" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/tech_innovation_onsulting.jpg" alt="Person explaining Tech innovations in software Development, Product and  technologies in rapinno tech">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- TECH SERVICE SECTION-->
<section class="tech-service-sec section-padding">
    <div class="wrapper">
        <div class="section-heading">
            <h2 class="main-heading">Services</h2>
            <!-- <p class="sub-heading">Curabitur a posuere erat. Maecenas ac risus id dui tristique consequat lorem augue
      </p> -->
        </div>
        <div class="row-wrap" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxFlex="33" fxFlex.sm="50" fxFlexFill>
                <div class="service-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_service_1.svg" alt="Line Icon for Architecture and Technology Services">
                    <h4 class="title">Architecture and Technology Services</h4>
                </div>
            </div>
            <div fxLayout="column" fxFlex="33" fxFlex.sm="50" fxFlexFill>
                <div class="service-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_service_2.svg" alt="Line Icon for Process Consulting Services">
                    <h4 class="title">Process Consulting Services
                    </h4>
                </div>
            </div>
            <div fxLayout="column" fxFlex="33" fxFlex.sm="50" fxFlexFill>
                <div class="service-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_service_3.svg" alt="Line Icon for Information Risk Management">
                    <h4 class="title">Information Risk Management
                    </h4>
                </div>
            </div>
            <div fxLayout="column" fxFlex="33" fxFlex.sm="50" fxFlexFill>
                <div class="service-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_service_4.svg" alt="Line Icon for Cloud Services">
                    <h4 class="title">Cloud Services</h4>
                </div>
            </div>
            <div fxLayout="column" fxFlex="33" fxFlex.sm="50" fxFlexFill>
                <div class="service-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_service_5.svg" alt="Line Icon for IT Strategy & Governance">
                    <h4 class="title">IT Strategy & Governance
                    </h4>
                </div>
            </div>
            <div fxLayout="column" fxFlex="33" fxFlex.sm="50" fxFlexFill>
                <div class="service-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_service_6.svg" alt="Line Icon for Customized Solutions">
                    <h4 class="title">Customized Solutions
                    </h4>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Tech Innovation Consulting</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>
<!-- TECH SERVICE SECTION-->
<section class="tech-service-sec section-padding">
    <div class="wrapper">
        <div class="section-heading">
            <h1 class="main-heading">Services</h1>
            <!-- <p class="sub-heading">Curabitur a posuere erat. Maecenas ac risus id dui tristique consequat lorem augue
      </p> -->
        </div>
        <div class="row-wrap" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxFlex="50" fxFlexFill>
                <div class="benefit-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_benefit_1.svg" alt="Icon for Accomplish Projects faster along with desired results">
                    <h4 class="title">Accomplish Projects faster along with desired results</h4>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlexFill>
                <div class="benefit-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_benefit_2.svg" alt="Line Icon for Improve efficiency in your IT operations">
                    <h4 class="title">Improve efficiency in your IT operations</h4>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlexFill>
                <div class="benefit-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_benefit_3.svg" alt="Line Icon for Increase Productivity">
                    <h4 class="title">Increase Productivity</h4>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlexFill>
                <div class="benefit-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_benefit_4.svg" alt="Line Icon for Reduce Risks">
                    <h4 class="title">Reduce Risks</h4>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlexFill>
                <div class="benefit-card">
                    <img src="../../../../../assets/images/tech-innovation/tech_benefit_5.svg" alt="Line Icon for Improve ROI">
                    <h4 class="title">Improve ROI</h4>
                </div>
            </div>
        </div>
    </div>
</section>