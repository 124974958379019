<ngx-spinner></ngx-spinner>
<!-- MAIN BANNER SECTION-->
<section class="main-section banner-sec">
    <div class="swiper-container" [swiper]="bannerConfig">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
                <img loading="lazy" src="../../../../../assets/images/banner1.webp" alt="Person holding a blub for  representing Intelligent Automation for your future growth">
                <!-- Banner-Content -->
                <div class="wrapper banner_container">
                    <div class="content_flex">
                        <div class="banner-content">
                            <h1>Intelligent Automation for your future growth</h1>
                            <button [routerLink]="['/our-services/intelligent-automation']" routerLinkActive="router-link-active" class="know-btn" mat-button> Read
                                More </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <img loading="lazy" src="../../../../../assets/images/banner2.webp" alt="Person launching a rocket representing Rapid Technology Innovations Delivered by Rapinno Tech">
                <!-- Banner-Content -->
                <div class="wrapper banner_container">
                    <div class="content_flex">
                        <div class="banner-content">
                            <h1>Rapid Technology Innovations Delivered</h1>
                            <button [routerLink]="['/rapid-software-development']" routerLinkActive="router-link-active" class="know-btn" mat-button>
                                Read More </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-slide">
                <img loading="lazy" src="../../../../../assets/images/banner3.webp" alt="Person Browsing an mobile application done by Rapinno Tech">
                <!-- Banner-Content -->
                <div class="wrapper banner_container">
                    <div class="content_flex">
                        <div class="banner-content">
                            <h1>Enterprise Development Framework for One stop web and mobile development</h1>
                            <button [routerLink]="['/full-stack-development']" routerLinkActive="router-link-active" class="know-btn" mat-button> Read More </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="swiper-slide">
        <img loading="lazy" src="../../../../../assets/images/banner4.webp" alt="Image showing providing solution for Global Technical Expertise for your diversified Technical Needs at Rapinno Tech">
        <div class="wrapper banner_container">
          <div class="content_flex">
            <div class="banner-content">
              <h1>GTE(Global Technical Expertise) for your diversified Technical Needs</h1>
              <button [routerLink]="['/enterprise-resource-planning']" routerLinkActive="router-link-active"
                class="know-btn" mat-button> Read More </button>
            </div>
          </div>
        </div>
      </div> -->
        </div>
        <div class="swiper-pagination banner-pagination-banner"></div>
        <div class="swiper-pagination banner-pagination-banner"></div>
        <div class="swiper-pagination banner-pagination-banner"></div>
        <div class="swiper-pagination banner-pagination-banner"></div>
    </div>
</section>
<!-- CLIENT SECTION-->
<section class="clients-section section-padding">
    <div class="wrapper">
        <div class="section-heading">
            <h1 class="main-heading">Our Partners & Clients</h1>
        </div>
        <div class="swiper-container" [swiper]="clientsSlider">
            <div class="swiper-wrapper">
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/client8.svg" alt="Raidon Cloud logo in Our Partners & Clients">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/client4.svg" alt="HDFC logo in Our Partners & Clients">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="200" height="80" src="../../../../../assets/images/client/26.png" alt="">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/client5.svg" alt="Bajaj Electricity limited logo in Our Partners & Clients">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/client6.svg" alt="Automation Anywhere limited logo in Our Partners & Clients">
                    </div>
                </div>
                <!-- 3rd -->
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/client3.svg" alt="UI Path logo in Our Partners & Clients">
                    </div>
                </div>
                <!-- 4th -->
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/client7.svg" alt="Keka logo in Our Partners & Clients">
                    </div>
                </div>
                <!-- 5th -->
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/client1.svg" alt="Rewise logo in Our Partners & Clients">
                    </div>
                </div>
                <!-- 6th -->
                <!-- validigi -->
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/valudigi.svg" alt="Raidon Cloud logo in Our Partners & Clients">
                    </div>
                </div>
                <!-- enablist -->
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/Enablist-01.svg" alt="Raidon Cloud logo in Our Partners & Clients">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="80" src="../../../../../assets/images/client/AST.svg" alt="Raidon Cloud logo in Our Partners & Clients">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../../assets/images/client/lorhan.png" alt="lorhan">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/adapty.png" alt="adapty">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/DI-360.png" alt="Di 360 ">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/DI-SAYS.png" alt="Di says">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/Inspiration-global.png" alt="Inspiration">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/Micro-labs.png" alt="Micro labs">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/Oniclouds.png" alt="Oni clouds">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/quadrant.png" alt="Quadrant">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/RA Chem Pharma.png" alt="Ra-chem pharma">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/Rashmi Pharma pvt. ltd.png" alt="rashmi pharama">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/Tech-Mahindra.png" alt="tech mahindra">
                    </div>
                </div>
                <div class="swiper-slide client-item">
                    <div class="client-image">
                        <img loading="lazy" width="150" height="100" src="../../../../assets/images/client/worklame.png" alt="worklane">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- WHAT WE DO SECTION-->
<section class="what-we-do-section section-padding">
    <div class="wrapper">
        <div class="section-heading">
            <h1 class="main-heading">What We Do</h1>
            <p class="sub-heading">
                With our innovative rapid development technology and with our talented & experienced engineers we are determined to provide you the robust and 360-degree satisfying solution as per your need.
            </p>
        </div>
        <div fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="50" fxFlexFill>
                <div class="media" [routerLink]="['/robotic-process-automation']">
                    <div class="media-left">
                        <img loading="lazy" src="../../../../../assets/images/what-we-do/what_1.svg" alt=" Robotic Process Automation in Rapinno Tech">
                    </div>
                    <div class="media-body">
                        <h3 class="heading">Robotic Process Automation</h3>
                        <p class="sub-desc">RapinnoTech aims to automate your essential business processes to enable you all the competitive advantages and to overcome your operational vulnerabilities. </p>
                    </div>
                </div>
                <div class="media" [routerLink]="['/full-stack-development']">
                    <div class="media-left">
                        <img loading="lazy" src="../../../../../assets/images/what-we-do/what_4.svg" alt="Full Stack Development in Rapinno Tech">
                    </div>
                    <div class="media-body">
                        <h3 class="heading">Full Stack Development</h3>
                        <p class="sub-desc">
                            From full stack web hosting & front end prototyping to designing customized APIs we will take care of developing robust front-end to back-end consoles as per your business requirement.
                        </p>
                    </div>
                </div>
                <div class="media" [routerLink]="['/big-data']">
                    <div class="media-left">
                        <img loading="lazy" src="../../../../../assets/images/what-we-do/what_5.svg" alt="Big Data in Rapinno Tech">
                    </div>
                    <div class="media-body">
                        <h3 class="heading">Big Data</h3>
                        <p class="sub-desc">
                            We will take care of managing and analysing large data sets in the real time to discover mission critical insights that will provide competitive edge by improving the overall performances of the business.
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlexFill>
                <div class="media" [routerLink]="['/rapinno-digital-platform']">
                    <div class="media-left">
                        <img loading="lazy" src="../../../../../assets/images/what-we-do/what_2.svg" alt="Rapinno Digital Platform in Rapinno Tech">
                    </div>
                    <div class="media-body">
                        <h3 class="heading">Rapinno Digital platform</h3>
                        <p class="sub-desc">Technical requirements often appear suddenly, without giving any prior clue. We understand the importance of such an emergency for businesses of all sizes and kinds.
                        </p>
                    </div>
                </div>
                <div class="media" [routerLink]="['/rapinno-data-digitization']">
                    <div class="media-left">
                        <img loading="lazy" src="../../../../../assets/images/what-we-do/what_3.svg" alt="Rapinno Data Digitization in Rapinno Tech">
                    </div>
                    <div class="media-body">
                        <h3 class="heading">Rapinno Data Digitization</h3>
                        <p class="sub-desc">Rapinno's Data Digitization will help you digitize your complete business in every aspect and will transform the past records, data and ledgers into digital files, by using computer vision and machine learning very rapidly. </p>
                    </div>
                </div>
                <div class="media" [routerLink]="['/rapinno-product-360']">
                    <div class="media-left">
                        <img loading="lazy" src="../../../../../assets/images/what-we-do/what_7.svg" alt="Human Capital Management in Rapinno Tech">
                    </div>
                    <div class="media-body">
                        <h3 class="heading">Rapinno Product 360</h3>
                        <p class="sub-desc">
                            Rapinno Product 360 provides an end-to-end solution that starts with understanding the concept, creating a product roadmap to building the brand image for start-ups, innovators & entrepreneurs.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ABOUT SECTION-->
<section class="about-sec section-padding">
    <div class="wrapper">
        <div class="about-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="45" fxFlexFill>
                <div class="about-img">
                    <img loading="lazy" src="../../../../../assets/images/service-banner/about.jpg" alt="Team of professionals in Rapinno tech">
                </div>
            </div>
            <div fxLayout="column" fxFlex="55" fxFlexFill>
                <div class="about-content">
                    <div>
                        <div class="section-heading">
                            <h2 class="main-heading">About us</h2>
                        </div>
                        <p> RapinnoTech solutions is a software innovation company whose primary vision is to deliver technology innovations at a rapid pace to its clients. The company is headquartered at hyderabad, india with a nearshore office in milton,
                            canada and oceania nearshore office in Sydney, australia. The tag line “Rapid Technology Innovations Delivered” makes it apt as we innovate on advanced intelligent technologies to address the client needs.
                        </p>
                        <button class="global-btn" mat-button [routerLink]=" ['/about-us']"> Know More </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Resources-Bolg SECTION-->
<div class="blog-section section-padding">
    <div class="wrapper">
        <div class="section-heading">
            <h3 class="main-heading">Resources</h3>
        </div>
        <div fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <!-- LATEST-BLOG -->
            <div fxLayout="column" fxFlex.xl="33" fxFlex.lg="33" fxFlex.md="33" fxFlex.sm="33" fxFlex.xs="100" fxFlexFill class="resource_column">
              <a [routerLink]="['/blogs/What-is-Hyperledger-and-how-is-it-adding-value-to-various-industries']">
                  <div class="blog-card">
                      <div class="blog-img">
                          <img loading="lazy" src="../../../../../assets/images/blog/51.webp" alt="Financial Services">
                      </div>
                      <div class="blog-content">
                          <h4 class="author">Tarun  / <span>Feb 17, 2022</span></h4>
                          <h3 class="short-desc">The Benefits of Robotics in Financial Services </h3>
                      </div>
                  </div>
              </a>
          </div>
            <div fxLayout="column" fxFlex.xl="33" fxFlex.lg="33" fxFlex.md="33" fxFlex.sm="33" fxFlex.xs="100" fxFlexFill class="resource_column">
              <a [routerLink]="['/blogs/questions-to-ask-ahead-of-AI-implementation']">
                  <div class="blog-card">
                      <div class="blog-img">
                          <img loading="lazy" src="../../../../../assets/images/blog/50.webp" alt="AI Implementation">
                      </div>
                      <div class="blog-content">
                          <h4 class="author"> Prem Kumar / <span>Feb 10, 2022</span></h4>
                          <h3 class="short-desc">10 Critical Questions To Ask Ahead Of AI Implementation</h3>
                      </div>
                  </div>
              </a>
          </div>
            <div fxLayout="column" fxFlex.xl="33" fxFlex.lg="33" fxFlex.md="33" fxFlex.sm="33" fxFlex.xs="100" fxFlexFill class="resource_column">
              <a [routerLink]="['/blogs/automation-in-banking-and-finance']">
                  <div class="blog-card">
                      <div class="blog-img">
                          <img loading="lazy" src="../../../../../assets/images/blog/49.webp" alt="Intelligent Process Automation">
                      </div>
                      <div class="blog-content">
                          <h4 class="author">Teja / <span>Feb 03. 2022</span></h4>
                          <h3 class="short-desc">How Financial And Banking Services Can Benefit By Adopting Intelligent Process Automation</h3>
                      </div>
                  </div>
              </a>
          </div>


        </div>
        <div class="view-more">
            <button [routerLink]="['/blogs']" routerLinkActive="router-link-active" class="global-btn" mat-button> View
                More </button>
        </div>
    </div>
</div>
<!-- TESTIMONIAL SECTION-->
<div class="wrapper">
    <section class="testimonial-section section-padding">
        <div class="xs-col-rev sm-col-rev" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="75" fxFlexFill>
                <div class="testimonial-slider">
                    <div class="swiper-container" [swiper]="testConfig">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide media-item">
                                <p class="media-desc">It was a pleasure working with the entire RapinnoTech development team. They were able to launch our mobile application in a few months and looks beautiful & works seamlessly
                                </p>
                                <div class="media">
                                    <div class="media-left">
                                        <h3 class="author-name">Vamsi krishna</h3>
                                        <p class="author-type">Head of product development</p>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide media-item">
                                <p class="media-desc">It's difficult to find a technical partner with a balance of business acumen but RapinnoTech provided us with technical and strategic insight. They have a wealth of knowledge in app development which was integral in the
                                    creation of a reliable product.
                                </p>
                                <div class="media">
                                    <div class="media-left">
                                        <h3 class="author-name">Mellisa Rozario</h3>
                                        <p class="author-type">IT Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlexFill>
                <div class="section-heading">
                    <h1 class="main-heading">Some Awesome <br> <span> Words </span>
                        <img loading="lazy" src="../../../../../assets/images/border.jpg" class="border_img">
                    </h1>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- CONTACT US SECTION-->
<div class="contact-section section-padding" id="scrollOne">
    <div class="wrapper">
        <div class="xs-col-rev sm-col-rev" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div fxLayout="column" fxFlex="40" fxFlexFill>
                <form [formGroup]="contactUsForm" class="contact-form form-section">
                    <div class="custom-form-group">
                        <div class="custom-form-control">
                            <label for="" class="custom-label">Name</label>
                            <mat-form-field appearance="outline">
                                <input type="text" matInput formControlName="name" placeholder="Enter your name" />
                                <mat-error *ngIf="name.invalid && ( name.dirty || name.touched )">
                                    <span>Name is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- Email-SELECT -->
                    <div class="custom-form-group">
                        <div class="custom-form-control">
                            <label for="" class="custom-label">Email</label>
                            <mat-form-field appearance="outline">
                                <input type="text" matInput formControlName="email" placeholder="Enter your email" />
                                <mat-error *ngIf="email.invalid && ( email.dirty || email.touched )">
                                    <span>Email is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="custom-form-group">
                        <div class="custom-form-control">
                            <label for="" class="custom-label">Phone Number</label>
                            <mat-form-field appearance="outline">
                                <input type="text" matInput formControlName="phoneNo" placeholder="Enter your phone number" />
                                <mat-error *ngIf="phoneNo.invalid && ( phoneNo.dirty || phoneNo.touched )">
                                    <span>PhoneNo is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- State-SELECT -->
                    <div class="custom-form-group">
                        <div class="custom-form-control">
                            <label for="" class="custom-label">Message</label>
                            <mat-form-field appearance="outline">
                                <textarea matInput formControlName="message" placeholder="Ex. It makes me feel..."></textarea>
                                <mat-error *ngIf="message.invalid && ( message.dirty || message.touched )">
                                    <span>Message is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <button (click)=" submitContactForm()" class="global-btn" mat-button> Send Request </button>
                </form>
            </div>
            <div fxLayout="column" fxFlex="60" fxFlexFill>
                <div class="section-heading">
                    <h1 class="main-heading">Let's get in <br> <span> touch </span>
                        <img loading="lazy" src="../../../../../assets/images/border.jpg" class="border_img">
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>
