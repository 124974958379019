<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlex.sm="50" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Innovative Product Development</h1>
                    </div>
                    <p>
                        RapinnoTech helps companies define strategies for building more effective organizations and skills based on portfolio management, product innovation, digital design, lifecycle management, new technologies, customer insights, and much more. Our proven
                        cross-industry expertise in these areas enables us to identify innovative strategies and processes geared towards generating customer benefits, ensuring profitable products, and driving growth. Our implementation process encourages
                        actionable changes right from the start and delivers results that will redefine your company for years to come.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlex.sm="50" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/innovative-product-development.jpg" alt="Laptop On Desk
            ">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec">
    <div class="wrapper">
        <div class="section-heading-content">
            <h4 class="heading_sec">We offer IT product development services and IT product maintenance services.</h4>
        </div>
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-left-content">
                    <div class="w-100">
                        <div class="left-head">
                            <h4>IT Product Development Services include</h4>
                        </div>
                        <div class="service_ul">
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> New product development – Concept Screening | Design & Development | Testing | Support
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Modification of the existing product
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Technology migrations or Product migrations
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> New version control and release
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-right-img">
                    <img src="../../../../../assets/images/services/in_product_right_img.svg" alt="Person working on laptop IT Product Development Services
          ">
                </div>
            </div>
        </div>
        <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxLayoutAlign="start start">
                <div class="service-right-img">
                    <img src="../../../../../assets/images/services/in_product_left_img.svg" alt="Girl working on IT Product Maintenance Services
          ">
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                <div class="service-left-content">
                    <div class="w-100">
                        <div class="left-head">
                            <h4>IT Product Maintenance Services include</h4>
                        </div>
                        <div class="service_ul">
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Application enhancements
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Configuration management
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Troubleshooting and bug fixing
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Performance management
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/services/checkbox.svg" alt="img"> Change implementation, code review, and testing
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Innovative Product Development</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>