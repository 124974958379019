import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-rapinno-intelligent-automation-ecosystem-defining-robotic-process-automation',
  templateUrl: './rapinno-intelligent-automation-ecosystem-defining-robotic-process-automation.component.html',
  styleUrls: ['./rapinno-intelligent-automation-ecosystem-defining-robotic-process-automation.component.scss']
})
export class RapinnoIntelligentAutomationEcosystemDefiningRoboticProcessAutomationComponent implements OnInit {

  
  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Rapinno Intelligent Automation Ecosystem: Defining Robotic Process Automation');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Rapinno Intelligent Automation Ecosystem: Defining Robotic Process Automation');
    this.seoService.updateOgDescription('RPA is a flexible, adaptable technology that works through the enterprise in many industries. Get the unique approach of advanced technology by integrating robotic processes with Rapinno Tech.');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/index/blog/ Rapinno Intelligent Automation Ecosystem: Defining Robotic Process Automation');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/39.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Rapinno Intelligent Automation Ecosystem: Defining Robotic Process Automation');
    this.seoService.updateMetaDescription('RPA is a flexible, adaptable technology that works through the enterprise in many industries. Get the unique approach of advanced technology by integrating robotic processes with Rapinno Tech.')
  }
}