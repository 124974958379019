import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digital-marketing',
  templateUrl: './digital-marketing.component.html',
  styleUrls: ['./digital-marketing.component.scss']
})
export class DigitalMarketingComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
  // this.setSeo();
  }

    // setSeo(): void {
    //   this.seoService.updatePageTitle('Digital Marketing | Rapinno Tech');
    //   this.seoService.updateOgTitle('Digital Marketing | Rapinno Tech');
    //   this.seoService.updateOgDescription('Rapinno Tech has helped 50+ small business to get their voice heard in the chaotic market and has helped them grow');
    //   this.seoService.updateOgType('Service Page');
    //   this.seoService.updateOgUrl('https://www.rapinnotech.com/digital-marketing');
    //   this.seoService.updateMetaTitle('Digital Marketing | Rapinno Tech'),
    //   this.seoService.updateMetaDescription('Rapinno Tech has helped 50+ small business to get their voice heard in the chaotic market and has helped them grow')
    // }
}
