import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-is-artificial-intelligence-transforming-the-financial-ecosystem',
  templateUrl: './how-is-artificial-intelligence-transforming-the-financial-ecosystem.component.html',
  styleUrls: ['./how-is-artificial-intelligence-transforming-the-financial-ecosystem.component.scss']
})
export class HowIsArtificialIntelligenceTransformingTheFinancialEcosystemComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How Is Artificial Intelligence Transforming The Financial Ecosystem?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | How Is Artificial Intelligence Transforming The Financial Ecosystem?');
    this.seoService.updateOgDescription('Financial institutions are using artificial intelligence-based solutions to unlock possibilities for revenue growth and reduce operating costs. AI algorithms are used to analyze data.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/how-is-artificial-intelligence-transforming-the-financial-ecosystem');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/34.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How Is Artificial Intelligence Transforming The Financial Ecosystem?'),
    this.seoService.updateMetaDescription('Financial institutions are using artificial intelligence-based solutions to unlock possibilities for revenue growth and reduce operating costs. AI algorithms are used to analyze data.')
  }
}
