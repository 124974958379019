export const environment = {
  production: true,
  serverBaseUrl: 'https://v3-api.dbgen.xyz/api/v1',
  domainUrl: 'https://www.rapinnotech.com',
  firebase:{
  apiKey: 'AIzaSyB5wwiqe7BorVq1gL1sK11aVgvCRpgXvkE',
  authDomain: 'rapinnotech-bd56b.firebaseapp.com',
  databaseURL: 'https://rapinnotech-bd56b.firebaseio.com',
  projectId: 'rapinnotech-bd56b',
  storageBucket: 'rapinnotech-bd56b.appspot.com',
  messagingSenderId: '613022053608',
  appId: '1:613022053608:web:7d3505d7c7433e17fe455d',
  measurementId: 'G-368RPXM7DK'
  }
};
