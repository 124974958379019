import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-why-is-cloud-computing-in-the-healthcare-sector-more-important-than-ever',
  templateUrl: './why-is-cloud-computing-in-the-healthcare-sector-more-important-than-ever.component.html',
  styleUrls: ['./why-is-cloud-computing-in-the-healthcare-sector-more-important-than-ever.component.scss']
})
export class WhyIsCloudComputingInTheHealthcareSectorMoreImportantThanEverComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Why Is Cloud Computing In The Healthcare Sector More Important Than Ever?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Why Is Cloud Computing In The Healthcare Sector More Important Than Ever?');
    this.seoService.updateOgDescription('Cloud computing in healthcare helps a push toward data automation in 2021 and beyond. The global healthcare cloud computing market will achieve $25.54B during 2020-2024.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/blogs/why-is-cloud-computing-in-the-healthcare-sector-more-important-than-ever');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/29.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Why Is Cloud Computing In The Healthcare Sector More Important Than Ever?'),
    this.seoService.updateMetaDescription('Cloud computing in healthcare helps a push toward data automation in 2021 and beyond. The global healthcare cloud computing market will achieve $25.54B during 2020-2024.')
  }
}