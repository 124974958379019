import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/modules/core/services/home.service';
import { ToastService } from 'src/app/modules/core/services/toast.service';

@Component({
  selector: 'app-cont-page',
  templateUrl: './cont-page.component.html',
  styleUrls: ['./cont-page.component.scss']
})
export class ContPageComponent implements OnInit {
  careerForm!: FormGroup;
  emailRegex = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  phoneRegex = new RegExp(/^[6-9]\d{9}$/);
  jobTypes = {
    frontend: 'Angular JS | React JS | Sencha ExtJS | JQuery |BackBone JS',
    backend: 'Java | PHP | Ruby On Rails | Node.JS | C#.Net',
    database: 'MySQL | Postgre SQL | MS SQL Server | Oracle | MongoDB',
    cloud: 'AWS | AZURE | Google Cloud | Jenkins/Git Labs',
  };
  allowedFileMimeTypes = [
    {
      mimeType: 'text/plain',
      extension: 'txt',
    },
    {
      mimeType: 'application/msword',
      extension: 'doc',
    },
    {
      mimeType: 'audio/mpeg',
      extension: 'mp3',
    },
    {
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessing',
      extension: 'docx',
    },
    { mimeType: 'application/vnd.ms-excel', extension: 'xls' },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extension: 'xlsx',
    },
    {
      mimeType: 'application/vnd.ms-powerpoint',
      extension: 'ppt',
    },
    {
      mimeType:
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      extension: 'pptx',
    },
    {
      mimeType: 'application/pdf',
      extension: 'pdf',
    },
  ];
  constructor(
    private _fb: FormBuilder,
    private toast: ToastService,
    private service: HomeService,
    private acr: ActivatedRoute,
    // private seoService: SEOService,
  ) {}

  ngOnInit(): void {
    this.careerForm = this._fb.group({
      name: [null, [Validators.required]],
      phoneNo: [
        null,
        [Validators.required, Validators.pattern(this.phoneRegex)],
      ],
      email: [null, [Validators.required, Validators.pattern(this.emailRegex)]],
      experience: [null, [Validators.required]],
      file: [null, [Validators.required]],
    });
    // this.setSeo();
  }

  async submitQuotationForm(): Promise<void> {
    if (this.careerForm.invalid) {
      this.toast.openSnackBar('Enter Valid Form');
      this.careerForm.markAllAsTouched();
      return;
    }
    this.careerForm.value.createdAt=new Date()
    const carrierFormDetails = this.careerForm.value;
    carrierFormDetails.jobType = "content writer";
    if(this.acr.snapshot.paramMap.get('job') !== null) {
      carrierFormDetails.job = this.jobTypes[this.acr.snapshot.paramMap.get('job')];
    }

    const res = await this.service.carrierForm(carrierFormDetails);
    if (res) {
      this.careerForm.reset();
    }
  }

  get name() {
    return this.careerForm.get('name');
  }
  get file() {
    return this.careerForm.get('file');
  }
  get email() {
    return this.careerForm.get('email');
  }
  get phoneNo() {
    return this.careerForm.get('phoneNo');
  }
  get experience() {
    return this.careerForm.get('experience');
  }


}
