import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tech-innovation',
  templateUrl: './tech-innovation.component.html',
  styleUrls: ['./tech-innovation.component.scss']
})
export class TechInnovationComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Tech Innovation Consulting | Rapinno Tech');
    this.seoService.updateOgTitle('Tech Innovation Consulting | Rapinno Tech');
    this.seoService.updateOgDescription('Innovation and systematically planned processes make your company stand out from the competition and are the driving forces behind growth and efficiency.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/tech-innovation-consulting');
    this.seoService.updateMetaTitle('Tech Innovation Consulting | Rapinno Tech'),
      this.seoService.updateMetaDescription('Innovation and systematically planned processes make your company stand out from the competition and are the driving forces behind growth and efficiency.')
  }
}
