import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.scss']
})
export class BrochureComponent implements OnInit {

  public secondSwapper: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    loop: false,
    longSwipes: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: false,
    autoplay: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      240: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 1,
      },

      768: {
        slidesPerView: 1,
      },
      991: {
        slidesPerView: 1,
      },
      1200: {
        slidesPerView: 1
      },
    },

  };
  constructor() { }

  ngOnInit(): void {
  }
  swipperInit() {
  }

}
