import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { SuccessPopupComponent } from 'src/app/modules/shared/modules/success-popup/success-popup.component';
import { SEOService } from '../../core/seo.service';
import { HomeService } from '../../core/services/home.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  quoteForm!: FormGroup;
  emailRegex = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  phoneRegex = new RegExp(
    /^[6-9]\d{9}$/
  );

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(
    // private seoService: SEOService,
    private _fb: FormBuilder,
    public snackBar: MatSnackBar,
    private toast: ToastService,
    private service: HomeService) { }
  openSnackBar() {
    this.snackBar.openFromComponent(SuccessPopupComponent, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['success-snackbar']
    });

  }


  //
  ngOnInit(): void {
    // this.setSeo();
    this.quoteForm = this._fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
      designation: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  async submitQuotationForm(): Promise<void> {
    if (this.quoteForm.invalid) {
      this.toast.openSnackBar('Enter Valid Form');
      this.quoteForm.markAllAsTouched();
      return;
    }
    this.quoteForm.value.createdAt=new Date()
    const res = await this.service.submitContactForm(this.quoteForm.value);
    if (res) {
      this.quoteForm.reset();
    }
  }

  get name() {
    return this.quoteForm.get('name');
  }
  get message() {
    return this.quoteForm.get('message');
  }
  get companyName() {
    return this.quoteForm.get('companyName');
  }
  get email() {
    return this.quoteForm.get('email');
  }
  // get phoneNo() {
  //   return this.quoteForm.get('phoneNo');
  // }
  get toknow() {
    return this.quoteForm.get('toknow');
  }
  get designation() {
    return this.quoteForm.get('designation');
  }
  // setSeo(): void {
  //   this.seoService.updatePageTitle('contact | Rapinno Tech');
  //   this.seoService.updateOgTitle('contact | Rapinno Tech');
  //   this.seoService.updateOgDescription('Rapinno Tech provides best cloud services for small businesses and start-ups at very affordable price. ');
  //   this.seoService.updateOgType('contact Page');
  //   this.seoService.updateMetaTitle('Cloud | Rapinno Tech'),
  //   this.seoService.updateMetaDescription('Rapinno Tech provides best cloud services for small businesses and start-ups at very affordable price. ')
  // }
}
