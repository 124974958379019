<section class="master_careerapply_sec">
    <div class="banner_sec">
      <div class="overlay"></div>
      <div class="banner_img">
        <img src="../../../../../assets/images/career-apply/banner.svg" alt="img" />
      </div>
    </div>
    </section>

    <section class="sec-header job_descirption">
        <div class="wrapper">
            <div class="job_details">
                <div class="section-heading">
                    <h4  class="main-heading">Flutter  Developer</h4>
                   </div>
                <div fxLayout="row" fxLayoutAlign="center start">
                    <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                        <div class="nods_desp">
                            <h3>Job Description</h3>
                            <p>The ideal candidate will be responsible for developing high-quality applications. They will also be 
                              responsible for developing, designing, and implementing testable and scalable code. </p>
                        </div>
                        </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center start">
                            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                                <div class="nods_desp">
                                    <h3>Roles &amp; Responsibilities: </h3>
                                    <ul>
                                        <li>Develop a hybrid mobile app for iOS and Android platform using the Flutter framework </li>
                                        <li>Collaborate with cross-functional teams to define, design, and ship new features.</li>
                                        <li>Apply industry standards during the development process to ensure high quality </li>
                                      </ul>
                                </div>
                                </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="center start">
                                    <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                                        <div class="nods_desp">
                                            <h3>Skills &amp; Job Requirements:</h3>
                                            <ul>
                                                <li>You should have a minimum of 2 yrs. of mobile development experience with 1 year 
                                                  experience in Flutter.</li>
                                                <li>Should have good knowledge of Flutter SDK & Dart programming language</li>
                                                <li>Experience with an understanding of Mobile Application Development Lifecycle </li>
                                                <li>Familiarity with 3rd party libraries, RESTful API s and their integration</li>
                                                <li>Should have strong experience with any of the state management solutions like Bloc, 
                                                  Provider.</li>
                                                <li>You should have worked on multiple Flutter projects.</li>
                                                <li>You should have very Good Logical and Analytical Skills.</li>
                                           </ul>
                                           <h3>Person Specification:</h3>
                                           <p>B.Tech with Relevant University degree</p>
                                           <h3>Job Type:</h3>
                                           <p>Full-time </p>
                                           <h3>Notice period:</h3>
                                           <p>Immediate to 15 days</p>
                                           <h3>No of Positions: 4</h3>
                                        </div>
                                        </div>
                                        </div>
                                     
                                        </div>
                                        </div>
                                        </section>
                                        
   <section class="master_careerapply_sec">
    <div class="wrapper">
    <div class="section-heading text-center">
       <h4 class="main-heading">Fill your details</h4>
                    <p class="sub-heading">Our team will review your resume and will get back to you</p>
                  </div>
                  <form class="career_form" [formGroup]="careerForm">
                    <div fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
                      fxLayout.xs="column" fxLayoutAlign="start stretch">
                      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <!-- Name-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input matInput placeholder="Enter Name" formControlName="name" />
                              <mat-error *ngIf="name.invalid && (name.dirty || name.touched)">
                                <span>Name is required</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <!-- Email-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input type="email" matInput placeholder="Enter Email" formControlName="email" />
                              <mat-error *ngIf="email.invalid && (email.dirty || email.touched)"><span>Enter Valid Email</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <!-- PHone No-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input type="number" matInput placeholder="Enter Phone No" formControlName="phoneNo" />
                              <mat-error *ngIf="
                                  phoneNo.invalid && (phoneNo.dirty || phoneNo.touched)
                                ">
                                <span>Enter Valid Phone</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <!-- Year of Exp-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input type="number" matInput placeholder="Enter total year of exp" formControlName="experience" />
                              <mat-error *ngIf="
                                  experience.invalid &&
                                  (experience.dirty || experience.touched)
                                ">
                                <span>Enter total years of exp</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="custom-form-group pb-20">
                      <label class="custom-label" for="">Upload Resume
                        <span class="dots">:</span></label>
                      <div class="custom-form-control">
                        <app-single-file [fileInput]="file" [size]="5"></app-single-file>
                        <mat-error *ngIf="file.errors && ( file.dirty || file.touched )">
                          <span>Please provide a valid Resume</span>
                        </mat-error>
                      </div>
                    </div>
                    <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
                      fxLayout.xs="column" fxLayoutAlign="start start">
                      <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        fxFlexFill fxLayoutAlign="start start">
                        <button (click)="submitQuotationForm()" class="btn btn-sm mat-button career_btn global-btn" mat-button>
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>  
                  </div>
                </section>