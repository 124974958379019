<section class="master_rapino_sec">
    <!-- SERVICE-BANNER-SEC -->
    <section class="service-intro-sec">
        <div class="banner-dots left">
            <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
        </div>
        <div class="banner-dots right">
            <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
        </div>
        <div class="wrapper">
            <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="60" fxFlexFill>
                    <div class="intro-content">
                        <div class="section-heading">
                            <h1 class="main-heading">RDP Service Booking</h1>
                        </div>
                        <p>
                            All your scheduling requirements, let it be in-house meeting scheduling or building a web app for a bus seat booking or any slot booking requirement, will be delivered to you will all the customization you want by your expert engineers. Our team will
                            understand your vision and will deliver the best product you can imagine.
                        </p>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="40" fxFlexFill>
                    <div class="intro-img">
                        <img class="raduis-img" src="../../../../../assets/images/service-banner/slot-booking.jpg" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h2>Our Expertises</h2>
                <p>We promise an all-in one platform where all your scheduling requirements will be met</p>
            </div>
            <section class="sec-padding">
                <div class="wrapper">
                    <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/slot-booking/1.svg" alt="">
                                <h4>Integrated payment options
                                </h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/slot-booking/2.svg" alt="">
                                <h4>Easy appointment booking
                                </h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/slot-booking/3.svg" alt="">
                                <h4>Automatic reminders</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/slot-booking/4.svg" alt="">
                                <h4>Rescheduling</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/slot-booking/5.svg" alt="">
                                <h4>Adaptable calender view</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/slot-booking/6.svg" alt="">
                                <h4>Mobile friendly</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/slot-booking/7.svg" alt="">
                                <h4>Reports generation</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
    <!-- CTS SECTION-->
    <section class="section-cta section-padding">
        <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
        <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <div class="cta-content">
            <h3 class="cta-heading">RapinnoTech slot booking development service will take care of the entire front end and back development</h3>
            <button (click)="slotBrochure()" class="know-btn" mat-button> View
        Brochure </button>
        </div>
        <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
    </section>
    <section class="service-heading-content-sec">
        <div class="wrapper">
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/slot-booking/ps.svg" alt="">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h3>Problem Statement</h3>
                            <p>
                                Most of the appointment or slot booking solutions face an issue when value -addition at process flow level comes in to picture. Most booking systems lack the scope to integrate APIs which can regulate and optimize client retention as well.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h4>Solution</h4>
                            <p>
                                Our experience has made us understand that by developing a centralised system which enables easier measurements and benchmarking for efficacy of resolution. We design an advanced system to drive better resolution rate and efficacy. Our solution will completely
                                customized as per your requirements.
                            </p>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/slot-booking/sol.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tech_stock_sec sec-padding">
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h4>Tech Stack</h4>
                <p>We follow the time & material business model and Offshore dedicated team business model. Our technology stack includes:
                </p>
            </div>
            <div class="wrapper">
                <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch">
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box first_box">
                            <h4>Mobile Application</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-8.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box first_box">
                            <h4>Database</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-1.svg" alt="">
                                <!-- <img src="../../../../../assets/images/e-commerce/t-2.svg" alt=""> -->
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box second_box">
                            <h4>Front End</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-3.svg" alt="">
                                <img src="../../../../../assets/images/e-commerce/t-4.svg" alt="">
                                <img src="../../../../../assets/images/e-commerce/t-5.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box third_box">
                            <h4>Backend</h4>
                            <div class="tech_images">
                                <!-- <img src="../../../../../assets/images/e-commerce/t-6.svg" alt=""> -->
                                <img src="../../../../../assets/images/e-commerce/t-7.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>