import { HowIsTheBankingIndustryBenefitingComponent } from './modules/pages/blogs/how-is-the-banking-industry-benefiting/how-is-the-banking-industry-benefiting.component';
import { TestingInTheCloudComponent } from './modules/pages/blogs/testing-in-the-cloud/testing-in-the-cloud.component';
import { AiAndIotBlendedComponent } from './modules/pages/blogs/ai-and-iot-blended/ai-and-iot-blended.component';
import { CaseStudiesListingComponent } from './modules/pages/case-studies-listing/case-studies-listing.component';
import { CloudComputingBetterDatasecurityComponent } from './modules/pages/cloud-computing-better-datasecurity/cloud-computing-better-datasecurity.component';
import { BlockchainEnsuresSecurityComponent } from './modules/pages/blockchain-ensures-security/blockchain-ensures-security.component';
import { BlogSixComponent } from './modules/pages/blog-six/blog-six.component';
import { PotentialRisksBlogTwoComponent } from './modules/pages/potential-risks-blog-two/potential-risks-blog-two.component';
import { RapinnoDataDigitizationComponent } from './rapinno-data-digitization/rapinno-data-digitization.component';
import { BlogFiveComponent } from './modules/pages/blog-five/blog-five.component';
import { RapinnoProductThreesixtyComponent } from './modules/pages/rapinno-product-threesixty/rapinno-product-threesixty.component';
import { IntegratedMarketingServiceComponent } from './modules/pages/integrated-marketing-service/integrated-marketing-service.component';
import { ProductMigrationComponent } from './modules/pages/product-migration/product-migration.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './modules/pages/about-us/about-us.component';
import { AdaptiveSourcingComponent } from './modules/pages/adaptive-sourcing/adaptive-sourcing.component';
import { AiAutomationComponent } from './modules/pages/ai-automation/ai-automation.component';
import { ApplicationDevelopmentComponent } from './modules/pages/application-development/application-development.component';
import { BigDataComponent } from './modules/pages/big-data/big-data.component';
import { BlockchainComponent } from './modules/pages/blockchain/blockchain.component';
import { BlogFourComponent } from './modules/pages/blog-four/blog-four.component';
import { BlogListingComponent } from './modules/pages/blog-listing/blog-listing.component';
import { BlogOneComponent } from './modules/pages/blog-one/blog-one.component';
import { BlogThreeComponent } from './modules/pages/blog-three/blog-three.component';
import { BlogTwoComponent } from './modules/pages/blog-two/blog-two.component';
import { CareerApplyComponent } from './modules/pages/career-apply/career-apply.component';
import { CareerComponent } from './modules/pages/career/career.component';
import { CloudComponent } from './modules/pages/cloud/cloud.component';
import { ContactUsComponent } from './modules/pages/contact-us/contact-us.component';
// import { CrmComponent } from './modules/pages/crm/crm.component';
import { DigitalMarketingComponent } from './modules/pages/digital-marketing/digital-marketing.component';
import { DmsComponent } from './modules/pages/dms/dms.component';
import { ECommerceComponent } from './modules/pages/e-commerce/e-commerce.component';
import { EngineApiComponent } from './modules/pages/engine-api/engine-api.component';
import { EnterpriseResourcePlanningComponent } from './modules/pages/enterprise-resource-planning/enterprise-resource-planning.component';
import { FleetManagementComponent } from './modules/pages/fleet-management/fleet-management.component';
import { FullStackComponent } from './modules/pages/full-stack/full-stack.component';
import { HomeComponent } from './modules/pages/home/home.component';
import { HumanManagementComponent } from './modules/pages/human-management/human-management.component';
import { IndexComponent } from './modules/pages/index/index.component';
import { IntelligentAutomationComponent } from './modules/pages/intelligent-automation/intelligent-automation.component';
import { InternetOfThingsComponent } from './modules/pages/internet-of-things/internet-of-things.component';
// import { ItRecruitmentComponent } from './modules/pages/it-recruitment/it-recruitment.component';
import { MatrimonyComponent } from './modules/pages/matrimony/matrimony.component';
import { ProductDevelopmentComponent } from './modules/pages/product-development/product-development.component';
import { RapidApplicationDevelopmentComponent } from './modules/pages/rapid-application-development/rapid-application-development.component';
import { RapinnoDigitalPlatformComponent } from './modules/pages/rapinno-digital-platform/rapinno-digital-platform.component';
// import { RapinnoSolutionComponent } from './modules/pages/rapinno-solution/rapinno-solution.component';
import { RealEstateComponent } from './modules/pages/real-estate/real-estate.component';
import { RoboticProcessAutomationComponent } from './modules/pages/robotic-process-automation/robotic-process-automation.component';
// import { SalesServiceComponent } from './modules/pages/sales-service/sales-service.component';
import { SlotBookingComponent } from './modules/pages/slot-booking/slot-booking.component';
import { SoftwareDevelopmentComponent } from './modules/pages/software-development/software-development.component';
import { SoftwareTestingComponent } from './modules/pages/software-testing/software-testing.component';
import { TechInnovationComponent } from './modules/pages/tech-innovation/tech-innovation.component';
import { TechStackComponent } from './modules/pages/tech-stack/tech-stack.component';
import { AngdovpPageComponent } from './modules/pages/carrers/angdovp-page/angdovp-page.component';
import { UidevpPageComponent } from './modules/pages/carrers/uidevp-page/uidevp-page.component';
import { GrapPageComponent } from './modules/pages/carrers/grap-page/grap-page.component';
import { DevpPageComponent } from './modules/pages/carrers/devp-page/devp-page.component';
import { DesgPageComponent } from './modules/pages/carrers/desg-page/desg-page.component';
import { ContPageComponent } from './modules/pages/carrers/cont-page/cont-page.component';
import { FulldvpPageComponent } from './modules/pages/carrers/fulldvp-page/fulldvp-page.component';
import { MobileBankingAdoptionComponent } from './modules/pages/mobile-banking-adoption/mobile-banking-adoption.component';
import { ProtectYourselfFromMobileBankingScamsAndHacksComponent } from './modules/pages/protect-yourself-from-mobile-banking-scams-and-hacks/protect-yourself-from-mobile-banking-scams-and-hacks.component';
import { BlockchainTransformEcommerceComponent } from './modules/pages/blockchain-transform-ecommerce/blockchain-transform-ecommerce.component';
import { DataSecurityBlogComponent } from './modules/pages/data-security-blog/data-security-blog.component';
import { PotentialRisksBlogComponent } from './modules/pages/potential-risks-blog/potential-risks-blog.component';
import { VideosComponent } from './modules/pages/videos/videos.component';
import { CaseStudyOneComponent } from './modules/pages/case-study-one/case-study-one.component';
import { WhyIsFlutterBlogComponent } from './modules/pages/why-is-flutter-blog/why-is-flutter-blog.component';
import { WhyYourSmallBusinessRequiresBlogComponent } from './modules/pages/why-your-small-business-requires-blog/why-your-small-business-requires-blog.component';
import { AcceleratingDigitalTransformationComponent } from './modules/pages/blogs/accelerating-digital-transformation/accelerating-digital-transformation.component';
import { WhyIsAnErpImplementationChallengingComponent } from './modules/pages/blogs/why-is-an-erp-implementation-challenging/why-is-an-erp-implementation-challenging.component';
import { RoboticProcessAutomationTheFutureThatBanksShouldBankOnComponent } from './modules/pages/blogs/robotic-process-automation-the-future-that-banks-should-bank-on/robotic-process-automation-the-future-that-banks-should-bank-on.component';
import { DigitalInBfsiIsNoLongerATechnologyComponent } from './modules/pages/digital-in-bfsi-is-no-longer-a-technology/digital-in-bfsi-is-no-longer-a-technology.component';
import { HowErpHelpsKeepYourHealthcareOrganisationHealthyInThisPandemicComponent } from './modules/pages/blogs/how-erp-helps-keep-your-healthcare-organisation-healthy-in-this-pandemic/how-erp-helps-keep-your-healthcare-organisation-healthy-in-this-pandemic.component';
import { HowToChooseTheBestSuitedErpSoftwareForSchoolsCollegesAndUniversitiesComponent } from './modules/pages/blogs/how-to-choose-the-best-suited-erp-software-for-schools-colleges-and-universities/how-to-choose-the-best-suited-erp-software-for-schools-colleges-and-universities.component';
import { HowArtificialIntelligenceBoostTheErpSoftwareComponent } from './modules/pages/blogs/how-artificial-intelligence-boost-the-erp-software/how-artificial-intelligence-boost-the-erp-software.component';
import { HowErpProjectManagementIsKeyToASuccessfulImplementationComponent } from './modules/pages/blogs/how-erp-project-management-is-key-to-a-successful-implementation/how-erp-project-management-is-key-to-a-successful-implementation.component';
import { TheKeyTechnologyChallengesOfEmergingStartupsAndHowToNavigateComponent } from './modules/pages/the-key-technology-challenges-of-emerging-startups-and-how-to-navigate/the-key-technology-challenges-of-emerging-startups-and-how-to-navigate.component';
import { HowToPreferTheBestSoftwareArchitecturePatternAndWhatAreTheAdvantagesComponent } from './modules/pages/blogs/how-to-prefer-the-best-software-architecture-pattern-and-what-are-the-advantages/how-to-prefer-the-best-software-architecture-pattern-and-what-are-the-advantages.component';
import { PocVsMvpVsPrototypeComponent } from './modules/pages/blogs/poc-vs-mvp-vs-prototype/poc-vs-mvp-vs-prototype.component';
import { WhatIsMarketTestingWhyItIsImportantForNewProductOrServicesComponent } from './modules/pages/blogs/what-is-market-testing-why-it-is-important-for-new-product-or-services/what-is-market-testing-why-it-is-important-for-new-product-or-services.component';
import { RpaIaWhatTheDifferenceAndWhichIsBestForYourBusinessNowComponent } from './modules/pages/blogs/rpa-ia-what-the-difference-and-which-is-best-for-your-business-now/rpa-ia-what-the-difference-and-which-is-best-for-your-business-now.component';
import { RapinnoIaEcoSystemComponent } from './modules/pages/ia-eco-system/ia-eco-system.component';
import { IntelligentAutomationANewEraOfInnovationComponent } from './modules/pages/blogs/intelligent-automation-a-new-era-of-innovation/intelligent-automation-a-new-era-of-innovation.component';
import { RapinnoManagedServicesComponent } from './modules/pages/managed-services/managed-services.component';
import { WhyShouldEnterprisesMoveTowardsIntelligentAutomationComponent } from './modules/pages/blogs/why-should-enterprises-move-towards-intelligent-automation/why-should-enterprises-move-towards-intelligent-automation.component';
import { HowArtificialIntelligenceIsAGameChangerForBanksComponent } from './modules/pages/blogs/how-artificial-intelligence-is-a-game-changer-for-banks/how-artificial-intelligence-is-a-game-changer-for-banks.component';
import { HowIsArtificialIntelligenceTransformingTheFinancialEcosystemComponent } from './modules/pages/blogs/how-is-artificial-intelligence-transforming-the-financial-ecosystem/how-is-artificial-intelligence-transforming-the-financial-ecosystem.component';
import { OurTeamComponent } from './modules/pages/our-team/our-team.component';
import { WhyIsCloudComputingInTheHealthcareSectorMoreImportantThanEverComponent } from './modules/pages/blogs/why-is-cloud-computing-in-the-healthcare-sector-more-important-than-ever/why-is-cloud-computing-in-the-healthcare-sector-more-important-than-ever.component';
import { HowAugmentedIntelligenceIsABetterSolutionForHealthcareComponent } from './modules/pages/blogs/how-augmented-intelligence-is-a-better-solution-for-healthcare/how-augmented-intelligence-is-a-better-solution-for-healthcare.component';
import { SecurityServicesComponent } from './modules/pages/security-services/security-services.component';
import { AdvancedPlanningSystemComponent } from './modules/pages/advanced-planning-system/advanced-planning-system.component';
import { HowDoDigitalTrendsPredictAPostPandemicFutureComponent } from './modules/pages/blogs/how-do-digital-trends-predict-a-post-pandemic-future/how-do-digital-trends-predict-a-post-pandemic-future.component';
import { WhatIsRapinnoIntelligentAutomationEcosystemComponent } from './modules/pages/blogs/what-is-rapinno-intelligent-automation-ecosystem/what-is-rapinno-intelligent-automation-ecosystem.component';
import { RapinnoIntelligentAutomationEcosystemDefiningRoboticProcessAutomationComponent } from './modules/pages/blogs/rapinno-intelligent-automation-ecosystem-defining-robotic-process-automation/rapinno-intelligent-automation-ecosystem-defining-robotic-process-automation.component';
import { RapinnoIntelligentAutomationEcosystemDefiningBusinessProcessManagementComponent } from './modules/pages/blogs/rapinno-intelligent-automation-ecosystem-defining-business-process-management/rapinno-intelligent-automation-ecosystem-defining-business-process-management.component';
import { AreYouUsingTheTopRpaToolComponent } from './modules/pages/blogs/are-you-using-the-top-rpa-tool/are-you-using-the-top-rpa-tool.component';
import { FiveReasonsWhyRPADeploymentsFailComponent } from './modules/pages/blogs/five-reasons-why-rpa-deployments-fail/five-reasons-why-rpa-deployments-fail.component';
import { KeywaysToASuccessfulRPAImplementationComponent } from './modules/pages/blogs/keyways-to-a-successful-rpa-implementation/keyways-to-a-successful-rpa-implementation.component';
import { HowToChooseTheBestRPASoftwareInComponent } from './modules/pages/blogs/how-to-choose-the-best-rpa-software-in/how-to-choose-the-best-rpa-software-in.component';
import { HowIsBlockchainTechnologyRedefiningOurWorldForTheBetterComponent } from './modules/pages/blogs/how-is-blockchain-technology-redefining-our-world-for-the-better/how-is-blockchain-technology-redefining-our-world-for-the-better.component';
import { TechnicalBuzzwordsYouNeedToKnowToGetStartedForAnExcitingInnovationJourneyComponent } from './modules/pages/blogs/technical-buzzwords-you-need-to-know-to-get-started-for-an-exciting-innovation-journey/technical-buzzwords-you-need-to-know-to-get-started-for-an-exciting-innovation-journey.component';
import { WhatAreNFTsAndHowAreTheyImpactingTheWorldOfArtComponent } from './modules/pages/blogs/what-are-nfts-and-how-are-they-impacting-the-world-of-art/what-are-nfts-and-how-are-they-impacting-the-world-of-art.component';
import { WhatIsHyperledgerAndHowIsItAddingValueToVariousIndustriesComponent } from './modules/pages/blogs/what-is-hyperledger-and-how-is-it-adding-value-to-various-industries/what-is-hyperledger-and-how-is-it-adding-value-to-various-industries.component';
import { AutomationInBankingAndFinanceComponent } from './modules/pages/blogs/automation-in-banking-and-finance/automation-in-banking-and-finance.component';
import { QuestionsToAskAheadOfAIImplementationComponent } from './modules/pages/blogs/questions-to-ask-ahead-of-ai-implementation/questions-to-ask-ahead-of-ai-implementation.component';
import { BenefitsOfRoboticsInFinancialFervicesComponent } from './modules/pages/blogs/benefits-of-robotics-in-financial-fervices/benefits-of-robotics-in-financial-fervices.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'prefix',
    children: [
      // {
      //   path: '',
      //   redirectTo: 'index',
      //   pathMatch: 'full'
      // },
      // {
      //   path: 'index',
      //   component: IndexComponent,
      // },

      {
        path: '',
        component: IndexComponent
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
      },
      {
        path: 'contact',
        component: ContactUsComponent,
      },
      {
        path: 'career',
        component: CareerComponent,
      },
      {
        path: 'career-apply/:job',
        component: CareerApplyComponent,
      },
      {
        path: 'career-apply',
        component: CareerApplyComponent,
      },
      {
        path: 'blogs',
        component: BlogListingComponent,
      },
      {
        path: 'our-team',
        component: OurTeamComponent,
      },

      {
        path: 'blogs/Best_Practices_of_Effective_Performance_Testing',
        component: BlogThreeComponent,
      },
      {
        path: 'blogs/Need_for_Security_Testing_In_Changing_Times',
        component: BlogOneComponent,
      },
      {
        path: 'blogs/Reasons_Why_to_Digitize_Your_Business_Right_Away_Part_1',
        component: BlogFourComponent,
      },
      {
        path: 'blogs/Reasons_Why_To_Digitize_Your_Business_Right_Away_Part_2',
        component: BlogFiveComponent
      },
      {
        path: 'blogs/Why_Your_Bank_Requires_a_Banking_App',
        component: BlogSixComponent
      },
      {
        path: 'blogs/Why_does_your_business_need_Security_Testing',
        component: BlogTwoComponent
      },

      {
        path: 'blogs/data_security_protection_threats_methodologies_part1',
        component: DataSecurityBlogComponent
      },
      {
        path: 'blogs/potential_risks_of_poor_data_security_part1',
        component: PotentialRisksBlogComponent
      },
      {
        path: 'blogs/potential_risks_of_poor_data_security_part2',
        component: PotentialRisksBlogTwoComponent
      },
      {
        path: 'blogs/How_Your_Mobile_Bank_App_Can_Be_a_Source_of_Extra_Revenue',
        component: MobileBankingAdoptionComponent
      },
      {
        path: 'blogs/how_to_protect_yourself_from_mobile_banking_scams_and_hacks',
        component: ProtectYourselfFromMobileBankingScamsAndHacksComponent
      },
      {
        path: 'blogs/how-can-blockchain-transform-the-e-commerce-segment',
        component: BlockchainTransformEcommerceComponent
      },
      {
        path: 'blogs/how-blockchain-ensures-security-for-the-banking-industry',
        component: BlockchainEnsuresSecurityComponent
      },
      {
        path: 'blogs/what_are_the_benefits_and_technology_of_developing_cross_platform_mobile_app_development',
        component: CloudComputingBetterDatasecurityComponent
      },
      {
        path: 'blogs/how_to_prefer_the_best_software_architecture_pattern_and_what_are_the_advantages',
        component: HowToPreferTheBestSoftwareArchitecturePatternAndWhatAreTheAdvantagesComponent
      },

      {
        path: 'tech-innovation-consulting',
        component: TechInnovationComponent,
      },
      {
        path: 'rapid-software-development',
        component: SoftwareDevelopmentComponent,
      },
      {
        path: 'adaptive-sourcing',
        component: AdaptiveSourcingComponent,
      },
      {
        path: 'rapicon-advanced-planning-system',
        component: AdvancedPlanningSystemComponent,
      },
      {
        path: 'software-testing',
        component: SoftwareTestingComponent,
      },
      {
        path: 'innovative-product-development',
        component: ProductDevelopmentComponent,
      },
      // {
      //   path: 'it-recruitment',
      //   component: ItRecruitmentComponent,
      // },
      {
        path: 'our-services/ai-&-automation',
        component: AiAutomationComponent,
      },
      {
        path: 'intelligent-automation',
        component: IntelligentAutomationComponent,
      },
      {
        path: 'robotic-process-automation',
        component: RoboticProcessAutomationComponent,
      },
      {
        path: 'blockchain',
        component: BlockchainComponent,
      },
      {
        path: 'big-data',
        component: BigDataComponent,
      },
      {
        path: 'cloud-tech-and-services',
        component: CloudComponent,
      },
      {
        path: 'internet-of-things',
        component: InternetOfThingsComponent,
      },
      {
        path: 'enterprise-resource-planning',
        component: EnterpriseResourcePlanningComponent,
      },
      {
        path: 'security-services',
        component: SecurityServicesComponent,
      },
      {
        path: 'our-services/digital-marketing',
        component: DigitalMarketingComponent,
      },
      // {
      //   path: 'our-services/sales-service',
      //   component: SalesServiceComponent,
      // },
      {
        path: 'full-stack-development',
        component: FullStackComponent,
      },
      {
        path: 'rapinno-digital-platform/e-commerce',
        component: ECommerceComponent,
      },
      {
        path: 'rapinno-digital-platforms/customer-service-platform',
        component: RealEstateComponent,
      },
      {
        path: 'rapinno-digital-platform/service-booking',
        component: SlotBookingComponent,
      },
      {
        path: 'rapinno-digital-platform/social-Platform',
        component: MatrimonyComponent,
      },
      {
        path: 'rapinno-digital-platform/dms',
        component: DmsComponent,
      },
      {
        path: 'application-development',
        component: ApplicationDevelopmentComponent,
      },
      {
        path: 'rapinno-digital-platform/fleet-management',
        component: FleetManagementComponent,
      },
      {
        path: 'engine-api',
        component: EngineApiComponent,
      },
      {
        path: 'tech-stack',
        component: TechStackComponent,
      },
      // {
      //   path: 'our-services/rapinno-solution',
      //   component: RapinnoSolutionComponent
      // },
      {
        path: 'rapinno-digital-platform/rapid-application-development',
        component: RapidApplicationDevelopmentComponent
      },
      {
        path: 'rapinno-digital-platform/product-migration',
        component: ProductMigrationComponent
      },
      {
        path: 'rapinno-marketing-360',
        component: IntegratedMarketingServiceComponent
      },
      {
        path: 'rapinno-digital-platform',
        component: RapinnoDigitalPlatformComponent,
      },
      {
        path: 'rapinno-product-360',
        component: RapinnoProductThreesixtyComponent
      },
      {
        path: 'rapinno-data-digitization',
        component: RapinnoDataDigitizationComponent
      },
      {
        path: 'rapinno-ia-eco-system',
        component: RapinnoIaEcoSystemComponent
      },
      {
        path: 'rapinno-managed-services',
        component: RapinnoManagedServicesComponent
      },
      {
        path: 'career/angular-developer',
        component: AngdovpPageComponent
      },
      {
        path: 'career/ui-developement',
        component: UidevpPageComponent
      },
      {
        path: 'career/motion-graphics',
        component: GrapPageComponent,
      },
      {
        path: 'career/node-js-developer',
        component: DevpPageComponent
      },
      {
        path: 'career/ui-ux',
        component: DesgPageComponent
      },
      {
        path: 'career/content-writer',
        component: ContPageComponent
      },
      {
        path: 'career/fllutter',
        component: FulldvpPageComponent,
      },
      {
        path: 'case-studies',
        component: CaseStudiesListingComponent
      },
      {
        path: 'videos',
        component: VideosComponent
      },
      {
        path: 'case-studies/we-go-beyond-erp-implementation',
        component: CaseStudyOneComponent
      },
      {
        path: 'blogs/why-is-flutter-still-in-hype-for-cross-platform-app-development',
        component: WhyIsFlutterBlogComponent
      },
      {
        path: 'blogs/why-your-small-business-requires-a-document-management-system',
        component: WhyYourSmallBusinessRequiresBlogComponent
      },
      {
        path: 'blogs/accelerating-digital-transformation-through-an-intelligent-automation-platform',
        component: AcceleratingDigitalTransformationComponent
      },
      {
        path: 'blogs/ai-and-iot-blended-in-future-technology-what-it-is-and-why-it-matters',
        component: AiAndIotBlendedComponent
      },
      {
        path: 'blogs/testing-in-the-cloud-exploring-the-banking-sector',
        component: TestingInTheCloudComponent
      },
      {
        path: 'blogs/how-is-the-banking-industry-benefiting-from-cloud-transformation',
        component: HowIsTheBankingIndustryBenefitingComponent
      },
      {
        path: 'blogs/Why-is-an-erp-implementation-challenging',
        component: WhyIsAnErpImplementationChallengingComponent
      },
      {
        path: 'blogs/robotic-process-automation-the-future-that-banks-should-bank-on works',
        component: RoboticProcessAutomationTheFutureThatBanksShouldBankOnComponent
      },
      {
        path: 'case-studies/digital-in-bfsi-is-no-longer-a-technology',
        component: DigitalInBfsiIsNoLongerATechnologyComponent
      },
      {
        path: 'case-studies/the-key-technology-challenges-of-emerging-startups-and-how-to-navigate',
        component: TheKeyTechnologyChallengesOfEmergingStartupsAndHowToNavigateComponent
      },
      {
        path: 'blogs/how-eRP-helps-keep-your-healthcare-organisation-healthy-in-this-pandemic',
        component: HowErpHelpsKeepYourHealthcareOrganisationHealthyInThisPandemicComponent

      },
      {
        path: 'blogs/ how_to_choose_the_best_suited_erp_software_for_schools_colleges_and_universities',
        component: HowToChooseTheBestSuitedErpSoftwareForSchoolsCollegesAndUniversitiesComponent

      },
      {
        path: 'blogs/how-artificial-intelligence-boost-the-erp-software',
        component: HowArtificialIntelligenceBoostTheErpSoftwareComponent

      },
      {
        path: 'blogs/how-erp-project-management-is-key-to-a-successful-implementation',
        component: HowErpProjectManagementIsKeyToASuccessfulImplementationComponent
      },
      {
        path: 'blogs/poc-vs-mvp-vs-Prototype',
        component: PocVsMvpVsPrototypeComponent
      },
      {
        path: 'blogs/what-is-market-testing-why-it-is-important-for-new-product-or-services',
        component: WhatIsMarketTestingWhyItIsImportantForNewProductOrServicesComponent
      },
      {
        path: 'blogs/rpa-ia-what-the-difference-and-which-is-best-for-your-business-now',
        component: RpaIaWhatTheDifferenceAndWhichIsBestForYourBusinessNowComponent
      },
      {
        path: 'blogs/intelligent-automation-a-new-era-of-innovation',
        component: IntelligentAutomationANewEraOfInnovationComponent
      },
      {
        path: 'blogs/why-should-enterprises-move-towards-intelligent-automation',
        component: WhyShouldEnterprisesMoveTowardsIntelligentAutomationComponent
      },
      {
        path: 'blogs/How-Artificial-Intelligence-Is-A-Game-Changer-For-Banks-In-The-21-st-Century',
        component: HowArtificialIntelligenceIsAGameChangerForBanksComponent
      },
      {
        path: 'blogs/how-is-artificial-intelligence-transforming-the-financial-ecosystem',
        component: HowIsArtificialIntelligenceTransformingTheFinancialEcosystemComponent
      },
      {
        path: 'blogs/why-is-cloud-computing-in-the-healthcare-sector-more-important-than-ever',
        component: WhyIsCloudComputingInTheHealthcareSectorMoreImportantThanEverComponent
      },
      {
        path: 'blogs/how-augmented-intelligence-is-a-better-solution-for-healthcare',
        component: HowAugmentedIntelligenceIsABetterSolutionForHealthcareComponent
      },
      {
        path: 'blogs/How-Do-2021-Digital-Trends-Predict-A-Post-Pandemic-Future',
        component: HowDoDigitalTrendsPredictAPostPandemicFutureComponent
      },
      {
        path: 'blogs/What-Is-Rapinno-Intelligent-Automation-Ecosystem',
        component: WhatIsRapinnoIntelligentAutomationEcosystemComponent
      },
      {
        path: 'blogs/rapinno-intelligent-automation-ecosystem-defining-robotic-process-automation',
        component:RapinnoIntelligentAutomationEcosystemDefiningRoboticProcessAutomationComponent
      },
      {
        path: 'blogs/rapinno-intelligent-automation-ecosystem-defining-business-process-management',
        component:RapinnoIntelligentAutomationEcosystemDefiningBusinessProcessManagementComponent
      },
      {
        path: 'blogs/are-you-using-the-top-rpa-tools-in-2022',
        component:AreYouUsingTheTopRpaToolComponent
      },
      {
        path: 'blogs/Five-reasons-why-RPA-deployments-fail',
        component:FiveReasonsWhyRPADeploymentsFailComponent
      },
      {
        path: 'blogs/8-keyways-to-a-successful-RPA-implementation',
        component:KeywaysToASuccessfulRPAImplementationComponent
      },
      {
        path: 'blogs/How-to-choose-the-best-RPA-software-in-2022',
        component:HowToChooseTheBestRPASoftwareInComponent
      },
      {
        path: 'blogs/How-is-Blockchain-Technology-redefining-our-world-for-the-better',
        component:HowIsBlockchainTechnologyRedefiningOurWorldForTheBetterComponent
      },
      {
        path: 'blogs/Technical-Buzzwords-you-need-to-know-to-get-started-for-an-exciting-innovation-journey',
        component: TechnicalBuzzwordsYouNeedToKnowToGetStartedForAnExcitingInnovationJourneyComponent
      },
      {
        path: 'blogs/What-are-NFTs-and-how-are-they-impacting-the-world-of-art',
        component: WhatAreNFTsAndHowAreTheyImpactingTheWorldOfArtComponent
      },
      {
        path: 'blogs/What-is-Hyperledger-and-how-is-it-adding-value-to-various-industries',
        component: WhatIsHyperledgerAndHowIsItAddingValueToVariousIndustriesComponent
      },
      {
        path: 'blogs/automation-in-banking-and-finance',
        component: AutomationInBankingAndFinanceComponent
      },
      {
        path: 'blogs/questions-to-ask-ahead-of-AI-implementation',
        component:QuestionsToAskAheadOfAIImplementationComponent
      },
      {
        path: 'blogs/benefits-of-robotics-in-financial-fervices',
        component:BenefitsOfRoboticsInFinancialFervicesComponent
      },
    ],
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 150],
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

