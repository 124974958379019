<section class="master_careerapply_sec">
    <div class="banner_sec">
      <div class="overlay"></div>
      <div class="banner_img">
        <img src="../../../../../assets/images/career-apply/banner.svg" alt="img" />
      </div>
    </div>
    </section>

    <section class="sec-header job_descirption">
        <div class="wrapper">
            <div class="job_details">
                <div class="section-heading">
                    <h4  class="main-heading">Content Writer </h4>
                   </div>
                <div fxLayout="row" fxLayoutAlign="center start">
                    <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                        <div class="nods_desp">
                            <h3>Job Description</h3>
                            <p>Content Writer responsibilities include conducting thorough research on industry-related topics, 
                              generating ideas for new content types, and proofreading articles before publication</p>
                        </div>
                        </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center start">
                            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                                <div class="nods_desp">
                                    <h3>Job Description includes:</h3>
                                    <ul>
                                        <li>Accountable for all content marketing initiatives to drive traffic, engagement, leads that deliver 
                                          sales.</li>
                                         <li>Research and write original SEO-ready content for company website, blogs, articles, 
                                          publications, infographics, online communication, and content assets, including case</li>
                                         <li>Creating useful content that drives traffic </li>
                                         <li>Grow our reach to end audiences and improve engagement/conversion across channels </li>
                                         <li>Must understand the basic best practices of the main social media channels, which content 
                                          and approaches work on each, and why </li>
                                         <li>Repurpose and optimize existing content for digital channels. </li>
                                      </ul>
                                    
                                </div>
                                </div>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="center start">
                                    <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                                        <div class="nods_desp">
                                            <h3>Skills &amp; Experience</h3>
                                            <ul>
                                                <li>Minimum 2 years demonstrated experience in writing/editing/publishing 
                                                  corporate content. </li>
                                                <li> Highly creative writing skills with an ability to write original and engaging content</li>
                                                <li>Experience researching and reporting on relevant industry trends and best practices to 
                                                  identify opportunities for new or improved SEO content. </li>
                                                <li>Demonstrated experience writing blog posts  </li>
                                                <li>Ability to develop new topics, research, and find a unique spin on existing topics </li>
                                                <li>Experience with content management systems and a strong understanding of content 
                                                  workflows  </li>
                                                <li>Understanding of best practices for promotion/sharing content through social media 
                                                  channels. Social experience for a B2B services company preferred.  </li>
                                                <li>Reading and analysing articles/content & summarizing articles. </li>
                                              
                                              </ul>
                                              <h3>Person Specification: </h3>
                                              <p>B.Tech or B.SC with Relevant University degree </p>
                                              <h3>Joining: </h3>
                                              <p>Immediate to 15 days </p>
                                              <h3>Job Type: </h3>
                                              <p>Full-time</p>
                                              <h3>No of Positions: </h3>
                                              <p>Sr. Content Writer – 2 </p>
                                              <p>Jr. Content Writer – 2</p>
                                        </div>
                                        </div>
                                        </div>


                        </div>
                        </div>
    </section>

    
   <section class="master_careerapply_sec">
    <div class="wrapper">
    <div class="section-heading text-center">
       <h4 class="main-heading">Fill your details</h4>
                    <p class="sub-heading">Our team will review your resume and will get back to you</p>
                  </div>
                  <form class="career_form" [formGroup]="careerForm">
                    <div fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
                      fxLayout.xs="column" fxLayoutAlign="start stretch">
                      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <!-- Name-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input matInput placeholder="Enter Name" formControlName="name" />
                              <mat-error *ngIf="name.invalid && (name.dirty || name.touched)">
                                <span>Name is required</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <!-- Email-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input type="email" matInput placeholder="Enter Email" formControlName="email" />
                              <mat-error *ngIf="email.invalid && (email.dirty || email.touched)"><span>Enter Valid Email</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <!-- PHone No-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input type="number" matInput placeholder="Enter Phone No" formControlName="phoneNo" />
                              <mat-error *ngIf="
                                  phoneNo.invalid && (phoneNo.dirty || phoneNo.touched)
                                ">
                                <span>Enter Valid Phone</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <!-- Year of Exp-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input type="number" matInput placeholder="Enter total year of exp" formControlName="experience" />
                              <mat-error *ngIf="
                                  experience.invalid &&
                                  (experience.dirty || experience.touched)
                                ">
                                <span>Enter total years of exp</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="custom-form-group pb-20">
                      <label class="custom-label" for="">Upload Resume
                        <span class="dots">:</span></label>
                      <div class="custom-form-control">
                        <app-single-file [fileInput]="file" [size]="5"></app-single-file>
                        <mat-error *ngIf="file.errors && ( file.dirty || file.touched )">
                          <span>Please provide a valid Resume</span>
                        </mat-error>
                      </div>
                    </div>
                    <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
                      fxLayout.xs="column" fxLayoutAlign="start start">
                      <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        fxFlexFill fxLayoutAlign="start start">
                        <button (click)="submitQuotationForm()" class="btn btn-sm mat-button career_btn global-btn" mat-button>
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>   
                  </div>
                </section>