import { SEOService } from '../../core/seo.service';
import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-engine-api',
  templateUrl: './engine-api.component.html',
  styleUrls: ['./engine-api.component.scss']
})
export class EngineApiComponent implements OnInit {

  public webConfig: SwiperConfigInterface = {

    direction: 'horizontal',
    slidesPerView: 8,
    speed: 2000,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    keyboard: true,
    loop: false,
    longSwipes: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: false,
    // autoplay: true,
    slidesOffsetAfter: 0,
    pagination: true,
    navigation: {
      nextEl: '.web-button-next',
      prevEl: '.web-button-prev',
    },
    breakpoints: {
      240: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 2,
      },

      768: {
        slidesPerView: 3,
      },
      991: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 8,
      },
    },
  };
  public mobileConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 8,
    speed: 2000,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    keyboard: true,
    loop: false,
    longSwipes: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: false,
    // autoplay: true,
    slidesOffsetAfter: 0,
    pagination: true,
    navigation: {
      nextEl: '.mobile-button-next',
      prevEl: '.mobile-button-prev',
    },
    breakpoints: {
      240: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 2,
      },

      768: {
        slidesPerView: 3,
      },
      991: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 8,
      },
    },
  };
  public databaseConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 8,
    speed: 2000,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    keyboard: true,
    loop: false,
    longSwipes: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: false,
    // autoplay: true,
    slidesOffsetAfter: 0,
    pagination: true,
    navigation: {
      nextEl: '.database-button-next',
      prevEl: '.database-button-prev',
    },
    breakpoints: {
      240: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 2,
      },

      768: {
        slidesPerView: 3,
      },
      991: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 8,
      },
    },
  };
  public cloudConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 8,
    speed: 2000,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    keyboard: true,
    loop: false,
    longSwipes: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: false,
    // autoplay: true,
    slidesOffsetAfter: 0,
    pagination: true,
    navigation: {
      nextEl: '.cloud-button-next',
      prevEl: '.cloud-button-prev',
    },
    breakpoints: {
      240: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 2,
      },

      768: {
        slidesPerView: 3,
      },
      991: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 8,
      },
    },
  };
  public backendConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 8,
    speed: 2000,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    keyboard: true,
    loop: false,
    longSwipes: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: false,
    // autoplay: true,
    slidesOffsetAfter: 0,
    pagination: true,
    navigation: {
      nextEl: '.backend-button-next',
      prevEl: '.backend-button-prev',
    },
    breakpoints: {
      240: {
        slidesPerView: 1,
      },
      600: {
        slidesPerView: 2,
      },

      768: {
        slidesPerView: 3,
      },
      991: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 8,
      },
    },
  };
  constructor(
    private router: Router,
    private vps: ViewportScroller,
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }
  scrollTech() {
    this.vps.scrollToAnchor('scrollTech');

    this.router.navigate(['/home']).then((data) => {
      if (data) {
        setTimeout(() => {
          this.vps.scrollToAnchor('scrollTech');
        }, 1000);
      }
    });
  }
  scrollEngine() {
    this.vps.scrollToAnchor('scrollEngine');

    this.router.navigate(['/home']).then((data) => {
      if (data) {
        setTimeout(() => {
          this.vps.scrollToAnchor('scrollEngine');
        }, 1000);
      }
    });
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Digital Platform | Engine-AP');
    this.seoService.updateOgTitle('Rapinno Digital Platform | Engine-AP');
    this.seoService.updateOgDescription('Rapinno Tech Digital platform Engine - APIs Helps Business With Powerful Pre-Designed Engines, We Assure Rapid Software Development.');
    this.seoService.updateOgType('Rapinno Digital Platform');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/Rapinno Digital Platform');
    this.seoService.updateMetaTitle('Rapinno Digital Platform | Engine-AP'),
    this.seoService.updateMetaDescription('Rapinno Tech Digital platform Engine - APIs Helps Business With Powerful Pre-Designed Engines, We Assure Rapid Software Development.')
    }
}
