<!-- SERVICE INTRO SECTION-->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlex.sm="50" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Full Stack Development</h1>
                    </div>
                    <p>
                        RapinnoTech specializes in software development for a wide range of technologies, from front-end prototyping to a full set of back-end services. Every company wants to set a faster pace of growth. You can achieve this dynamic with our full-stack development
                        services. Our full-stack developers have a deep understanding and experience to create full-fledged applications for your company.
                    </p>
                    <p>
                        Our full-stack development solutions encompass all kinds of tools and frameworks, such as Node.js, Rapid Js, Full Stack, Express, MongoDB, AngularJS, React, jQuery, and others. Our full-stack web developers create robust web applications that allow you
                        to realize your business's full potential.
                    </p>

                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlex.sm="50" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/robotic_process_automation.jpg" alt="Robo Operating a laptop">

                </div>
            </div>
        </div>
    </div>
</section>
<!-- TECH SERVICE SECTION-->
<section class="tech-service-sec section-padding">
    <div class="wrapper">
        <div class="section-heading-content">
            <h4 class="heading_sec">We provide the development team which can easily be integrated with your in-house team or work as a separate extension to your business</h4>
        </div>
        <div class="row-wrap" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxFlex="25" fxFlex.sm="50" fxFlexFill>
                <div class="soft-cycle-box">
                    <img class="soft-cycle-icon" src="../../../../../assets/images/soft-development/soft_dev_1.svg" alt="">
                    <h3 class="soft-cycle-title">Pre-screening of each developer to ensure you get the best manpower</h3>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.sm="50" fxFlexFill>
                <div class="soft-cycle-box">
                    <img class="soft-cycle-icon" src="../../../../../assets/images/soft-development/soft_dev_2.svg" alt="">
                    <h3 class="soft-cycle-title">Complete visibility and control over the team structure.</h3>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.sm="50" fxFlexFill>
                <div class="soft-cycle-box">
                    <img class="soft-cycle-icon" src="../../../../../assets/images/soft-development/soft_dev_3.svg" alt="">
                    <h3 class="soft-cycle-title">Scaling the team rapidly, Helping the clients to hire the right suitable resource within less turnaround time.</h3>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.sm="50" fxFlexFill>
                <div class="soft-cycle-box">
                    <img class="soft-cycle-icon" src="../../../../../assets/images/soft-development/soft_dev_4.svg" alt="">
                    <h3 class="soft-cycle-title">State of the art offshore development center with high-security controls.</h3>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Full Stack Development</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>

<section class="tech-service-sec section-padding">
    <div class="wrapper">
        <div class="section-heading">
            <!-- <h1 class="main-heading">Services</h1> -->
            <p class="sub-heading">We provide the development team which can easily be integrated with your in-house team or work as a separate extension to your business:
            </p>
        </div>
        <ul class="stack-list">
            <h4 class="stack-heading"> Front End</h4>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/front_stack_1.svg" alt="">
                <h4 class="title">Angular JS</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/front_stack_2.svg" alt="">
                <h4 class="title">React JS</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/front_stack_3.svg" alt="">
                <h4 class="title">Sencha extJS</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/front_stack_4.svg" alt="">
                <h4 class="title">jQuery</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/front_stack_5.svg" alt="">
                <h4 class="title">BackBone JS</h4>
            </li>
        </ul>
        <ul class="stack-list">
            <h4 class="stack-heading"> Back End</h4>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/back_stack_1.svg" alt="">
                <h4 class="title">Java</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/back_stack_2.svg" alt="">
                <h4 class="title">PHP</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/back_stack_3.svg" alt="">
                <h4 class="title">Ruby On Rails</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/back_stack_4.svg" alt="">
                <h4 class="title">node JS</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/back_stack_5.svg" alt="">
                <h4 class="title">C#.Net</h4>
            </li>
        </ul>
        <ul class="stack-list">
            <h4 class="stack-heading"> Database</h4>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/db_stack_1.svg" alt="">
                <h4 class="title">MySQL</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/db_stack_2.svg" alt="">
                <h4 class="title">Postgre SQL</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/db_stack_3.svg" alt="">
                <h4 class="title">MS SQL Server</h4>
            </li>
            <li class="stack-card">
                <img class="stack_sector" src="../../../../../assets/images/soft-development/stack/db_stack_4.svg" alt="">
                <h4 class="title">Oracle</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/db_stack_5.svg" alt="">
                <h4 class="title">MongoDB</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/db_stack_6.svg" alt="">
                <h4 class="title">Cassandra DB</h4>
            </li>
        </ul>

        <ul class="stack-list">
            <h4 class="stack-heading"> Cloud, Hosting & DevOps</h4>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/host_stack_1.svg" alt="">
                <h4 class="title">AWS</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/host_stack_2.svg" alt="">
                <h4 class="title">AZURE</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/host_stack_3.svg" alt="">
                <h4 class="title">Google Cloud</h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/host_stack_4.svg" alt="">
                <h4 class="title">Git Labs / Jenkins
                </h4>
            </li>
            <li class="stack-card">
                <img src="../../../../../assets/images/soft-development/stack/host_stack_5.svg" alt="">
                <h4 class="title">Puppet / Ansible / Chef</h4>
            </li>

        </ul>
    </div>
</section>