import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-stack',
  templateUrl: './full-stack.component.html',
  styleUrls: ['./full-stack.component.scss']
})
export class FullStackComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }

  setSeo(): void {
    this.seoService.updatePageTitle('Full Stack Development | Rapinno Tech');
    this.seoService.updateOgTitle('Full Stack Development | Rapinno Tech');
    this.seoService.updateOgDescription('Rapinno Tech specializes in software development for a wide range of technologies, from front-end prototyping to a full set of back-end services.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/full-stack-development');
    this.seoService.updateMetaTitle('Full Stack Development | Rapinno Tech'),
    this.seoService.updateMetaDescription('Rapinno Tech specializes in software development for a wide range of technologies, from front-end prototyping to a full set of back-end services.')
  }
}
