<div class="master-modal slider_modal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div mat-dialog-content>
        <!-- Modal-Body -->
        <div class="modal-body">
          <div class="modal_close_btn">
            <span (click)="dialogClose()" class="modal-close "><i class="material-icons">close</i></span>
          </div>
          <div fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
            fxLayoutAlign="start stretch">
            <div fxLayout="column" fxFlex="100" fxFlexFill>
              <img src="../../../../../assets/images/brochure/dm-br-1.jpg" alt="img">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
