import { Component, OnInit } from '@angular/core';
import { SEOService } from '../modules/core/seo.service';

@Component({
  selector: 'app-rapinno-data-digitization',
  templateUrl: './rapinno-data-digitization.component.html',
  styleUrls: ['./rapinno-data-digitization.component.scss']
})
export class RapinnoDataDigitizationComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Data Digitization | Rapinno Tech');
    this.seoService.updateOgTitle('Rapinno Data Digitization | Rapinno Tech');
    this.seoService.updateOgDescription('Rapinno Data Digitization method not only optimizes the digital future of your business but also creates the database for past data & records');
    this.seoService.updateOgType('Service');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/solutions/rapinno-data-digitization');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/service-banner/banner_2.png');
    this.seoService.updateMetaTitle('Rapinno Data Digitization | Rapinno Tech'),
    this.seoService.updateMetaDescription('Rapinno Data Digitization method not only optimizes the digital future of your business but also creates the database for past data & records')
  }
}
