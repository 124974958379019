import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-blockchain-ensures-security',
  templateUrl: './blockchain-ensures-security.component.html',
  styleUrls: ['./blockchain-ensures-security.component.scss']
})
export class BlockchainEnsuresSecurityComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
  }
  setSeo(): void {
    this.seoService.updatePageTitle('RapinnoTech Blog | How blockchain ensures security for the banking industry? ');
    this.seoService.updateOgTitle('RapinnoTech Blog | How blockchain ensures security for the banking industry? ');
    this.seoService.updateOgDescription('Blockchain has revolutionized the way it is addressing the issues that are related with fiscal transaction, including currency reproduction and double spending.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/how-blockchain-ensures-security-for-the-banking-industry');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/13.webp');
    this.seoService.updateMetaTitle(' How blockchain ensures security for the banking industry? '),
      this.seoService.updateMetaDescription('Blockchain has revolutionized the way it is addressing the issues that are related with fiscal transaction, including currency reproduction and double spending.')
  }
}
