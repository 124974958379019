import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-to-choose-the-best-rpa-software-in',
  templateUrl: './how-to-choose-the-best-rpa-software-in.component.html',
  styleUrls: ['./how-to-choose-the-best-rpa-software-in.component.scss']
})
export class HowToChooseTheBestRPASoftwareInComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How to choose the best RPA software in 2022');
    this.seoService.updateOgTitle('Rapinno Tech Blog | How to choose the best RPA software in 2022');
    this.seoService.updateOgDescription('Identifying the apt RPA technology will become the key to success for most businesses in 2022. Let’s find out how to do it.');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/How-to-choose-the-best-RPA-software-in-2022?');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/44.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How to choose the best RPA software in 2022'),
    this.seoService.updateMetaDescription('Identifying the apt RPA technology will become the key to success for most businesses in 2022. Let’s find out how to do it.')
  }
}
