import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-five-reasons-why-rpa-deployments-fail',
  templateUrl: './five-reasons-why-rpa-deployments-fail.component.html',
  styleUrls: ['./five-reasons-why-rpa-deployments-fail.component.scss']
})
export class FiveReasonsWhyRPADeploymentsFailComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Five reasons why RPA deployments fail');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Five reasons why RPA deployments fail');
    this.seoService.updateOgDescription('Automating robotic processes offers significant benefits, but many RPA pilots go nowhere due to a mix of planning, personnel, and implementation issues.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/Five-reasons-why-RPA-deployments-fail');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/42.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Five reasons why RPA deployments fail'),
    this.seoService.updateMetaDescription('Automating robotic processes offers significant benefits, but many RPA pilots go nowhere due to a mix of planning, personnel, and implementation issues.')
  }
}
