<section class="master_rapino_sec">
    <!-- SERVICE-BANNER-SEC -->
    <section class="service-intro-sec">
        <div class="banner-dots left">
            <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
        </div>
        <div class="banner-dots right">
            <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
        </div>
        <div class="wrapper">
            <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
                <div fxLayout="column" fxFlex="60" fxFlexFill>
                    <div class="intro-content">
                        <div class="section-heading">
                            <h1 class="main-heading">RDP DMS</h1>
                        </div>
                        <p>
                            With RapinnoTech's document management system, you will be offered an integrated system where you can find all the essential file & folder management, advanced search facility & collaboration options in one system. Our solution will include all the desired
                            administration tools to define the roles of various users, the level of document security, detailed log of activity and many more.
                        </p>
                    </div>
                </div>
                <div fxLayout="column" fxFlex="40" fxFlexFill>
                    <div class="intro-img">
                        <img class="raduis-img" src="../../../../../assets/images/service-banner/dms.jpg" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h2 class="text-center">Our Expertises</h2>
                <p>We promise an all-in one platform where you can manage all your internal documents</p>
            </div>
            <section class="sec-padding">
                <div class="wrapper">
                    <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch">
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/1.svg" alt="">
                                <h4>Document input</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/2.svg" alt="">
                                <h4>Document indexing</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/3.svg" alt="">
                                <h4>Robust Document search</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/4.svg" alt="">
                                <h4>Document processing</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/5.svg" alt="">
                                <h4>Workflow automation</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/6.svg" alt="">
                                <h4>Coupon management system
                                </h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/7.svg" alt="">
                                <h4>Customization</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/8.svg" alt="">
                                <h4>Embedded viewer</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/9.svg" alt="">
                                <h4>Embedded viewer</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/10.svg" alt="">
                                <h4>versioning control </h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/11.svg" alt="">
                                <h4>Permissions</h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/12.svg" alt="">
                                <h4>Universal format support
                                </h4>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                            <div class="service_box">
                                <img src="../../../../../assets/images/dms/13.svg" alt="">
                                <h4>On-site & cloud deployments
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
    <!-- CTS SECTION-->
    <section class="section-cta section-padding">
        <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
        <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <div class="cta-content">
            <h3 class="cta-heading">RapinnoTech document management system service will take care of the entire front end and back development</h3>
            <button (click)="dmsBrochure()" class="know-btn" mat-button> View
        Brochure </button>
        </div>
        <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
    </section>
    <section class="service-heading-content-sec">
        <div class="wrapper">
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/matrimony/ps.svg" alt="">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h3>Problem Statement</h3>
                            <p>
                                Companies with huge number of employees or large enterprises often deal with issues like unorganized & scattered storage of documents, unrestricted access with lack of access control and difficulty with document tracking.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h4>Solution</h4>
                            <p>
                                RapinnoTech's robust document indexing and search features enables you and your employees to access documents as per their access level. Our access control aspect is integrated to the central system where admin can seamlessly manage access level of employees.
                                Needless to say, our ultra security feature will solve all your privacy issues related to document sharing.
                            </p>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/matrimony/sol.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="tech_stock_sec sec-padding">
        <div class="wrapper">
            <div class="global_heading service_head  text-center">
                <h4>Tech Stack</h4>
                <p>We follow the time & material business model and Offshore dedicated team business model. Our technology stack includes:
                </p>
            </div>
            <div class="wrapper">
                <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                    <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="30" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box first_box">
                            <h4>Database</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-1.svg" alt="">
                                <img src="../../../../../assets/images/e-commerce/t-2.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="30" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box second_box">
                            <h4>Front End</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-3.svg" alt="">
                                <img src="../../../../../assets/images/e-commerce/t-4.svg" alt="">
                                <img src="../../../../../assets/images/e-commerce/t-5.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="30" fxFlex.xs="100" fxFlexFill>
                        <div class="tech_stock_box third_box">
                            <h4>Backend</h4>
                            <div class="tech_images">
                                <img src="../../../../../assets/images/e-commerce/t-7.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>