import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-rapinno-product-threesixty',
  templateUrl: './rapinno-product-threesixty.component.html',
  styleUrls: ['./rapinno-product-threesixty.component.scss']
})
export class RapinnoProductThreesixtyComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }

  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Product 360| Rapinno Tech');
    this.seoService.updateOgTitle('Rapinno Product 360| Rapinno Tech');
    this.seoService.updateOgDescription('Rapinnp Tech Product 360 provides an end-to-end solution starting from understanding the concept  to digital marketing and in-house sales support.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/rapinno-product-360');
    this.seoService.updateMetaTitle('Rapinno Product 360| Rapinno Tech'),
    this.seoService.updateMetaDescription('Rapinnp Tech Product 360 provides an end-to-end solution starting from understanding the concept  to digital marketing and in-house sales support.')
  }


}
