<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Blockchain</h1>
                    </div>
                    <p>
                        Blockchain applications, the technology behind cryptocurrencies, are now extended to payments, identity management, insurance, healthcare, and physical assets' digitization. Rapinno's blockchain experts can help companies from different industries to
                        implement specialized, distributed ledger technology solutions. We help can you make peer-to-peer (P2P) transactions faster, safer, and more transparent while reducing fraud risk.
                    </p>
                    <p>
                        Blockchain IT solutions can improve the ROI of IoT-related investments in the oil and gas, retail, and manufacturing industries as DLT help address common scalability, privacy, and reliability issues. By partnering with RapinnoTech, you can quickly implement
                        enterprise-wide Blockchain initiatives. We can help you accelerate time-to-market for such projects through rapid prototyping, flexible design, and strategic partnerships with leading businesses and academic institutions.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/block-chain.jpg" alt="Connected blocks in digital world">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="main-blockchain--sec">
    <!-- SERVICE-HEADING-SEC -->
    <div class="service_head">
        <h2>Our key offerings include</h2>
    </div>
    <!-- Our key offerings include -->
    <section class="service-heading-content-sec sec-bg">
        <div class="wrapper">
            <!-- <div class="section-heading-content">
      </div> -->
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div>
                            <div class="left-head">
                                <h2>Blockchain Technology</h2>

                                <p>
                                    We design distributed ledger technology for both public and private blockchains, including custom tokens, single nodes, hash algorithms, and architectures. Our consensus protocols provide templates for proof of work and definition, reducing double spending
                                    errors and the need for outdated third parties.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/services/blockchain/we_assess.svg" alt="Illustration showcasing developer designing applications for blockchain">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- We elaborate on prototypes -->
    <section class="service-heading-content-sec sec-bg-two">
        <div class="wrapper">
            <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill fxLayoutAlign="start start">
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/services/blockchain/we_elaborate.svg" alt="Illustration showcasing developer designing smart contract">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                    <div class="service-left-content d-flex-center">
                        <div>
                            <div class="left-head">
                                <h2>Smart Contracts</h2>

                                <p>
                                    We program smart contracts for blockchain networks, providing decentralized network solutions for the e-commerce, banking, real estate, supply chain and gaming sectors. We design smart contracts to record loan applications and intellectual property, validate
                                    insurance eligibility, produce letters of credit for foreign transactions, and create decentralized autonomous organizations (DAOs).
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- We implement end  -->
    <!-- <section class="service-heading-content-sec sec-bg-two">
    <div class="wrapper">
      <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
        fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex.xl="80" fxFlex.lg="80" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="100" fxFlexFill>
          <div class="innovate-box">
            <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
              fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
              <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100"
                fxFlexFill>
                <div class="innovate-content">
                  <div>
                    <p>
                      Our managed blockchain application services include blockchain wallet applications and exchange
                      platforms for desktop, mobile and browser applications. We code applications for exchanging
                      multiple cryptocurrencies and tokens based on blockchain, automatic generation of public and
                      private keys, conducting online transactions, participation in ICO (initial coin offers) and
                      analysing hypermedia and data blocks in the background.
                    </p>
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100"
                fxFlexFill fxLayoutAlign="end end">
                <div class="innovate-img">
                  <img src="../../../../../assets/images/services/blockchain/we_implement.svg" alt="img">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
    <!-- We ensure the solution -->
    <section class="service-heading-content-sec sec-bg">
        <div class="wrapper">
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div>
                            <div class="left-head">
                                <h2>Blockchain Minning Solutions</h2>

                                <p>
                                    Our embedded Blockchain development experts create cryptocurrency mining computer systems designed to improve the security of distributed reason technology. We accelerate transaction checks by incorporating application-specific integrated circuit (ASIC)
                                    chips into the mining hardware and programming double-cycle hash verification functionality.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/services/blockchain/we_ensure.svg" alt="Illustration showcasing developer minning for blockchain application">
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- We enable the client -->
    <section class="service-heading-content-sec sec-bg sec-bg-two">
        <div class="wrapper">
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/services/blockchain/we_implement.svg" alt="Illustration showcasing developer deevloping wallet using blockchain">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h2>Wallet & Expense Applications</h2>
                            <p>
                                Our managed blockchain application services include blockchain wallet applications and exchange platforms for desktop, mobile and browser applications. We code applications for exchanging multiple cryptocurrencies and tokens based on blockchain, automatic
                                generation of public and private keys, conducting online transactions, participation in ICO (initial coin offers) and analysing hypermedia and data blocks in the background.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- CTS SECTION-->
    <section class="section-cta section-padding">
        <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
        <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <div class="cta-content">
            <h3 class="cta-heading">We can help you in Blockchain</h3>
            <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> CONTACT
        NOW </button>
        </div>
        <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
    </section>
    <!-- We test the solution -->
    <!-- <section class="service-heading-content-sec sec-bg-two">
    <div class="wrapper">
      <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
        fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-right-img">
            <img src="../../../../../assets/images/services/blockchain/we_test.svg" alt="img">
          </div>
        </div>
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-left-content d-flex-center">
            <div class="left-head">
              <p>
                We test the solution after integrations
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
    <!-- We then deploy a blockchain -->
    <!-- <section class="service-heading-content-sec sec-bg sec-bg-four">
    <div class="wrapper">
      <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
        fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-left-content d-flex-center">
            <div class="left-head">
              <p>
                We then deploy a blockchain solution across the network.
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-right-img">
            <img src="../../../../../assets/images/services/blockchain/we_then.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </section> -->
    <!-- We deliver end to endblockchain -->
    <!-- <section class="service-heading-content-sec sec-bg-five">
    <div class="wrapper">
      <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
        fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-right-img">
            <img src="../../../../../assets/images/services/blockchain/we_test.svg" alt="img">
          </div>
        </div>
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-left-content d-flex-center">
            <div class="left-head">
              <p>
                We deliver end to end the blockchain support matrix.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</section>