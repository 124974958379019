<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
  <div class="banner-dots left">
    <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="" />
  </div>
  <div class="banner-dots right">
    <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="" />
  </div>
  <div class="wrapper">
    <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
      fxLayoutAlign="start stretch" fxLayoutGap="8px">
      <div fxLayout="column" fxFlex="60" fxFlexFill>
        <div class="intro-content">
          <div class="section-heading">
            <h1 class="main-heading">Career</h1>
          </div>
          <p>
            Rapinnotech is committed to helping our clients be their best. We
            love to learn & grow fulfilling not only ours but also the
            organizational goals as well. If you are interested in joining a
            team that will push you to redefine your full potential, check out
            our job openings below and join us!
          </p>
        </div>
      </div>
      <div fxLayout="column" fxFlex="40" fxFlexFill>
        <div class="intro-img">
          <img class="raduis-img" src="../../../../../assets/images/service-banner/career.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="tech-service-sec mt-50">
  <div class="wrapper">
    <div class="section-heading">
      <h4 class="main-heading">Showing current offers and jobs available</h4>
      <p class="sub-heading">
        RapinnoTech is an equal opportunity employer as we regularly innovate
        on advanced software technologies to deliver solutions to clients on a
        rapid note. We are looking for talent in the technology stack like
      </p>
    </div>
    <div class="row-wrap service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
      fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch">
      <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
        <div class="it-recruitment-card">
          <div class="w-100">
            <h3 class="title">
              Motion Graphics
            </h3>
            <button class="global-btn" mat-button [routerLink]="['/career/motion-graphics']">
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
        <div class="it-recruitment-card">
          <div class="w-100">
            <h3 class="title">
                 Node.js Developer
            </h3>
            <button class="global-btn" mat-button [routerLink]="['/career/node-js-developer']">
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
        <div class="it-recruitment-card">
          <div class="w-100">
            <h3 class="title"> UI/UX </h3>
            <button class="global-btn" mat-button [routerLink]="['/career/ui-ux']">
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
        <div class="it-recruitment-card">
          <div class="w-100">
            <h3 class="title">UI Developer</h3>
            <button class="global-btn" mat-button [routerLink]="['/career/ui-developement']">
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
        <div class="it-recruitment-card">
          <div class="w-100">
            <h3 class="title">
              Angular Developer
            </h3>
            <button class="global-btn" mat-button [routerLink]="['/career/angular-developer']">
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
        <div class="it-recruitment-card">
          <div class="w-100">
            <h3 class="title">Content Writer</h3>
            <button class="global-btn" mat-button [routerLink]="['/career/content-writer']">
              Apply Now
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
        <div class="it-recruitment-card">
          <div class="w-100">
            <h3 class="title">Flutter Developer</h3>
            <button class="global-btn" mat-button [routerLink]="['/career/fllutter']">
              Apply Now
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
