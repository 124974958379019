import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adaptive-sourcing',
  templateUrl: './adaptive-sourcing.component.html',
  styleUrls: ['./adaptive-sourcing.component.scss']
})
export class AdaptiveSourcingComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }


    setSeo(): void {
      this.seoService.updatePageTitle('Rapinno Adaptive Sourcing | Rapinno Tech');
      this.seoService.updateOgTitle('Rapinno Adaptive Sourcing | Rapinno Tech');
      this.seoService.updateOgDescription( 'RapinnoTech\'s Adaptive Sourcing methodology is based on the assessment of future trends and possibilities of industries.');
      this.seoService.updateOgType('Service Page');
      this.seoService.updateOgUrl('https://www.rapinnotech.com/adaptive-sourcing');
      this.seoService.updateMetaTitle('Rapinno Adaptive Sourcing | Rapinno Tech'),
      this.seoService.updateMetaDescription('Rapinno Tech\'s Adaptive Sourcing methodology is based on the assessment of future trends and possibilities of industries')
    }
}
