import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-is-the-banking-industry-benefiting',
  templateUrl: './how-is-the-banking-industry-benefiting.component.html',
  styleUrls: ['./how-is-the-banking-industry-benefiting.component.scss']
})
export class HowIsTheBankingIndustryBenefitingComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How Is The Banking Industry Benefiting From Cloud Transformation');
    this.seoService.updateOgTitle('Rapinno Tech Blog | How Is The Banking Industry Benefiting From Cloud Transformation');
    this.seoService.updateOgDescription('The Benefits of Cloud Computing Cloud computing solutions have catalyzed digital banking transformation, delivering benefits that impact front office and back-office operating models. ');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/how-is-the-banking-industry-benefiting-from-cloud-transformation');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/20.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How Is The Banking Industry Benefiting From Cloud Transformation'),
    this.seoService.updateMetaDescription('The Benefits of Cloud Computing Cloud computing solutions have catalyzed digital banking transformation, delivering benefits that impact front office and back-office operating models. ')
  }
}
