<section class="master_careerapply_sec">
  <section class="banner_sec">
    <div class="overlay"></div>
    <div class="banner_img">
      <img src="../../../../../assets/images/career-apply/banner.svg" alt="img" />
    </div>
  </section>

    <section class="sec-header job_descirption">
        <div class="wrapper">
            <div class="job_details">
                <div class="section-heading">
                    <h4 class="main-heading"> Node.js Developer</h4>
                 </div>
                <div fxLayout="row" fxLayoutAlign="center start">
                    <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                        <div class="role_desp">
                          <h3>Job Description</h3>
                          <p>We are looking for an experienced Node.Js developer to join our team. The candidate 
                            should know writing server-side web application logic in JavaScript and/or variants of it, 
                            such as CoffeeScript, IcedCoffeeScript, etc.</p>
                      <h4>Roles and Responsibilities :</h4>
                      <ul>
                          <li>Developing and maintaining all server-side network components.</li>
                          <li>Ensuring optimal performance of the central database and responsiveness to 
                            front-end requests.</li>
                          <li>Collaborating with front-end developers on the integration of elements. </li>
                          <li>Developing high-performance applications by writing testable, reusable, and efficient 
                            code</li>
                          <li>Implementing effective security protocols, data protection measures, and storage 
                            solutions. </li>
                          <li>Running diagnostic tests, repairing defects, and providing technical support. </li>
                          <li>Documenting Node.js processes, including database schemas, as well as preparing 
                            reports. </li>
                          <li>Will be responsible for building and integrating Systems using Node.JS API's</li>
                          <li>Fluency in working with REST APIs web services and orchestrate workflow in NodeJS</li>
                          <li>Good Knowledge of Server Setups for staging and production servers</li>
                      </ul>
                    </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center start">
                    <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                        <div class="role_desp">
                      <h4>Skills & Experience:</h4>
                      <ul>
                          <li>Extensive knowledge of JavaScript, web stacks, libraries, and NodeJS frameworks. </li>
                          <li>Exceptional analytical and problem-solving aptitude. </li>
                          <li>At least two years of experience with integration methods and technologies, 
                            including web services, REST, APIs using Express.js </li>
                          <li>Integration of data storage solutions PostgreSQL </li>
                      </ul>
                    </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center start">
                    <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                        <div class="role_desp">
                      <h4>Work Experience: 3 to 4 yrs.</h4>
                      <h4>Person Specification:</h4>
                      <p>B.Tech with Relevant University degree</p>
                      <h4>Job Type: </h4>
                      <p>Full-time </p>
                      <h4>Joining:</h4>
                      <p> Immediate to 15 days </p>
                      <h4>No of Positions: 2 </h4>
                      </div>
                      </div>
                      </div>
                </div>
                </div>
     </section>
     
   <section class="master_careerapply_sec">
    <div class="wrapper">
    <div class="section-heading text-center">
       <h4 class="main-heading">Fill your details</h4>
                    <p class="sub-heading">Our team will review your resume and will get back to you</p>
                  </div>
                  <form class="career_form" [formGroup]="careerForm">
                    <div fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
                      fxLayout.xs="column" fxLayoutAlign="start stretch">
                      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <!-- Name-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input matInput placeholder="Enter Name" formControlName="name" />
                              <mat-error *ngIf="name.invalid && (name.dirty || name.touched)">
                                <span>Name is required</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <!-- Email-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input type="email" matInput placeholder="Enter Email" formControlName="email" />
                              <mat-error *ngIf="email.invalid && (email.dirty || email.touched)"><span>Enter Valid Email</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                        <!-- PHone No-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input type="number" matInput placeholder="Enter Phone No" formControlName="phoneNo" />
                              <mat-error *ngIf="
                                  phoneNo.invalid && (phoneNo.dirty || phoneNo.touched)
                                ">
                                <span>Enter Valid Phone</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <!-- Year of Exp-INPUT -->
                        <div class="custom-form-group">
                          <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                              <input type="number" matInput placeholder="Enter total year of exp" formControlName="experience" />
                              <mat-error *ngIf="
                                  experience.invalid &&
                                  (experience.dirty || experience.touched)
                                ">
                                <span>Enter total years of exp</span>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="custom-form-group pb-20">
                      <label class="custom-label" for="">Upload Resume
                        <span class="dots">:</span></label>
                      <div class="custom-form-control">
                        <app-single-file [fileInput]="file" [size]="5"></app-single-file>
                        <mat-error *ngIf="file.errors && ( file.dirty || file.touched )">
                          <span>Please provide a valid Resume</span>
                        </mat-error>
                      </div>
                    </div>
                    <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
                      fxLayout.xs="column" fxLayoutAlign="start start">
                      <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
                        fxFlexFill fxLayoutAlign="start start">
                        <button (click)="submitQuotationForm()" class="btn btn-sm mat-button career_btn global-btn" mat-button>
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>   
                  </div>
                </section>
                     