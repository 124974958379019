import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-blog-five',
  templateUrl: './blog-five.component.html',
  styleUrls: ['./blog-five.component.scss']
})
export class BlogFiveComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Reasons Why to Digitize Your Business Right Away (Part 2)');
    this.seoService.updateOgTitle('Reasons Why to Digitize Your Business Right Away (Part 2)');
    this.seoService.updateOgDescription('The blog describes the reasons why businesses should digitize completely. It also talks about data security.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/5.jpg');
    this.seoService.updateMetaTitle('Reasons Why to Digitize Your Business Right Away (Part 2)'),
      this.seoService.updateMetaDescription('The blog describes the reasons why businesses should digitize completely. It also talks about data security.')
  }
}
