import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intelligent-automation',
  templateUrl: './intelligent-automation.component.html',
  styleUrls: ['./intelligent-automation.component.scss']
})
export class IntelligentAutomationComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }


    setSeo(): void {
      this.seoService.updatePageTitle('Intelligent Automation  | Rapinno Tech');
      this.seoService.updateOgTitle('Intelligent Automation  | Rapinno Tech');
      this.seoService.updateOgDescription('With Rapinno Tech Intelligent Automation Services and Solutions can help you meet your business process challenges cost-effectively, without risking your "large IT systems".');
      this.seoService.updateOgType('Service Page');
      this.seoService.updateOgUrl('https://www.rapinnotech.com/intelligent-automation');
      this.seoService.updateMetaTitle('Intelligent Automation | Rapinno Tech');
      this.seoService.updateMetaDescription('With Rapinno Tech Intelligent Automation Services and Solutions can help you meet your business process challenges cost-effectively, without risking your "large IT systems".')
    }

}
