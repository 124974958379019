
import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-robotic-process-automation',
  templateUrl: './robotic-process-automation.component.html',
  styleUrls: ['./robotic-process-automation.component.scss']
})
export class RoboticProcessAutomationComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }

    setSeo(): void {
      this.seoService.updatePageTitle('Robotic Process Automation | Rapinno Tech');
      this.seoService.updateOgTitle('Robotic Process Automation | Rapinno Tech');
      this.seoService.updateOgDescription('Rapinno Tech\'s (RPA) enables you to automate routine tasks with intelligent software bots.');
      this.seoService.updateOgType('Service Page');
      this.seoService.updateOgUrl('https://www.rapinnotech.com/robotic-process-automation');
      this.seoService.updateOgImage('/assets/images/service-banner/robotic_process_automation.jpg')
      this.seoService.updateMetaTitle('Robotic Process Automation | Rapinno Tech'),
      this.seoService.updateMetaDescription('Rapinno Tech\'s (RPA) enables you to automate routine tasks with intelligent software bots.')
    }
}
