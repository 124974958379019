import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-erp-project-management-is-key-to-a-successful-implementation',
  templateUrl: './how-erp-project-management-is-key-to-a-successful-implementation.component.html',
  styleUrls: ['./how-erp-project-management-is-key-to-a-successful-implementation.component.scss']
})
export class HowErpProjectManagementIsKeyToASuccessfulImplementationComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How ERP Project Management Is Key to a Successful Implementation?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | How ERP Project Management Is Key to a Successful Implementation?');
    this.seoService.updateOgDescription('An effective ERP system can be the basis of business analysis for an organization. Effective ERP project management is crucial to a project success, like bringing companies one step closer to success.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/blogs/how-erp-project-management-is-key-to-a-successful-implementation');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/20.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How ERP Project Management Is Key to a Successful Implementation?');
    this.seoService.updateMetaDescription('An effective ERP system can be the basis of business analysis for an organization. Effective ERP project management is crucial to a projects success, like bringing companies one step closer to success. ')
  }
}