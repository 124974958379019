<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Rapid Application Development</h1>
                    </div>
                    <p>
                        Rapinno Tech's rapid application development model of software development focuses on the user design cycle of prototyping, testing, and refining. When the waterfall method is planned and rigid, RAD is flexible and responsive to user input. Although the
                        RAD model of software development is 30 years old, the modern business needs to bring engaging experiences to users on all devices has reinvigorated it from native mobile and web-based apps to conversation apps.
                    </p>
                    <p>
                        This model relies on prototyping and rapid iterative development cycles to accelerate development and obtain early feedback from business users</p>
                    <!-- <p>Planning: Prototyping needs and limitations are identified and agreed upon by developers, designers, IT and
            engineers.</p>
          <p>Design: The initial prototyping and modelling are done after several iterations. Feedback from user
            experiences helps to design the overall architecture of the application.</p>
          <p>Development: Basic coding, testing and integration with back-end services are done at this stage.
            Improvements can be made based on requirements.</p>
          <p>Version: The development team moves the components from the test version to production. The application is
            deployed on time and ready to be used by the end user. </p> -->
                </div>
            </div>
            <div fxLayout="column" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/rapid_application_development.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="service-heading-content-sec application_development_features">
    <div class="wrapper">
        <section class="rapinno_methodology_sec">
            <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                    <div class="service_head our_methodology_head">
                        <h2 class="text-center">Our Key offering</h2>
                        <p></p>
                    </div>
                </div>
            </div>
        </section>

        <div class="row-wrap service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="development_features_box">
                    <img src="../../../../assets/images/rapid-application-development/1.png">
                    <h3 class="title text-center">Planning</h3>
                    <p class="sub-title text-center">Prototyping needs and limitations are identified and agreed upon by developers, designers, IT and engineers
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="development_features_box">
                    <img src="../../../../assets/images/rapid-application-development/2.png">
                    <h3 class="title text-center">Design</h3>
                    <p class="sub-title text-center">The initial prototyping and modelling are done after several iterations. Feedback from user experiences helps to design the overall architecture of the application</p>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="development_features_box">
                    <img src="../../../../assets/images/rapid-application-development/3.png">
                    <h3 class="title text-center">Development</h3>
                    <p class="sub-title text-center">Basic coding, testing and integration with back-end services are done at this stage. Improvements can be made based on requirements.
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="development_features_box">
                    <img src="../../../../assets/images/rapid-application-development/4.png">
                    <h3 class="title">Version</h3>
                    <p class="sub-title text-center">The development team moves the components from the test version to production. The application is deployed on time and ready to be used by the end user. .
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Rapid Application Development</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec">
    <div class="wrapper">
        <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-right-img">
                    <img src="../../../../assets/images/rapid-application-development/5.png">
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="service-left-content">
                    <div>
                        <div class="left-head">
                            <h4>Why do startups opt for Rapid Application Development?</h4>
                        </div>
                        <div class="service_ul">
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> A fast application development solution for the market
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Innovative mobile apps within budget
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Practical involvement in the development process from start to finish
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Increase ROI with value-driven development
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="xs-col-rev service_row mt-20" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxLayoutAlign="end end">
                <div class="service-left-content">
                    <div>
                        <div class="left-head">
                            <h3> Benefits of Rapid Application Development Model</h3>
                        </div>
                        <div class="service_ul">
                            <ul>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Apps are typically developed and delivered within a few weeks.
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Reduces overall project costs and risks
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Use readily available tools for faster and better app integration.
                                </li>
                                <li>
                                    <img src="../../../../../assets/images/green_ok.svg" alt="img"> Encourage user feedback throughout the development cycle.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill fxFlexFill fxLayoutAlign="center end">
                <div class="service-right-img">
                    <img src="../../../../assets/images/rapid-application-development/6.png">
                </div>
            </div>
        </div>
    </div>
</section>