import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-robotic-process-automation-the-future-that-banks-should-bank-on',
  templateUrl: './robotic-process-automation-the-future-that-banks-should-bank-on.component.html',
  styleUrls: ['./robotic-process-automation-the-future-that-banks-should-bank-on.component.scss']
})
export class RoboticProcessAutomationTheFutureThatBanksShouldBankOnComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Robotic Process Automation (RPA): The Future That Banks Should Bank On!');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Robotic Process Automation (RPA): The Future That Banks Should Bank On!');
    this.seoService.updateOgDescription('Robotic Process Automation or (RPA) in the banking sector is vast and profound, and it is expected to expand its footprints in the coming years.  It has also dramatically streamlined a wide variety of back-office processes that once bogged down bank workers.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/robotic-process-automation-the-future-that-banks-should-bank-on works');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/22.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Robotic Process Automation (RPA): The Future That Banks Should Bank On!'),
    this.seoService.updateMetaDescription('Robotic Process Automation or (RPA) in the banking sector is vast and profound, and it is expected to expand its footprints in the coming years.  It has also dramatically streamlined a wide variety of back-office processes that once bogged down bank workers.')
  }
}
