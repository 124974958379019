<section class="digital_platform_sec">
  <div class="wrapper">
    <div class="main_heading section-heading text-center">
      <h4 class="main-heading">Rapid Application Development</h4>
    </div>
  </div>
  <div class="digital_platform_bg">
    <!-- digital_platform_row-1 -->
    <div class="digital_platform_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
      fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
        <div class="img_box">
          <img src="../../../../../assets/images/application_dev/1.svg" alt="img">
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
        <div class="content_box">
          <div>
            <h4>E-commerce</h4>
            <p>Your retail/e-commerce business will augment as your e-commerce will integrate all the top features like,
              coupon management, catalogue management, multi-vendor management system.Even if you have an all
              set-up e-commerce but you are looking for a better advance platform, we will ensure that the transition is
              completely seamless and hassle-free. Our dedicated team will ensure that your vision pen outs the way you
              want.
            </p>
            <button class="btn btn-sm global-btn primary-bg raduis-25" mat-button [routerLink]="['/e-commerce']">View
              More</button>
          </div>
        </div>
      </div>
    </div>
    <!-- digital_platform_row-2 -->
    <div class="digital_platform_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
      fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
        <div class="img_box">
          <img src="../../../../../assets/images/application_dev/2.svg" alt="img">
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
        <div class="content_box">
          <div>
            <h4>Real estate</h4>
            <p>
              Rappino Tech will provide a 360 - degree solution which will include real estate web design and
              development for realtors, real estate agents for your real estate firm. We will work with you to
              understand your ideas and concepts so that we can deliver an all feature web/mobile application that will
              help you grow.
            </p>
            <button class="btn btn-sm global-btn primary-bg raduis-25" mat-button [routerLink]="['/real-estate']">View
              More</button>
          </div>
        </div>
      </div>
    </div>
    <!-- digital_platform_row-3 -->
    <div class="digital_platform_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
      fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
        <div class="img_box">
          <img src="../../../../../assets/images/application_dev/3.svg" alt="img">
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
        <div class="content_box">
          <div>
            <h4>Slot Booking</h4>
            <p>
              All your scheduling requirements, let it be in-house meeting scheduling or building a web app for a bus
              seat booking or any slot booking requirement, will be delivered to you will all the customization you want
              by your expert engineers. Our team will understand your vision and will deliver the best product you can
              imagine.
            </p>
            <button class="btn btn-sm global-btn primary-bg raduis-25" mat-button [routerLink]="['/slot-booking']">View
              More</button>
          </div>
        </div>
      </div>
    </div>
    <!-- digital_platform_row-4 -->
    <div class="digital_platform_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
      fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="0px">
      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
        <div class="img_box">
          <img src="../../../../../assets/images/application_dev/4.svg" alt="img">
        </div>
      </div>
      <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
        <div class="content_box">
          <div>
            <h4>Matrimony</h4>
            <p>
              Create your own Matrimony web or mobile app with all your desired features like, Social sign-in or log-in,
              GPS integration, in-app chat & camera for video calls, etc. Our team will work along side you tirelessly to
              understand your vision. We will deep dive into the minds of the target audience as well to design the best
              platform where they can start their journey of love.
            </p>
            <button class="btn btn-sm global-btn primary-bg raduis-25" mat-button [routerLink]="['/matrimony']">View
              More</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
