import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-blog-six',
  templateUrl: './blog-six.component.html',
  styleUrls: ['./blog-six.component.scss']
})
export class BlogSixComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) {}

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('RapinnoTech Blog | Why Your Bank Requires a Banking App?');
    this.seoService.updateOgTitle('RapinnoTech Blog | Why Your Bank Requires a Banking App?');
    this.seoService.updateOgDescription('With Digitization on rise, banks are looking to upgrade their in-app services. But Why? Let’s find out ');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/Why_Your_Bank_Requires_a_Banking_App');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/blog_1.webp');
    this.seoService.updateMetaTitle(' Why Your Bank Requires a Banking App?'),
      this.seoService.updateMetaDescription('With Digitization on rise, banks are looking to upgrade their in-app services. But Why? Let’s find out ')
  }

}
