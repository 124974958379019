import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-what-is-market-testing-why-it-is-important-for-new-product-or-services',
  templateUrl: './what-is-market-testing-why-it-is-important-for-new-product-or-services.component.html',
  styleUrls: ['./what-is-market-testing-why-it-is-important-for-new-product-or-services.component.scss']
})
export class WhatIsMarketTestingWhyItIsImportantForNewProductOrServicesComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Page Title	Rapinno Tech Blog |How To Market Test Your Product Before Launching Your Online Business');
    this.seoService.updateOgTitle('Page Title	Rapinno Tech Blog |How To Market Test Your Product Before Launching Your Online Business');
    this.seoService.updateOgDescription('Test marketing involves bringing a product to market, even if its small. Only a test conducted under real-world purchasing conditions can better predict your brands future innovation performance.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/blogs/what-is-market-testing-why-it-is-important-for-new-product-or-services');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/29.webp');
    this.seoService.updateMetaTitle('Page Title	Rapinno Tech Blog |How To Market Test Your Product Before Launching Your Online Business'),
    this.seoService.updateMetaDescription('Test marketing involves bringing a product to market, even if its small. Only a test conducted under real-world purchasing conditions can better predict your brands future innovation performance.')
  }
}
