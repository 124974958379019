import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SlotBookingBrochureComponent } from '../../components/slot-booking-brochure/slot-booking-brochure.component';

@Component({
  selector: 'app-slot-booking',
  templateUrl: './slot-booking.component.html',
  styleUrls: ['./slot-booking.component.scss']
})
export class SlotBookingComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  slotBrochure(): void {
    this._dialog.open(SlotBookingBrochureComponent, {
      width: '50%',
      panelClass: 'custom-modal',
      disableClose: true
    });
  }

  setSeo(): void {
    this.seoService.updatePageTitle('RDP Service Booking | Rapinno Tech');
    this.seoService.updateOgTitle('RDP Service Booking | Rapinno Tech');
    this.seoService.updateOgDescription('All your service booking requirements will be delivered to you with all the customization you want by our expert engineers.');
    this.seoService.updateOgType('RDP - Service Page');
    this.seoService.updateMetaTitle('RDP Service Booking | Rapinno Tech'),
    this.seoService.updateMetaDescription('All your service booking requirements will be delivered to you with all the customization you want by our expert engineers.')
  }
}
