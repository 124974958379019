
import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SuccessPopupComponent } from 'src/app/modules/shared/modules/success-popup/success-popup.component';
import { ToastService } from 'src/app/modules/core/services/toast.service';
import { Meta, Title } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { SEOService } from '../../core/seo.service';
import { HomeService } from '../../core/services/home.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  public _albums: Array<any> = [];
  contactUsForm!: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  // BANNER-SWIPPER
  public bannerConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    observer: true,
    observeParents: true,
    freeMode: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    loop: true,
    lazy: true,
    preloadImages: true,
    pagination: {
      el: '.banner-pagination-banner',
      clickable: true,
    },
  };
  public clientsSlider: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 5,
    keyboard: true,
    loop: false,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    spaceBetween: 30,
    // autoplay: false,
    autoplay: {
      delay: 5500,
      disableOnInteraction: false,
    },
    // pagination: {
    //   el: '.swiper-pagination-two',
    //   clickable: true,
    // },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    breakpoints: {
      // when window width is >= 240px
      240: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    },
  };
  public testConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    speed: 2000,
    spaceBetween: 15,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    keyboard: true,
    loop: false,
    longSwipes: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: false,
    // autoplay: true,
    slidesOffsetAfter: 0,
    pagination: true,
    navigation: {
      nextEl: '.test-button-next',
      prevEl: '.test-button-prev',
    },
    breakpoints: {
      // when window width is >= 240px
      240: {
        slidesPerView: 1,
      },
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      // when window width is >= 640px
      768: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
    },
  };
  emailRegex = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
  phoneRegex = new RegExp(
    /^[6-9]\d{9}$/
  );
  constructor(
    public snackBar: MatSnackBar,
    private _fb: FormBuilder,
    private toast: ToastService,
    private service: HomeService,
    private seoService: SEOService,
    private spinner: NgxSpinnerService) {
  }
  openSnackBar(): void {
    this.snackBar.openFromComponent(SuccessPopupComponent, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['success-snackbar']
    });
  }
  ngOnInit(): void {
       /** spinner starts on init */
       this.spinner.show();

       setTimeout(() => {
         /** spinner ends after 5 seconds */
         this.spinner.hide();
       }, 1000);


    this.contactUsForm = this._fb.group({
      name: ['', [Validators.required]],
      message: ['', [Validators.required]],
      phoneNo: ['', [Validators.required, Validators.pattern(this.phoneRegex)]],
      email: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
    });
    this.setSeo();

  }
  async submitContactForm(): Promise<void> {
    if (this.contactUsForm.invalid) {
      this.toast.openSnackBar('Enter Valid Form');
      this.contactUsForm.markAllAsTouched();
      return;
    }
    this.contactUsForm.value.createdAt=new Date()
    const res = await this.service.submitContactForm(this.contactUsForm.value);
    if (res) {
      this.contactUsForm.reset();
    }
  }

  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech | Rapid Technology Innovations Delivered');
    this.seoService.updateOgTitle('Rapinno Tech | Rapid Technology Innovations Delivered');
    this.seoService.updateOgDescription('With our innovative rapid development technology, we are determined to provide you a robust and 360-degree satisfying solution as per your need.');
    this.seoService.updateOgType('Home Page');
    this.seoService.updateMetaTitle('Rapinno Tech | Rapid Technology Innovations Delivered'),
    this.seoService.updateMetaDescription('With our innovative rapid development technology, we are determined to provide you a robust and 360-degree satisfying solution as per your need.')
  }
  get name(): AbstractControl {
    return this.contactUsForm.get('name') as AbstractControl;
  }
  get message(): AbstractControl {
    return this.contactUsForm.get('message')as AbstractControl;
  }
  get phoneNo(): AbstractControl {
    return this.contactUsForm.get('phoneNo') as AbstractControl;
  }
  get email(): AbstractControl {
    return this.contactUsForm.get('email') as AbstractControl;
  }
}
