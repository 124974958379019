<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Rapinno Security Services</h1>
                    </div>
                    <p>
                        Rapinno security services help organizations meet the security and compliance requirements of the next generation with increased agility, flexibility and cost-effectiveness by ensuring they meet compliance requirements with our security services.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../assets/images/security-services/services-banner.png" alt="Businessmen meeting to discuss the implementation of Adaptive sourcing">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="s-services">
    <img src="../../../../assets/images/security-services/Oval.svg" class="oval" alt="img">
    <div class="wrapper">
        <div fxlayout="row" fxLayout.lt-lg="column" fxLayoutAlign="flex-start center" fxLayoutGap="50px" fxLayoutGap.lt-lg="0px">
            <div fxLayout="column" fxFlex="30" fxFlex.lt-lg="100">
                <h1>Security Management Services</h1>
                <p>Information security threats come from various directions , not just from internet. Information security needs holistic approach that covers
                </p>
            </div>
            <div fxLayout="column" fxFlex="70" fxFlex.lt-lg="100">
                <div fxLayout="row wrap" fxLayout.xs="column">
                    <div fxLayout="column" fxFlex="33.333" fxFlex.lt-lg="50" fxFlex.xs="100">
                        <div class="card">
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" id="Group_10427" data-name="Group 10427" width="58.656" height="58.656" viewBox="0 0 58.656 58.656">
                                    <path id="icons8_security_shield_green"
                                        d="M18.693,2a1.078,1.078,0,0,0-.755.277s-5.4,4.755-11.859,4.755A1.078,1.078,0,0,0,5,8.11v8.813c0,4.8,2.221,12.7,13.239,17.34a1.078,1.078,0,0,0,.837,0c11.018-4.641,13.239-12.54,13.239-17.34V8.11a1.078,1.078,0,0,0-1.078-1.078c-6.462,0-11.859-4.755-11.859-4.755A1.078,1.078,0,0,0,18.693,2Zm-.035,7.188A4.316,4.316,0,0,1,22.97,13.5v1.438a2.163,2.163,0,0,1,2.156,2.156v5.751A2.163,2.163,0,0,1,22.97,25H14.345a2.163,2.163,0,0,1-2.156-2.156V17.095a2.163,2.163,0,0,1,2.156-2.156V13.5A4.316,4.316,0,0,1,18.658,9.188Zm0,2.156A2.163,2.163,0,0,0,16.5,13.5v1.438h4.313V13.5A2.163,2.163,0,0,0,18.658,11.344Zm0,6.469a2.156,2.156,0,1,0,2.156,2.156A2.156,2.156,0,0,0,18.658,17.814Z"
                                        transform="translate(10.67 11.155)" fill="#fff" />
                                </svg>
                            </div>
                            <p>Enterprise Security Program Design and Implementation</p>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="33.333" fxFlex.lt-lg="50" fxFlex.xs="100">
                        <div class="card">
                            <!-- <img src="../../../../assets/images/security-services/services/2.svg" alt="img"> -->
                            <div class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" id="Icon" width="58.656" height="58.656" viewBox="0 0 58.656 58.656">
                                    <g id="sidebar" transform="translate(15.302 17.852)">
                                        <path id="Path"
                                            d="M15.3,23.8H1.836A1.858,1.858,0,0,1,0,21.923V1.879A1.858,1.858,0,0,1,1.836,0H15.3V1.253H1.836a.619.619,0,0,0-.612.626V21.923a.619.619,0,0,0,.612.626H15.3Z"
                                            transform="translate(0 0)" fill="#fff" />
                                        <path id="Shape"
                                            d="M10.022,23.8H0V0H10.022A1.882,1.882,0,0,1,11.9,1.879V21.923A1.882,1.882,0,0,1,10.022,23.8ZM3.758,16.286a.626.626,0,0,0,0,1.252H8.143a.626.626,0,1,0,0-1.252Zm0-5.011a.626.626,0,0,0,0,1.252H8.143a.626.626,0,1,0,0-1.252Zm0-5.011a.627.627,0,0,0,0,1.253H8.143a.627.627,0,0,0,0-1.253Z"
                                            transform="translate(17.002 0)" fill="#fff" />
                                    </g>
                                    <path id="icons8_medium_risk"
                                        d="M15.337,14.568,9.024,3.465a.912.912,0,0,0-1.589,0l-6.313,11.1a.924.924,0,0,0,0,.93.905.905,0,0,0,.79.456H14.543a.905.905,0,0,0,.79-.456A.924.924,0,0,0,15.337,14.568Zm-6.505-.722a.3.3,0,0,1-.3.3h-.6a.3.3,0,0,1-.3-.3v-.6a.3.3,0,0,1,.3-.3h.6a.3.3,0,0,1,.3.3Zm0-2.109a.3.3,0,0,1-.3.3h-.6a.3.3,0,0,1-.3-.3V7.519a.3.3,0,0,1,.3-.3h.6a.3.3,0,0,1,.3.3Z"
                                        transform="translate(16.33 19.105)" fill="#fff" />
                                </svg>
                            </div>
                            <p>IT Risk assessment</p>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="33.333" fxFlex.lt-lg="50" fxFlex.xs="100">
                        <div class="card">
                            <div class="icon">

                                <svg xmlns="http://www.w3.org/2000/svg" id="Icon" width="58.656" height="58.656" viewBox="0 0 58.656 58.656">
                                    <path id="icons8_data_backup"
                                        d="M6,4A2,2,0,0,0,4,6V9a1,1,0,0,0,1,1H25a1,1,0,0,0,1-1V6a2,2,0,0,0-2-2ZM5,12a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H15.518A8.964,8.964,0,0,1,26,14.523V13a1,1,0,0,0-1-1Zm18,4.008a6.994,6.994,0,1,0,4.836,12.043L29,29.3l.975-5.264L25,25l1.477,1.588a5,5,0,1,1,.059-7.123,1,1,0,1,0,1.414-1.414A6.976,6.976,0,0,0,23,16.008ZM5,20a1,1,0,0,0-1,1v3a2,2,0,0,0,2,2h8.523a8.859,8.859,0,0,1,0-6Z"
                                        transform="translate(12.341 14.333)" fill="#fff" />
                                </svg>
                            </div>
                            <p>Disaster Recover Planning and Business Continuity</p>
                        </div>
                    </div>

                    <div fxLayout="column" fxFlex="33.333" fxFlex.lt-lg="50" fxFlex.xs="100">
                        <div class="card">
                            <div class="icon">

                                <svg xmlns="http://www.w3.org/2000/svg" id="Icon" width="58.656" height="58.656" viewBox="0 0 58.656 58.656">
                                    <path id="icons8_test"
                                        d="M8.411,6A3.415,3.415,0,0,0,5,9.411v15.5a3.415,3.415,0,0,0,3.411,3.411H25.156a3.415,3.415,0,0,0,3.411-3.411V9.411A3.415,3.415,0,0,0,25.156,6Zm0,1.861a.93.93,0,1,1-.93.93A.931.931,0,0,1,8.411,7.861Zm3.1,0a.93.93,0,1,1-.93.93A.931.931,0,0,1,11.512,7.861ZM7.481,11.582H26.087V24.916a.931.931,0,0,1-.93.93H8.411a.931.931,0,0,1-.93-.93Zm15.8,2.472a.93.93,0,0,0-.64.281l-1.823,1.823-.583-.583a.93.93,0,1,0-1.315,1.315l1.24,1.24a.93.93,0,0,0,1.315,0l2.481-2.481a.93.93,0,0,0-.676-1.6ZM10.272,15.3a.93.93,0,1,0,0,1.861h6.2a.93.93,0,1,0,0-1.861Zm13.006,4.023a.93.93,0,0,0-.64.281L20.815,21.43l-.583-.583a.93.93,0,1,0-1.315,1.315l1.24,1.24a.93.93,0,0,0,1.315,0l2.481-2.481a.93.93,0,0,0-.676-1.6ZM10.272,20.885a.93.93,0,1,0,0,1.861h6.2a.93.93,0,1,0,0-1.861Z"
                                        transform="translate(12.557 12.164)" fill="#fff" />
                                </svg>
                            </div>
                            <p>Vulnerability Assessment and Penetration Testing</p>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="33.333" fxFlex.lt-lg="50" fxFlex.xs="100">
                        <div class="card">
                            <div class="icon">

                                <svg xmlns="http://www.w3.org/2000/svg" id="Icon" width="58.656" height="58.656" viewBox="0 0 58.656 58.656">
                                    <path id="Subtraction_2" data-name="Subtraction 2"
                                        d="M18,26H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H12.5a1,1,0,0,1,.707.293l6.5,6.5A.993.993,0,0,1,20,7.5V24A2,2,0,0,1,18,26ZM10,11.07a2.593,2.593,0,0,0-2.59,2.59v1.427a3.886,3.886,0,1,0,5.18,0V13.66A2.584,2.584,0,0,0,10.154,11.1.433.433,0,0,0,10,11.07ZM12,1.9V7a1,1,0,0,0,1,1h5.1Zm-2,17.8a.432.432,0,0,1-.432-.432v-.552a.864.864,0,1,1,.864,0v.552A.432.432,0,0,1,10,19.705ZM8.271,14.5V13.66a1.727,1.727,0,0,1,3.454,0V14.5a3.861,3.861,0,0,0-3.454,0Z"
                                        transform="translate(19.067 16.341)" fill="#fff" />
                                </svg>
                            </div>
                            <p>Data Security Services</p>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="33.333" fxFlex.lt-lg="50" fxFlex.xs="100">
                        <div class="card">
                            <div class="icon">

                                <svg xmlns="http://www.w3.org/2000/svg" id="Icon" width="58.656" height="58.656" viewBox="0 0 58.656 58.656">
                                    <path id="icons8_computer"
                                        d="M6,4A2.015,2.015,0,0,0,4,6V19a1.992,1.992,0,0,0,.693,1.5A1.986,1.986,0,0,0,4,22v2a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2V22a1.986,1.986,0,0,0-.693-1.5A1.992,1.992,0,0,0,26,19V6a2.015,2.015,0,0,0-2-2ZM6,6H24V19H6ZM7,22H17a1,1,0,0,1,0,2H7a1,1,0,0,1,0-2Zm16,0a1,1,0,1,1-1,1A1,1,0,0,1,23,22Z"
                                        transform="translate(14.792 14.341)" fill="#fff" />
                                </svg>
                            </div>
                            <p>Computer Forensics</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="network-security">
    <div class="wrapper">
        <div class="service_head text-center">
            <h2>Network and Security Stack</h2>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="50px">
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                <h3>Networking Services</h3>
                <div class="card">
                    <p>Enterprise Network & DC Designs and Build</p>
                </div>
                <div class="card">
                    <p>Cisco DNAC, Cisco SD-WAN, Cisco ACI, Cisco SDA, IPv6</p>
                </div>
                <div class="card">
                    <p>End to End Network Audit</p>
                </div>
                <div class="card">
                    <p>Cisco, Meraki and Aruba Wireless design and build</p>
                </div>
                <div class="card">
                    <p>Juniper design and deployment with all EX series platforms</p>
                </div>
                <div class="card">
                    <p>F5 load balancer deployment and troubleshooting
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                <h3>Security Services</h3>
                <div class="card">
                    <p>Cisco ASA, Palo Alto, Checkpoint, Fortinet, Juniper, Sonicwall</p>
                </div>
                <div class="card">
                    <p>New Firewall Designs and deployments</p>
                </div>
                <div class="card">
                    <p>Firewall Migrations from one Vendor to another Vendor</p>
                </div>
                <div class="card">
                    <p>Advanced Firewall features configurations</p>
                </div>
                <div class="card">
                    <p>End to End Firewall and Security Audits</p>
                </div>
                <div class="card">
                    <p>CASB, Bluecoat, Endpoint Security, IAM, ADFs, Octa, SSO</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tech_how_we_do">
    <div class="wrapper">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutGap="50px">
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <img src="../../../../assets/images/security-services/Secure-Server-rafiki.png" alt="img">
            </div>
            <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="70" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="section-heading">
                    <h3 class="main-heading">What Sets Us Apart from Others?</h3>
                    <p>We have a dedicated Security Operations Center through which our customer's security operations are carried out effectively.
                    </p>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="25px">
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                        <ul class="choose_list">
                            <li><img src="../../../../../assets/images/green_ok.svg" alt=""> 24X7 Operations
                            </li>
                            <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Network Device log monitoring
                            </li>
                            <li><img src="../../../../../assets/images/green_ok.svg" alt=""> OS and Application log monitoring
                            </li>
                            <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Antivirus Console</li>
                        </ul>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.lt-md="100">
                        <ul class="choose_list">
                            <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Event Correlation and Alerting
                            </li>
                            <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Reports</li>
                            <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Firewalls, IDS/IPS, Antivirus, Internet Proxy
                            </li>
                            <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Vulnerability Management
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Security Services</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact
            Now
        </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>

<section class="ss-features">
    <div class="wrapper">
        <div class="service_head text-center">
            <h2>Our Value Proposition</h2>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="feature">
                    <img src="../../../../assets/images/security-services/bg1.svg" alt="img">
                    <div class="content">
                        <p>Improve Security
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="feature">
                    <img src="../../../../assets/images/security-services/bg2.svg" alt="img">
                    <div class="content">
                        <p>Proactive approach to IT Problems
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="feature">
                    <img src="../../../../assets/images/security-services/bg3.svg" alt="img">
                    <div class="content">
                        <p>Network Availability, Repair & Response
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="feature">
                    <img src="../../../../assets/images/security-services/bg4.svg" alt="img">
                    <div class="content">
                        <p>Stable Pricing Model for Contract Term
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>