import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-blog-four',
  templateUrl: './blog-four.component.html',
  styleUrls: ['./blog-four.component.scss']
})
export class BlogFourComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }



  setSeo(): void {
    this.seoService.updatePageTitle('Reasons Why to Digitize Your Business Right Away (Part 1)');
    this.seoService.updateOgTitle('Reasons Why to Digitize Your Business Right Away (Part 1)');
    this.seoService.updateOgDescription('The transition to digital technologies has long ceased to be fashionable. Unsurprisingly, exploring the world or doing business at your fingertips are not trends but an everyday necessity now.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/1.jpg');
    this.seoService.updateMetaTitle('Reasons Why to Digitize Your Business Right Away (Part 1)'),
    this.seoService.updateMetaDescription('The transition to digital technologies has long ceased to be fashionable. Unsurprisingly, exploring the world or doing business at your fingertips are not trends but an everyday necessity now.')
  }
}
