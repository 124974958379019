import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BrochureSliderComponent } from '../../components/brochure-slider/brochure-slider.component';
@Component({
  selector: 'app-e-commerce',
  templateUrl: './e-commerce.component.html',
  styleUrls: ['./e-commerce.component.scss']
})
export class ECommerceComponent implements OnInit {
  constructor(
    private _dialog: MatDialog,
    private seoService: SEOService
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
  brochure(): void {
    this._dialog.open(BrochureSliderComponent, {
      width: '50%',
      panelClass: 'custom-modal',
      disableClose: true
    });
  }
  setSeo(): void {
    this.seoService.updatePageTitle('RDP E-commerce | Rapinno Tech');
    this.seoService.updateOgTitle('RDP E-commerce | Rapinno Tech');
    this.seoService.updateOgDescription('Your e-commerce business will augment as your e-commerce will integrate all the top features like coupon management, catalog management.');
    this.seoService.updateOgType('RDP Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/rapinno-digital-platform/e-commerce');
    this.seoService.updateMetaTitle('RDP E-commerce | Rapinno Tech'),
    this.seoService.updateMetaDescription('Your e-commerce business will augment as your e-commerce will integrate all the top features like coupon management, catalog management.')
  }
}
