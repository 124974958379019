import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-slot-booking-brochure',
  templateUrl: './slot-booking-brochure.component.html',
  styleUrls: ['./slot-booking-brochure.component.scss']
})
export class SlotBookingBrochureComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<SlotBookingBrochureComponent>,
  ) { }

  ngOnInit(): void {
  }
  dialogClose(result?: any) {
    this.dialogRef.close(result ? result : null);
  }
}
