import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-are-you-using-the-top-rpa-tool',
  templateUrl: './are-you-using-the-top-rpa-tool.component.html',
  styleUrls: ['./are-you-using-the-top-rpa-tool.component.scss']
})
export class AreYouUsingTheTopRpaToolComponent implements OnInit {

  constructor( private seoService: SEOService) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Are you using the top RPA tools in 2022?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Are you using the top RPA tools in 2022?');
    this.seoService.updateOgDescription('This blog introduces the five best tools to compare for Robotic Process Automation and RPA tools. Of all the tools available in the market, we have highlighted some of the best RPA tools available for use.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/ Are you using the top RPA tools in 2022?');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/41.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Are you using the top RPA tools in 2022?'),
    this.seoService.updateMetaDescription('This blog introduces the five best tools to compare for Robotic Process Automation and RPA tools. Of all the tools available in the market, we have highlighted some of the best RPA tools available for use.')
  }
}

