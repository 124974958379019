<section class="blog-listing-section">
    <div class="wrapper">
        <div class="section-heading mt-30">
            <h1 class="main-heading">Videos</h1>
        </div>
        <div class="row blog_listing_row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column_one_listing" fxFlex="65" fxFlexFill>
                <!-- <div class="video_listing">
          <video autoplay autoplay="autoplay" inline playsinline controls [muted]='"muted"' id="myVideo" [muted]="true"
            [autoplay]="true" [loop]="true" #contactUsVideo>
            <source src="../../../../assets/videos/video.m4v" type="video/mp4">
          </video>
          <h4 class="video_heading">Start Your Digital Journey With RapinnoTech Digital Platforms</h4>
        </div> -->
                <div fxLayout="row wrap" fxLayout.xs="column">
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200" src="https://www.youtube.com/embed/eSM_YkWeS7k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Advantages of IoT</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200" src="https://www.youtube.com/embed/L9M1MkWAr3o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">What Makes a Great Mobile App</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200" src="https://www.youtube.com/embed/4IQXzZLW1Po" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">We are RapinnoTech</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200" src="https://www.youtube.com/embed/Vxtse4AzRUE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">6 R's of Cloud Migration</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200" src="https://www.youtube.com/embed/rVIEolDMudk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Benefits of Cloud Migration</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200" src="https://www.youtube.com/embed/ZW1BbEJNvW8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Advantages of Custom Application Development</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200" src="https://www.youtube.com/embed/y1GkEvjhSog" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Flutter software</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/XGcRBPxbhyg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">How Rapinno Product 360 Works?</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/W37sfnRDGCs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Cloud Deployment Model - Rapinno Tech</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/M0KH-jIveoo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Right Cloud Delivery Model</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/ghdk3V7aim4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Rapinno Product 360</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/pOS9IHO3rVE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Rapinno Tech's Key IoT Offerings Include</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/_DSscDwZngI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Benefits of Rapinno Tech's Customer Relationship Management</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/aqZh0zKqTsQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Benefits of Rapinno Product 360</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/CZXtgOE-c_I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Benefits of Rapinno Product 360</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/dMQVht1DZAE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading"> Rapinno Product 360</h4>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                        <div class="video_listing">
                            <iframe width="100%" height="200"  src="https://www.youtube.com/embed/Zo-AwRgD42c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <h4 class="video_heading">Advantages of an MVP</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column_two_listing" fxFlex="35" fxFlexFill>
                <div class="newsletter-section form-section">
                    <h3 class="news-heading">Subscribe to newsletter</h3>
                    <form (ngSubmit)="addNewsSletter(newsletterForm)" #newsletterForm="ngForm" [formGroup]="newsSletterForm" class="form-section">
                        <div class="custom-form-group">
                            <div class="custom-form-control">
                                <mat-form-field appearance="outline">
                                    <input type="text" matInput formControlName="email" placeholder="E-MAIL ADDRESS" />
                                    <mat-error>
                                        <span>Enter Valid Email</span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <button type="submit" class="global-btn" mat-button>Subscribe Now </button>
                    </form>
                    <ul class="s-links">
                        <p>Follow Us</p>
                        <li>
                            <a href="https://www.linkedin.com/company/rapinnotech/" target="_blank">
                                <img src="../../../../../assets/images/social/1.svg">
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/rapinnotech" target="_blank">
                                <img src="../../../../../assets/images/social/2.svg">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/RapinnoTech" target="_blank">
                                <img src="../../../../../assets/images/social/3.svg">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>