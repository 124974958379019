<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Digital Marketing</h1>
                    </div>
                    <p>
                        Digital marketing plays an important role in a company's branding, visibility and reach. Since everyone has access to the Internet and mobile devices, it is crucial for any company to have a digital presence. It all starts with a website and the digital
                        journey begins. At RapinnoTech, we help you design your digital presence correctly. We will then make your presence search visible, strengthen your social presence and ensure that you reach the right target group.
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/rapinno-digital-platform.jpg" alt="img">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="main-blockchain--sec">
    <!-- SERVICE-HEADING-SEC -->
    <div class="service_head">
        <h2>Service Offerings:</h2>
    </div>
    <!-- Our key offerings include -->
    <section class="service-heading-content-sec sec-bg">
        <div class="wrapper">
            <!-- <div class="section-heading-content">
      </div> -->
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h3>Content Marketing & Design</h3>
                            <p>
                                Our experienced content creators and designers will understand your brand vision and the brand image you want to portray and then will design the best content with the factors that will wow you and your customers. From logo designs to writing captivating
                                blogs and curating engaging videos, our team will ensure that your brand's story is represented properly
                            </p>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/digital/1.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- We elaborate on prototypes -->
    <section class="service-heading-content-sec sec-bg-two">
        <div class="wrapper">
            <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/digital/2.svg" alt="img">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h3>Search Engine Optimization </h3>
                            <p>
                                While designing and developing the website for your brand, our SEO experts will ensure that the whole website becomes completely search engine friendly. Our experts will do in-depth keyword research and will plant them cleverly so that the over all SERP
                                (Search Engine Rank Page) of the website increases significantly overtime.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- We implement end  -->
    <section class="innovate-sec">
        <div class="wrapper">
            <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="80" fxFlex.lg="80" fxFlex.md="80" fxFlex.sm="80" fxFlex.xs="100" fxFlexFill>
                    <div class="innovate-box">
                        <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                            <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                                <div class="innovate-content">
                                    <div class="left-head">
                                        <h3>Social Media</h3>
                                        <p>
                                            Our team will optimize all your social media handles and will design eye grasping posters and videos so that your followers are constantly updated. Our skilled marketers will design high-level content and social media strategy which will not only engage
                                            your followers but also help you generate quality leads.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                                <div class="innovate-img">
                                    <img src="../../../../../assets/images/digital/3.svg" alt="img">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="service-heading-content-sec sec-bg">
        <div class="wrapper">
            <!-- <div class="section-heading-content">
      </div> -->
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-right-img">
                        <img src="../../../../../assets/images/digital/4.svg" alt="">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content d-flex-center">
                        <div class="left-head">
                            <h3>Ads Management</h3>
                            <p>
                                As per AIDA model, our expert strategists will do through market research and create multiple buyer personas which will give you a glimpse into your target customer's mind. Then accordingly will ad strategy will be designed to achieve your marketing objectives.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- CTS SECTION-->
    <section class="section-cta section-padding">
        <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
        <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <div class="cta-content">
            <h3 class="cta-heading">We can help you in Digital Marketing</h3>
            <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> CONTACT
        NOW </button>
        </div>
        <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
        <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
    </section>
    <!-- Our key offerings include -->
    <!-- We elaborate on prototypes -->
    <!-- <section class="service-heading-content-sec sec-bg-two">
    <div class="wrapper">
      <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
        fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-right-img">
            <img src="../../../../../assets/images/digital/5.svg" alt="img">
          </div>
        </div>
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-left-content d-flex-center">
            <div class="left-head">
              <h4>Press Relations </h4>
              <p>
                We can help you manage your press relations by getting the right stories about your organization to the
                media.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</section>