import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';
@Component({
  selector: 'app-application-development',
  templateUrl: './application-development.component.html',
  styleUrls: ['./application-development.component.scss']
})
export class ApplicationDevelopmentComponent implements OnInit {
  constructor(
    private seoService: SEOService
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapid Application Development | Rapinno Tech');
    this.seoService.updateOgTitle('Rapid Application Development  | Rapinno Tech');
    this.seoService.updateOgDescription('At Rapinno Tech, we offer a wide range of software testing and QA services latest technologies like AI/ML products. We continuously face demanding quality assurance requirements and can quickly adapt to the industry\'s agility with our structured processes, tools, and techniques.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/rapid-application-development');
    this.seoService.updateMetaTitle('Rapid Application Development | Rapinno Tech'),
    this.seoService.updateMetaDescription('At Rapinno Tech, we offer a wide range of software testing and QA services latest technologies like AI/ML products. We continuously face demanding quality assurance requirements and can quickly adapt to the industry\'s agility with our structured processes, tools, and techniques.')
  }
}
