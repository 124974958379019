import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-protect-yourself-from-mobile-banking-scams-and-hacks',
  templateUrl: './protect-yourself-from-mobile-banking-scams-and-hacks.component.html',
  styleUrls: ['./protect-yourself-from-mobile-banking-scams-and-hacks.component.scss']
})
export class ProtectYourselfFromMobileBankingScamsAndHacksComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
  }
  setSeo(): void {
    this.seoService.updatePageTitle('RapinnoTech Blog |How to Protect Yourself from Mobile Banking Scams and Hacks?');
    this.seoService.updateOgTitle('RapinnoTech Blog ||How to Protect Yourself from Mobile Banking Scams and Hacks?');
    this.seoService.updateOgDescription('How can you protect yourself when banking from your phone?What can financial institutions do for mobile app security?');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/8.webp');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/how_to_protect_yourself_from_mobile_banking_scams_and_hacks?');
    this.seoService.updateMetaTitle('RapinnoTech Blog | How Your Mobile Bank App Can Be a Source of Extra Revenue?'),
    this.seoService.updateMetaDescription('In this blog we describe different methods of ensuring security in mobile banking apps')
  }
}
