<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">RapiCon - Advanced Planning System</h1>
                    </div>
                    <p>
                        RapiCon Advanced Planning System(APS) helps you make the best use of People, Processes, Information, and Technology in order to increase service levels and, at the same time, reduce operational costs. We have helped our clients in cost reductions, revenue
                        increases, and significant improvements to both customer service and employee satisfaction. RapiCon Advanced Planning System (APS) supports planners in making better decisions based on the right information and using powerful,
                        flexible visualization and optimization techniques. It helps to establish operational excellence by enabling better planning decisions at strategic, tactical, and operational levels.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../assets/images/service-banner/advanced-planning-system.jpg" alt="Businessmen meeting to discuss the implementation of Adaptive sourcing">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="salient-features">
    <div class="wrapper">
        <div class="section-heading">
            <h3 class="main-heading">RapiCon APS Salient Features</h3>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="20" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="feature">
                    <img src="../../../../assets/images/advanced-planning-system/features/bg1.svg" alt="img">
                    <div class="content">
                        <img src="../../../../assets/images/advanced-planning-system/features/1.svg" alt="img">
                        <p>End-to-End Production Planning & Scheduling
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="20" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="feature">
                    <img src="../../../../assets/images/advanced-planning-system/features/bg2.svg" alt="img">
                    <div class="content pl-8">
                        <img src="../../../../assets/images/advanced-planning-system/features/2.svg" alt="img">
                        <p>Dynamic Order Priority Management
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="20" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="feature">
                    <img src="../../../../assets/images/advanced-planning-system/features/bg3.svg" alt="img">
                    <div class="content">
                        <img src="../../../../assets/images/advanced-planning-system/features/3.svg" alt="img">
                        <p>Effective Equipment Maintenance Planning
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="20" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="feature">
                    <img src="../../../../assets/images/advanced-planning-system/features/bg4.svg" alt="img">
                    <div class="content pl-8">
                        <img src="../../../../assets/images/advanced-planning-system/features/4.svg" alt="img">
                        <p>Customizable Product Sequence Process
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="20" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="feature">
                    <img src="../../../../assets/images/advanced-planning-system/features/bg5.svg" alt="img">
                    <div class="content">
                        <img src="../../../../assets/images/advanced-planning-system/features/5.svg" alt="img">
                        <p>Batch Size Automation
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="tech_how_we_do">
    <div class="wrapper">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutGap="50px">
            <div fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="70" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="section-heading">
                    <h3 class="main-heading">Benefits Of Using RapiCon - Advanced Planning System</h3>
                </div>
                <ul class="choose_list">
                    <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Get rid of spending hours and days preparing production schedules.
                    </li>
                    <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Ability to predict the expected completion time for each production lot.</li>
                    <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Ease of changing priorities at any time.
                    </li>
                    <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Assess the utilization of production lines and individual equipment.</li>
                    <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Enable decisions to maximize equipment utilization.</li>
                    <li><img src="../../../../../assets/images/green_ok.svg" alt=""> Enable decisions to drive process improvements in terms of reducing delivery times.</li>
                </ul>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <img src="../../../../assets/images/advanced-planning-system/benfits.jpg" alt="img">
            </div>
        </div>
    </div>
</section>


<section class="bg-content">
    <div class="wrapper">
        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="column" fxFlex="75" fxFlex.lt-lg="100" fxLayoutAlign="center center">
                <h4>"As per a Survey, Plant Heads and Production Heads spend at least 3 to 4 days in a month for production schedule which is never implemented and end up managing the same manually at the cost of losing efficiencies. "
                </h4>
            </div>
        </div>
    </div>
</section>

<section class="why-choose-us">
    <div class="wrapper">
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="flex-start center" fxLayoutGap="50px">
            <div fxLayout="column" fxLayout="column" fxFlex.xl="70" fxFlex.lg="70" fxFlex.md="70" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="section-heading">
                    <h3 class="main-heading">Why Choose Rapicon - APS?</h3>
                </div>
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
                    <div fxLayout="column" fxFlex="50">
                        <div class="card">
                            <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="10px">
                                <img src="../../../../assets/images/advanced-planning-system/why_choose_us/delivery.svg" alt="delivery">
                                <p>On-Time Delivery</p>
                            </div>
                        </div>
                        <div class="card" style="background-color: #f5f7ff;">
                            <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="10px">
                                <img src="../../../../assets/images/advanced-planning-system/why_choose_us/utilization.svg" alt="utilization">
                                <p>Maximum Capacity Utilization
                                </p>
                            </div>
                        </div>
                        <div class="card" style="background-color: #ecf7ff;">
                            <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="10px">
                                <img src="../../../../assets/images/advanced-planning-system/why_choose_us/profit.svg" alt="profit">
                                <p>Maximum Profit</p>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="column" fxFlex="50">
                        <div class="card" style="background-color: #fdf9ec;">
                            <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="10px">
                                <img src="../../../../assets/images/advanced-planning-system/why_choose_us/wastage.svg" alt="wastage">
                                <p>Reduced Production Wastage
                                </p>
                            </div>
                        </div>
                        <div class="card" style="background-color: #f5fffa;">
                            <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="10px">
                                <img src="../../../../assets/images/advanced-planning-system/why_choose_us/revenue.svg" alt="revenue">
                                <p>Maximum Revenue</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <img src="../../../../assets/images/advanced-planning-system/why_choose_us.jpg" alt="img">
            </div>
        </div>
    </div>
</section>

<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">Looking for a Demo?</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact
            Now
        </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>

<section class="deployment">
    <div class="wrapper">
        <div class="service_head text-center">
            <h2>RapiCon - Advanced Planning System Deployment Methodology</h2>
            <p>Stage-wise Deployment Methodology: Our approach of deploying RapiCon APS involves the following stages:
            </p>
        </div>
        <div fxLayout="row wrap" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="card s1">
                    <div class="mt-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <img src="../../../../assets/images/advanced-planning-system/deployment/1.svg" alt="img">
                        <h3>Step 01</h3>
                    </div>
                    <p>Understand the current production planning and scheduling practices</p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="card s2">
                    <div class="mt-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <img src="../../../../assets/images/advanced-planning-system/deployment/2.svg" alt="img">
                        <h3>Step 02</h3>
                    </div>
                    <p>Assess the products manufacturing details</p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="card s3">
                    <div class="mt-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <img src="../../../../assets/images/advanced-planning-system/deployment/3.svg" alt="img">
                        <h3>Step 03</h3>
                    </div>
                    <p>Configuring RapiCon APS as per client requirements agreed mutually</p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="card s4">
                    <div class="mt-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <img src="../../../../assets/images/advanced-planning-system/deployment/4.svg" alt="img">
                        <h3>Step 04</h3>
                    </div>
                    <p>Reengineering the processes, redefining the roles of individuals in the organization
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="card s5">
                    <div class="mt-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <img src="../../../../assets/images/advanced-planning-system/deployment/5.svg" alt="img">
                        <h3>Step 05</h3>
                    </div>
                    <p>Training respective users on use of the tool and defining the roles and responsibilities for deployment
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="card s6">
                    <div class="mt-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <img src="../../../../assets/images/advanced-planning-system/deployment/6.svg" alt="img">
                        <h3>Step 06</h3>
                    </div>
                    <p>Training respective users on Transitioning the processes from current practice to RapiCon APS driven reengineered processes
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.lt-lg="50" fxFlex.xs="100">
                <div class="card s7">
                    <div class="mt-20" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                        <img src="../../../../assets/images/advanced-planning-system/deployment/7.svg" alt="img">
                        <h3>Step 07</h3>
                    </div>
                    <p>Signoff and sustaining the improvements achieved
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>