import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-cloud-computing-better-datasecurity',
  templateUrl: './cloud-computing-better-datasecurity.component.html',
  styleUrls: ['./cloud-computing-better-datasecurity.component.scss']
})
export class CloudComputingBetterDatasecurityComponent implements OnInit {


  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How can you utilize Cloud Computing for Stronger Healthcare Data Security?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | How can you utilize Cloud Computing for Stronger Healthcare Data Security?');
    this.seoService.updateOgDescription('Cloud computing solutions can make healthcare operations even more convenient and cost-effective. It can help covered entities to download some of this data and keep it securely stored.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/14.jpg');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/what_are_the_benefits_and_technology_of_developing_cross_platform_mobile_app_development');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How can you utilize Cloud Computing for Stronger Healthcare Data Security?'),
      this.seoService.updateMetaDescription('Cloud computing solutions can make healthcare operations even more convenient and cost-effective. It can help covered entities to download some of this data and keep it securely stored.')
  }
}
