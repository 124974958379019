<div class="master-modal slider_modal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <!-- Modal-Header -->
      <!-- <div class="modal-header">
        <h4 class="modal-title">Review and rating</h4>
        <span (click)="dialogClose()" class="modal-close"><i class="material-icons">close</i></span>
        <div class="modal_close_btn">
          <span (click)="dialogClose()" class="modal-close "><i class="material-icons">close</i></span>
        </div>
      </div> -->
      <!-- Modal content-->

        <div mat-dialog-content>
          <!-- Modal-Body -->
          <div class="modal-body">
            <div class="modal_close_btn">
              <span (click)="dialogClose()" class="modal-close "><i class="material-icons">close</i></span>
            </div>
            <div fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
              fxLayoutAlign="start stretch">
              <div fxLayout="column" fxFlex="100" fxFlexFill>
                <app-brochure></app-brochure>
              </div>
            </div>
          </div>
          <!-- Modal-Footer -->
          <!-- <div class="modal-footer">
            <button type="submit" mat-button class="global-btn primary-bg  radius-25">Save</button>
          </div> -->
        </div>
    </div>
  </div>
</div>
