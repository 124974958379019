import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-potential-risks-blog',
  templateUrl: './potential-risks-blog.component.html',
  styleUrls: ['./potential-risks-blog.component.scss']
})
export class PotentialRisksBlogComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }

  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | POTENTIAL RISKS OF POOR DATA Security Part 1');
    this.seoService.updateOgTitle('Rapinno Tech Blog | POTENTIAL RISKS OF POOR DATA Security Part 1');
    this.seoService.updateOgDescription('The blog describes the reasons why businesses should digitize completely. It also talks about data security.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/7.webp');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/potential_risks_of_poor_data_security_part1');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | POTENTIAL RISKS OF POOR DATA Security Part 1'),
      this.seoService.updateMetaDescription('The blog describes the reasons why businesses should digitize completely. It also talks about data security.')
  }
}
