import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';
@Component({
  selector: 'app-blog-two',
  templateUrl: './blog-two.component.html',
  styleUrls: ['./blog-two.component.scss']
})
export class BlogTwoComponent implements OnInit {
  constructor(
    private seoService: SEOService
  ) { }
  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Why does your business need Security Testing?');
    this.seoService.updateOgTitle('Why does your business need Security Testing?');
    this.seoService.updateOgDescription('Cyber-attacks are on the rise! However, many businesses are still not implementing enough security measures to protect themselves.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/Why_does_your_business_need_Security_Testing');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/blog_2.webp');
    this.seoService.updateMetaTitle('Why does your business need Security Testing?'),
      this.seoService.updateMetaDescription('Cyber-attacks are on the rise! However, many businesses are still not implementing enough security measures to protect themselves. ')
  }
}
