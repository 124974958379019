// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverBaseUrl: 'https://v3-api.dbgen.xyz/api/v1',
  domainUrl: 'https://www.rapinnotech.com',
  firebase:{
  apiKey: 'AIzaSyB5wwiqe7BorVq1gL1sK11aVgvCRpgXvkE',
  authDomain: 'rapinnotech-bd56b.firebaseapp.com',
  databaseURL: 'https://rapinnotech-bd56b.firebaseio.com',
  projectId: 'rapinnotech-bd56b',
  storageBucket: 'rapinnotech-bd56b.appspot.com',
  messagingSenderId: '613022053608',
  appId: '1:613022053608:web:7d3505d7c7433e17fe455d',
  measurementId: 'G-368RPXM7DK'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
