<div class="inner-banner">
    <img src="../../../../../assets/images/contact-banner.svg" alt="">
</div>
<section class="mapsection">
    <div class="wrapper">
        <div class="section-heading">
            <h1 class="main-heading"> Our Offices </h1>
            <p class="sub-heading">We are a global team dedicated to solve problems
            </p>
        </div>
        <img src="../../../../../assets/images/map.png" alt="map">
        <!-- <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1ooa_ecbL1xnSw8rKxWzVTG1r2_k7RA1I" width="100%" height="480"></iframe> -->
        <!-- <iframe src="https://www.google.com/maps/d/embed?mid=1ooa_ecbL1xnSw8rKxWzVTG1r2_k7RA1I" width="100%" height="480"></iframe> -->
        <!-- <iframe src="https://www.google.com/maps/d/embed?mid=1ooa_ecbL1xnSw8rKxWzVTG1r2_k7RA1I" height="480" style="width:100%"></iframe> -->
    </div>
</section>
<section class="contact-details-section">
    <div class="wrapper">
        <div class="section-heading">
            <h1 class="main-heading">We are here to help you</h1>
            <p class="sub-heading">Our team will understand your requirement and will contact you soon.
            </p>
        </div>

        <div fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
            fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="50" fxFlexFill>
                <form [formGroup]="quoteForm" class="contact-form form-section" style="padding: 70px 28px 60px 46px;">
                    <div class="custom-form-group">
                        <!-- <label class="custom-label" for="">Name<span class="star">*</span></label> -->
                        <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                                <input type="text" matInput formControlName="name" placeholder="Enter your Name" />
                                <mat-error *ngIf="name.invalid && ( name.dirty || name.touched )">
                                    <span>Name is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- noEmp-SELECT -->
                    <div class="custom-form-group">
                        <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                                <input matInput formControlName="designation" placeholder="Enter your designation" />
                                <mat-error *ngIf="designation.invalid && ( designation.dirty || designation.touched )">
                                    <span>designation is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="custom-form-group">
                        <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                                <input type="text" matInput formControlName="email" placeholder="Enter your email" />
                                <mat-error>
                                    <span *ngIf="email.hasError('required')">Email is required</span>
                                    <span *ngIf="email.hasError('pattern')">Enter Valid Email</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- State-SELECT -->
                    <div class="custom-form-group">
                        <div class="custom-form-control">
                            <mat-form-field appearance="outline">
                                <textarea rows="1" cols="5" matInput formControlName="message"
                                    placeholder="Message"></textarea>
                                <mat-error *ngIf="message.invalid && ( message.dirty || message.touched )">
                                    <span>Message is required</span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <button (click)=" submitQuotationForm()" class="global-btn" mat-button> Submit </button>
                    </div>

                </form>
            </div>
            <div fxLayout="column" fxFlex="50" class="footer-sec" fxFlexFill
                style="padding: 40px 28px 40px 46px;margin: 50px auto;">
                <div class="footer" style="padding-top: 5px;">
                    <div class="ft-text">
                        <h4 class="location-type"><img src="../../../../../assets/images/icons/Icons/usa.svg"
                                alt="">&nbsp; USA</h4>
                        <h6 class="address-line"> Rapinno Tech Solutions Inc.
                            <br>1968 S.Coast Hwy #2989 LAGUNA BEACH,
                            <br> California, 92651 United States of America.
                        </h6>

                        <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/mail.svg" alt="">
                            Email:
                            info.usa@rapinnotech.com
                        </h6>
                        <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/phone.svg" alt="">
                            Phone:+1 949 516 8425
                            ‎
                        </h6>
                        <br>
                        <h4 class="location-type"><img src="../../../../../assets/images/icons/Icons/germany.svg"
                                alt=""> &nbsp; GERMANY</h4>
                        <h6 class="address-line"> Rapinno Tech Solutions GmbH
                            <br> Julius-Hatry-Straße 1,
                            <br> 68163 Mannheim, Germany.
                        </h6>
                        <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/mail.svg" alt="">
                            Email:
                            info.europe@rapinnotech.com
                        </h6>
                        <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/phone.svg" alt="">
                            Phone: + 49 17693133848
                            ‎
                        </h6>
                        <br>
                        <h4 class="location-type"><img src="../../../../../assets/images/icons/Icons/indai.svg" alt="">
                            &nbsp; INDIA</h4>
                        <h6 class="address-line"> Rapinno Tech Solutions Private Limited
                            <br>2nd Floor, Silicon Towers, Silicon Valley,
                            <br>Near Image Gardens, Madhapur,
                            <br>HITEC City, Telangana 500081.
                        </h6>
                        <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/mail.svg" alt="">
                            Email:
                            info.india@rapinnotech.com
                        </h6>
                        <h6 class="address-line"> <img src="../../../../../assets/images/icons/Icons/phone.svg" alt="">
                            Phone:
                            ‎+91 40 44720040
                        </h6>
                    </div>
                </div>
                <!-- Email-SELECT -->

                <!-- <div class="custom-form-group">
            <div class="custom-form-control">
              <mat-form-field appearance="outline">
                <input type="text" matInput formControlName="phoneNo" placeholder="Enter your phone No" />
                <mat-error *ngIf="phoneNo.invalid && ( phoneNo.dirty || phoneNo.touched )">
                  <span *ngIf="phoneNo.hasError('required')">Phone No is required</span>
                  <span *ngIf="phoneNo.hasError('pattern')">Enter Valid Phone No</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->
                <!-- companyName -->
                <!-- <div class="custom-form-group">
            <div class="custom-form-control">
              <mat-form-field appearance="outline">
                <input type="text" matInput formControlName="companyName" placeholder="Enter your Company Name" />
                <mat-error *ngIf="companyName.invalid && ( companyName.dirty || companyName.touched )">
                  <span>Company Name is required</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->
                <!-- toknow-SELECT -->
                <!-- <div class="custom-form-group">
            <div class="custom-form-control">
              <mat-form-field appearance="outline">
                <input type="text" matInput formControlName="toknow" placeholder="What you want to know" />
                <mat-error *ngIf="toknow.invalid && ( toknow.dirty || toknow.touched )">
                  <span> is required</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div> -->
            </div>
        </div>

    </div>
</section>