import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-resource-planning',
  templateUrl: './enterprise-resource-planning.component.html',
  styleUrls: ['./enterprise-resource-planning.component.scss']
})
export class EnterpriseResourcePlanningComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }

  setSeo(): void {
    this.seoService.updatePageTitle('ERP | Rapinno Tech');
    this.seoService.updateOgTitle('ERP | Rapinno Tech');
    this.seoService.updateOgDescription('Rappino Tech have implemented simple yet customized ERP solutions at very reasonable cost for a spectrum of businesses');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/enterprise-resource-planning');
    this.seoService.updateMetaTitle('ERP | Rapinno Tech'),
    this.seoService.updateMetaDescription('Rappino Tech have implemented simple yet customized ERP solutions at very reasonable cost for a spectrum of businesses')
  }

}
