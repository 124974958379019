import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-why-should-enterprises-move-towards-intelligent-automation',
  templateUrl: './why-should-enterprises-move-towards-intelligent-automation.component.html',
  styleUrls: ['./why-should-enterprises-move-towards-intelligent-automation.component.scss']
})
export class WhyShouldEnterprisesMoveTowardsIntelligentAutomationComponent implements OnInit {
  constructor( 
    private seoService: SEOService
    ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Why Should Enterprises Move Towards Intelligent Automation?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Why Should Enterprises Move Towards Intelligent Automation?');
    this.seoService.updateOgDescription('Here are ways intelligent automation will help your business grow. In the latest technology trend, organizations are looking for intelligent automation tools to solve business challenges.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/why-should-enterprises-move-towards-intelligent-automation');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/32.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | Why Should Enterprises Move Towards Intelligent Automation?'),
    this.seoService.updateMetaDescription('Here are ways intelligent automation will help your business grow. In the latest technology trend, organizations are looking for intelligent automation tools to solve business challenges.')
  }
}
