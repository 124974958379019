<div class="uploader-sec">
  <div
    class="uploader-box"
    ondragover="return true;"
    (click)="fileVariable.click()"
    dropzone
    (hovered)="toggleHover($event)"
    (dropped)="onDrop($event)"
    [class.hovering]="isHovering"
  >
    <div class="upload-single-img">
      <div class="master-img-upload">
        <div class="uploader">
          <img
            class="upload-icon"
            src="../../../../../../assets/images/uploader.svg"
            alt=""
          />
          <h2>Drag & Drop your file here</h2>
          <h4 class="file-size">
            <ng-container *ngIf="isDimensionCheck">
              File dimensions ({{ width }} <i class="material-icons">close</i>
              {{ height }})</ng-container
            >
          </h4>
          <button type="button" class="upload-btn" mat-button>
            Browse Files
            <img
              src="../../../../../assets/images/icons/upload-arrow.svg"
              alt=""
              class="upload-btn-img"
            />
            <input
              type="file"
              class="input-upload"
              #fileVariable
              (click)="$event.stopPropagation()"
              (change)="onDrop(fileVariable.files)"
            />
          </button>
          <span class="support-file">Supported: pdf</span>
        </div>
      </div>
    </div>
  </div>

  <div class="uploaded-imgs" *ngIf="fileInput.value || percentage">
    <ul class="img-listing">
      <li class="listing-img">
        <img
          *ngIf="fileInput.value"
          src="../../../../../assets/images/pdf.svg"
          alt="img"
        />
        {{ file?.name }}

        <i
          *ngIf="fileInput.value"
          class="material-icons remove-icon"
          (click)="removeFile()"
          >close</i
        >
        <mat-progress-spinner
          [value]="percentage | async"
          [diameter]="50"
          *ngIf="!fileInput.value && percentage"
        >
        </mat-progress-spinner>
      </li>
    </ul>
  </div>
</div>

<div *ngIf="file">
  <div *ngIf="snapshot | async as snap"></div>
</div>
