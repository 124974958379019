import { SEOService } from '../../core/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-development',
  templateUrl: './software-development.component.html',
  styleUrls: ['./software-development.component.scss']
})
export class SoftwareDevelopmentComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapid Software Development | Rapinno Tech');
    this.seoService.updateOgTitle('Rapid Software Development | Rapinno Tech');
    this.seoService.updateOgDescription('Guided by a proven rapid application development model, we have created thousands of software in the shortest possible time.');
    this.seoService.updateOgType('Service Page');
    this.seoService.updateMetaTitle('Rapid Software Development | Rapinno Tech'),
      this.seoService.updateMetaDescription('Guided by a proven rapid application development model, we have created thousands of software in the shortest possible time.')
  }
}
