<div class="swiper-container" (swiperTransitionStart)="swipperInit()"  [swiper]="secondSwapper">
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <img src="../../../../../assets/images/brochure/ec-br-1.jpg" alt="">
    </div>
    <div class="swiper-slide">
      <img src="../../../../../assets/images/brochure/ec-br-2.jpg" alt="">
    </div>
  </div>
  <span class="navigations">
    <span class="controls">
      <i class="swiper-button-prev second-swiper-button-prev"></i>
      <i class="swiper-button-next second-swiper-button-next"></i>
    </span>
  </span>
</div>
