import { VideosComponent } from './modules/pages/videos/videos.component';
import { CaseStudiesListingComponent } from './modules/pages/case-studies-listing/case-studies-listing.component';
import { ProtectYourselfFromMobileBankingScamsAndHacksComponent } from './modules/pages/protect-yourself-from-mobile-banking-scams-and-hacks/protect-yourself-from-mobile-banking-scams-and-hacks.component';
import { RapinnoDataDigitizationComponent } from './rapinno-data-digitization/rapinno-data-digitization.component';
import { DataSecurityBlogComponent } from './modules/pages/data-security-blog/data-security-blog.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AboutUsComponent } from './modules/pages/about-us/about-us.component';
import { AdaptiveSourcingComponent } from './modules/pages/adaptive-sourcing/adaptive-sourcing.component';
import { AiAutomationComponent } from './modules/pages/ai-automation/ai-automation.component';
import { ApplicationDevelopmentComponent } from './modules/pages/application-development/application-development.component';
import { BigDataComponent } from './modules/pages/big-data/big-data.component';
import { BlockchainComponent } from './modules/pages/blockchain/blockchain.component';
import { BlogFourComponent } from './modules/pages/blog-four/blog-four.component';
import { BlogListingComponent } from './modules/pages/blog-listing/blog-listing.component';
import { BlogOneComponent } from './modules/pages/blog-one/blog-one.component';
import { BlogThreeComponent } from './modules/pages/blog-three/blog-three.component';
import { BlogTwoComponent } from './modules/pages/blog-two/blog-two.component';
import { CareerApplyComponent } from './modules/pages/career-apply/career-apply.component';
import { CareerComponent } from './modules/pages/career/career.component';
import { CloudComponent } from './modules/pages/cloud/cloud.component';
import { ContactUsComponent } from './modules/pages/contact-us/contact-us.component';
import { CrmComponent } from './modules/pages/crm/crm.component';
import { DigitalMarketingComponent } from './modules/pages/digital-marketing/digital-marketing.component';
import { DmsComponent } from './modules/pages/dms/dms.component';
import { ECommerceComponent } from './modules/pages/e-commerce/e-commerce.component';
import { EngineApiComponent } from './modules/pages/engine-api/engine-api.component';
import { EnterpriseResourcePlanningComponent } from './modules/pages/enterprise-resource-planning/enterprise-resource-planning.component';
import { FleetManagementComponent } from './modules/pages/fleet-management/fleet-management.component';
import { FullStackComponent } from './modules/pages/full-stack/full-stack.component';
import { HomeComponent } from './modules/pages/home/home.component';
import { HumanManagementComponent } from './modules/pages/human-management/human-management.component';
import { IndexComponent } from './modules/pages/index/index.component';
import { IntelligentAutomationComponent } from './modules/pages/intelligent-automation/intelligent-automation.component';
import { InternetOfThingsComponent } from './modules/pages/internet-of-things/internet-of-things.component';
// import { ItRecruitmentComponent } from './modules/pages/it-recruitment/it-recruitment.component';
import { MatrimonyComponent } from './modules/pages/matrimony/matrimony.component';
import { ProductDevelopmentComponent } from './modules/pages/product-development/product-development.component';
import { RapidApplicationDevelopmentComponent } from './modules/pages/rapid-application-development/rapid-application-development.component';
import { RapinnoDigitalPlatformComponent } from './modules/pages/rapinno-digital-platform/rapinno-digital-platform.component';
// import { RapinnoSolutionComponent } from './modules/pages/rapinno-solution/rapinno-solution.component';
import { RealEstateComponent } from './modules/pages/real-estate/real-estate.component';
import { RoboticProcessAutomationComponent } from './modules/pages/robotic-process-automation/robotic-process-automation.component';
// import { SalesServiceComponent } from './modules/pages/sales-service/sales-service.component';
import { SlotBookingComponent } from './modules/pages/slot-booking/slot-booking.component';
import { SoftwareDevelopmentComponent } from './modules/pages/software-development/software-development.component';
import { SoftwareTestingComponent } from './modules/pages/software-testing/software-testing.component';
import { TechInnovationComponent } from './modules/pages/tech-innovation/tech-innovation.component';
import { TechStackComponent } from './modules/pages/tech-stack/tech-stack.component';
import { HeaderComponent } from './modules/components/header/header.component';
import { FooterComponent } from './modules/components/footer/footer.component';
import { BlogDetailsComponent } from './modules/pages/blog-details/blog-details.component';
import { BrochureSliderComponent } from './modules/components/brochure-slider/brochure-slider.component';
import { BrochureComponent } from './modules/components/brochure/brochure.component';
import { DmsBrochureComponent } from './modules/components/dms-brochure/dms-brochure.component';
import { FleetManagementBrochureComponent } from './modules/components/fleet-management-brochure/fleet-management-brochure.component';
import { MatrimonyBrochureComponent } from './modules/components/matrimony-brochure/matrimony-brochure.component';
import { RealEstateBrochureComponent } from './modules/components/real-estate-brochure/real-estate-brochure.component';
import { SlotBookingBrochureComponent } from './modules/components/slot-booking-brochure/slot-booking-brochure.component';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClientModule } from '@angular/common/http';
import { ProductMigrationComponent } from './modules/pages/product-migration/product-migration.component';
import { IntegratedMarketingServiceComponent } from './modules/pages/integrated-marketing-service/integrated-marketing-service.component';
import { RapinnoProductThreesixtyComponent } from './modules/pages/rapinno-product-threesixty/rapinno-product-threesixty.component';
import { BlogFiveComponent } from './modules/pages/blog-five/blog-five.component';
import { AngularFireModule } from '@angular/fire';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { SharedModule } from './modules/shared/modules/shared.module';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { PotentialRisksBlogTwoComponent } from './modules/pages/potential-risks-blog-two/potential-risks-blog-two.component';
import { AngdovpPageComponent } from './modules/pages/carrers/angdovp-page/angdovp-page.component';
import { ContPageComponent } from './modules/pages/carrers/cont-page/cont-page.component';
import { DesgPageComponent } from './modules/pages/carrers/desg-page/desg-page.component';
import { DevpPageComponent } from './modules/pages/carrers/devp-page/devp-page.component';
import { FulldvpPageComponent } from './modules/pages/carrers/fulldvp-page/fulldvp-page.component';
import { GrapPageComponent } from './modules/pages/carrers/grap-page/grap-page.component';
import { UidevpPageComponent } from './modules/pages/carrers/uidevp-page/uidevp-page.component';
import { MobileBankingAdoptionComponent } from './modules/pages/mobile-banking-adoption/mobile-banking-adoption.component';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { CaseStudyOneComponent } from './modules/pages/case-study-one/case-study-one.component';
import { WhyIsFlutterBlogComponent } from './modules/pages/why-is-flutter-blog/why-is-flutter-blog.component';
import { WhyYourSmallBusinessRequiresBlogComponent } from './modules/pages/why-your-small-business-requires-blog/why-your-small-business-requires-blog.component';
import { AcceleratingDigitalTransformationComponent } from './modules/pages/blogs/accelerating-digital-transformation/accelerating-digital-transformation.component';
import { WhyIsAnErpImplementationChallengingComponent } from './modules/pages/blogs/why-is-an-erp-implementation-challenging/why-is-an-erp-implementation-challenging.component';
import { RapinnoIaEcoSystemComponent } from './modules/pages/ia-eco-system/ia-eco-system.component';
import { RapinnoManagedServicesComponent } from './modules/pages/managed-services/managed-services.component';
import { OurTeamComponent } from './modules/pages/our-team/our-team.component';
import { SecurityServicesComponent } from './modules/pages/security-services/security-services.component';
import { AdvancedPlanningSystemComponent } from './modules/pages/advanced-planning-system/advanced-planning-system.component';

@NgModule({
  declarations: [
    AppComponent,
    AppComponent,
    CareerComponent, ProductDevelopmentComponent,
    IndexComponent, HomeComponent, HeaderComponent, FooterComponent,
    TechInnovationComponent, SoftwareDevelopmentComponent, SoftwareTestingComponent,
    AdaptiveSourcingComponent,
    AiAutomationComponent,
    IntelligentAutomationComponent,
    BlogListingComponent,
    BlogDetailsComponent,
    AboutUsComponent,
    ContactUsComponent,
    RoboticProcessAutomationComponent,
    BlockchainComponent,
    BigDataComponent,
    CloudComponent,
    BlogOneComponent,
    BlogTwoComponent,
    BlogThreeComponent,
    InternetOfThingsComponent,
    EnterpriseResourcePlanningComponent,
    CrmComponent,
    HumanManagementComponent,
    DigitalMarketingComponent,
    // SalesServiceComponent,
    FullStackComponent,
    ECommerceComponent,
    RealEstateComponent,
    BrochureSliderComponent,
    SlotBookingComponent,
    MatrimonyComponent,
    DmsComponent,
    BrochureComponent,
    ApplicationDevelopmentComponent,
    RealEstateBrochureComponent,
    SlotBookingBrochureComponent,
    MatrimonyBrochureComponent,
    DmsBrochureComponent,
    FleetManagementBrochureComponent,
    FleetManagementComponent,
    RapinnoDigitalPlatformComponent,
    RapinnoIaEcoSystemComponent,
    RapinnoManagedServicesComponent,
    CareerApplyComponent,
    EngineApiComponent,
    TechStackComponent,
    BlogFourComponent,
    RapidApplicationDevelopmentComponent,
    // RapinnoSolutionComponent,
    ProductMigrationComponent,
    IntegratedMarketingServiceComponent,
    RapinnoProductThreesixtyComponent,
    BlogFiveComponent,
    DataSecurityBlogComponent,
    RapinnoDataDigitizationComponent,
    PotentialRisksBlogTwoComponent,
    AngdovpPageComponent,
    ContPageComponent,
    DesgPageComponent,
    DevpPageComponent,
    FulldvpPageComponent,
    GrapPageComponent,
    UidevpPageComponent,
    MobileBankingAdoptionComponent,
    ProtectYourselfFromMobileBankingScamsAndHacksComponent,
    CaseStudiesListingComponent,
    VideosComponent,
    CaseStudyOneComponent,
    WhyIsFlutterBlogComponent,
    WhyYourSmallBusinessRequiresBlogComponent,
    AcceleratingDigitalTransformationComponent,
    WhyIsAnErpImplementationChallengingComponent,
    OurTeamComponent,
    SecurityServicesComponent,
    AdvancedPlanningSystemComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    SharedModule,
    SwiperModule,
    NgxSpinnerModule,
    HttpClientModule,
    ScullyLibModule
  ],
  entryComponents: [
    BrochureComponent,
    BrochureSliderComponent,
    DmsBrochureComponent,
    FleetManagementBrochureComponent,
    MatrimonyBrochureComponent,
    RealEstateBrochureComponent,
    SlotBookingBrochureComponent
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
