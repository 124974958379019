<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Customer Relationship Management</h1>
                    </div>
                    <p>
                        RapinnoTech's customer relationship management solutions use techniques such as predictive modelling, collecting intelligent insights through forecasting, data mining and text mining to put the customer at the center of everything. We help our customers
                        accelerate their CRM transformation initiatives to increase sales, increase productivity and improve customer service - while reducing operating costs and increasing time to market. Our partnership with leading solution providers
                        uniquely positions us to help organizations better understand our customers and improve conversations. We help our clients get the most out of their CRM implementation by enabling a customer-centric digital business based on business
                        needs.
                    </p>
                </div>
            </div>
            <div fxLayout="column" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/crm.jpg" alt="img">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="crm-offers-section">
    <div class="wrapper">
        <div fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div fxLayout="column" fxFlex="40" fxFlexFill>
                <ul class="crm-list">
                    <li>Connecting the previously deployed devices</li>
                    <li>Quick Prototyping of IoT framework</li>
                    <li>Showcase business value in a prototype</li>
                    <li>Create a series of connected devices as per need</li>
                    <li>Identify the right IoT solution that best fits the client needs</li>
                    <li>Making devices operational as per desire functionality</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">We can help you in Customer Relationship Management</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>