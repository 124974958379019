import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Injectable({
  providedIn: 'root',
})

export class SEOService {
  constructor(private title: Title, private meta: Meta) {}

  updatePageTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({ name: 'og:url', content: url });
  }

  updateOgTitle(title: string) {
    this.meta.updateTag({ name: 'og:title', content: title });
  }

  updateOgType(type: string) {
    this.meta.updateTag({ name: 'og:type', content: type });
  }

  updateOgDescription(description: string) {
    this.meta.updateTag({ name: 'og:description', content: description });
  }

  updateOgImage(desc: string) {
    this.meta.updateTag({ name: 'og:image', content: `${environment.domainUrl}${desc}` });
  }

  updateMetaTitle(title: string) {
    this.meta.updateTag({ name: 'title', content: title });
  }

  updateMetaDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  addTags(tags: Array<string>) {
    if (tags && tags.length > 0) {
      const metaTags = tags.map((tag) => ({ name: tag }));
      console.log(metaTags);
      this.meta.addTags(metaTags);
    }
  }
}
