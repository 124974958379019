import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-data-security-blog',
  templateUrl: './data-security-blog.component.html',
  styleUrls: ['./data-security-blog.component.scss']
})
export class DataSecurityBlogComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) {

  }

  ngOnInit(): void {
    this.setSeo();
  }

  setSeo(): void {
    this.seoService.updatePageTitle('Data Security & Protection: Threats & Methodologies (Part1)');
    this.seoService.updateOgTitle('Data Security & Protection: Threats & Methodologies (Part1)');
    this.seoService.updateOgDescription('Data is the ammo that every business is after. But it comes with threat of breach and leak. Here we discuss about different methods of data protection and how they work.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/Data_Security_&_Protection:_Threats_&_Methodologies_Part1');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/6.wenp');
    this.seoService.updateMetaTitle('Data Security & Protection: Threats & Methodologies (Part1)'),
    this.seoService.updateMetaDescription('Data is the ammo that every business is after. But it comes with threat of breach and leak. Here we discuss about different methods of data protection and how they work.')
  }
}
