import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/modules/core/seo.service';

@Component({
  selector: 'app-how-is-blockchain-technology-redefining-our-world-for-the-better',
  templateUrl: './how-is-blockchain-technology-redefining-our-world-for-the-better.component.html',
  styleUrls: ['./how-is-blockchain-technology-redefining-our-world-for-the-better.component.scss']
})
export class HowIsBlockchainTechnologyRedefiningOurWorldForTheBetterComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | How is Blockchain Technology redefining our world for the better?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | How is Blockchain Technology redefining our world for the better?');
    this.seoService.updateOgDescription('Blockchain Technology is a file of records that are called blocks that are linked together and secured with cryptography.  It has the potential to revolutionize the way banking, retail, and government work for better.');
    this.seoService.updateOgType('Article');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/blogs/How- is-Blockchain-Technology-redefining-our- world-for-the-better-?');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/45.webp');
    this.seoService.updateMetaTitle('Rapinno Tech Blog | How is Blockchain Technology redefining our world for the better?'),
    this.seoService.updateMetaDescription('Blockchain Technology is a file of records that are called blocks that are linked together and secured with cryptography.  It has the potential to revolutionize the way banking, retail, and government work for better.')
  }
}
