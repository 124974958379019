import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-blog-three',
  templateUrl: './blog-three.component.html',
  styleUrls: ['./blog-three.component.scss']
})
export class BlogThreeComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
    this.setSeo();
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Data Security & Protection: Threats & Methodologies (Part1)');
    this.seoService.updateOgTitle('Data Security & Protection: Threats & Methodologies (Part1)');
    this.seoService.updateOgDescription('Data is the ammo that every business is after. But it comes with threat of breach and leak. Here we discuss about different methods of data protection and how they work.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/Best_Practices_of_Effective_Performance_Testing');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/blog_3.webp');
    this.seoService.updateMetaTitle('Reasons Why to Digitize Your Business Right Away (Part 2)'),
      this.seoService.updateMetaDescription('Data is the ammo that every business is after. But it comes with threat of breach and leak. Here we discuss about different methods of data protection and how they work.')
  }
}
