import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-real-estate-brochure',
  templateUrl: './real-estate-brochure.component.html',
  styleUrls: ['./real-estate-brochure.component.scss']
})
export class RealEstateBrochureComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<RealEstateBrochureComponent>,
  ) { }

  ngOnInit(): void {
  }
  dialogClose(result?: any) {
    this.dialogRef.close(result ? result : null);
  }
}
