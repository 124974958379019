<section class="master_rapino_sec">
  <!-- SERVICE-BANNER-SEC -->
  <section class="service-intro-sec">
    <div class="banner-dots left">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
      <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
        fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <div fxLayout="column" fxFlex="60" fxFlexFill>
          <div class="intro-content">
            <div class="section-heading">
              <h1 class="main-heading">Rapinno Digital platform</h1>
            </div>
            <p>
              Technological requirements often emerge suddenly, without providing any prior clues. We understand the
              importance of this urgency faced by companies of all sizes and types. Our rapid app development services
              are designed to meet the urgent needs of customers.
            </p>
            <p>
              Guided by a proven rapid application development model, we have created thousands of software in the
              shortest possible time. Whether you are in urgent need of a mobile app, website, online store or chatbot,
              we are ready to serve you.
            </p>
            <p>
              Guided by a proven rapid application development model, we have created thousands of software in the
              shortest possible time. Whether you are in urgent need of a mobile app, website, online store or chatbot,
              we are ready to serve you.
            </p>
          </div>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlexFill>
          <div class="intro-img">
            <img class="raduis-img" src="../../../../../assets/images/service-banner/rapinno-digital-platform.jpg" alt="img">
            <!-- <img class="raduis-img" src="../../../../../assets/test-bn.svg" alt="img"> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- tech_stock_sec -->
  <section class="tech_stock_sec sec-padding digital_tech_stock_sec" id="scrollTech">
    <div class="wrapper">
      <div class="global_heading service_head  text-center">
        <h2>Tech Stack</h2>
        <p>We follow the time & material business model and Offshore dedicated team business model. Our technology stack
          includes:</p>
      </div>
      <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
        fxLayout.xs="column" fxLayoutAlign="center stretch">
        <!-- Web Technologies -->
        <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="tech_stock_box first_box">
            <h4>Web Technologies</h4>
            <div class="tech_images">
              <ul>
                <div class="swiper-container" [swiper]="webConfig" (indexChange)="onIndexChange($event)">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/web-technologies/1.svg"
                          class="wd-100" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/web-technologies/2.svg"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/web-technologies/3.svg"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/web-technologies/4.svg"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/web-technologies/5.svg"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/web-technologies/6.svg"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/web-technologies/7.svg"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/web-technologies/8.svg"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/web-technologies/9.svg"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/backend/1.png" class="wd-100"
                          alt="img">
                      </li>
                    </div>
                  </div>
                  <div class="navigations">
                    <div class="swiper-button-prev web-button-prev">
                    </div>
                    <div class="swiper-button-next web-button-next">
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <!-- Mobile Technologies -->
        <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="tech_stock_box first_box">
            <h4>Mobile Technologies</h4>
            <div class="tech_images">
              <ul>
                <div class="swiper-container" [swiper]="mobileConfig" (indexChange)="onIndexChange($event)">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/mobile-technologies/1.png"
                          class="wd-100" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/mobile-technologies/2.png"
                          class="wd-100" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/mobile-technologies/3.png"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/mobile-technologies/4.png"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/mobile-technologies/5.png"
                          alt="img">
                      </li>
                    </div>
                  </div>
                  <div class="navigations">
                    <div class="swiper-button-prev mobile-button-prev">
                    </div>
                    <div class="swiper-button-next mobile-button-next">
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <!-- Database Technologies -->
        <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="tech_stock_box first_box">
            <h4>Database</h4>
            <div class="tech_images">
              <ul>
                <div class="swiper-container" [swiper]="databaseConfig" (indexChange)="onIndexChange($event)">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/database/1.png" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/database/2.png" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/database/3.png" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/database/4.png" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/database/5.png" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/database/6.png" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/database/7.png" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/database/8.png" alt="img">
                      </li>
                    </div>
                  </div>
                  <div class="navigations">
                    <div class="swiper-button-prev database-button-prev">
                    </div>
                    <div class="swiper-button-next database-button-next">
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <!-- Cloud Platforms Technologies -->
        <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="tech_stock_box first_box">
            <h4>Cloud Platforms</h4>
            <div class="tech_images">
              <ul>
                <div class="swiper-container" [swiper]="cloudConfig" (indexChange)="onIndexChange($event)">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/cloud-platforms/1.png"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/cloud-platforms/2.png"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/cloud-platforms/3.png"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/cloud-platforms/4.png"
                          class="wd-100" alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/cloud-platforms/5.png"
                          alt="img">
                      </li>
                    </div>
                  </div>
                  <div class="navigations">
                    <div class="swiper-button-prev cloud-button-prev">
                    </div>
                    <div class="swiper-button-next cloud-button-next">
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <!-- Back End Services Technologies -->
        <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="tech_stock_box first_box">
            <h4>Back End Services</h4>
            <div class="tech_images">
              <ul>
                <div class="swiper-container" [swiper]="backendConfig" (indexChange)="onIndexChange($event)">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/backend/2.png" class="wd-100"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/backend/3.png" class="wd-100"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/backend/4.png" class="wd-100"
                          alt="img">
                      </li>
                    </div>
                    <div class="swiper-slide">
                      <li>
                        <img src="../../../../../assets/images/rapinno-digital-platform/backend/5.png" class="wd-100"
                          alt="img">
                      </li>
                    </div>
                  </div>
                  <div class="navigations">
                    <div class="swiper-button-prev backend-button-prev">
                    </div>
                    <div class="swiper-button-next backend-button-next">
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <!-- Database Technologies -->
        <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="tech_stock_box first_box">
            <h4>Automation Tools</h4>
            <div class="tech_images">
              <ul class="justify-start">
                <li>
                  <img src="../../../../../assets/images/rapinno-digital-platform/automation-tools/1.png" alt="img"
                    class="wd-100">
                </li>
                <li>
                  <img src="../../../../../assets/images/rapinno-digital-platform/automation-tools/2.png" alt="img"
                    class="wd-100">
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Database Technologies -->
        <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="tech_stock_box first_box">
            <h4>QA Tools</h4>
            <div class="tech_images">
              <ul class="justify-start">
                <li>
                  <img src="../../../../../assets/images/rapinno-digital-platform/qa-tools/1.jpg" alt="img"
                    class="wd-100">
                </li>
                <li>
                  <img src="../../../../../assets/images/rapinno-digital-platform/qa-tools/2.png" alt="img"
                    class="wd-100">
                </li>
                <li>
                  <img src="../../../../../assets/images/rapinno-digital-platform/qa-tools/3.png" alt="img"
                    class="wd-100">
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Database Technologies -->
        <!-- <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100"
          fxFlexFill>
          <div class="tech_stock_box first_box">
            <h4></h4>
            <div class="tech_images">
              <ul class="justify-evenly">
                <li>
                  <img src="../../../../../assets/images/rapinno-digital-platform/automation-tools/2.png" alt="img"
                    class="wd-100">
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</section>
