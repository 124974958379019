<section class="master_rapino_sec">

  <!-- SERVICE-BANNER-SEC -->
  <section class="service-intro-sec">
    <div class="banner-dots left">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
      <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
      <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column"
        fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <div fxLayout="column" fxFlex="60" fxFlexFill>
          <div class="intro-content">
            <div class="section-heading">
              <h1 class="main-heading">RDP Fleet Management</h1>
            </div>
            <p>
              With RapinnoTech fleet booking software, we will take care of all your requirements starting from pool
              vehicle bookings to fuel management, maintenance management and more. We understand the system and that is
              why we will provide a customized solution as per your requirement. We are here to ensure an easier travel
              option for your employees.
            </p>
          </div>
        </div>
        <div fxLayout="column" fxFlex="40" fxFlexFill>
          <div class="intro-img">
            <img class="raduis-img" src="../../../../../assets/images/service-banner/fleet-management.jpg" alt="img">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="wrapper">
      <div class="global_heading service_head  text-center">
        <h2>Our Expertise </h2>
        <p>We promise an all-in one platform where your employees or clients can easily book vehicles
        </p>
      </div>
      <section class="sec-padding">
        <div class="wrapper">
          <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
            fxLayout.xs="column" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/1.svg" alt="">
                <h4>GPS vehicle tracking</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/2.svg" alt="">
                <h4>Route planning and monitoring</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/3.svg" alt="">
                <h4>Driver identification</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/4.svg" alt="">
                <h4>Real-time visibility</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/5.svg" alt="">
                <h4>Attendance marking</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/6.svg" alt="">
                <h4>Log sheet</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/7.svg" alt="">
                <h4>Reports and analytics</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/8.svg" alt="">
                <h4>Vendor management</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/9.svg" alt="">
                <h4>Billing and invoicing</h4>
              </div>
            </div>
            <div fxLayout="column" fxFlex.xl="30" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="50" fxFlex.xs="100"
              fxFlexFill>
              <div class="service_box">
                <img src="../../../../../assets/images/fleet-management/10.svg" alt="">
                <h4>Realtime notifications</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
  <!-- CTS SECTION-->
  <section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt=""  >
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  >
    <div class="cta-content">
      <h3 class="cta-heading">RapinnoTech Fleet Management development service will take care of the entire front end
        and back
        development</h3>
      <button (click)="fleetBrochure()" class="know-btn" mat-button> View
        Brochure </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  >
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt=""  >
  </section>
  <section class="service-heading-content-sec">
    <div class="wrapper">
      <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
        fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-right-img">
            <img src="../../../../../assets/images/fleet-management/ps.jpg" alt="">
          </div>
        </div>
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-left-content d-flex-center">
            <div class="left-head">
              <h3>Problem Statement</h3>
              <p>
                Most of our clients, when approached us, were looking for a system which can give inputs about the
                mistreatment of vehicle or misuse or over the use of fuel etc, apart from managing the overall operation
                and stability between delivery and pick up. Each one of our clients was looking for a robust system that
                can help them resolve all these issues on the go.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
        fxLayout.xs="column" fxLayoutAlign="center stretch" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-left-content d-flex-center">
            <div class="left-head">
              <h4>Solution</h4>
              <p>
                Apart from being cost-effective and super easy to implement and use, we provided all the necessary
                touchpoints in a specially curated system for each client which helped them to resolve their daily
                issues on the go. Most of our clients witnessed rapid growth in the subsequent months as well. With all
                these experience, our team is can deliver you super customized fleet management service as per your
                requirement.
              </p>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
          <div class="service-right-img">
            <img src="../../../../../assets/images/fleet-management/sol.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="tech_stock_sec sec-padding">
    <div class="wrapper">
      <div class="global_heading service_head  text-center">
        <h4>Tech Stack</h4>
        <p>We follow the time & material business model and Offshore dedicated team business model. Our technology stack
          includes:</p>
      </div>
      <div class="wrapper">
        <div class="row-wrap" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row"
          fxLayout.xs="column" fxLayoutAlign="center stretch">
          <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
            <div class="tech_stock_box first_box">
              <h4>Mobile Application</h4>
              <div class="tech_images">
                <img src="../../../../../assets/images/e-commerce/t-8.svg" alt="">
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
            <div class="tech_stock_box first_box">
              <h4>Database</h4>
              <div class="tech_images">
                <img src="../../../../../assets/images/e-commerce/t-1.svg" alt="">
                <!-- <img src="../../../../../assets/images/e-commerce/t-2.svg" alt=""> -->
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
            <div class="tech_stock_box second_box">
              <h4>Front End</h4>
              <div class="tech_images">
                <img src="../../../../../assets/images/e-commerce/t-3.svg" alt="">
                <img src="../../../../../assets/images/e-commerce/t-4.svg" alt="">
                <img src="../../../../../assets/images/e-commerce/t-5.svg" alt="">
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="40" fxFlex.xs="100" fxFlexFill>
            <div class="tech_stock_box third_box">
              <h4>Backend</h4>
              <div class="tech_images">
                <!-- <img src="../../../../../assets/images/e-commerce/t-6.svg" alt=""> -->
                <img src="../../../../../assets/images/e-commerce/t-7.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
