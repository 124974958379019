import { Component, OnInit } from '@angular/core';
import { SEOService } from '../../core/seo.service';

@Component({
  selector: 'app-why-is-flutter-blog',
  templateUrl: './why-is-flutter-blog.component.html',
  styleUrls: ['./why-is-flutter-blog.component.scss']
})
export class WhyIsFlutterBlogComponent implements OnInit {

  constructor(
    private seoService: SEOService
  ) { }

  ngOnInit(): void {
  }
  setSeo(): void {
    this.seoService.updatePageTitle('Rapinno Tech Blog | Why is Flutter still in hype for Cross-Platform app development?');
    this.seoService.updateOgTitle('Rapinno Tech Blog | Why is Flutter still in hype for Cross-Platform app development?');
    this.seoService.updateOgDescription('Flutter apps run faster both in terms of development and performance. Compared to other cross-platform technologies, apps built with Flutter are relatively faster.');
    this.seoService.updateOgType('Blog');
    this.seoService.updateOgUrl('https://www.rapinnotech.com/resources/why-is-flutter-still-in-hype-for-cross-platform-app-development');
    this.seoService.updateOgImage('https://rapinnotech.com/assets/images/blog/16.webp');
    this.seoService.updateMetaTitle(' How blockchain ensures security for the banking industry? '),
    this.seoService.updateMetaDescription('Flutter apps run faster both in terms of development and performance. Compared to other cross-platform technologies, apps built with Flutter are relatively faster.')
  }
}
