<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Rapinno IA Ecosystem</h1>
                    </div>
                    <p>
                        Intelligent automation is a broad-based term that comprises multiple technologies to make your business shine all the way. It is based on four primary pillars – Business Process Management (BPM), Robotic Process Automation (RPA), Artificial Intelligence
                        (AI), and Business Integrations.
                    </p>
                    <p>The synergy between these technologies is inseparable – automate the company’s high-level (corporate) processes using BPM, and task-level processes using RPA, supported by analytics, and decision making using Artificial Intelligence
                        (AI).
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../assets/images/rapinno-ia-eco-system/ai-bg.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- SERVICE-HEADING-SEC -->
<section class="service-heading-content-sec innovate-sec">
    <div class="wrapper">
        <div class="service_head text-center">
            <h2>Capabilities</h2>
        </div>
        <div class="innovate-box">
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head">
                            <h3>Business Process Management</h3>
                            <p>
                                Business Process Management (BPM) is a process-oriented technology that synchronizes various entities – people, systems, and data to generate actionable results. The primary aim of BPM is to ensure that the operational and business process foundation
                                is strong and secure. Simply stated, it streamlines business processes by automating the workflows that help organizations to optimize their business automation benefits to achieving better results. The BPM lifecycle comprises
                                five stages: Design, Modelling, Execution, Monitoring, Optimization, and Re-engineering.
                            </p>
                            <p>
                                BPM has a vital role to play in the organizational development process and is regarded as a key component of operational intelligence (OI) solutions to offer real-time, actionable data. This real-time information can be utilized in myriad ways – alerts
                                can be triggered timely or executive decisions can be derived using real-time dashboards.
                            </p>

                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div>
                        <img src="../../../../assets/images/rapinno-ia-eco-system/img1.png" width="100%" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="innovate-box">
            <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div>
                        <img src="../../../../assets/images/rapinno-ia-eco-system/img2.png" width="100%" alt="">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head">
                            <h3>Robotic Process Automation</h3>
                            <p>
                                Robotic Process Automation (RPA) is a rapidly emerging field in the spectrum of digital technologies that automate the tasks in computer applications, manually performed by humans that are highly cumbersome and time-consuming. It accelerates the pace
                                of digital transformation and is construed as a quick and easy way to streamline core processes, often extending the life of legacy systems.
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="innovate-box">
            <div class="service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head">
                            <h3>Artificial Intelligence</h3>
                            <p>
                                Artificial intelligence is no longer a buzzword, as was perceived a few decades back. It has become a necessity, rather than a luxury in today’s commercial and business world. AI is finding increased applicability in almost every segment of the industry,
                                right from healthcare, travel to simple eCommerce portals.
                            </p>
                            <p>
                                Using AI, data, and analytics, organizations can create intelligent workflows to produce quantifiable business outcomes. Whether they are simple automation or complex virtual assistants, AI is here to stay for long to make the life of humans simple and
                                productive. Organizations can benefit immensely from AI implementation, though initially, it might look daunting to make a transition from manual to automated workflows. However, we at Rappinotech can make this a hassle-free
                                process.
                            </p>
                        </div>
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div>
                        <img src="../../../../assets/images/rapinno-ia-eco-system/img3.png" width="100%" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="innovate-box">
            <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="15px">
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div>
                        <img src="../../../../assets/images/rapinno-ia-eco-system/img4.png" width="100%" alt="">
                    </div>
                </div>
                <div fxLayout="column" fxFlex.xl="40" fxFlex.lg="40" fxFlex.md="40" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                    <div class="service-left-content">
                        <div class="left-head">
                            <h3>Integrations</h3>
                            <p>
                                Integrations require coding, logic, and configurations to develop the right landscape, up and running. Also, once integrated, the new landscape has to be tested to ensure whether it works in consonance with the defined business requirements. Any integration
                                that doesn’t accomplish the right purpose is of little value with ample wastage of time, effort, and resources. Hence, to develop a unified, multi-purpose platform to solve your business challenges and generate the right
                                value, you need the right consultant partner to take this initiative forward.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-solutions section-padding">
    <div class="wrapper">
        <div class="service_head text-center">
            <h2>Specialized Solutions</h2>
        </div>
        <div fxLayout="row" fxLayout.md="row wrap" fxLayout.sm="row wrap" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.md="0px" fxLayoutGap.sm="0px">
            <div fxLayout="column" fxFlex="25" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100">
                <div class="card">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/pic1.png" alt="">
                    <div class="content">
                        <h3><b>Object Detection Model based automation solutions</b></h3>
                        <p>Intelligent automation solution to detect specific objects either in a static image or a live footage and notify the bot to take certain action. The model can be trained to any specific image that has distinct properties that can
                            be detected by computer vision.</p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100">
                <div class="card">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/pic2.png" alt="">
                    <div class="content">
                        <h3><b>Image Classification Model base automation solutions</b></h3>
                        <p>Intelligent automation solution to classify images according to the categories trained and tested and thereby robots taking specific action based on each category. The same logic can be applied to classify a specific object based
                            on certain properties for e.g in agri, an object can be trained to classify whether it is ripe or not, or spoiled or good.
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100">
                <div class="card">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/pic3.png" alt="">
                    <div class="content">
                        <h3><b>Text Recognition, Context analysis, and Sentiment analysis</b></h3>
                        <p>Intelligent automation solution to identify specific texts or phrases, analyze the overall context of the conversation, and update the sentiment of the conversation that can help the RPA bot to take specific action plans.
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="25" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100">
                <div class="card">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/pic4.png" alt="">
                    <div class="content">
                        <h3><b>Chatbot based solutions</b></h3>
                        <p>AI powered Chatbot using NLP and Automation to drive conversations and handle most of the volumes on behalf of human force.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-work section-padding">
    <div class="wrapper">
        <div class="service_head text-center">
            <h2>How We Work</h2>
        </div>
        <div class="work-cards" fxLayout="row" fxLayout.md="row wrap" fxLayout.sm="row wrap" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.md="0px" fxLayoutGap.sm="0px">
            <div class="work-box" fxLayout="column" fxFlex="20" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxLayoutAlign="center center" fxLayoutAlign.md="flex-start center" fxLayoutAlign.sm="flex-start center" fxLayoutGap="20px" fxLayoutGap.md="0px" fxLayoutGap.sm="0px">
                <img src="../../../../assets/images/rapinno-ia-eco-system/1.png" alt="">
                <img src="../../../../assets/images/rapinno-ia-eco-system/arrow1.png" class="img-arrow" alt="">
                <div class="content">
                    <h3>Initial assessment</h3>
                    <p>We dig deeper into your business landscape, do a thorough post-mortem and understand your current challenges to prepare an initial assessment report.</p>
                </div>
            </div>
            <div class="work-box" fxLayout="column" fxFlex="20" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxLayout.xs="column-reverse" fxLayout.sm="column-reverse" fxLayout.md="column-reverse" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.md="0px"
                fxLayoutGap.sm="0px">
                <div class="content">
                    <h3>Planning</h3>
                    <p>Once the initial assessment report is ready we draw up a detailed strategic plan, align our resources and design the right business model that is in sync with your organizational landscape.</p>
                </div>
                <img src="../../../../assets/images/rapinno-ia-eco-system/arrow2.png" class="img-arrow position-up" alt="">
                <img src="../../../../assets/images/rapinno-ia-eco-system/2.png" class="position-up" alt="">
            </div>
            <div class="work-box" fxLayout="column" fxFlex="20" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.md="0px" fxLayoutGap.sm="0px">
                <img src="../../../../assets/images/rapinno-ia-eco-system/3.png" alt="">
                <img src="../../../../assets/images/rapinno-ia-eco-system/arrow3.png" class="img-arrow" alt="">
                <div class="content">
                    <h3>Implementation</h3>
                    <p>As part of this phase, we implement the blueprint of the business model that has been developed. Our implementation team will work closely with you to ensure zero gaps.</p>
                </div>
            </div>
            <div class="work-box" fxLayout="column" fxFlex="20" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxLayout.xs="column-reverse" fxLayout.sm="column-reverse" fxLayout.md="column-reverse" fxLayoutAlign="center center" fxLayoutGap="20px" fxLayoutGap.md="0px"
                fxLayoutGap.sm="0px">
                <div class="content">
                    <h3>Managing</h3>
                    <p>Not just implementation, we can manage a complete portfolio of your solutions including setting up dedicated centers of excellence to streamline your business operations.</p>
                </div>
                <img src="../../../../assets/images/rapinno-ia-eco-system/arrow4.png" class="img-arrow position-up" alt="">
                <img src="../../../../assets/images/rapinno-ia-eco-system/4.png" class="position-up" alt="">
            </div>
            <div class="work-box" fxLayout="column" fxFlex="20" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxLayoutAlign="space-between center" fxLayoutGap="20px" fxLayoutGap.md="0px" fxLayoutGap.sm="0px">
                <img src="../../../../assets/images/rapinno-ia-eco-system/5.png" alt="">
                <div class="content">
                    <h3>Support</h3>
                    <p>We have a dedicated support team that works round the clock to log and resolve any technical issues, ensuring minimal disruption to your business landscape.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CTS SECTION-->
<section class="section-cta section-padding">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <div class="cta-content">
        <h3 class="cta-heading">Let's Discuss How Our IA Ecosystem Can Help You</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> Contact Now
    </button>
    </div>
    <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt="">
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>


<section class="section-benefits section-padding">
    <div class="wrapper">
        <div class="service_head text-center">
            <h2>Benefits</h2>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                <div class="benfit" fxLayout="row" fxLayoutGap="20px">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/bulb.png" alt="">
                    <div class="benefit-content">
                        <h2>Gain actionable insights from enormous data</h2>
                        <p>Gain actionable insights to drive high-level decision making using our AI-powered tools and methodologies.
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                <div class="benfit" fxLayout="row" fxLayoutGap="20px">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/bulb.png" alt="">
                    <div class="benefit-content">
                        <h2>Generate a seamless integration eco-system</h2>
                        <p>Generate unified information flow and secured data access across varied platforms and applications with our seamless and hassle-free integration framework.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                <div class="benfit" fxLayout="row" fxLayoutGap="20px">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/bulb.png" alt="">
                    <div class="benefit-content">
                        <h2>Automate tasks to drive business results</h2>
                        <p>Empower teams to automate tasks with ease, driving high-level transparency and efficiency.
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                <div class="benfit" fxLayout="row" fxLayoutGap="20px">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/bulb.png" alt="">
                    <div class="benefit-content">
                        <h2>Assess your current state of business</h2>
                        <p>Leverage business automation tools to assess your inefficiencies and plug gaps to gain a competitive edge in your business.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                <div class="benfit" fxLayout="row" fxLayoutGap="20px">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/bulb.png" alt="">
                    <div class="benefit-content">
                        <h2>Optimize your business processes</h2>
                        <p>Leverage data analytics and AI to automate your business processes to enhance efficiency, improve RoI and drive superior business outcomes.
                        </p>
                    </div>
                </div>
            </div>
            <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
                <div class="benfit" fxLayout="row" fxLayoutGap="20px">
                    <img src="../../../../assets/images/rapinno-ia-eco-system/bulb.png" alt="">
                    <div class="benefit-content">
                        <h2>Deploy applications, anywhere, anytime</h2>
                        <p>Run your applications on any cloud platform, anytime and manage any network vendor infrastructure
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>