<!-- SERVICE-BANNER-SEC -->
<section class="service-intro-sec">
    <div class="banner-dots left">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="banner-dots right">
        <img class="overlay-img-right" src="../../../../../assets/images/dots-right.svg" alt="">
    </div>
    <div class="wrapper">
        <div class="intro-row" fxLayout="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
            <div class="column stack_left" fxFlex="60" fxFlexFill>
                <div class="intro-content">
                    <div class="section-heading">
                        <h1 class="main-heading">Rapinno Product 360</h1>
                    </div>
                    <p>
                        After designing and developing products for so many clients from scratch we have ventured into helping start-ups, innovators & entrepreneurs. We can guide you throughout the process of product development or we can help you out at some specific stages
                        of the process. Product 360 provides an end-to-end solution starting with understanding the concept, creating the product roadmap, designing, developing and testing the product, launching and deploying the product, market testing,
                        branding, digital marketing and in-house sales support.
                    </p>
                </div>
            </div>
            <div class="column stack_right" fxFlex="40" fxFlexFill>
                <div class="intro-img">
                    <img class="raduis-img" src="../../../../../assets/images/service-banner/rapinno_droduct_360.jpg" alt="Word cloud in shape of a bulb to represent idea and innovation">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- rapino product -->
<section class="service-data-collect-sec">
    <div class="wrapper">
        <div class="process-data">
            <!-- <img class="data-flow-img" src="../../assets/images/rapinno-product-360/rapinno_product_360.gif" alt="flow chat of data processing"> -->
            <!-- <video width="100%" height="350">
      <source src="../../assets/images/rapinno-product-360/kkr.m4v" type="video/m4v">
   </video> -->
            <div class="video_box">
                <video autoplay="autoplay" preload="none" src="../../assets/images/rapinno-product-360/rapinno_product.mp4">
        </video>
            </div>
            <!-- <div class="embed-responsive embed-responsive-16by9">
  <iframe class="embed-responsive-item" style="width:100%" src="../../assets/images/rapinno-product-360/kkr.m4v"></iframe>
</div> -->
        </div>
        <!-- <div class="data-bnt">
  <div class="xs-col-rev service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row"
  fxLayout.sm="row" fxLayout.xs="row" fxLayoutAlign="start stretch">
  <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="25" fxFlex.xs="25" fxFlexFill>
    <button [routerLink]="" routerLinkActive="router-link-active" class="know-btn" mat-button> Document Collection </button>
    </div>
    <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="25" fxFlex.xs="25" fxFlexFill>
      <button [routerLink]="" routerLinkActive="router-link-active" class="know-btn" mat-button> Scanning / Indexing </button>
      </div>
      <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="25" fxFlex.xs="25" fxFlexFill>
        <button [routerLink]="" routerLinkActive="router-link-active" class="know-btn" mat-button> Data Processing </button>
        </div>
        <div fxLayout="column" fxFlex.xl="25" fxFlex.lg="25" fxFlex.md="25" fxFlex.sm="25" fxFlex.xs="25" fxFlexFill>
          <button [routerLink]="" routerLinkActive="router-link-active" class="know-btn" mat-button> Data Storage </button>
          </div>
        </div>
    </div> -->
    </div>
</section>
<!-- Our Methodology -->
<div class="wrapper">
    <section class="rapinno_methodology_sec">
        <div class="" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
            <div fxLayout="column" fxFlex.xl="100" fxFlex.lg="100" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100" fxFlexFill>
                <div class="service_head our_methodology_head">
                    <h2 class="text-center">Our Methodology</h2>
                    <p>Our experience in product development will assist you to bring your idea to the end-consumer.</p>
                </div>
            </div>
        </div>
    </section>
    <section class="rapinno_methodology_service">
        <div class="line_overlay"></div>
        <div class="methodology_service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column medthod_left" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_left">
                    <img src="../../../../assets/images/rapinno-product-360/1.png" alt="Illustration showcasing a girl sorting out different ideas">
                </div>
            </div>
            <div class="column medthod_right" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_right">
                    <div class="w-100">
                        <h3><span>Scope Finalization</span></h3>
                        <p>We usually push for multiple meetings and discussions to understand the scope of your idea and if required we work on magnifying it which may bring you more satisfaction and revenue in the long run. Once we arrive on the same page
                            we move on to the next step. </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="methodology_service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column medthod_left" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_left">
                    <img src="../../../../assets/images/rapinno-product-360/2.png" alt="Ilustration showcasing a man designing blueprint for new the idea">
                </div>
            </div>
            <div class="column medthod_right" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_right">
                    <div class="w-100">
                        <h3><span>Design & Architecture</span></h3>
                        <p>After finalizing the idea we start designing multiple blueprints and conceptualize architectures so that you will have options to choose or both parties can brainstorm on the very same to get your ideas and values incorporated
                            in the blueprint.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="methodology_service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column medthod_left" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_left">
                    <img src="../../../../assets/images/rapinno-product-360/3.png" alt="Illustration showcasing the team leader explaing the proof of concept">
                </div>
            </div>
            <div class="column medthod_right" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_right">
                    <div class="w-100">
                        <h3><span>Proof Of Concept</span></h3>
                        <p>We create proof of concept with basic but essential USPs of the product. And in case of complex products we create multiple POCs which addresses each of the core functionalities. </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="methodology_service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column medthod_left" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_left">
                    <img src="../../../../assets/images/rapinno-product-360/4.png" alt="Illustration showcasing the minimum vible product">
                </div>
            </div>
            <div class="column medthod_right" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_right">
                    <div class="w-100">
                        <h3><span>Minimum Viable Product</span></h3>
                        <p>Once we get the proof of concept and you are satisfied with the outcome then we will move to the next stage - Minimum Viable Product. We will design the prototype with all the features and will test it sample audience identical
                            to the actual target audience. This will help us to find bugs and faults before we actually launch the product. </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="methodology_service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column medthod_left" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_left">
                    <img src="../../../../assets/images/rapinno-product-360/market.svg" alt="Illustration showcasing the team leader explaing the proof of concept">
                </div>
            </div>
            <div class="column medthod_right" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_right">
                    <div class="w-100">
                        <h3><span>Marketing Testing</span></h3>
                        <p>Market testing is a very vital step in any product development cycle. We carry out very thorough market testing just to test the waters to understand whether your idea and vision is being accepted by the target audience or not.
                            </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="methodology_service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column medthod_left" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_left">
                    <img src="../../../../assets/images/rapinno-product-360/5.png" alt="Illustration showcasing the team leader explaing the proof of concept">
                </div>
            </div>
            <div class="column medthod_right" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_right">
                    <div class="w-100">
                        <h3><span>Product Launch</span></h3>
                        <p>After multiple testing, checking and proofreading we will design the final product which will be market ready and will be able to attract the target audience that you desire. We will also design the complete go-to-market strategy
                            with the target market and audience in mind. </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="methodology_service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column medthod_left" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_left">
                    <img src="../../../../assets/images/rapinno-product-360/6.png" alt="Illustration showcasing marketing team working on branding and positioning">
                </div>
            </div>
            <div class="column medthod_right" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_right">
                    <div class="w-100">
                        <h3><span>Branding & Positioning</span></h3>
                        <p>As the product goes to market our experienced and talented marketing team will help you in creating brand awareness and will try to position the product as per your vision in the mind of your target audience. We will create a full
                            proof branding strategy and we will follow it thoroughly to achieve the required goals.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="methodology_service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column medthod_left" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_left">
                    <img src="../../../../assets/images/rapinno-product-360/7.png" alt="Illustration showcasing the lift off of the brand using integrated marketing strategy ">
                </div>
            </div>
            <div class="column medthod_right" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_right">
                    <div class="w-100">
                        <h3><span>Integrated Marketing</span></h3>
                        <p>Our marketing team will also devise a complete marketing strategy which will include both digital and conventional marketing activities. The strategy will be strictly based on the AIDA model so that at each stage we can use multiple
                            analytical tools to measure and optimize strategy.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="methodology_service_row" fxLayout="row" fxLayout.xl="row" fxLayout.lg="row" fxLayout.md="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="25px">
            <div class="column medthod_left" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_left">
                    <img src="../../../../assets/images/rapinno-product-360/8.png" alt="Illustration showcasing the sales team working to create a buzz">
                </div>
            </div>
            <div class="column  medthod_right" fxFlex.xl="50" fxFlex.lg="50" fxFlex.md="50" fxFlex.sm="50" fxFlex.xs="100" fxFlexFill>
                <div class="methodology_service_right">
                    <div class="w-100">
                        <h3><span>Sales Support</span></h3>
                        <p>
                            In case you need assistance in sales activities like market research, inside sales, list building etc, our team can help you from scratch. By collaborating with the marketing team we will also strategize the whole sales activity with a motive to generate
                            maximum revenue.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<section class="section-cta">
    <img class="top-left-overlay" src="../../../../../assets/images/cta/top-left-overlay.svg" alt="">
    <!-- <img class="top-right-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  > -->
    <div class="cta-content">
        <h3 class="cta-heading">Lets Get Started With Your Idea</h3>
        <button [routerLink]="['/contact']" routerLinkActive="router-link-active" class="know-btn" mat-button> CONTACT
      NOW </button>
    </div>
    <!-- <img class="bottom-left-overlay" src="../../../../../assets/images/cta/cta-dots.svg" alt=""  > -->
    <img class="bottom-right-overlay" src="../../../../../assets/images/cta/bottom-right-overlay.svg" alt="">
</section>